import clsx from 'clsx'
import { HidingHeader } from 'hiding-header-react'
import Image from 'next/image'
import Link from 'next/link'
import { FunctionComponent, MouseEvent, useCallback, useMemo, useState } from 'react'
import type { ImageResult } from '../app/data/content/ImageFragment'
import type { CommonPageProps } from '../pages/[[...page]]'
import { isDefined } from '../utils/isDefined'
import { isSpecialKeyPressed } from '../utils/isSpecialKeyPressed'
import { Container } from './Container'
import { ContemberLink } from './ContemberLink'
import style from './Header.module.sass'
import { Submenu } from './Submenu'

export interface HeaderProps {
	colorful: boolean
	logo?: ImageResult
	logoOnWhite?: ImageResult
	primaryNavigationItems: NonNullable<
		Pick<CommonPageProps, 'header'>['header']
	>['primaryNavigationItems']
}

const primaryNavigationItemHasSubmenu = (item: HeaderProps['primaryNavigationItems'][number]) =>
	item.listNavigation || item.tiles

export const Header: FunctionComponent<HeaderProps> = ({
	colorful,
	primaryNavigationItems,
	logo,
	logoOnWhite,
}) => {
	const [isHome, setIsHome] = useState(true)
	const [submenuOpenedItem, setSubmenuOpenedItem] = useState<
		null | HeaderProps['primaryNavigationItems'][number]
	>(null)

	primaryNavigationItems = useMemo(
		() => primaryNavigationItems.filter((item) => isDefined(item.link)),
		[primaryNavigationItems]
	)

	const primaryNavigationItemsWithSubmenu = useMemo(
		() => primaryNavigationItems.filter((item) => primaryNavigationItemHasSubmenu(item)),
		[primaryNavigationItems]
	)

	const logoWithFallback = logo ?? logoOnWhite
	const logoOnWhiteWithFallback = logoOnWhite ?? logo

	const onLogoClick = useCallback(
		(event: MouseEvent<HTMLAnchorElement>) => {
			if (!isSpecialKeyPressed(event.nativeEvent) && submenuOpenedItem !== null) {
				event.preventDefault()
				setSubmenuOpenedItem(null)
				return
			}
		},
		[submenuOpenedItem]
	)

	return (
		<>
			<div
				className={clsx(
					style.gutter,
					isHome && style.is_home,
					submenuOpenedItem && style.is_submenuOpen
				)}
			/>
			<HidingHeader onHomeChange={(isHome) => setIsHome(isHome)}>
				<div
					className={clsx(
						style.inner,
						isHome && style.is_home,
						submenuOpenedItem && style.is_submenuOpen
					)}>
					<Container>
						<div className={style.content}>
							{colorful && isHome && logoWithFallback ? (
								<Link href="/">
									{/* eslint-disable-next-line */}
									<a
										className={style.logo}
										aria-label="Přejí na úvodní stránku"
										style={{
											'--header-logo-width': logoWithFallback.width,
											'--header-logo-height': logoWithFallback.height,
										}}
										onClick={onLogoClick}>
										<div className={clsx(style.logoIn)}>
											<Image
												src={logoWithFallback.url}
												alt={logoWithFallback.alt ?? ''}
												layout="fill"
											/>
										</div>
									</a>
								</Link>
							) : (
								logoOnWhiteWithFallback && (
									<Link href="/">
										{/* eslint-disable-next-line */}
										<a
											className={style.logo}
											aria-label="Přejí na úvodní stránku"
											style={{
												'--header-logo-width': logoOnWhiteWithFallback.width,
												'--header-logo-height': logoOnWhiteWithFallback.height,
											}}
											onClick={onLogoClick}>
											<div className={style.logoIn}>
												<Image
													src={logoOnWhiteWithFallback.url}
													alt={logoOnWhiteWithFallback.alt ?? ''}
													layout="fill"
												/>
											</div>
										</a>
									</Link>
								)
							)}
							<button
								type="button"
								className={style.menuToggle}
								onClick={() => {
									if (submenuOpenedItem) {
										setSubmenuOpenedItem(null)
									} else {
										setSubmenuOpenedItem(primaryNavigationItemsWithSubmenu[0] ?? null)
									}
								}}>
								{submenuOpenedItem ? 'Zpět >' : 'Menu'}
							</button>
							<nav className={style.navigation}>
								<ul className={style.navigationList}>
									{primaryNavigationItems.map((item) => (
										<li key={item.id} className={style.navigationItem}>
											{/* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */}
											<ContemberLink link={item.link!}>
												{/* eslint-disable-next-line */}
												<a
													className={clsx(
														style.navigationLink,
														item.link?.isHighlighted && [style.isHighlighted, style.view_fill]
													)}
													target={item.link?.targetBlank ? '_blank' : undefined}
													onClick={(event) => {
														if (
															isSpecialKeyPressed(event.nativeEvent) ||
															!primaryNavigationItemHasSubmenu(item)
														) {
															setSubmenuOpenedItem(null)
															return
														}
														event.preventDefault()

														if (item.id === submenuOpenedItem?.id) {
															setSubmenuOpenedItem(null)
														} else {
															setSubmenuOpenedItem(item)
														}
													}}>
													{item.link?.title}
												</a>
											</ContemberLink>
										</li>
									))}
								</ul>
							</nav>
						</div>
					</Container>
					<div className={clsx(style.submenu, submenuOpenedItem && style.is_open)}>
						<Submenu
							items={primaryNavigationItemsWithSubmenu}
							activeItemId={submenuOpenedItem?.id}
							onActiveItemChange={setSubmenuOpenedItem}
						/>
					</div>
				</div>
			</HidingHeader>
			<div className={clsx(style.gutter, isHome && style.is_home)} />
			<button
				className={clsx(style.submenuBackgroundColoser, submenuOpenedItem && style.is_open)}
				type="button"
				aria-label="Zavřít menu"
				onClick={() => setSubmenuOpenedItem(null)}
			/>
		</>
	)
}
