import type { InternalLinkResult } from '../app/data/content/InternalLinkFragment'
import { ContemberInternalLink } from './ContemberLink'
import style from './CourseAttribute.module.sass'

export interface CourseAttributeProps {
	label: string
	link?: InternalLinkResult
}

export const CourseAttribute: React.FunctionComponent<CourseAttributeProps> = ({
	label,
	link,
	children,
}) => {
	return (
		<div>
			<h4 className={style.label}>{label}</h4>
			<div className={style.text}>
				{link ? (
					<ContemberInternalLink link={link}>
						<a className={style.link}>{children}</a>
					</ContemberInternalLink>
				) : (
					children
				)}
			</div>
		</div>
	)
}
