import { useRouter } from 'next/dist/client/router'
import Script from 'next/script'
import React, { useEffect } from 'react'
import { getConfig } from '../app/next/config'
import type { CommonPageProps } from '../pages/[[...page]]'

type GoogleTagProps = Exclude<Pick<CommonPageProps, 'siteGroup' | 'locale'>, null>

export function GoogleTag(props: GoogleTagProps) {
	let GTM_ID: string

	if (props.siteGroup === 'plneVedomi' && props.locale === 'cs') {
		GTM_ID = getConfig().publicRuntimeConfig.PLNEVEDOMI_GTM_ID
	} else if (props.siteGroup === 'plneVedomi' && props.locale === 'en') {
		GTM_ID = getConfig().publicRuntimeConfig.MINDFULNESS_GTM_ID
	} else {
		GTM_ID = getConfig().publicRuntimeConfig.JOGAONLINE_GTM_ID
	}

	const router = useRouter()
	useEffect(() => {
		const handleRouteChange = (url: string) => {
			window.gtag('config', GTM_ID, { page_path: url })
		}
		router.events.on('routeChangeComplete', handleRouteChange)
		return () => {
			router.events.off('routeChangeComplete', handleRouteChange)
		}
	}, [GTM_ID, router.events])

	return (
		<>
			<Script
				strategy="afterInteractive"
				src={`https://www.googletagmanager.com/gtag/js?id=${GTM_ID}`}
			/>
			<Script id="google-tag" strategy="afterInteractive">
				{
					/* js */ `
				window.dataLayer = window.dataLayer || [];
				function gtag(){dataLayer.push(arguments);}
				gtag('js', new Date());

				gtag('config', '${GTM_ID}');
				`
				}
			</Script>
		</>
	)
}
