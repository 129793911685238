import type { CommonPageProps } from '../pages/[[...page]]'
import { ArticleHeading } from './ArticleHeading'
import { Breadcrumbs } from './Breadcrumbs'
import { Container } from './Container'
import { ContentRenderer } from './ContentRenderer'
import { Spacer } from './Spacer'
import { Wysiwyg } from './Wysiwyg'

export type GenericPageProps = Exclude<
	Pick<CommonPageProps['page'], 'genericPage'>['genericPage'],
	null
>

export const GenericPage: React.FunctionComponent<GenericPageProps> = ({
	headline,
	lead,
	content,
}) => {
	return (
		<>
			<Container>
				{headline && (
					<Breadcrumbs
						items={[
							{
								label: headline,
							},
						]}
					/>
				)}
			</Container>
			<Spacer break1000={28} />
			<ArticleHeading headline={headline}>{lead && <Wysiwyg source={lead} />}</ArticleHeading>
			<Container size="narrow" disableGutters>
				{content && <ContentRenderer content={content} />}
			</Container>
			<Spacer initial={80} break1000={160} />
		</>
	)
}
