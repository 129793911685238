import type { CourseLecturerTileResult } from '../app/data/content/CourseLecturerTileFragment'
import { ContemberInternalLink } from './ContemberLink'
import style from './CourseLecturerTile.module.sass'
import { TileImage } from './TileImage'

export interface CourseLecturerTileProps {
	data: CourseLecturerTileResult
}

export const CourseLecturerTile: React.FunctionComponent<CourseLecturerTileProps> = ({ data }) => {
	return (
		<ContemberInternalLink link={data.link}>
			<a className={style.wrapper}>
				<h2 className={style.name}>{data.name}</h2>
				<TileImage
					image={data.image}
					borderRadius="all"
					reversedHover
					sizes="(max-width: 1300px) 420px, 305px"
				/>
			</a>
		</ContemberInternalLink>
	)
}
