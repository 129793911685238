/* eslint-disable @typescript-eslint/no-explicit-any */
declare global {
	interface Window {
		DarujmeObject: string
		Darujme: (...args: any[]) => void
	}
}

export function DarujmeWidget(props: { token: string }) {
	const { token } = props

	return (
		<div style={{ marginInline: 'auto', width: '600px', marginBlock: '3rem' }}>
			<iframe
				title="Darujme.cz - widget"
				width="600"
				height="312"
				src={`https://www.darujme.cz/widget?token=${token}`}
				scrolling="no"
				name={`widget-${token}`}
				style={{
					width: '600px',
					maxWidth: '100%',
					overflow: 'hidden',
					border: '1px solid rgb(220, 220, 220)',
					boxShadow: 'rgba(0, 0, 0, 0.09) 0px 2px 18px 0px',
					height: '312px',
				}}></iframe>
		</div>
	)
}
