import { useTranslate } from '../app/contexts/TranslationsContext'
import type { CommonPageProps } from '../pages/[[...page]]'
import { ArticleHeading } from './ArticleHeading'
import { Breadcrumbs } from './Breadcrumbs'
import { Container } from './Container'
import { ContentRenderer } from './ContentRenderer'
import { OtherTiles } from './OtherTiles'
import { Spacer } from './Spacer'
import { Wysiwyg } from './Wysiwyg'

export type ArticlePageProps = Exclude<Pick<CommonPageProps['page'], 'article'>['article'], null>

export const ArticlePage: React.FunctionComponent<ArticlePageProps> = ({
	category,
	headline,
	lead,
	image,
	content,
	site,
	otherArticles,
}) => {
	const translate = useTranslate()

	return (
		<>
			<Container>
				<Breadcrumbs
					items={[
						{
							label: translate('articles'),
							link: site?.articleListPage?.link,
						},
						{
							label: headline || translate('article'),
						},
					]}
				/>
			</Container>
			<Spacer break1000={28} />
			<ArticleHeading category={category} headline={headline} image={image}>
				{lead && <Wysiwyg source={lead} />}
			</ArticleHeading>
			<Container size="narrow" disableGutters>
				{content && <ContentRenderer content={content} />}
			</Container>
			{otherArticles.length > 0 && (
				<Container>
					<Spacer initial={120} />
					<OtherTiles
						articles={{
							items: otherArticles,
							sectionTitle: {
								link: site?.articleListPage?.link,
							},
						}}
					/>
				</Container>
			)}
			<Spacer initial={80} break1000={160} />
		</>
	)
}
