import { Icon } from './Icon'
import style from './UndefinedImageTile.module.sass'

export const UndefinedImageTile: React.FunctionComponent = ({ children }) => {
	return (
		<div className={style.wrapper}>
			{children && <h2 className={style.title}>{children}</h2>}
			<div className={style.background}>
				<Icon name="jogaLogo" />
			</div>
		</div>
	)
}
