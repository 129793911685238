import clsx from 'clsx'
import type { InternalLinkResult } from '../app/data/content/InternalLinkFragment'
import { ContemberInternalLink } from './ContemberLink'
import style from './SectionTitle.module.sass'

interface SectionTitleProps {
	title: string
	link: InternalLinkResult
	alignment?: {
		break1000: 'center' | 'right'
	}
}

export const SectionTitle: React.FunctionComponent<SectionTitleProps> = ({
	title,
	link,
	alignment,
}) => {
	return (
		<div className={clsx(style.wrapper, style[`view_${alignment?.break1000}`])}>
			<ContemberInternalLink link={link}>
				<a>
					<h2 className={style.title}>{title}</h2>
				</a>
			</ContemberInternalLink>
		</div>
	)
}
