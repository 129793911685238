import Image from 'next/image'
import type { FunctionComponent } from 'react'
import type { PersonResult } from '../app/data/content/PersonFragment'
import { richTextToPlaintext } from '../libs/contember/richTextToPlaintext'
import { PageListTitle } from './PageListTitle'
import style from './TeamList.module.sass'
import { Wysiwyg } from './Wysiwyg'

export type TeamListProps = {
	title?: string
	people: Array<{
		id: string
		about?: string
		person?: PersonResult
	}>
}

export const TeamList: FunctionComponent<TeamListProps> = ({ title, people }) => {
	return (
		<section className={style.wrapper}>
			{title && <PageListTitle>{title}</PageListTitle>}
			<div className={style.people}>
				{people.map(({ id, person, about }) => {
					if (!person) {
						return
					}
					about = about && richTextToPlaintext(about) ? about : person.about // @TODO: memoize
					return (
						<div key={id} className={style.person}>
							<div className={style.personAvatar}>
								{person.image && (
									<Image
										src={person.image.url}
										alt={person.image.alt ?? person.name}
										sizes="100px"
										layout="fill"
										objectFit="cover"
									/>
								)}
							</div>
							<h3 className={style.personName}>{person.name}</h3>
							{about && (
								<div className={style.personAbout}>
									<Wysiwyg source={about} />
								</div>
							)}
							{person.email && (
								<div>
									<a className={style.personEmail} href={`mailto:${person.email}`}>
										{person.email}
									</a>
								</div>
							)}
							{person.phone && (
								<div>
									<a className={style.personPhone} href={`tel:${person.phone}`}>
										{person.phone}
									</a>
								</div>
							)}
						</div>
					)
				})}
			</div>
		</section>
	)
}
