import type { CommonPageProps } from '../pages/[[...page]]'
import { Breadcrumbs } from './Breadcrumbs'
import { Container } from './Container'
import { CourseTiles } from './CourseTiles'
import { PageListTitle } from './PageListTitle'
import { Spacer } from './Spacer'

export type CourseListPageProps = Exclude<
	Pick<CommonPageProps['page'], 'courseList'>['courseList'],
	null
>

export const CourseListPage: React.FunctionComponent<CourseListPageProps> = ({
	title = 'Kurzy',
	courses,
}) => {
	return (
		<Container>
			<Breadcrumbs
				items={[
					{
						label: title,
					},
				]}
			/>
			<Spacer initial={32} break1000={80} />
			<PageListTitle>{title}</PageListTitle>
			<Spacer initial={24} />
			<CourseTiles courses={courses} />
			<Spacer initial={60} break1000={80} />
		</Container>
	)
}
