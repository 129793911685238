import type { InternalLinkResult } from '../app/data/content/InternalLinkFragment'
import type { PoseCategoryChipResult } from '../app/data/content/PoseCategoryChipFragment'
import { Chip } from './Chip'
import { makePoseCategoryAnchor } from './GroupedPoseTiles'
import style from './PoseCategoryChips.module.sass'

export interface PoseCategoryChipsProps {
	categories: PoseCategoryChipResult[]
	link: InternalLinkResult | undefined
}

export const PoseCategoryChips: React.FunctionComponent<PoseCategoryChipsProps> = ({
	categories,
	link,
}) => {
	return (
		<div className={style.wrapper}>
			{categories.map((category) => (
				<div key={category.id} className={style.item}>
					<Chip
						icon={category.icon}
						href={
							link && category.label && `${link.url}#${makePoseCategoryAnchor(category.label)}`
						}>
						{category.label}
					</Chip>
				</div>
			))}
		</div>
	)
}
