/* eslint-disable react/jsx-no-target-blank */
import { ReferenceRendererProps, RichTextRenderer } from '@contember/react-client'
import type { FunctionComponent } from 'react'
import React from 'react'
import { useTranslate } from '../app/contexts/TranslationsContext'
import type { ContentBlockResult } from '../app/data/content/ContentBlockFragment'
import type { ContentResult } from '../app/data/content/ContentFragment'
import type { SiteSpecificContentResult } from '../app/data/content/SiteSpecificContentFragment'
import { useContentRendererCopyPasteBugWorkaround } from '../utils/useContentRendererCopyPasteBugWorkaround'
import { ArticleTiles, ArticleTilesProps } from './ArticleTiles'
import { Container } from './Container'
import { ContentImage } from './ContentImage'
import { CourseTiles } from './CourseTiles'
import { DarujmeWidget } from './DarujmeWidget'
import { PoseBlock } from './PoseBlock'
import { SectionTitle } from './SectionTitle'
import { Spacer } from './Spacer'
import { SubscribeBannerNewsletter } from './SubscribeBannerNewsletter'
import { TeamList } from './TeamList'
import { Wysiwyg } from './Wysiwyg'

export interface ContentRendererProps {
	content: ContentResult
	siteSpecificContent?: SiteSpecificContentResult
	containerDisableGutters?: boolean
}

type Block = ReferenceRendererProps<ContentBlockResult['references'][number]>

const standaloneTypes = ['reference']
const nestedTypes = ['listItem']

export const ContentRenderer: FunctionComponent<ContentRendererProps> = ({
	content,
	siteSpecificContent,
	containerDisableGutters = false,
}) => {
	const articlesReferenceEndOffset: {
		[referenceId: string]: number
	} = {}
	const articlesReferenceOffsetColumn: {
		[referenceId: string]: ArticleTilesProps['offsetColumn']
	} = {}

	const blocks = useContentRendererCopyPasteBugWorkaround(content.blocks)
	const translate = useTranslate()

	return (
		<div>
			<RichTextRenderer
				blocks={blocks}
				sourceField="json"
				renderElement={(element) => {
					const { type } = element.element
					if (standaloneTypes.includes(type) || nestedTypes.includes(type)) {
						return element.fallback
					}
					if (type === 'anchor') {
						if (
							element.element.href.includes('https://') ||
							element.element.href.includes('http://')
						) {
							return (
								<a href={element.element.href} target="_blank">
									{element.children}
								</a>
							)
						} else {
							return <a href={element.element.href}>{element.children}</a>
						}
					}
					return (
						<Container disableGutters={containerDisableGutters}>
							<Wysiwyg>{element.fallback}</Wysiwyg>
						</Container>
					)
				}}
				referenceRenderers={{
					rawHtml: function RawHtml({ reference }: Block) {
						const tokenMatch = reference.primaryText?.match(/data-darujme-widget-token="([^"]+)"/)
						const token = tokenMatch ? tokenMatch[1] : null

						if (token) {
							return <DarujmeWidget token={token} />
						}

						return (
							<section>
								<Spacer initial={18} break768={26} />
								<Container size="narrow" disableGutters={containerDisableGutters}>
									<Wysiwyg>
										<div dangerouslySetInnerHTML={{ __html: reference.primaryText ?? '' }} />
									</Wysiwyg>
								</Container>
								<Spacer initial={40} break768={60} />
							</section>
						)
					},
					fundraisingToken: function fundraisingToken({ reference }) {
						return (
							reference.primaryText && (
								<section>
									<DarujmeWidget token={reference.primaryText} />
								</section>
							)
						)
					},
					image: function Image({ reference }: Block) {
						return reference.image && <ContentImage image={reference.image} />
					},
					poses: function Poses(block: Block) {
						if (!siteSpecificContent) {
							return null
						}
						return (
							<section>
								<Spacer initial={80} break1000={160} />
								<Container size="narrow" disableGutters={containerDisableGutters}>
									<PoseBlock
										title={block.reference.primaryText || translate('posesLong')}
										link={
											siteSpecificContent.poseListPage && siteSpecificContent.poseListPage.link
												? siteSpecificContent.poseListPage.link
												: undefined
										}
										categories={siteSpecificContent.poseCategories}
									/>
								</Container>
							</section>
						)
					},
					courses: function Courses(block: Block) {
						if (!siteSpecificContent) {
							return null
						}
						return (
							<section>
								<Spacer initial={60} break768={120} />
								<Container disableGutters={containerDisableGutters}>
									{siteSpecificContent?.courseListPage?.link && (
										<>
											<SectionTitle
												title={block.reference.primaryText || 'Naše kurzy'}
												link={siteSpecificContent.courseListPage.link}
												alignment={{ break1000: 'center' }}
											/>
											<Spacer initial={30} break1000={70} />
										</>
									)}
									<CourseTiles courses={siteSpecificContent.courses} />
								</Container>
							</section>
						)
					},
					articles: function Articles(block: Block) {
						const { highlightFirstArticle, linkToMoreArticles } = block.reference
						if (!siteSpecificContent) {
							return null
						}
						const numberOfArticles = highlightFirstArticle ? 5 : 4
						if (!(block.reference.id in articlesReferenceEndOffset)) {
							articlesReferenceEndOffset[block.reference.id] =
								Math.max(0, ...Object.values(articlesReferenceEndOffset)) + numberOfArticles
						}
						const endOffset = articlesReferenceEndOffset[block.reference.id]
						if (!(block.reference.id in articlesReferenceOffsetColumn)) {
							articlesReferenceOffsetColumn[block.reference.id] =
								Object.keys(articlesReferenceOffsetColumn).length % 2 === 0 ? 'left' : 'right'
						}
						const offsetColumn = articlesReferenceOffsetColumn[block.reference.id]
						return (
							<section>
								<Container disableGutters={containerDisableGutters}>
									{!highlightFirstArticle && <Spacer initial={80} break1000={140} />}
									<ArticleTiles
										articles={siteSpecificContent.articles.slice(
											endOffset - numberOfArticles,
											endOffset
										)}
										hasMain={highlightFirstArticle}
										offsetColumn={offsetColumn}
									/>
									{linkToMoreArticles && siteSpecificContent.articleListPage?.link && (
										<>
											<Spacer initial={40} break768={80} />
											<SectionTitle
												title={block.reference.primaryText || 'Více článků'}
												link={siteSpecificContent.articleListPage.link}
												alignment={{ break1000: 'center' }}
											/>
										</>
									)}
								</Container>
							</section>
						)
					},
					subscribeBanner: function SubscribeBanner() {
						if (!siteSpecificContent?.subscribeBox) {
							return
						}
						const {
							title,
							text,
							logo,
							shapeColorA,
							shapeColorB,
							shapeColorC,
							emailLabel,
							submitLabel,
							externalMailingListId,
						} = siteSpecificContent.subscribeBox
						return (
							<section>
								<Spacer initial={60} break1000={100} />
								<SubscribeBannerNewsletter
									formAction={externalMailingListId}
									title={title}
									logo={logo}
									shapeColorA={shapeColorA}
									shapeColorB={shapeColorB}
									shapeColorC={shapeColorC}
									emailLabel={emailLabel}
									submitLabel={submitLabel}>
									{text && <RichTextRenderer source={text} />}
								</SubscribeBannerNewsletter>
							</section>
						)
					},
					team: function Team(block: Block) {
						return (
							<Container size="narrow" disableGutters={containerDisableGutters}>
								<TeamList
									title={block.reference.primaryText}
									people={block.reference.people?.items ?? []}
								/>
							</Container>
						)
					},
				}}
			/>
		</div>
	)
}
