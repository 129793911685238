import { useTranslate } from '../app/contexts/TranslationsContext'
import type { CommonPageProps } from '../pages/[[...page]]'
import { ArticleTiles } from './ArticleTiles'
import { Breadcrumbs } from './Breadcrumbs'
import { Container } from './Container'
import { PageListTitle } from './PageListTitle'
import { Spacer } from './Spacer'
import { Wysiwyg } from './Wysiwyg'

export type ArticleCategoryPageProps = Exclude<
	Pick<CommonPageProps['page'], 'articleCategory'>['articleCategory'],
	null
>

export const ArticleCategoryPage: React.FunctionComponent<ArticleCategoryPageProps> = ({
	label,
	lead,
	site,
	articles,
}) => {
	const translate = useTranslate()

	return (
		<Container>
			<Breadcrumbs
				items={[
					{
						label: translate('articles'),
						link: site?.articleListPage?.link,
					},
					{
						label: label || translate('category'),
					},
				]}
			/>
			<Spacer initial={32} break1000={80} />
			<PageListTitle>{label}</PageListTitle>
			<Spacer initial={8} break1000={16} />
			{lead && (
				<>
					<Wysiwyg source={lead} />
					<Spacer initial={32} break1000={0} />
				</>
			)}
			<Spacer initial={32} />
			<ArticleTiles articles={articles} />
			<Spacer initial={40} break1000={100} />
		</Container>
	)
}
