import { useTranslate } from '../app/contexts/TranslationsContext'
import type { ArticleTileResult } from '../app/data/content/ArticleTileFragment'
import type { CourseTileResult } from '../app/data/content/CourseTileFragment'
import type { InternalLinkResult } from '../app/data/content/InternalLinkFragment'
import type { PoseTileResult } from '../app/data/content/PoseTileFragment'
import { ArticleTiles } from './ArticleTiles'
import { CourseTiles } from './CourseTiles'
import style from './OtherTiles.module.sass'
import { PoseTiles } from './PoseTiles'
import { SectionTitle } from './SectionTitle'

export interface OtherTilesProps {
	poses?: {
		sectionTitle: SectionTitle
		items: PoseTileResult[]
	}
	articles?: {
		sectionTitle: SectionTitle
		items: ArticleTileResult[]
	}
	courses?: {
		sectionTitle: SectionTitle
		items: CourseTileResult[]
	}
}

interface SectionTitle {
	title?: string
	link?: InternalLinkResult
}

export const OtherTiles: React.FunctionComponent<OtherTilesProps> = ({
	poses,
	articles,
	courses,
}) => {
	const translate = useTranslate()

	return (
		<div className={style.wrapper}>
			{poses && (
				<section className={style.section}>
					<PoseTiles poses={poses.items} />
					{poses.sectionTitle && poses.sectionTitle.link && (
						<div className={style.title}>
							<SectionTitle
								link={poses.sectionTitle.link}
								title={poses.sectionTitle.title ?? translate('posesMore')}
								alignment={{ break1000: 'right' }}
							/>
						</div>
					)}
				</section>
			)}
			{articles && (
				<section className={style.section}>
					<ArticleTiles articles={articles.items} offsetColumn="right" />
					{articles && articles.sectionTitle.link && (
						<div className={style.title}>
							<SectionTitle
								link={articles.sectionTitle.link}
								title={articles.sectionTitle.title ?? 'Více článků'}
								alignment={{ break1000: 'right' }}
							/>
						</div>
					)}
				</section>
			)}
			{courses && (
				<section className={style.section}>
					<CourseTiles courses={courses.items} />
					{courses.sectionTitle && courses.sectionTitle.link && (
						<div className={style.title}>
							<SectionTitle
								link={courses.sectionTitle.link}
								title={courses.sectionTitle.title ?? 'Další kurzy'}
								alignment={{ break1000: 'right' }}
							/>
						</div>
					)}
				</section>
			)}
		</div>
	)
}
