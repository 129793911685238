import clsx from 'clsx'
import { Icon } from './Icon'
import style from './PlayButton.module.sass'

interface PlayButtonProps {
	size?: 'small' | 'middle' | 'big'
	position?: undefined | 'center'
	responsive?: boolean
}

export const PlayButton: React.FunctionComponent<PlayButtonProps> = ({
	size = 'middle',
	position,
	responsive,
}) => {
	return (
		<div
			className={clsx(
				style.wrapper,
				style.view_size,
				style[`view_size_${size}`],
				position && style.view_position,
				position && style[`view_position_${position}`],
				responsive && style.view_responsive
			)}>
			<div className={style.main}>
				<div className={style.icon}>
					<Icon name="playButton" />
				</div>
			</div>
		</div>
	)
}
