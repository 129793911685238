import type { CommonPageProps } from '../pages/[[...page]]'
import { ContentRenderer } from './ContentRenderer'
import { Hero } from './Hero'
import { Spacer } from './Spacer'

export type HomePageProps = Exclude<Pick<CommonPageProps['page'], 'homepage'>['homepage'], null>

export const HomePage: React.FunctionComponent<HomePageProps> = ({
	subtitle,
	content,
	siteSpecificContent,
	heroTiles,
	titlePartA,
	titlePartB,
	titlePartC,
	titlePartD,
	locale,
}) => {
	return (
		<>
			<Hero
				tiles={heroTiles}
				titlePartA={titlePartA}
				titlePartB={titlePartB}
				titlePartC={titlePartC}
				titlePartD={titlePartD}
				locale={locale}>
				{subtitle}
			</Hero>
			{content && (
				<>
					<Spacer initial={48} break1000={32} />
					<ContentRenderer content={content} siteSpecificContent={siteSpecificContent} />
				</>
			)}
			<Spacer initial={100} />
		</>
	)
}
