import Image from 'next/image'
import Link from 'next/link'
import type { FunctionComponent } from 'react'
import { useUrlWithoutOrigin } from '../app/contexts/OriginContext'
import type { ImageResult } from '../app/data/content/ImageFragment'
import style from './Chip.module.sass'

export interface ChipProps {
	icon?: ImageResult
	href?: string
}

export const Chip: FunctionComponent<ChipProps> = ({ icon, href, children }) => {
	const inner = (
		<>
			{icon && (
				<span
					className={style.icon}
					style={{
						['--chip-icon-aspectRatio']: (icon.width || 1) / (icon.height || 1),
					}}>
					<Image src={icon.url} alt={icon.alt ?? ''} layout="fill" sizes="60px" />
				</span>
			)}
			<span className={style.text}>{children}</span>
		</>
	)

	const url = useUrlWithoutOrigin(href ?? '')

	if (url) {
		return (
			<Link href={url}>
				<a className={style.wrapper}>{inner}</a>
			</Link>
		)
	}

	return <div className={style.wrapper}>{inner}</div>
}
