import Image from 'next/image'
import { useTranslate } from '../app/contexts/TranslationsContext'
import type { CommonPageProps } from '../pages/[[...page]]'
import { Breadcrumbs } from './Breadcrumbs'
import { Container } from './Container'
import { ContentRenderer } from './ContentRenderer'
import { CourseAttribute } from './CourseAttribute'
import style from './CourseLecturerPage.module.sass'
import { lessonsCountLabel } from './CourseTile'
import { CourseTiles } from './CourseTiles'
import { Spacer } from './Spacer'
import { UndefinedImageTile } from './UndefinedImageTile'

export type CourseLecturerPageProps = Exclude<
	Pick<CommonPageProps['page'], 'courseLecturer'>['courseLecturer'],
	null
>

export const CourseLecturerPage: React.FunctionComponent<CourseLecturerPageProps> = ({
	name,
	image,
	content,
	courses,
	site,
}) => {
	const translate = useTranslate()

	return (
		<>
			<Container>
				<Breadcrumbs
					items={[
						{
							label: translate('lecturers'),
							link: site?.courseLecturerListPage?.link,
						},
						{
							label: name || translate('lecturer'),
						},
					]}
				/>
				<Spacer initial={32} break1000={80} />
				<section className={style.main}>
					<h1 className={style.name}>{name}</h1>

					<div className={style.imageColumn}>
						<div className={style.image}>
							{image ? (
								<Image
									src={image.url}
									alt={image.alt ?? ''}
									layout="fill"
									objectFit="cover"
									sizes="480px"
								/>
							) : (
								<UndefinedImageTile>{name}</UndefinedImageTile>
							)}
						</div>
					</div>

					<div>
						<CourseAttribute label="Počet kurzů">
							{lessonsCountLabel('course', courses.length)}
						</CourseAttribute>
					</div>

					{content && (
						<div className={style.content}>
							<ContentRenderer content={content} />
						</div>
					)}
				</section>

				{courses.length > 0 && (
					<section>
						<Spacer initial={24} />
						<h2 className={style.sectionTitle}>Lekce, které {name} vyučuje</h2>
						<CourseTiles courses={courses} />
					</section>
				)}
				<Spacer initial={80} break1000={160} />
			</Container>
		</>
	)
}
