// Based on https://gist.github.com/Majkl578/947036
export const webalize = (str: string) => {
	const charlist = [
		['Á', 'A'],
		['Ä', 'A'],
		['Č', 'C'],
		['Ç', 'C'],
		['Ď', 'D'],
		['É', 'E'],
		['Ě', 'E'],
		['Ë', 'E'],
		['Í', 'I'],
		['Ň', 'N'],
		['Ó', 'O'],
		['Ö', 'O'],
		['Ř', 'R'],
		['Š', 'S'],
		['Ť', 'T'],
		['Ú', 'U'],
		['Ů', 'U'],
		['Ü', 'U'],
		['Ý', 'Y'],
		['Ž', 'Z'],
		['á', 'a'],
		['ä', 'a'],
		['č', 'c'],
		['ç', 'c'],
		['ď', 'd'],
		['é', 'e'],
		['ě', 'e'],
		['ë', 'e'],
		['í', 'i'],
		['ň', 'n'],
		['ó', 'o'],
		['ö', 'o'],
		['ř', 'r'],
		['š', 's'],
		['ť', 't'],
		['ú', 'u'],
		['ů', 'u'],
		['ü', 'u'],
		['ý', 'y'],
		['ž', 'z'],
	]
	for (const i in charlist) {
		const re = new RegExp(charlist[i][0], 'g')
		str = str.replace(re, charlist[i][1])
	}

	str = str.replace(/[^a-z0-9]/gi, '-')
	str = str.replace(/\-+/g, '-')
	if (str[0] == '-') {
		str = str.substring(1, str.length)
	}
	if (str[str.length - 1] == '-') {
		str = str.substring(0, str.length - 1)
	}

	return str.toLowerCase()
}
