import React from 'react'

export const OriginContext = React.createContext('')

export function useUrlWithoutOrigin(url: string) {
	const context = React.useContext(OriginContext)
	if (typeof url === 'string') {
		return String(url.startsWith(context) ? url.replace(context, '') : url)
	}
	return ''
}
