import type { CourseTileResult } from '../app/data/content/CourseTileFragment'
import { CourseTile } from './CourseTile'
import style from './CourseTiles.module.sass'
export interface CourseTilesProps {
	courses: CourseTileResult[]
}

export const CourseTiles: React.FunctionComponent<CourseTilesProps> = ({ courses }) => {
	return (
		<div className={style.wrapper}>
			<div className={style.main}>
				{courses.map((course) => (
					<div className={style.item} key={course.id}>
						<CourseTile data={course} />
					</div>
				))}
			</div>
		</div>
	)
}
