import clsx from 'clsx'
import { FunctionComponent, useRef, useState } from 'react'
import { useTranslate } from '../app/contexts/TranslationsContext'
import { apiFetcher } from '../utils/apiFetcher'
import { formDataToObject } from '../utils/formDataToObject'
import { Container } from './Container'
import style from './CourseSubsribeBannerNewsletter.module.sass'
import { EmailNewsletter } from './EmailNewsletter'

export interface CourseSubsribeBannerNewsletterProps {
	courseId: string
}

export const CourseSubsribeBannerNewsletter: FunctionComponent<
	CourseSubsribeBannerNewsletterProps
> = ({ courseId, children }) => {
	const formRef = useRef<HTMLFormElement>(null)
	const [isSubmitting, setIsSubmitting] = useState(false)
	const [message, setMessage] = useState<null | string>(null)
	const translate = useTranslate()

	return (
		<form
			ref={formRef}
			className={clsx(style.wrapper, message && style.has_message)}
			onSubmit={async (event) => {
				event.preventDefault()
				if (!formRef.current || isSubmitting) {
					return
				}
				setMessage('…')
				setIsSubmitting(true)

				const formData = new FormData(formRef.current)

				try {
					const { message } = await apiFetcher(
						'subscribeToCourse',
						// eslint-disable-next-line @typescript-eslint/no-explicit-any
						formDataToObject(formData) as any
					)
					formRef.current.reset()
					setMessage(message)
				} catch {
					alert(translate('error'))
				}
				setIsSubmitting(false)
			}}>
			<input type="hidden" name="courseId" value={courseId} />
			<Container>
				<div className={style.content}>
					<h3 className={style.title}>{children}</h3>
					<div className={style.subscribe}>
						<EmailNewsletter
							buttonLabel={translate('signIn')}
							buttonStyle="fill"
							isSubmitting={isSubmitting}
						/>
					</div>
				</div>
			</Container>
			<button type="button" className={style.message} onClick={() => setMessage(null)}>
				{message}
			</button>
		</form>
	)
}
