import { useMemo } from 'react'
import type { CommonPageProps } from '../pages/[[...page]]'
import { Footer } from './Footer'
import { Header } from './Header'
import style from './Layout.module.sass'
import { Seo } from './Seo'

export type LayoutProps = Pick<CommonPageProps, 'header' | 'footer' | 'seo' | 'canonicalUrl'> & {
	colorfulHeader: boolean
}

export const Layout: React.FunctionComponent<LayoutProps> = ({
	colorfulHeader,
	header,
	footer,
	seo,
	canonicalUrl,
	children,
}) => {
	const mergedSeo = useMemo(() => {
		const result = seo.base
		for (const [key, value] of Object.entries(seo.pageSpecific)) {
			if (value) {
				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				// @ts-ignore
				result[key] = value
			}
		}
		return result
	}, [seo])

	return (
		<>
			<Seo siteCode={seo.siteCode} seo={mergedSeo} canonicalUrl={canonicalUrl} />
			<div className={style.wrapper}>
				<Header
					colorful={colorfulHeader}
					logo={header?.logo}
					logoOnWhite={header?.logoOnWhite}
					primaryNavigationItems={header?.primaryNavigationItems || []}
				/>
				<div className={style.main}>{children}</div>
				<div className={style.footer}>{footer && <Footer data={footer} />}</div>
			</div>
		</>
	)
}
