import clsx from 'clsx'
import type { CourseTileResult } from '../app/data/content/CourseTileFragment'
import { ContemberInternalLink } from './ContemberLink'
import style from './CourseTile.module.sass'
import { PlayButton } from './PlayButton'
import { TileImage } from './TileImage'
export interface CourseTileProps {
	data: CourseTileResult
}

export const lessonsCountLabel = (type: 'lesson' | 'course', count: number) => {
	// @TODO: translation?
	if (type === 'lesson') {
		if (count >= 1 && count <= 4) {
			return `${count} lekce`
		}
		return `${count} lekcí`
	} else {
		if (count === 1) {
			return `${count} kurz`
		} else if (count >= 2 && count <= 4) {
			return `${count} kurzy`
		}
		return `${count} kurzů`
	}
}

export const CourseTile: React.FunctionComponent<CourseTileProps> = ({
	data: { headline, shortLead, image, link, paginateLessons, difficulty },
}) => {
	return (
		<ContemberInternalLink link={link}>
			<a className={style.wrapper}>
				<div className={style.main}>
					{image && (
						<div className={style.image}>
							<TileImage image={image} borderRadius="top" sizes="290px" />
							<PlayButton position="center" size="middle" responsive />
							<div className={style.labels}>
								{difficulty && (
									<div className={clsx(style.label, style.view_difficulty)}>{difficulty}</div>
								)}
								{paginateLessons.pageInfo.totalCount > 0 && (
									<div className={clsx(style.label, style.view_lessonsCount)}>
										{lessonsCountLabel('lesson', paginateLessons.pageInfo.totalCount)}
									</div>
								)}
							</div>
						</div>
					)}
					<div className={style.content}>
						<h3 className={style.headline}>{headline}</h3>
						<div className={style.shortLead}>{shortLead}</div>
						<div className={style.button}>Přihlásit se</div>{' '}
						{/* TODO: button or just plain text ? */}
					</div>
				</div>
			</a>
		</ContemberInternalLink>
	)
}
