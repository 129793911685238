import clsx from 'clsx'
import { useRouter } from 'next/dist/client/router'
import Link from 'next/link'
import { useCallback, useEffect } from 'react'
import { useUrlWithoutOrigin } from '../app/contexts/OriginContext'
import { useTranslate } from '../app/contexts/TranslationsContext'
import type { CommonPageProps } from '../pages/[[...page]]'
import { useIsFirstRender } from '../utils/useIsFirstRender'
import { Breadcrumbs } from './Breadcrumbs'
import { Container } from './Container'
import { ContentRenderer } from './ContentRenderer'
import { CourseAttribute } from './CourseAttribute'
import style from './CoursePage.module.sass'
import { CourseSubsribeBannerNewsletter } from './CourseSubscribeBannerNewsletter'
import { lessonsCountLabel } from './CourseTile'
import { Icon } from './Icon'
import { OtherTiles } from './OtherTiles'
import { Spacer } from './Spacer'
import { Video } from './Video'
import { Wysiwyg } from './Wysiwyg'

export type CoursePageProps = Exclude<Pick<CommonPageProps['page'], 'course'>['course'], null>

export const CoursePage: React.FunctionComponent<CoursePageProps> = ({
	id,
	headline,
	courses,
	site,
	lessons,
	teaser,
	difficulty,
	lecturer,
	content,
	lead,
	link,
	numberOfUnlockedLessons,
	unlockKey,
	openedLesson,
	lessonIntervalInDays,
}) => {
	headline = openedLesson?.headline ?? headline ?? 'Kurz'
	lead = openedLesson?.lead ?? lead
	const video = openedLesson?.video ?? teaser

	const isFirstRender = useIsFirstRender()
	const router = useRouter()
	const translate = useTranslate()

	const createUnlockUrl = useCallback(
		(unlockKey?: string, lessonId?: string) => {
			const url = new URL(link?.url ?? '')
			if (unlockKey) {
				url.searchParams.set('key', unlockKey)
			}
			if (lessonId) {
				url.searchParams.set('lesson', lessonId)
			}
			return url.toString()
		},
		[link]
	)

	useEffect(() => {
		const localStorageKey = `course-key-${id}`
		if (unlockKey) {
			localStorage.setItem(localStorageKey, unlockKey.key)
		} else {
			const storedKey = localStorage.getItem(localStorageKey)
			if (storedKey) {
				router.push(createUnlockUrl(storedKey))
			}
		}
	}, [createUnlockUrl, id, router, unlockKey])

	return (
		<>
			<Container>
				<Breadcrumbs
					items={[
						{
							label: 'Kurzy',
							link: site?.courseListPage?.link,
						},
						{
							label: headline,
						},
					]}
				/>
			</Container>

			<section>
				<Container disableGutters>
					{video && (
						<div className={style.video}>
							<Video
								videoId={video.videoId}
								aspectRatioMobile={375 / 230}
								aspectRatioDesktop={1281 / 570}
							/>
						</div>
					)}
				</Container>
				<Container>
					<div className={style.content}>
						<div className={style.attributes}>
							{difficulty && (
								<div className={style.attribute}>
									<CourseAttribute label={translate('difficulty')}>{difficulty}</CourseAttribute>
								</div>
							)}
							{lessons.length > 0 && (
								<div className={style.attribute}>
									<CourseAttribute label="Rozsah">
										{lessonsCountLabel('lesson', lessons.length)}
									</CourseAttribute>
								</div>
							)}
							{lecturer?.name && (
								<div className={style.attribute}>
									{/* @TODO: use link */}
									<CourseAttribute label="Lektor" link={lecturer.link}>
										{lecturer.name}
									</CourseAttribute>
								</div>
							)}
							{numberOfUnlockedLessons === 0 && lessons.length > 0 && (
								<section className={style.newsletter}>
									{/* TODO: data from contember (?) + form */}
									<CourseSubsribeBannerNewsletter courseId={id}>
										Všechny lekce Vám zašleme na e‑mail zdarma
									</CourseSubsribeBannerNewsletter>
								</section>
							)}
							{numberOfUnlockedLessons > 0 && unlockKey && (
								<Container>
									<div className={style.lessons}>
										<h3 className={style.lessonsTitle}>Všechny lekce</h3>
										<ul className={style.lessonsList}>
											{lessons.map((lesson, i) => {
												const isLocked = !(link && i < numberOfUnlockedLessons)
												return (
													<li
														key={lesson.id}
														className={clsx(style.lessonsItem, isLocked && style.is_locked)}>
														{isLocked ? (
															<div className={style.lessonsLocked}>
																<Icon name="lock" /> {lesson.headline}
																{!isFirstRender && (
																	<>
																		{' '}
																		(
																		{(() => {
																			const date = new Date(unlockKey.startDate)
																			date.setDate(date.getDate() + i * lessonIntervalInDays)
																			return date.toLocaleDateString('cs-CZ')
																		})()}
																		)
																	</>
																)}
															</div>
														) : (
															<CourseLink href={createUnlockUrl(unlockKey.key, lesson.id)}>
																{lesson.headline}
															</CourseLink>
														)}
													</li>
												)
											})}
										</ul>
									</div>
								</Container>
							)}
						</div>
						<div className={style.description}>
							<h2 className={style.headline}>{headline}</h2>
							{lead && (
								<div className={style.lead}>
									<Wysiwyg source={lead} />
								</div>
							)}

							{content && <ContentRenderer content={content} containerDisableGutters />}
						</div>
					</div>
				</Container>
			</section>

			{courses.length > 0 && (
				<Container>
					<Spacer initial={120} />
					<OtherTiles
						courses={{
							items: courses,
							sectionTitle: {
								link: site?.courseListPage?.link,
							},
						}}
					/>
				</Container>
			)}

			<Spacer initial={60} break768={80} />
		</>
	)
}

export const CourseLink: React.FunctionComponent<{ href: string; children: React.ReactNode }> = ({
	href,
	children,
}) => {
	const url = useUrlWithoutOrigin(href)

	return (
		<Link href={url}>
			<a className={style.lessonsLink}>{children}</a>
		</Link>
	)
}
