import clsx from 'clsx'
import Image from 'next/image'
import type { FunctionComponent } from 'react'
import type { TileListResult } from '../app/data/content/TileListFragment'
import { Container } from './Container'
import style from './Hero.module.sass'
import { HeroTiles } from './HeroTiles'

export interface HeroProps {
	tiles?: TileListResult
	titlePartA?: string
	titlePartB?: string
	titlePartC?: string
	titlePartD?: string
	locale: string
}

export const Hero: FunctionComponent<HeroProps> = ({
	tiles,
	titlePartA,
	titlePartB,
	titlePartC,
	titlePartD,
	locale,
	children,
}) => {
	return (
		<div className={clsx(style.wrapper, style[`view_locale_${locale}`])}>
			<Container>
				<div className={style.main}>
					<div className={style.background}>
						<div className={style.green}>
							<div className={style.isDesktopBackground}>
								<svg
									xmlns="http://www.w3.org/2000/svg"
									viewBox="0 0 1936.7 1237.2"
									width="100%"
									height="100%"
									fill="none">
									<path
										d="M906 1131.8c-580.7 141.5-894.3-43.5-894.3-264.4 15.2-298.5 348-464.2 620.8-533.4 452.8-115 1099-141.5 1280.4 142 148.8 232.4-426.3 514.4-1007 655.8z"
										fill="#a1d8bf"
									/>
								</svg>
							</div>
							<div className={style.isMobileBackground}>
								<svg
									xmlns="http://www.w3.org/2000/svg"
									viewBox="0 0 1719.7 1237.2"
									width="100%"
									height="100%"
									fill="none">
									<path
										d="M828.2 1037.8C325.9 1179.3 54.7 994.3 54.7 773.4c13.2-298.5 301-464.2 537-533.4C983.3 125 1542.2 98.5 1699 382c128.7 232.4-368.7 514.4-871 655.8z"
										fill="#a1d8bf"
									/>
								</svg>
							</div>
						</div>
						<div className={style.purple}>
							<div className={style.isDesktopBackground}>
								<svg
									xmlns="http://www.w3.org/2000/svg"
									viewBox="0 0 1936.7 1237.2"
									width="100%"
									height="100%"
									fill="none">
									<path
										d="M951.9 546c303 241.9 240.3 503.3 48.9 613.8-263.3 141-510.2 44.7-655-67.6C105.4 906-118.4 571.2 70.8 332c155-196.4 578-27.8 881 214.1z"
										fill="#a79fcd"
									/>
								</svg>
							</div>
							<div className={style.isMobileBackground}>
								<svg
									xmlns="http://www.w3.org/2000/svg"
									viewBox="0 0 1719.7 1237.2"
									width="100%"
									height="100%"
									fill="none">
									<path
										d="M951.9 546c303 241.9 240.3 503.3 48.9 613.8-263.3 141-510.2 44.7-655-67.6C105.4 906-118.4 571.2 70.8 332c155-196.4 578-27.8 881 214.1z"
										fill="#a79fcd"
									/>
								</svg>
							</div>
						</div>
						<div className={style.turquoise}>
							<div className={style.isDesktopBackground}>
								<svg
									xmlns="http://www.w3.org/2000/svg"
									viewBox="0 0 1936.7 1237.2"
									width="100%"
									height="100%"
									fill="none">
									<path
										d="M150.7 303.5C306-52 574.7-59 731 97.3c204.4 217.8 175.3 481.3 104.3 650.2C717.5 1028 452.2 1330.7 172 1210c-230-99-176.5-551.2-21.3-906.5z"
										fill="#7dc6ca"
									/>
								</svg>
							</div>
							<div className={style.isMobileBackground}>
								<svg
									xmlns="http://www.w3.org/2000/svg"
									viewBox="0 0 1719.7 1237.2"
									width="100%"
									height="100%"
									fill="none">
									<path
										d="M150.7 303.5C306-52 574.7-59 731 97.3c204.4 217.8 175.3 481.3 104.3 650.2C717.5 1028 452.2 1330.7 172 1210c-230-99-176.5-551.2-21.3-906.5z"
										fill="#7dc6ca"
									/>
								</svg>
							</div>
						</div>
					</div>
					<div className={style.mainIn}>
						<div className={style.heroImage}>
							<Image
								priority
								src="/images/yoga_pose.png"
								alt=""
								width="410"
								height="521"
								objectFit="contain"
								unoptimized
							/>
						</div>
						<h1 className={style.title}>
							<div className={style.title_firstPart}>
								<span className={style.title_firstPart_firstWord}>{titlePartA}</span>
								<span className={style.title_firstPart_secondWord}>{titlePartB}</span>
								<br />
							</div>
							<div className={style.title_secondPart}>{titlePartC}</div>
							<div className={style.title_thirdPart}>{titlePartD}</div>
						</h1>
					</div>
					<h2 className={style.content}>{children}</h2>
				</div>

				{tiles && <HeroTiles tiles={tiles} />}
			</Container>
		</div>
	)
}
