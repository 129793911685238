import type { PoseTileResult } from '../app/data/content/PoseTileFragment'
import { ContemberInternalLink } from './ContemberLink'
import style from './PoseTile.module.sass'
import { TileImage } from './TileImage'

export interface PoseTileProps {
	data: PoseTileResult
}

export const PoseTile: React.FunctionComponent<PoseTileProps> = ({
	data: { image, headline, link },
}) => {
	return (
		<ContemberInternalLink link={link}>
			<a className={style.wrapper}>
				<div className={style.main}>
					<div className={style.image}>
						<TileImage image={image} borderRadius="all" sizes="394px" />
					</div>
					<h3 className={style.headline}>{headline}</h3>
				</div>
			</a>
		</ContemberInternalLink>
	)
}
