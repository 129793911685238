import clsx from 'clsx'
import { useTranslate } from '../app/contexts/TranslationsContext'
import type { ArticleTileResult } from '../app/data/content/ArticleTileFragment'
import style from './ArticleTile.module.sass'
import { ContemberInternalLink } from './ContemberLink'
import { TileImage } from './TileImage'

interface ArticleTileProps {
	isMain?: boolean
	data: ArticleTileResult
}

export const ArticleTile: React.FunctionComponent<ArticleTileProps> = ({
	isMain,
	data: { headline, link, shortLead, image, category },
}) => {
	const translate = useTranslate()

	return (
		<ContemberInternalLink link={link}>
			<a className={clsx(style.wrapper, isMain && style.view_main)}>
				<div className={style.main}>
					{image && (
						<div className={style.image}>
							<TileImage image={image} borderRadius="all" sizes="(max-width: 1300px) 50vw, 610px" />
						</div>
					)}
					<div>
						{category && <h4 className={style.category}>{category.label}</h4>}
						<h2 className={style.title}>{headline}</h2>
						<div className={style.text}>{shortLead}</div>
						<div className={style.more}>{translate('articleMore')}</div>
					</div>
				</div>
			</a>
		</ContemberInternalLink>
	)
}
