import Image from 'next/image'
import type { FunctionComponent } from 'react'
import type { ImageResult } from '../app/data/content/ImageFragment'
import style from './ContentImage.module.sass'

export interface ContentImageProps {
	image: ImageResult
}

export const ContentImage: FunctionComponent<ContentImageProps> = ({ image }) => {
	return (
		<div className={style.wrapper}>
			<Image
				className={style.image}
				src={image.url}
				alt={image.alt}
				width={image.width || 1}
				height={image.height || 1}
				sizes="800px"
			/>
		</div>
	)
}
