import clsx from 'clsx'
import style from './EmailNewsletter.module.sass'

export enum EmailNewsletterPreset {
	Default = 'Default',
	SmartEmailing = 'SmartEmailing',
}

export interface EmailNewsletterProps {
	buttonLabel?: string
	buttonStyle?: 'fill' | 'transparent'
	isSubmitting?: boolean
	preset?: EmailNewsletterPreset
	emailLabel?: string
}

export const EmailNewsletter: React.FunctionComponent<EmailNewsletterProps> = ({
	buttonLabel = 'Odebírat',
	buttonStyle = 'transparent',
	isSubmitting = false,
	emailLabel,
	preset,
}) => {
	const htmlName =
		preset === EmailNewsletterPreset.SmartEmailing ? 'fields[df_emailaddress]' : 'email'

	return (
		<div className={style.wrapper}>
			<label className={style.label}>
				<input
					className={style.input}
					name={htmlName}
					type="email"
					placeholder={emailLabel}
					autoComplete="email"
					required
					readOnly={isSubmitting}
				/>
			</label>

			{preset === EmailNewsletterPreset.SmartEmailing && (
				<>
					<input type="hidden" name="referrer" value="" />
					<input type="hidden" name="sessionid" value="" />
					<input type="hidden" name="sessionUid" value="" />
					<input type="hidden" name="_do" value="webFormHtmlRenderer-webFormForm-submit" />
				</>
			)}

			<div className={style.button}>
				<button
					className={clsx(style.submit, style[`view_${buttonStyle}`])}
					type="submit"
					name="_submit"
					disabled={isSubmitting}>
					{buttonLabel}
				</button>
			</div>
		</div>
	)
}
