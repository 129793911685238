import type { FunctionComponent } from 'react'
import { ListNavigation } from './ListNavigation'
import type { SubmenuProps } from './Submenu'
import style from './SubmenuItem.module.sass'
import { TileList } from './TileList'

export interface SubmenuItemProps {
	item: SubmenuProps['items'][number]
}

export const SubmenuItem: FunctionComponent<SubmenuItemProps> = ({ item }) => {
	return (
		<div className={style.wrapper}>
			{item.listNavigation?.columns && (
				<div className={style.listNavigation}>
					<ListNavigation navigation={item.listNavigation} />
				</div>
			)}
			{item.tiles?.items && (
				<div className={style.tiles}>
					<TileList tileList={item.tiles} />
				</div>
			)}
		</div>
	)
}
