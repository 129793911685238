import Image from 'next/image'
import type { FunctionComponent } from 'react'
import type { ImageResult } from '../app/data/content/ImageFragment'
import style from './ArticleHeading.module.sass'
import type { ArticlePageProps } from './ArticlePage'
import { Container } from './Container'
import { ContemberInternalLink } from './ContemberLink'

interface ArticleHeadingProps {
	category?: ArticlePageProps['category']
	headline?: string
	image?: ImageResult
}

export const ArticleHeading: FunctionComponent<ArticleHeadingProps> = ({
	category,
	headline,
	image,
	children,
}) => {
	return (
		<div className={style.wrapper}>
			<Container>
				{category && (
					<div className={style.category}>
						<ContemberInternalLink link={category.link}>
							<a className={style.categoryLink}>{category.label}</a>
						</ContemberInternalLink>
					</div>
				)}
				<h2 className={style.headline}>{headline}</h2>
			</Container>
			<Container size="thin">
				<div className={style.lead}>{children}</div>
			</Container>
			{image && (
				<Container size="thin" disableGutters>
					<div className={style.image}>
						<Image
							src={image.url}
							width={image.width || 1}
							height={image.height || 1}
							alt={image.alt || ''}
							sizes="(max-width: 1300px) 100vw, 576px"
						/>
					</div>
				</Container>
			)}
		</div>
	)
}
