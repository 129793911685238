import Link from 'next/link'
import { Children, cloneElement, FunctionComponent } from 'react'
import { useUrlWithoutOrigin } from '../app/contexts/OriginContext'
import type { InternalLinkResult } from '../app/data/content/InternalLinkFragment'
import type { LinkResult } from '../app/data/content/LinkFragment'
import { LinkType } from '../generated/content'
import { assertDefined } from '../utils/assertDefined'
import { assertNever } from '../utils/assertNever'

export interface ContemberLinkProps {
	link: LinkResult
}

export const ContemberLink: FunctionComponent<ContemberLinkProps> = ({
	link: { type, externalLink, internalLink },
	children,
}) => {
	const url = useUrlWithoutOrigin(externalLink ?? '#')
	if (type === LinkType.internal) {
		return <ContemberInternalLink link={internalLink}>{children}</ContemberInternalLink>
	} else if (type === LinkType.external) {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		const child: any = Children.only(children)
		return cloneElement(child, {
			href: url,
		})
	} else {
		return assertNever(type)
	}
}

export interface ContemberInternalLinkProps {
	link: InternalLinkResult | undefined
}

export const ContemberInternalLink: FunctionComponent<ContemberInternalLinkProps> = ({
	link,
	children,
}) => {
	assertDefined(link)
	const url = useUrlWithoutOrigin(link.url)
	return <Link href={url}>{children}</Link>
}
