import { useTranslate } from '../app/contexts/TranslationsContext'
import type { CommonPageProps } from '../pages/[[...page]]'
import { Breadcrumbs } from './Breadcrumbs'
import { Container } from './Container'
import { CourseLecturerTiles } from './CourseLecturerTiles'
import { PageListTitle } from './PageListTitle'
import { Spacer } from './Spacer'

export type CourseLecturerListPageProps = Exclude<
	Pick<CommonPageProps['page'], 'courseLecturerList'>['courseLecturerList'],
	null
>

export const CourseLecturerListPage: React.FunctionComponent<CourseLecturerListPageProps> = ({
	title,
	lecturers,
}) => {
	const translate = useTranslate()

	return (
		<Container>
			<Breadcrumbs
				items={[
					{
						label: title ?? translate('lecturers'),
					},
				]}
			/>
			<Spacer initial={32} break1000={80} />
			<PageListTitle>{title}</PageListTitle>
			<Spacer initial={24} />
			<section>
				<CourseLecturerTiles courseLecturers={lecturers} />
			</section>
			<Spacer initial={40} break1000={100} />
		</Container>
	)
}
