import { createContext, useCallback, useContext } from 'react'

const TranslationsContext = createContext<Record<string, string>>({})

export const TranslationsProvider = TranslationsContext.Provider

export const useTranslate = () => {
	const translations = useContext(TranslationsContext)

	return useCallback((key: string) => translations[key] ?? key, [translations])
}
