import clsx from 'clsx'
import Image from 'next/image'
import { useTranslate } from '../app/contexts/TranslationsContext'
import type { CommonPageProps } from '../pages/[[...page]]'
import { Breadcrumbs } from './Breadcrumbs'
import { Chip } from './Chip'
import { Container } from './Container'
import { ContentRenderer } from './ContentRenderer'
import { OtherTiles } from './OtherTiles'
import style from './PosePage.module.sass'
import { Spacer } from './Spacer'

export type PosePageProps = Exclude<Pick<CommonPageProps['page'], 'pose'>['pose'], null>

export const PosePage: React.FunctionComponent<PosePageProps> = ({
	headline,
	subtitle,
	image,
	content,
	category,
	difficulty,
	site,
	poses,
	otherArticles,
}) => {
	const translate = useTranslate()
	const containsLongWord = (headline: string) => {
		const stringArr = headline.split(' ')

		return stringArr.some((string) => string.length > 12)
	}

	return (
		<Container>
			<Breadcrumbs
				items={[
					{
						label: translate('poses'),
						link: site?.poseListPage?.link,
					},
					{
						label: headline || translate('pose'),
					},
				]}
			/>
			<section className={style.wrapper}>
				<div>
					<h1
						className={clsx(
							style.headline,
							headline && containsLongWord(headline) && style.view_containsLongWord
						)}>
						{headline}
					</h1>
					{subtitle && <h2 className={style.subtitle}>{subtitle}</h2>}
					<div className={style.chips}>
						<div className={style.chip}>
							{category && <Chip icon={category.icon}>{category.label}</Chip>}
						</div>
						<div className={style.chip}>
							{difficulty && <Chip icon={difficulty.icon}>{difficulty.label}</Chip>}
						</div>
					</div>
				</div>
				<div className={style.imageColumn}>
					<div className={style.image}>
						{image && (
							<Image
								src={image.url}
								layout="fill"
								objectFit="cover"
								alt={image.alt ?? ''}
								sizes="580px"
							/>
						)}
					</div>
				</div>
				{content && (
					<div className={style.content}>
						<ContentRenderer content={content} containerDisableGutters />
					</div>
				)}
			</section>

			<Spacer initial={120} />

			<OtherTiles
				poses={{
					items: poses,
					sectionTitle: {
						link: site?.poseListPage?.link,
					},
				}}
				articles={{
					items: otherArticles,
					sectionTitle: {
						link: site?.articleListPage?.link,
					},
				}}
			/>

			<Spacer initial={80} break1000={160} />
		</Container>
	)
}
