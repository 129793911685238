import type { FunctionComponent } from 'react'
import type { TileListResult } from '../app/data/content/TileListFragment'
import { HeroTile } from './HeroTile'
import style from './HeroTiles.module.sass'

export interface HeroTilesProps {
	tiles: TileListResult
}

export const HeroTiles: FunctionComponent<HeroTilesProps> = ({ tiles }) => {
	return (
		<div className={style.wrapper}>
			<div className={style.main}>
				{tiles.items.map(
					(tile) =>
						tile.tile?.link && (
							<div className={style.item} key={tile.id}>
								<HeroTile
									image={tile.tile.image}
									title={tile.tile.link.title || ''}
									link={tile.tile.link}
									video={tile.tile.isVideo}
								/>
							</div>
						)
				)}
			</div>
		</div>
	)
}
