import type { InternalLinkResult } from '../app/data/content/InternalLinkFragment'
import type { PoseCategoryChipResult } from '../app/data/content/PoseCategoryChipFragment'
import style from './PoseBlock.module.sass'
import { PoseCategoryChips } from './PoseCategoryChips'
import { SectionTitle } from './SectionTitle'

export interface PoseBlockProps {
	title: string
	link: InternalLinkResult | undefined
	categories: PoseCategoryChipResult[]
}

export const PoseBlock: React.FunctionComponent<PoseBlockProps> = ({ title, link, categories }) => {
	return (
		<div className={style.wrapper}>
			<div className={style.title}>
				{link && title && (
					<SectionTitle title={title} link={link} alignment={{ break1000: 'center' }} />
				)}
			</div>
			<div className={style.poses}>
				<PoseCategoryChips categories={categories} link={link} />
			</div>
		</div>
	)
}
