import type { CommonPageProps } from '../pages/[[...page]]'
import { MessagePage } from './MessagePage'

export type NewsletterSubscribeSuccessPageProps = Exclude<
	Pick<CommonPageProps['page'], 'newsletterSubscribeSuccessPage'>['newsletterSubscribeSuccessPage'],
	null
>

export const NewsletterSubscribeSuccessPage: React.FunctionComponent<
	NewsletterSubscribeSuccessPageProps
> = ({ title, returnLinkText }) => <MessagePage title={title} returnLinkText={returnLinkText} />
