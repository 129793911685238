import type { CourseLecturerTileResult } from '../app/data/content/CourseLecturerTileFragment'
import { CourseLecturerTile } from './CourseLecturerTile'
import style from './CourseLecturerTiles.module.sass'

export interface CourseLecturerTilesProps {
	courseLecturers: CourseLecturerTileResult[]
}

export const CourseLecturerTiles: React.FunctionComponent<CourseLecturerTilesProps> = ({
	courseLecturers,
}) => {
	return (
		<div className={style.wrapper}>
			{courseLecturers.map((lecturer) => (
				<div key={lecturer.id}>
					<CourseLecturerTile data={lecturer} />
				</div>
			))}
		</div>
	)
}
