/* eslint-disable */

export const AllTypesProps: Record<string,any> = {
	Query:{
		getLink:{
			by:{
				type:"LinkUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"LinkWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		listLink:{
			filter:{
				type:"LinkWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"LinkOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		paginateLink:{
			filter:{
				type:"LinkWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"LinkOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			first:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		getLinkable:{
			by:{
				type:"LinkableUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"LinkableWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		listLinkable:{
			filter:{
				type:"LinkableWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"LinkableOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		paginateLinkable:{
			filter:{
				type:"LinkableWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"LinkableOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			first:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		getRedirect:{
			by:{
				type:"RedirectUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"RedirectWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		listRedirect:{
			filter:{
				type:"RedirectWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"RedirectOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		paginateRedirect:{
			filter:{
				type:"RedirectWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"RedirectOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			first:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		getFooter:{
			by:{
				type:"FooterUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"FooterWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		listFooter:{
			filter:{
				type:"FooterWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"FooterOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		paginateFooter:{
			filter:{
				type:"FooterWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"FooterOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			first:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		getImage:{
			by:{
				type:"ImageUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"ImageWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		listImage:{
			filter:{
				type:"ImageWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"ImageOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		paginateImage:{
			filter:{
				type:"ImageWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"ImageOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			first:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		getSeo:{
			by:{
				type:"SeoUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"SeoWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		listSeo:{
			filter:{
				type:"SeoWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"SeoOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		paginateSeo:{
			filter:{
				type:"SeoWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"SeoOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			first:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		getHomepage:{
			by:{
				type:"HomepageUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"HomepageWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		listHomepage:{
			filter:{
				type:"HomepageWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"HomepageOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		paginateHomepage:{
			filter:{
				type:"HomepageWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"HomepageOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			first:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		getHeader:{
			by:{
				type:"HeaderUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"HeaderWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		listHeader:{
			filter:{
				type:"HeaderWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"HeaderOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		paginateHeader:{
			filter:{
				type:"HeaderWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"HeaderOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			first:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		getHeaderPrimaryNavigationItem:{
			by:{
				type:"HeaderPrimaryNavigationItemUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"HeaderPrimaryNavigationItemWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		listHeaderPrimaryNavigationItem:{
			filter:{
				type:"HeaderPrimaryNavigationItemWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"HeaderPrimaryNavigationItemOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		paginateHeaderPrimaryNavigationItem:{
			filter:{
				type:"HeaderPrimaryNavigationItemWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"HeaderPrimaryNavigationItemOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			first:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		getListNavigation:{
			by:{
				type:"ListNavigationUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"ListNavigationWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		listListNavigation:{
			filter:{
				type:"ListNavigationWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"ListNavigationOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		paginateListNavigation:{
			filter:{
				type:"ListNavigationWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"ListNavigationOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			first:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		getListNavigationColumn:{
			by:{
				type:"ListNavigationColumnUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"ListNavigationColumnWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		listListNavigationColumn:{
			filter:{
				type:"ListNavigationColumnWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"ListNavigationColumnOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		paginateListNavigationColumn:{
			filter:{
				type:"ListNavigationColumnWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"ListNavigationColumnOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			first:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		getListNavigationColumnItem:{
			by:{
				type:"ListNavigationColumnItemUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"ListNavigationColumnItemWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		listListNavigationColumnItem:{
			filter:{
				type:"ListNavigationColumnItemWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"ListNavigationColumnItemOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		paginateListNavigationColumnItem:{
			filter:{
				type:"ListNavigationColumnItemWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"ListNavigationColumnItemOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			first:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		getArticle:{
			by:{
				type:"ArticleUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"ArticleWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		listArticle:{
			filter:{
				type:"ArticleWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"ArticleOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		paginateArticle:{
			filter:{
				type:"ArticleWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"ArticleOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			first:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		getSite:{
			by:{
				type:"SiteUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"SiteWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		listSite:{
			filter:{
				type:"SiteWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"SiteOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		paginateSite:{
			filter:{
				type:"SiteWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"SiteOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			first:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		getArticleCategory:{
			by:{
				type:"ArticleCategoryUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"ArticleCategoryWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		listArticleCategory:{
			filter:{
				type:"ArticleCategoryWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"ArticleCategoryOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		paginateArticleCategory:{
			filter:{
				type:"ArticleCategoryWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"ArticleCategoryOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			first:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		getPose:{
			by:{
				type:"PoseUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"PoseWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		listPose:{
			filter:{
				type:"PoseWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"PoseOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		paginatePose:{
			filter:{
				type:"PoseWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"PoseOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			first:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		getPoseCategory:{
			by:{
				type:"PoseCategoryUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"PoseCategoryWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		listPoseCategory:{
			filter:{
				type:"PoseCategoryWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"PoseCategoryOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		paginatePoseCategory:{
			filter:{
				type:"PoseCategoryWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"PoseCategoryOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			first:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		getPoseDifficulty:{
			by:{
				type:"PoseDifficultyUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"PoseDifficultyWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		listPoseDifficulty:{
			filter:{
				type:"PoseDifficultyWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"PoseDifficultyOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		paginatePoseDifficulty:{
			filter:{
				type:"PoseDifficultyWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"PoseDifficultyOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			first:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		getCourse:{
			by:{
				type:"CourseUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"CourseWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		listCourse:{
			filter:{
				type:"CourseWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"CourseOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		paginateCourse:{
			filter:{
				type:"CourseWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"CourseOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			first:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		getCourseLesson:{
			by:{
				type:"CourseLessonUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"CourseLessonWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		listCourseLesson:{
			filter:{
				type:"CourseLessonWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"CourseLessonOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		paginateCourseLesson:{
			filter:{
				type:"CourseLessonWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"CourseLessonOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			first:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		getArticleListPage:{
			by:{
				type:"ArticleListPageUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"ArticleListPageWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		listArticleListPage:{
			filter:{
				type:"ArticleListPageWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"ArticleListPageOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		paginateArticleListPage:{
			filter:{
				type:"ArticleListPageWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"ArticleListPageOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			first:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		getCourseListPage:{
			by:{
				type:"CourseListPageUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"CourseListPageWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		listCourseListPage:{
			filter:{
				type:"CourseListPageWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"CourseListPageOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		paginateCourseListPage:{
			filter:{
				type:"CourseListPageWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"CourseListPageOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			first:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		getPoseListPage:{
			by:{
				type:"PoseListPageUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"PoseListPageWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		listPoseListPage:{
			filter:{
				type:"PoseListPageWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"PoseListPageOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		paginatePoseListPage:{
			filter:{
				type:"PoseListPageWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"PoseListPageOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			first:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		getContent:{
			by:{
				type:"ContentUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"ContentWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		listContent:{
			filter:{
				type:"ContentWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"ContentOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		paginateContent:{
			filter:{
				type:"ContentWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"ContentOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			first:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		getContentBlock:{
			by:{
				type:"ContentBlockUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"ContentBlockWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		listContentBlock:{
			filter:{
				type:"ContentBlockWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"ContentBlockOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		paginateContentBlock:{
			filter:{
				type:"ContentBlockWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"ContentBlockOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			first:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		getContentReference:{
			by:{
				type:"ContentReferenceUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"ContentReferenceWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		listContentReference:{
			filter:{
				type:"ContentReferenceWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"ContentReferenceOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		paginateContentReference:{
			filter:{
				type:"ContentReferenceWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"ContentReferenceOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			first:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		getTile:{
			by:{
				type:"TileUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"TileWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		listTile:{
			filter:{
				type:"TileWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"TileOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		paginateTile:{
			filter:{
				type:"TileWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"TileOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			first:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		getTileList:{
			by:{
				type:"TileListUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"TileListWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		listTileList:{
			filter:{
				type:"TileListWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"TileListOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		paginateTileList:{
			filter:{
				type:"TileListWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"TileListOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			first:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		getTileListItem:{
			by:{
				type:"TileListItemUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"TileListItemWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		listTileListItem:{
			filter:{
				type:"TileListItemWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"TileListItemOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		paginateTileListItem:{
			filter:{
				type:"TileListItemWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"TileListItemOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			first:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		getYoutubeVideo:{
			by:{
				type:"YoutubeVideoUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"YoutubeVideoWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		listYoutubeVideo:{
			filter:{
				type:"YoutubeVideoWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"YoutubeVideoOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		paginateYoutubeVideo:{
			filter:{
				type:"YoutubeVideoWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"YoutubeVideoOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			first:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		getSeoDefault:{
			by:{
				type:"SeoDefaultUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"SeoDefaultWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		listSeoDefault:{
			filter:{
				type:"SeoDefaultWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"SeoDefaultOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		paginateSeoDefault:{
			filter:{
				type:"SeoDefaultWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"SeoDefaultOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			first:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		getCourseLecturer:{
			by:{
				type:"CourseLecturerUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"CourseLecturerWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		listCourseLecturer:{
			filter:{
				type:"CourseLecturerWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"CourseLecturerOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		paginateCourseLecturer:{
			filter:{
				type:"CourseLecturerWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"CourseLecturerOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			first:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		getCourseLecturerListPage:{
			by:{
				type:"CourseLecturerListPageUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"CourseLecturerListPageWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		listCourseLecturerListPage:{
			filter:{
				type:"CourseLecturerListPageWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"CourseLecturerListPageOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		paginateCourseLecturerListPage:{
			filter:{
				type:"CourseLecturerListPageWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"CourseLecturerListPageOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			first:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		getNewsletterSubscription:{
			by:{
				type:"NewsletterSubscriptionUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"NewsletterSubscriptionWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		listNewsletterSubscription:{
			filter:{
				type:"NewsletterSubscriptionWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"NewsletterSubscriptionOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		paginateNewsletterSubscription:{
			filter:{
				type:"NewsletterSubscriptionWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"NewsletterSubscriptionOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			first:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		validateCreateNewsletterSubscription:{
			data:{
				type:"NewsletterSubscriptionCreateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		getVisitor:{
			by:{
				type:"VisitorUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"VisitorWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		listVisitor:{
			filter:{
				type:"VisitorWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"VisitorOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		paginateVisitor:{
			filter:{
				type:"VisitorWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"VisitorOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			first:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		validateCreateVisitor:{
			data:{
				type:"VisitorCreateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		getCourseSubscription:{
			by:{
				type:"CourseSubscriptionUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"CourseSubscriptionWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		listCourseSubscription:{
			filter:{
				type:"CourseSubscriptionWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"CourseSubscriptionOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		paginateCourseSubscription:{
			filter:{
				type:"CourseSubscriptionWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"CourseSubscriptionOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			first:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		validateCreateCourseSubscription:{
			data:{
				type:"CourseSubscriptionCreateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		getSubscribeBox:{
			by:{
				type:"SubscribeBoxUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"SubscribeBoxWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		listSubscribeBox:{
			filter:{
				type:"SubscribeBoxWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"SubscribeBoxOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		paginateSubscribeBox:{
			filter:{
				type:"SubscribeBoxWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"SubscribeBoxOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			first:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		getNewsletterSubscribeSuccessPage:{
			by:{
				type:"NewsletterSubscribeSuccessPageUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"NewsletterSubscribeSuccessPageWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		listNewsletterSubscribeSuccessPage:{
			filter:{
				type:"NewsletterSubscribeSuccessPageWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"NewsletterSubscribeSuccessPageOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		paginateNewsletterSubscribeSuccessPage:{
			filter:{
				type:"NewsletterSubscribeSuccessPageWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"NewsletterSubscribeSuccessPageOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			first:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		getGenericPage:{
			by:{
				type:"GenericPageUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"GenericPageWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		listGenericPage:{
			filter:{
				type:"GenericPageWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"GenericPageOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		paginateGenericPage:{
			filter:{
				type:"GenericPageWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"GenericPageOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			first:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		getPerson:{
			by:{
				type:"PersonUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"PersonWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		listPerson:{
			filter:{
				type:"PersonWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"PersonOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		paginatePerson:{
			filter:{
				type:"PersonWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"PersonOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			first:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		getPersonList:{
			by:{
				type:"PersonListUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"PersonListWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		listPersonList:{
			filter:{
				type:"PersonListWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"PersonListOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		paginatePersonList:{
			filter:{
				type:"PersonListWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"PersonListOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			first:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		getPersonListItem:{
			by:{
				type:"PersonListItemUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"PersonListItemWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		listPersonListItem:{
			filter:{
				type:"PersonListItemWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"PersonListItemOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		paginatePersonListItem:{
			filter:{
				type:"PersonListItemWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"PersonListItemOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			first:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		getCourseEmail:{
			by:{
				type:"CourseEmailUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"CourseEmailWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		listCourseEmail:{
			filter:{
				type:"CourseEmailWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"CourseEmailOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		paginateCourseEmail:{
			filter:{
				type:"CourseEmailWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"CourseEmailOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			first:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		getTranslations:{
			by:{
				type:"TranslationsUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"TranslationsWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		listTranslations:{
			filter:{
				type:"TranslationsWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"TranslationsOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		paginateTranslations:{
			filter:{
				type:"TranslationsWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"TranslationsOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			first:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	Link:{
		internalLink:{
			filter:{
				type:"LinkableWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	UUID: "String",
	LinkType: "enum",
	Linkable:{
		redirect:{
			filter:{
				type:"RedirectWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		homepage:{
			filter:{
				type:"HomepageWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		article:{
			filter:{
				type:"ArticleWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		articleCategory:{
			filter:{
				type:"ArticleCategoryWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		pose:{
			filter:{
				type:"PoseWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		course:{
			filter:{
				type:"CourseWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		articleList:{
			filter:{
				type:"ArticleListPageWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		courseList:{
			filter:{
				type:"CourseListPageWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		poseList:{
			filter:{
				type:"PoseListPageWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		courseLecturer:{
			filter:{
				type:"CourseLecturerWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		courseLecturerList:{
			filter:{
				type:"CourseLecturerListPageWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		newsletterSubscribeSuccessPage:{
			filter:{
				type:"NewsletterSubscribeSuccessPageWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		genericPage:{
			filter:{
				type:"GenericPageWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	Redirect:{
		link:{
			filter:{
				type:"LinkableWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		target:{
			filter:{
				type:"LinkableWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	LinkableWhere:{
		id:{
			type:"UUIDCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		url:{
			type:"StringCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		redirect:{
			type:"RedirectWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		homepage:{
			type:"HomepageWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		article:{
			type:"ArticleWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		articleCategory:{
			type:"ArticleCategoryWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		pose:{
			type:"PoseWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		course:{
			type:"CourseWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		articleList:{
			type:"ArticleListPageWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		courseList:{
			type:"CourseListPageWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		poseList:{
			type:"PoseListPageWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		courseLecturer:{
			type:"CourseLecturerWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		courseLecturerList:{
			type:"CourseLecturerListPageWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		newsletterSubscribeSuccessPage:{
			type:"NewsletterSubscribeSuccessPageWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		genericPage:{
			type:"GenericPageWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		and:{
			type:"LinkableWhere",
			array:true,
			arrayRequired:false,
			required:false
		},
		or:{
			type:"LinkableWhere",
			array:true,
			arrayRequired:false,
			required:false
		},
		not:{
			type:"LinkableWhere",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	UUIDCondition:{
		and:{
			type:"UUIDCondition",
			array:true,
			arrayRequired:false,
			required:true
		},
		or:{
			type:"UUIDCondition",
			array:true,
			arrayRequired:false,
			required:true
		},
		not:{
			type:"UUIDCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		null:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		isNull:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		eq:{
			type:"UUID",
			array:false,
			arrayRequired:false,
			required:false
		},
		notEq:{
			type:"UUID",
			array:false,
			arrayRequired:false,
			required:false
		},
		in:{
			type:"UUID",
			array:true,
			arrayRequired:false,
			required:true
		},
		notIn:{
			type:"UUID",
			array:true,
			arrayRequired:false,
			required:true
		},
		lt:{
			type:"UUID",
			array:false,
			arrayRequired:false,
			required:false
		},
		lte:{
			type:"UUID",
			array:false,
			arrayRequired:false,
			required:false
		},
		gt:{
			type:"UUID",
			array:false,
			arrayRequired:false,
			required:false
		},
		gte:{
			type:"UUID",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	StringCondition:{
		and:{
			type:"StringCondition",
			array:true,
			arrayRequired:false,
			required:true
		},
		or:{
			type:"StringCondition",
			array:true,
			arrayRequired:false,
			required:true
		},
		not:{
			type:"StringCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		null:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		isNull:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		eq:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		notEq:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		in:{
			type:"String",
			array:true,
			arrayRequired:false,
			required:true
		},
		notIn:{
			type:"String",
			array:true,
			arrayRequired:false,
			required:true
		},
		lt:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		lte:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		gt:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		gte:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		contains:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		startsWith:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		endsWith:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		containsCI:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		startsWithCI:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		endsWithCI:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	RedirectWhere:{
		id:{
			type:"UUIDCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		link:{
			type:"LinkableWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		target:{
			type:"LinkableWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		and:{
			type:"RedirectWhere",
			array:true,
			arrayRequired:false,
			required:false
		},
		or:{
			type:"RedirectWhere",
			array:true,
			arrayRequired:false,
			required:false
		},
		not:{
			type:"RedirectWhere",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	HomepageWhere:{
		id:{
			type:"UUIDCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		link:{
			type:"LinkableWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		subtitle:{
			type:"StringCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		seo:{
			type:"SeoWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		content:{
			type:"ContentWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		heroTiles:{
			type:"TileListWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		site:{
			type:"SiteWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		titlePartA:{
			type:"StringCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		titlePartB:{
			type:"StringCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		titlePartC:{
			type:"StringCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		titlePartD:{
			type:"StringCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		and:{
			type:"HomepageWhere",
			array:true,
			arrayRequired:false,
			required:false
		},
		or:{
			type:"HomepageWhere",
			array:true,
			arrayRequired:false,
			required:false
		},
		not:{
			type:"HomepageWhere",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	SeoWhere:{
		id:{
			type:"UUIDCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		title:{
			type:"StringCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		description:{
			type:"StringCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		ogTitle:{
			type:"StringCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		ogDescription:{
			type:"StringCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		ogImage:{
			type:"ImageWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		and:{
			type:"SeoWhere",
			array:true,
			arrayRequired:false,
			required:false
		},
		or:{
			type:"SeoWhere",
			array:true,
			arrayRequired:false,
			required:false
		},
		not:{
			type:"SeoWhere",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ImageWhere:{
		id:{
			type:"UUIDCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		url:{
			type:"StringCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		width:{
			type:"IntCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		height:{
			type:"IntCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		size:{
			type:"IntCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		type:{
			type:"StringCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		alt:{
			type:"StringCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		and:{
			type:"ImageWhere",
			array:true,
			arrayRequired:false,
			required:false
		},
		or:{
			type:"ImageWhere",
			array:true,
			arrayRequired:false,
			required:false
		},
		not:{
			type:"ImageWhere",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	IntCondition:{
		and:{
			type:"IntCondition",
			array:true,
			arrayRequired:false,
			required:true
		},
		or:{
			type:"IntCondition",
			array:true,
			arrayRequired:false,
			required:true
		},
		not:{
			type:"IntCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		null:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		isNull:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		eq:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		notEq:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		in:{
			type:"Int",
			array:true,
			arrayRequired:false,
			required:true
		},
		notIn:{
			type:"Int",
			array:true,
			arrayRequired:false,
			required:true
		},
		lt:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		lte:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		gt:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		gte:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ContentWhere:{
		id:{
			type:"UUIDCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		blocks:{
			type:"ContentBlockWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		and:{
			type:"ContentWhere",
			array:true,
			arrayRequired:false,
			required:false
		},
		or:{
			type:"ContentWhere",
			array:true,
			arrayRequired:false,
			required:false
		},
		not:{
			type:"ContentWhere",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ContentBlockWhere:{
		id:{
			type:"UUIDCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		order:{
			type:"IntCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		json:{
			type:"StringCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		content:{
			type:"ContentWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		references:{
			type:"ContentReferenceWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		and:{
			type:"ContentBlockWhere",
			array:true,
			arrayRequired:false,
			required:false
		},
		or:{
			type:"ContentBlockWhere",
			array:true,
			arrayRequired:false,
			required:false
		},
		not:{
			type:"ContentBlockWhere",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ContentReferenceWhere:{
		id:{
			type:"UUIDCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		type:{
			type:"ContentReferenceTypeCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		primaryText:{
			type:"StringCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		block:{
			type:"ContentBlockWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		image:{
			type:"ImageWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		highlightFirstArticle:{
			type:"BooleanCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		linkToMoreArticles:{
			type:"BooleanCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		people:{
			type:"PersonListWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		and:{
			type:"ContentReferenceWhere",
			array:true,
			arrayRequired:false,
			required:false
		},
		or:{
			type:"ContentReferenceWhere",
			array:true,
			arrayRequired:false,
			required:false
		},
		not:{
			type:"ContentReferenceWhere",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ContentReferenceTypeCondition:{
		and:{
			type:"ContentReferenceTypeCondition",
			array:true,
			arrayRequired:false,
			required:true
		},
		or:{
			type:"ContentReferenceTypeCondition",
			array:true,
			arrayRequired:false,
			required:true
		},
		not:{
			type:"ContentReferenceTypeCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		null:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		isNull:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		eq:{
			type:"ContentReferenceType",
			array:false,
			arrayRequired:false,
			required:false
		},
		notEq:{
			type:"ContentReferenceType",
			array:false,
			arrayRequired:false,
			required:false
		},
		in:{
			type:"ContentReferenceType",
			array:true,
			arrayRequired:false,
			required:true
		},
		notIn:{
			type:"ContentReferenceType",
			array:true,
			arrayRequired:false,
			required:true
		},
		lt:{
			type:"ContentReferenceType",
			array:false,
			arrayRequired:false,
			required:false
		},
		lte:{
			type:"ContentReferenceType",
			array:false,
			arrayRequired:false,
			required:false
		},
		gt:{
			type:"ContentReferenceType",
			array:false,
			arrayRequired:false,
			required:false
		},
		gte:{
			type:"ContentReferenceType",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ContentReferenceType: "enum",
	BooleanCondition:{
		and:{
			type:"BooleanCondition",
			array:true,
			arrayRequired:false,
			required:true
		},
		or:{
			type:"BooleanCondition",
			array:true,
			arrayRequired:false,
			required:true
		},
		not:{
			type:"BooleanCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		null:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		isNull:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		eq:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		notEq:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		in:{
			type:"Boolean",
			array:true,
			arrayRequired:false,
			required:true
		},
		notIn:{
			type:"Boolean",
			array:true,
			arrayRequired:false,
			required:true
		},
		lt:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		lte:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		gt:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		gte:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	PersonListWhere:{
		id:{
			type:"UUIDCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		items:{
			type:"PersonListItemWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		and:{
			type:"PersonListWhere",
			array:true,
			arrayRequired:false,
			required:false
		},
		or:{
			type:"PersonListWhere",
			array:true,
			arrayRequired:false,
			required:false
		},
		not:{
			type:"PersonListWhere",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	PersonListItemWhere:{
		id:{
			type:"UUIDCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		order:{
			type:"IntCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		list:{
			type:"PersonListWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		person:{
			type:"PersonWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		about:{
			type:"StringCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		and:{
			type:"PersonListItemWhere",
			array:true,
			arrayRequired:false,
			required:false
		},
		or:{
			type:"PersonListItemWhere",
			array:true,
			arrayRequired:false,
			required:false
		},
		not:{
			type:"PersonListItemWhere",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	PersonWhere:{
		id:{
			type:"UUIDCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		name:{
			type:"StringCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		about:{
			type:"StringCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		email:{
			type:"StringCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		phone:{
			type:"StringCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		image:{
			type:"ImageWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		and:{
			type:"PersonWhere",
			array:true,
			arrayRequired:false,
			required:false
		},
		or:{
			type:"PersonWhere",
			array:true,
			arrayRequired:false,
			required:false
		},
		not:{
			type:"PersonWhere",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	TileListWhere:{
		id:{
			type:"UUIDCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		items:{
			type:"TileListItemWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		and:{
			type:"TileListWhere",
			array:true,
			arrayRequired:false,
			required:false
		},
		or:{
			type:"TileListWhere",
			array:true,
			arrayRequired:false,
			required:false
		},
		not:{
			type:"TileListWhere",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	TileListItemWhere:{
		id:{
			type:"UUIDCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		order:{
			type:"IntCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		list:{
			type:"TileListWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		tile:{
			type:"TileWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		and:{
			type:"TileListItemWhere",
			array:true,
			arrayRequired:false,
			required:false
		},
		or:{
			type:"TileListItemWhere",
			array:true,
			arrayRequired:false,
			required:false
		},
		not:{
			type:"TileListItemWhere",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	TileWhere:{
		id:{
			type:"UUIDCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		image:{
			type:"ImageWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		link:{
			type:"LinkWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		isVideo:{
			type:"BooleanCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		and:{
			type:"TileWhere",
			array:true,
			arrayRequired:false,
			required:false
		},
		or:{
			type:"TileWhere",
			array:true,
			arrayRequired:false,
			required:false
		},
		not:{
			type:"TileWhere",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	LinkWhere:{
		id:{
			type:"UUIDCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		type:{
			type:"LinkTypeCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		title:{
			type:"StringCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		externalLink:{
			type:"StringCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		internalLink:{
			type:"LinkableWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		isHighlighted:{
			type:"BooleanCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		targetBlank:{
			type:"BooleanCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		and:{
			type:"LinkWhere",
			array:true,
			arrayRequired:false,
			required:false
		},
		or:{
			type:"LinkWhere",
			array:true,
			arrayRequired:false,
			required:false
		},
		not:{
			type:"LinkWhere",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	LinkTypeCondition:{
		and:{
			type:"LinkTypeCondition",
			array:true,
			arrayRequired:false,
			required:true
		},
		or:{
			type:"LinkTypeCondition",
			array:true,
			arrayRequired:false,
			required:true
		},
		not:{
			type:"LinkTypeCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		null:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		isNull:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		eq:{
			type:"LinkType",
			array:false,
			arrayRequired:false,
			required:false
		},
		notEq:{
			type:"LinkType",
			array:false,
			arrayRequired:false,
			required:false
		},
		in:{
			type:"LinkType",
			array:true,
			arrayRequired:false,
			required:true
		},
		notIn:{
			type:"LinkType",
			array:true,
			arrayRequired:false,
			required:true
		},
		lt:{
			type:"LinkType",
			array:false,
			arrayRequired:false,
			required:false
		},
		lte:{
			type:"LinkType",
			array:false,
			arrayRequired:false,
			required:false
		},
		gt:{
			type:"LinkType",
			array:false,
			arrayRequired:false,
			required:false
		},
		gte:{
			type:"LinkType",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	SiteWhere:{
		id:{
			type:"UUIDCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		code:{
			type:"StringCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		order:{
			type:"IntCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		articles:{
			type:"ArticleWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		internalName:{
			type:"StringCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		articleCategories:{
			type:"ArticleCategoryWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		poses:{
			type:"PoseWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		poseCategories:{
			type:"PoseCategoryWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		poseDifficulties:{
			type:"PoseDifficultyWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		courses:{
			type:"CourseWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		articleListPage:{
			type:"ArticleListPageWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		courseListPage:{
			type:"CourseListPageWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		poseListPage:{
			type:"PoseListPageWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		courseLecturers:{
			type:"CourseLecturerWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		courseLecturerListPage:{
			type:"CourseLecturerListPageWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		homePages:{
			type:"HomepageWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		subscribeBox:{
			type:"SubscribeBoxWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		header:{
			type:"HeaderWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		footer:{
			type:"FooterWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		genericPages:{
			type:"GenericPageWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		courseEmail:{
			type:"CourseEmailWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		translations:{
			type:"TranslationsWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		and:{
			type:"SiteWhere",
			array:true,
			arrayRequired:false,
			required:false
		},
		or:{
			type:"SiteWhere",
			array:true,
			arrayRequired:false,
			required:false
		},
		not:{
			type:"SiteWhere",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ArticleWhere:{
		id:{
			type:"UUIDCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		headline:{
			type:"StringCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		publishedAt:{
			type:"DateCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		site:{
			type:"SiteWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		seo:{
			type:"SeoWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		link:{
			type:"LinkableWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		lead:{
			type:"StringCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		shortLead:{
			type:"StringCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		image:{
			type:"ImageWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		content:{
			type:"ContentWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		category:{
			type:"ArticleCategoryWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		and:{
			type:"ArticleWhere",
			array:true,
			arrayRequired:false,
			required:false
		},
		or:{
			type:"ArticleWhere",
			array:true,
			arrayRequired:false,
			required:false
		},
		not:{
			type:"ArticleWhere",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	DateCondition:{
		and:{
			type:"DateCondition",
			array:true,
			arrayRequired:false,
			required:true
		},
		or:{
			type:"DateCondition",
			array:true,
			arrayRequired:false,
			required:true
		},
		not:{
			type:"DateCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		null:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		isNull:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		eq:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		},
		notEq:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		},
		in:{
			type:"Date",
			array:true,
			arrayRequired:false,
			required:true
		},
		notIn:{
			type:"Date",
			array:true,
			arrayRequired:false,
			required:true
		},
		lt:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		},
		lte:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		},
		gt:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		},
		gte:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	Date: "String",
	ArticleCategoryWhere:{
		id:{
			type:"UUIDCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		label:{
			type:"StringCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		articles:{
			type:"ArticleWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		site:{
			type:"SiteWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		seo:{
			type:"SeoWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		link:{
			type:"LinkableWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		lead:{
			type:"StringCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		originalPath:{
			type:"StringCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		and:{
			type:"ArticleCategoryWhere",
			array:true,
			arrayRequired:false,
			required:false
		},
		or:{
			type:"ArticleCategoryWhere",
			array:true,
			arrayRequired:false,
			required:false
		},
		not:{
			type:"ArticleCategoryWhere",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	PoseWhere:{
		id:{
			type:"UUIDCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		headline:{
			type:"StringCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		publishedAt:{
			type:"DateCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		link:{
			type:"LinkableWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		site:{
			type:"SiteWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		image:{
			type:"ImageWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		seo:{
			type:"SeoWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		subtitle:{
			type:"StringCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		content:{
			type:"ContentWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		category:{
			type:"PoseCategoryWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		difficulty:{
			type:"PoseDifficultyWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		and:{
			type:"PoseWhere",
			array:true,
			arrayRequired:false,
			required:false
		},
		or:{
			type:"PoseWhere",
			array:true,
			arrayRequired:false,
			required:false
		},
		not:{
			type:"PoseWhere",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	PoseCategoryWhere:{
		id:{
			type:"UUIDCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		label:{
			type:"StringCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		poses:{
			type:"PoseWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		site:{
			type:"SiteWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		icon:{
			type:"ImageWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		and:{
			type:"PoseCategoryWhere",
			array:true,
			arrayRequired:false,
			required:false
		},
		or:{
			type:"PoseCategoryWhere",
			array:true,
			arrayRequired:false,
			required:false
		},
		not:{
			type:"PoseCategoryWhere",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	PoseDifficultyWhere:{
		id:{
			type:"UUIDCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		label:{
			type:"StringCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		poses:{
			type:"PoseWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		site:{
			type:"SiteWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		icon:{
			type:"ImageWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		and:{
			type:"PoseDifficultyWhere",
			array:true,
			arrayRequired:false,
			required:false
		},
		or:{
			type:"PoseDifficultyWhere",
			array:true,
			arrayRequired:false,
			required:false
		},
		not:{
			type:"PoseDifficultyWhere",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	CourseWhere:{
		id:{
			type:"UUIDCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		headline:{
			type:"StringCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		lead:{
			type:"StringCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		shortLead:{
			type:"StringCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		publishedAt:{
			type:"DateCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		site:{
			type:"SiteWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		image:{
			type:"ImageWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		seo:{
			type:"SeoWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		link:{
			type:"LinkableWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		lessons:{
			type:"CourseLessonWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		difficulty:{
			type:"StringCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		teaser:{
			type:"YoutubeVideoWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		lessonIntervalInDays:{
			type:"IntCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		content:{
			type:"ContentWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		lecturer:{
			type:"CourseLecturerWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		and:{
			type:"CourseWhere",
			array:true,
			arrayRequired:false,
			required:false
		},
		or:{
			type:"CourseWhere",
			array:true,
			arrayRequired:false,
			required:false
		},
		not:{
			type:"CourseWhere",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	CourseLessonWhere:{
		id:{
			type:"UUIDCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		order:{
			type:"IntCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		headline:{
			type:"StringCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		lead:{
			type:"StringCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		course:{
			type:"CourseWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		video:{
			type:"YoutubeVideoWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		and:{
			type:"CourseLessonWhere",
			array:true,
			arrayRequired:false,
			required:false
		},
		or:{
			type:"CourseLessonWhere",
			array:true,
			arrayRequired:false,
			required:false
		},
		not:{
			type:"CourseLessonWhere",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	YoutubeVideoWhere:{
		id:{
			type:"UUIDCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		videoId:{
			type:"StringCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		and:{
			type:"YoutubeVideoWhere",
			array:true,
			arrayRequired:false,
			required:false
		},
		or:{
			type:"YoutubeVideoWhere",
			array:true,
			arrayRequired:false,
			required:false
		},
		not:{
			type:"YoutubeVideoWhere",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	CourseLecturerWhere:{
		id:{
			type:"UUIDCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		name:{
			type:"StringCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		courses:{
			type:"CourseWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		site:{
			type:"SiteWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		link:{
			type:"LinkableWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		seo:{
			type:"SeoWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		image:{
			type:"ImageWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		content:{
			type:"ContentWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		and:{
			type:"CourseLecturerWhere",
			array:true,
			arrayRequired:false,
			required:false
		},
		or:{
			type:"CourseLecturerWhere",
			array:true,
			arrayRequired:false,
			required:false
		},
		not:{
			type:"CourseLecturerWhere",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ArticleListPageWhere:{
		id:{
			type:"UUIDCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		seo:{
			type:"SeoWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		link:{
			type:"LinkableWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		title:{
			type:"StringCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		site:{
			type:"SiteWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		and:{
			type:"ArticleListPageWhere",
			array:true,
			arrayRequired:false,
			required:false
		},
		or:{
			type:"ArticleListPageWhere",
			array:true,
			arrayRequired:false,
			required:false
		},
		not:{
			type:"ArticleListPageWhere",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	CourseListPageWhere:{
		id:{
			type:"UUIDCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		seo:{
			type:"SeoWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		link:{
			type:"LinkableWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		title:{
			type:"StringCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		site:{
			type:"SiteWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		and:{
			type:"CourseListPageWhere",
			array:true,
			arrayRequired:false,
			required:false
		},
		or:{
			type:"CourseListPageWhere",
			array:true,
			arrayRequired:false,
			required:false
		},
		not:{
			type:"CourseListPageWhere",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	PoseListPageWhere:{
		id:{
			type:"UUIDCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		link:{
			type:"LinkableWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		seo:{
			type:"SeoWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		title:{
			type:"StringCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		site:{
			type:"SiteWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		and:{
			type:"PoseListPageWhere",
			array:true,
			arrayRequired:false,
			required:false
		},
		or:{
			type:"PoseListPageWhere",
			array:true,
			arrayRequired:false,
			required:false
		},
		not:{
			type:"PoseListPageWhere",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	CourseLecturerListPageWhere:{
		id:{
			type:"UUIDCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		title:{
			type:"StringCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		site:{
			type:"SiteWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		seo:{
			type:"SeoWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		link:{
			type:"LinkableWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		and:{
			type:"CourseLecturerListPageWhere",
			array:true,
			arrayRequired:false,
			required:false
		},
		or:{
			type:"CourseLecturerListPageWhere",
			array:true,
			arrayRequired:false,
			required:false
		},
		not:{
			type:"CourseLecturerListPageWhere",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	SubscribeBoxWhere:{
		id:{
			type:"UUIDCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		title:{
			type:"StringCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		text:{
			type:"StringCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		shapeColorA:{
			type:"StringCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		shapeColorB:{
			type:"StringCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		shapeColorC:{
			type:"StringCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		externalMailingListId:{
			type:"StringCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		site:{
			type:"SiteWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		logo:{
			type:"ImageWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		emailLabel:{
			type:"StringCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		submitLabel:{
			type:"StringCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		and:{
			type:"SubscribeBoxWhere",
			array:true,
			arrayRequired:false,
			required:false
		},
		or:{
			type:"SubscribeBoxWhere",
			array:true,
			arrayRequired:false,
			required:false
		},
		not:{
			type:"SubscribeBoxWhere",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	HeaderWhere:{
		id:{
			type:"UUIDCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		primaryNavigationItems:{
			type:"HeaderPrimaryNavigationItemWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		site:{
			type:"SiteWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		logo:{
			type:"ImageWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		logoOnWhite:{
			type:"ImageWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		and:{
			type:"HeaderWhere",
			array:true,
			arrayRequired:false,
			required:false
		},
		or:{
			type:"HeaderWhere",
			array:true,
			arrayRequired:false,
			required:false
		},
		not:{
			type:"HeaderWhere",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	HeaderPrimaryNavigationItemWhere:{
		id:{
			type:"UUIDCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		order:{
			type:"IntCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		header:{
			type:"HeaderWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		link:{
			type:"LinkWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		listNavigation:{
			type:"ListNavigationWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		tiles:{
			type:"TileListWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		and:{
			type:"HeaderPrimaryNavigationItemWhere",
			array:true,
			arrayRequired:false,
			required:false
		},
		or:{
			type:"HeaderPrimaryNavigationItemWhere",
			array:true,
			arrayRequired:false,
			required:false
		},
		not:{
			type:"HeaderPrimaryNavigationItemWhere",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ListNavigationWhere:{
		id:{
			type:"UUIDCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		columns:{
			type:"ListNavigationColumnWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		and:{
			type:"ListNavigationWhere",
			array:true,
			arrayRequired:false,
			required:false
		},
		or:{
			type:"ListNavigationWhere",
			array:true,
			arrayRequired:false,
			required:false
		},
		not:{
			type:"ListNavigationWhere",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ListNavigationColumnWhere:{
		id:{
			type:"UUIDCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		order:{
			type:"IntCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		label:{
			type:"StringCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		listNavigation:{
			type:"ListNavigationWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		items:{
			type:"ListNavigationColumnItemWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		and:{
			type:"ListNavigationColumnWhere",
			array:true,
			arrayRequired:false,
			required:false
		},
		or:{
			type:"ListNavigationColumnWhere",
			array:true,
			arrayRequired:false,
			required:false
		},
		not:{
			type:"ListNavigationColumnWhere",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ListNavigationColumnItemWhere:{
		id:{
			type:"UUIDCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		order:{
			type:"IntCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		column:{
			type:"ListNavigationColumnWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		link:{
			type:"LinkWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		and:{
			type:"ListNavigationColumnItemWhere",
			array:true,
			arrayRequired:false,
			required:false
		},
		or:{
			type:"ListNavigationColumnItemWhere",
			array:true,
			arrayRequired:false,
			required:false
		},
		not:{
			type:"ListNavigationColumnItemWhere",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	FooterWhere:{
		id:{
			type:"UUIDCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		navigation:{
			type:"ListNavigationWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		site:{
			type:"SiteWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		logo:{
			type:"ImageWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		copyright:{
			type:"StringCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		attribution:{
			type:"StringCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		and:{
			type:"FooterWhere",
			array:true,
			arrayRequired:false,
			required:false
		},
		or:{
			type:"FooterWhere",
			array:true,
			arrayRequired:false,
			required:false
		},
		not:{
			type:"FooterWhere",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	GenericPageWhere:{
		id:{
			type:"UUIDCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		headline:{
			type:"StringCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		lead:{
			type:"StringCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		publishedAt:{
			type:"DateCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		site:{
			type:"SiteWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		content:{
			type:"ContentWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		seo:{
			type:"SeoWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		link:{
			type:"LinkableWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		and:{
			type:"GenericPageWhere",
			array:true,
			arrayRequired:false,
			required:false
		},
		or:{
			type:"GenericPageWhere",
			array:true,
			arrayRequired:false,
			required:false
		},
		not:{
			type:"GenericPageWhere",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	CourseEmailWhere:{
		id:{
			type:"UUIDCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		title:{
			type:"StringCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		text:{
			type:"StringCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		buttonLabel:{
			type:"StringCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		logo:{
			type:"ImageWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		cover:{
			type:"ImageWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		site:{
			type:"SiteWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		contactInfoEmailAddress:{
			type:"StringCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		senderEmail:{
			type:"StringCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		and:{
			type:"CourseEmailWhere",
			array:true,
			arrayRequired:false,
			required:false
		},
		or:{
			type:"CourseEmailWhere",
			array:true,
			arrayRequired:false,
			required:false
		},
		not:{
			type:"CourseEmailWhere",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	TranslationsWhere:{
		id:{
			type:"UUIDCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		home:{
			type:"StringCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		articles:{
			type:"StringCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		article:{
			type:"StringCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		articleMore:{
			type:"StringCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		categories:{
			type:"StringCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		lecturers:{
			type:"StringCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		lecturer:{
			type:"StringCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		courses:{
			type:"StringCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		course:{
			type:"StringCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		poses:{
			type:"StringCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		pose:{
			type:"StringCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		site:{
			type:"SiteWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		posesLong:{
			type:"StringCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		posesMore:{
			type:"StringCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		listAll:{
			type:"StringCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		difficulty:{
			type:"StringCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		skillLevel:{
			type:"StringCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		signIn:{
			type:"StringCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		error:{
			type:"StringCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		and:{
			type:"TranslationsWhere",
			array:true,
			arrayRequired:false,
			required:false
		},
		or:{
			type:"TranslationsWhere",
			array:true,
			arrayRequired:false,
			required:false
		},
		not:{
			type:"TranslationsWhere",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	NewsletterSubscribeSuccessPageWhere:{
		id:{
			type:"UUIDCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		title:{
			type:"StringCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		returnLinkText:{
			type:"StringCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		link:{
			type:"LinkableWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		site:{
			type:"SiteWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		seo:{
			type:"SeoWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		and:{
			type:"NewsletterSubscribeSuccessPageWhere",
			array:true,
			arrayRequired:false,
			required:false
		},
		or:{
			type:"NewsletterSubscribeSuccessPageWhere",
			array:true,
			arrayRequired:false,
			required:false
		},
		not:{
			type:"NewsletterSubscribeSuccessPageWhere",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	Homepage:{
		link:{
			filter:{
				type:"LinkableWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		seo:{
			filter:{
				type:"SeoWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		content:{
			filter:{
				type:"ContentWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		heroTiles:{
			filter:{
				type:"TileListWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		site:{
			filter:{
				type:"SiteWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	Seo:{
		ogImage:{
			filter:{
				type:"ImageWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	Content:{
		blocks:{
			filter:{
				type:"ContentBlockWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"ContentBlockOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		blocksByReferences:{
			by:{
				type:"ContentBlocksByReferencesUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"ContentBlockWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		paginateBlocks:{
			filter:{
				type:"ContentBlockWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"ContentBlockOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			first:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	ContentBlock:{
		content:{
			filter:{
				type:"ContentWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		references:{
			filter:{
				type:"ContentReferenceWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"ContentReferenceOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		paginateReferences:{
			filter:{
				type:"ContentReferenceWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"ContentReferenceOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			first:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	ContentReference:{
		block:{
			filter:{
				type:"ContentBlockWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		image:{
			filter:{
				type:"ImageWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		people:{
			filter:{
				type:"PersonListWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	PersonList:{
		items:{
			filter:{
				type:"PersonListItemWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"PersonListItemOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		paginateItems:{
			filter:{
				type:"PersonListItemWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"PersonListItemOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			first:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	PersonListItem:{
		list:{
			filter:{
				type:"PersonListWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		person:{
			filter:{
				type:"PersonWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	Person:{
		image:{
			filter:{
				type:"ImageWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	PersonListItemOrderBy:{
		_random:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		_randomSeeded:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:false
		},
		order:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:false
		},
		list:{
			type:"PersonListOrderBy",
			array:false,
			arrayRequired:false,
			required:false
		},
		person:{
			type:"PersonOrderBy",
			array:false,
			arrayRequired:false,
			required:false
		},
		about:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	OrderDirection: "enum",
	PersonListOrderBy:{
		_random:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		_randomSeeded:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	PersonOrderBy:{
		_random:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		_randomSeeded:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:false
		},
		name:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:false
		},
		about:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:false
		},
		email:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:false
		},
		phone:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:false
		},
		image:{
			type:"ImageOrderBy",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ImageOrderBy:{
		_random:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		_randomSeeded:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:false
		},
		url:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:false
		},
		width:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:false
		},
		height:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:false
		},
		size:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:false
		},
		type:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:false
		},
		alt:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ContentReferenceOrderBy:{
		_random:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		_randomSeeded:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:false
		},
		type:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:false
		},
		primaryText:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:false
		},
		block:{
			type:"ContentBlockOrderBy",
			array:false,
			arrayRequired:false,
			required:false
		},
		image:{
			type:"ImageOrderBy",
			array:false,
			arrayRequired:false,
			required:false
		},
		highlightFirstArticle:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:false
		},
		linkToMoreArticles:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:false
		},
		people:{
			type:"PersonListOrderBy",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ContentBlockOrderBy:{
		_random:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		_randomSeeded:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:false
		},
		order:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:false
		},
		json:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:false
		},
		content:{
			type:"ContentOrderBy",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ContentOrderBy:{
		_random:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		_randomSeeded:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ContentBlocksByReferencesUniqueWhere:{
		references:{
			type:"ContentReferenceUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ContentReferenceUniqueWhere:{
		id:{
			type:"UUID",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	TileList:{
		items:{
			filter:{
				type:"TileListItemWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"TileListItemOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		itemsByTile:{
			by:{
				type:"TileListItemsByTileUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"TileListItemWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		paginateItems:{
			filter:{
				type:"TileListItemWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"TileListItemOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			first:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	TileListItem:{
		list:{
			filter:{
				type:"TileListWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		tile:{
			filter:{
				type:"TileWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	Tile:{
		image:{
			filter:{
				type:"ImageWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		link:{
			filter:{
				type:"LinkWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	TileListItemOrderBy:{
		_random:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		_randomSeeded:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:false
		},
		order:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:false
		},
		list:{
			type:"TileListOrderBy",
			array:false,
			arrayRequired:false,
			required:false
		},
		tile:{
			type:"TileOrderBy",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	TileListOrderBy:{
		_random:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		_randomSeeded:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	TileOrderBy:{
		_random:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		_randomSeeded:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:false
		},
		image:{
			type:"ImageOrderBy",
			array:false,
			arrayRequired:false,
			required:false
		},
		link:{
			type:"LinkOrderBy",
			array:false,
			arrayRequired:false,
			required:false
		},
		isVideo:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	LinkOrderBy:{
		_random:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		_randomSeeded:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:false
		},
		type:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:false
		},
		title:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:false
		},
		externalLink:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:false
		},
		internalLink:{
			type:"LinkableOrderBy",
			array:false,
			arrayRequired:false,
			required:false
		},
		isHighlighted:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:false
		},
		targetBlank:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	LinkableOrderBy:{
		_random:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		_randomSeeded:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:false
		},
		url:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:false
		},
		redirect:{
			type:"RedirectOrderBy",
			array:false,
			arrayRequired:false,
			required:false
		},
		homepage:{
			type:"HomepageOrderBy",
			array:false,
			arrayRequired:false,
			required:false
		},
		article:{
			type:"ArticleOrderBy",
			array:false,
			arrayRequired:false,
			required:false
		},
		articleCategory:{
			type:"ArticleCategoryOrderBy",
			array:false,
			arrayRequired:false,
			required:false
		},
		pose:{
			type:"PoseOrderBy",
			array:false,
			arrayRequired:false,
			required:false
		},
		course:{
			type:"CourseOrderBy",
			array:false,
			arrayRequired:false,
			required:false
		},
		articleList:{
			type:"ArticleListPageOrderBy",
			array:false,
			arrayRequired:false,
			required:false
		},
		courseList:{
			type:"CourseListPageOrderBy",
			array:false,
			arrayRequired:false,
			required:false
		},
		poseList:{
			type:"PoseListPageOrderBy",
			array:false,
			arrayRequired:false,
			required:false
		},
		courseLecturer:{
			type:"CourseLecturerOrderBy",
			array:false,
			arrayRequired:false,
			required:false
		},
		courseLecturerList:{
			type:"CourseLecturerListPageOrderBy",
			array:false,
			arrayRequired:false,
			required:false
		},
		newsletterSubscribeSuccessPage:{
			type:"NewsletterSubscribeSuccessPageOrderBy",
			array:false,
			arrayRequired:false,
			required:false
		},
		genericPage:{
			type:"GenericPageOrderBy",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	RedirectOrderBy:{
		_random:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		_randomSeeded:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:false
		},
		link:{
			type:"LinkableOrderBy",
			array:false,
			arrayRequired:false,
			required:false
		},
		target:{
			type:"LinkableOrderBy",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	HomepageOrderBy:{
		_random:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		_randomSeeded:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:false
		},
		link:{
			type:"LinkableOrderBy",
			array:false,
			arrayRequired:false,
			required:false
		},
		subtitle:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:false
		},
		seo:{
			type:"SeoOrderBy",
			array:false,
			arrayRequired:false,
			required:false
		},
		content:{
			type:"ContentOrderBy",
			array:false,
			arrayRequired:false,
			required:false
		},
		heroTiles:{
			type:"TileListOrderBy",
			array:false,
			arrayRequired:false,
			required:false
		},
		site:{
			type:"SiteOrderBy",
			array:false,
			arrayRequired:false,
			required:false
		},
		titlePartA:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:false
		},
		titlePartB:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:false
		},
		titlePartC:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:false
		},
		titlePartD:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	SeoOrderBy:{
		_random:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		_randomSeeded:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:false
		},
		title:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:false
		},
		description:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:false
		},
		ogTitle:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:false
		},
		ogDescription:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:false
		},
		ogImage:{
			type:"ImageOrderBy",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	SiteOrderBy:{
		_random:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		_randomSeeded:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:false
		},
		code:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:false
		},
		order:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:false
		},
		internalName:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:false
		},
		articleListPage:{
			type:"ArticleListPageOrderBy",
			array:false,
			arrayRequired:false,
			required:false
		},
		courseListPage:{
			type:"CourseListPageOrderBy",
			array:false,
			arrayRequired:false,
			required:false
		},
		poseListPage:{
			type:"PoseListPageOrderBy",
			array:false,
			arrayRequired:false,
			required:false
		},
		courseLecturerListPage:{
			type:"CourseLecturerListPageOrderBy",
			array:false,
			arrayRequired:false,
			required:false
		},
		homePages:{
			type:"HomepageOrderBy",
			array:false,
			arrayRequired:false,
			required:false
		},
		subscribeBox:{
			type:"SubscribeBoxOrderBy",
			array:false,
			arrayRequired:false,
			required:false
		},
		header:{
			type:"HeaderOrderBy",
			array:false,
			arrayRequired:false,
			required:false
		},
		footer:{
			type:"FooterOrderBy",
			array:false,
			arrayRequired:false,
			required:false
		},
		courseEmail:{
			type:"CourseEmailOrderBy",
			array:false,
			arrayRequired:false,
			required:false
		},
		translations:{
			type:"TranslationsOrderBy",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ArticleListPageOrderBy:{
		_random:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		_randomSeeded:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:false
		},
		seo:{
			type:"SeoOrderBy",
			array:false,
			arrayRequired:false,
			required:false
		},
		link:{
			type:"LinkableOrderBy",
			array:false,
			arrayRequired:false,
			required:false
		},
		title:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:false
		},
		site:{
			type:"SiteOrderBy",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	CourseListPageOrderBy:{
		_random:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		_randomSeeded:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:false
		},
		seo:{
			type:"SeoOrderBy",
			array:false,
			arrayRequired:false,
			required:false
		},
		link:{
			type:"LinkableOrderBy",
			array:false,
			arrayRequired:false,
			required:false
		},
		title:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:false
		},
		site:{
			type:"SiteOrderBy",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	PoseListPageOrderBy:{
		_random:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		_randomSeeded:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:false
		},
		link:{
			type:"LinkableOrderBy",
			array:false,
			arrayRequired:false,
			required:false
		},
		seo:{
			type:"SeoOrderBy",
			array:false,
			arrayRequired:false,
			required:false
		},
		title:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:false
		},
		site:{
			type:"SiteOrderBy",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	CourseLecturerListPageOrderBy:{
		_random:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		_randomSeeded:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:false
		},
		title:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:false
		},
		site:{
			type:"SiteOrderBy",
			array:false,
			arrayRequired:false,
			required:false
		},
		seo:{
			type:"SeoOrderBy",
			array:false,
			arrayRequired:false,
			required:false
		},
		link:{
			type:"LinkableOrderBy",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	SubscribeBoxOrderBy:{
		_random:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		_randomSeeded:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:false
		},
		title:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:false
		},
		text:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:false
		},
		shapeColorA:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:false
		},
		shapeColorB:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:false
		},
		shapeColorC:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:false
		},
		externalMailingListId:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:false
		},
		site:{
			type:"SiteOrderBy",
			array:false,
			arrayRequired:false,
			required:false
		},
		logo:{
			type:"ImageOrderBy",
			array:false,
			arrayRequired:false,
			required:false
		},
		emailLabel:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:false
		},
		submitLabel:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	HeaderOrderBy:{
		_random:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		_randomSeeded:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:false
		},
		site:{
			type:"SiteOrderBy",
			array:false,
			arrayRequired:false,
			required:false
		},
		logo:{
			type:"ImageOrderBy",
			array:false,
			arrayRequired:false,
			required:false
		},
		logoOnWhite:{
			type:"ImageOrderBy",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	FooterOrderBy:{
		_random:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		_randomSeeded:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:false
		},
		navigation:{
			type:"ListNavigationOrderBy",
			array:false,
			arrayRequired:false,
			required:false
		},
		site:{
			type:"SiteOrderBy",
			array:false,
			arrayRequired:false,
			required:false
		},
		logo:{
			type:"ImageOrderBy",
			array:false,
			arrayRequired:false,
			required:false
		},
		copyright:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:false
		},
		attribution:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ListNavigationOrderBy:{
		_random:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		_randomSeeded:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	CourseEmailOrderBy:{
		_random:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		_randomSeeded:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:false
		},
		title:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:false
		},
		text:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:false
		},
		buttonLabel:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:false
		},
		logo:{
			type:"ImageOrderBy",
			array:false,
			arrayRequired:false,
			required:false
		},
		cover:{
			type:"ImageOrderBy",
			array:false,
			arrayRequired:false,
			required:false
		},
		site:{
			type:"SiteOrderBy",
			array:false,
			arrayRequired:false,
			required:false
		},
		contactInfoEmailAddress:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:false
		},
		senderEmail:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	TranslationsOrderBy:{
		_random:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		_randomSeeded:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:false
		},
		home:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:false
		},
		articles:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:false
		},
		article:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:false
		},
		articleMore:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:false
		},
		categories:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:false
		},
		lecturers:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:false
		},
		lecturer:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:false
		},
		courses:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:false
		},
		course:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:false
		},
		poses:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:false
		},
		pose:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:false
		},
		site:{
			type:"SiteOrderBy",
			array:false,
			arrayRequired:false,
			required:false
		},
		posesLong:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:false
		},
		posesMore:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:false
		},
		listAll:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:false
		},
		difficulty:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:false
		},
		skillLevel:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:false
		},
		signIn:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:false
		},
		error:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ArticleOrderBy:{
		_random:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		_randomSeeded:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:false
		},
		headline:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:false
		},
		publishedAt:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:false
		},
		site:{
			type:"SiteOrderBy",
			array:false,
			arrayRequired:false,
			required:false
		},
		seo:{
			type:"SeoOrderBy",
			array:false,
			arrayRequired:false,
			required:false
		},
		link:{
			type:"LinkableOrderBy",
			array:false,
			arrayRequired:false,
			required:false
		},
		lead:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:false
		},
		shortLead:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:false
		},
		image:{
			type:"ImageOrderBy",
			array:false,
			arrayRequired:false,
			required:false
		},
		content:{
			type:"ContentOrderBy",
			array:false,
			arrayRequired:false,
			required:false
		},
		category:{
			type:"ArticleCategoryOrderBy",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ArticleCategoryOrderBy:{
		_random:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		_randomSeeded:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:false
		},
		label:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:false
		},
		site:{
			type:"SiteOrderBy",
			array:false,
			arrayRequired:false,
			required:false
		},
		seo:{
			type:"SeoOrderBy",
			array:false,
			arrayRequired:false,
			required:false
		},
		link:{
			type:"LinkableOrderBy",
			array:false,
			arrayRequired:false,
			required:false
		},
		lead:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:false
		},
		originalPath:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	PoseOrderBy:{
		_random:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		_randomSeeded:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:false
		},
		headline:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:false
		},
		publishedAt:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:false
		},
		link:{
			type:"LinkableOrderBy",
			array:false,
			arrayRequired:false,
			required:false
		},
		site:{
			type:"SiteOrderBy",
			array:false,
			arrayRequired:false,
			required:false
		},
		image:{
			type:"ImageOrderBy",
			array:false,
			arrayRequired:false,
			required:false
		},
		seo:{
			type:"SeoOrderBy",
			array:false,
			arrayRequired:false,
			required:false
		},
		subtitle:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:false
		},
		content:{
			type:"ContentOrderBy",
			array:false,
			arrayRequired:false,
			required:false
		},
		category:{
			type:"PoseCategoryOrderBy",
			array:false,
			arrayRequired:false,
			required:false
		},
		difficulty:{
			type:"PoseDifficultyOrderBy",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	PoseCategoryOrderBy:{
		_random:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		_randomSeeded:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:false
		},
		label:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:false
		},
		site:{
			type:"SiteOrderBy",
			array:false,
			arrayRequired:false,
			required:false
		},
		icon:{
			type:"ImageOrderBy",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	PoseDifficultyOrderBy:{
		_random:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		_randomSeeded:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:false
		},
		label:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:false
		},
		site:{
			type:"SiteOrderBy",
			array:false,
			arrayRequired:false,
			required:false
		},
		icon:{
			type:"ImageOrderBy",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	CourseOrderBy:{
		_random:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		_randomSeeded:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:false
		},
		headline:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:false
		},
		lead:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:false
		},
		shortLead:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:false
		},
		publishedAt:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:false
		},
		site:{
			type:"SiteOrderBy",
			array:false,
			arrayRequired:false,
			required:false
		},
		image:{
			type:"ImageOrderBy",
			array:false,
			arrayRequired:false,
			required:false
		},
		seo:{
			type:"SeoOrderBy",
			array:false,
			arrayRequired:false,
			required:false
		},
		link:{
			type:"LinkableOrderBy",
			array:false,
			arrayRequired:false,
			required:false
		},
		difficulty:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:false
		},
		teaser:{
			type:"YoutubeVideoOrderBy",
			array:false,
			arrayRequired:false,
			required:false
		},
		lessonIntervalInDays:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:false
		},
		content:{
			type:"ContentOrderBy",
			array:false,
			arrayRequired:false,
			required:false
		},
		lecturer:{
			type:"CourseLecturerOrderBy",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	YoutubeVideoOrderBy:{
		_random:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		_randomSeeded:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:false
		},
		videoId:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	CourseLecturerOrderBy:{
		_random:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		_randomSeeded:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:false
		},
		name:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:false
		},
		site:{
			type:"SiteOrderBy",
			array:false,
			arrayRequired:false,
			required:false
		},
		link:{
			type:"LinkableOrderBy",
			array:false,
			arrayRequired:false,
			required:false
		},
		seo:{
			type:"SeoOrderBy",
			array:false,
			arrayRequired:false,
			required:false
		},
		image:{
			type:"ImageOrderBy",
			array:false,
			arrayRequired:false,
			required:false
		},
		content:{
			type:"ContentOrderBy",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	NewsletterSubscribeSuccessPageOrderBy:{
		_random:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		_randomSeeded:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:false
		},
		title:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:false
		},
		returnLinkText:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:false
		},
		link:{
			type:"LinkableOrderBy",
			array:false,
			arrayRequired:false,
			required:false
		},
		site:{
			type:"SiteOrderBy",
			array:false,
			arrayRequired:false,
			required:false
		},
		seo:{
			type:"SeoOrderBy",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	GenericPageOrderBy:{
		_random:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		_randomSeeded:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:false
		},
		headline:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:false
		},
		lead:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:false
		},
		publishedAt:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:false
		},
		site:{
			type:"SiteOrderBy",
			array:false,
			arrayRequired:false,
			required:false
		},
		content:{
			type:"ContentOrderBy",
			array:false,
			arrayRequired:false,
			required:false
		},
		seo:{
			type:"SeoOrderBy",
			array:false,
			arrayRequired:false,
			required:false
		},
		link:{
			type:"LinkableOrderBy",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	TileListItemsByTileUniqueWhere:{
		tile:{
			type:"TileUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	TileUniqueWhere:{
		id:{
			type:"UUID",
			array:false,
			arrayRequired:false,
			required:false
		},
		link:{
			type:"LinkUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	LinkUniqueWhere:{
		id:{
			type:"UUID",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	Site:{
		articles:{
			filter:{
				type:"ArticleWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"ArticleOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		articleCategories:{
			filter:{
				type:"ArticleCategoryWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"ArticleCategoryOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		poses:{
			filter:{
				type:"PoseWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"PoseOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		poseCategories:{
			filter:{
				type:"PoseCategoryWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"PoseCategoryOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		poseDifficulties:{
			filter:{
				type:"PoseDifficultyWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"PoseDifficultyOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		courses:{
			filter:{
				type:"CourseWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"CourseOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		articleListPage:{
			filter:{
				type:"ArticleListPageWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		courseListPage:{
			filter:{
				type:"CourseListPageWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		poseListPage:{
			filter:{
				type:"PoseListPageWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		courseLecturers:{
			filter:{
				type:"CourseLecturerWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"CourseLecturerOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		courseLecturerListPage:{
			filter:{
				type:"CourseLecturerListPageWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		homePages:{
			filter:{
				type:"HomepageWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		subscribeBox:{
			filter:{
				type:"SubscribeBoxWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		header:{
			filter:{
				type:"HeaderWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		footer:{
			filter:{
				type:"FooterWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		genericPages:{
			filter:{
				type:"GenericPageWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"GenericPageOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		courseEmail:{
			filter:{
				type:"CourseEmailWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		translations:{
			filter:{
				type:"TranslationsWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		articlesBySeo:{
			by:{
				type:"SiteArticlesBySeoUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"ArticleWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		articlesByLink:{
			by:{
				type:"SiteArticlesByLinkUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"ArticleWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		articlesByContent:{
			by:{
				type:"SiteArticlesByContentUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"ArticleWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		articleCategoriesByArticles:{
			by:{
				type:"SiteArticleCategoriesByArticlesUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"ArticleCategoryWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		articleCategoriesBySeo:{
			by:{
				type:"SiteArticleCategoriesBySeoUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"ArticleCategoryWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		articleCategoriesByLink:{
			by:{
				type:"SiteArticleCategoriesByLinkUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"ArticleCategoryWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		posesByLink:{
			by:{
				type:"SitePosesByLinkUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"PoseWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		posesBySeo:{
			by:{
				type:"SitePosesBySeoUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"PoseWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		posesByContent:{
			by:{
				type:"SitePosesByContentUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"PoseWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		poseCategoriesByPoses:{
			by:{
				type:"SitePoseCategoriesByPosesUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"PoseCategoryWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		poseDifficultiesByPoses:{
			by:{
				type:"SitePoseDifficultiesByPosesUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"PoseDifficultyWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		coursesBySeo:{
			by:{
				type:"SiteCoursesBySeoUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"CourseWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		coursesByLink:{
			by:{
				type:"SiteCoursesByLinkUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"CourseWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		coursesByLessons:{
			by:{
				type:"SiteCoursesByLessonsUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"CourseWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		coursesByContent:{
			by:{
				type:"SiteCoursesByContentUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"CourseWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		courseLecturersByCourses:{
			by:{
				type:"SiteCourseLecturersByCoursesUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"CourseLecturerWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		courseLecturersByLink:{
			by:{
				type:"SiteCourseLecturersByLinkUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"CourseLecturerWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		courseLecturersBySeo:{
			by:{
				type:"SiteCourseLecturersBySeoUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"CourseLecturerWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		courseLecturersByContent:{
			by:{
				type:"SiteCourseLecturersByContentUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"CourseLecturerWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		genericPagesByContent:{
			by:{
				type:"SiteGenericPagesByContentUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"GenericPageWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		genericPagesBySeo:{
			by:{
				type:"SiteGenericPagesBySeoUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"GenericPageWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		genericPagesByLink:{
			by:{
				type:"SiteGenericPagesByLinkUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"GenericPageWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		paginateArticles:{
			filter:{
				type:"ArticleWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"ArticleOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			first:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		paginateArticleCategories:{
			filter:{
				type:"ArticleCategoryWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"ArticleCategoryOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			first:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		paginatePoses:{
			filter:{
				type:"PoseWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"PoseOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			first:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		paginatePoseCategories:{
			filter:{
				type:"PoseCategoryWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"PoseCategoryOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			first:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		paginatePoseDifficulties:{
			filter:{
				type:"PoseDifficultyWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"PoseDifficultyOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			first:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		paginateCourses:{
			filter:{
				type:"CourseWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"CourseOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			first:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		paginateCourseLecturers:{
			filter:{
				type:"CourseLecturerWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"CourseLecturerOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			first:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		paginateGenericPages:{
			filter:{
				type:"GenericPageWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"GenericPageOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			first:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	Article:{
		site:{
			filter:{
				type:"SiteWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		seo:{
			filter:{
				type:"SeoWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		link:{
			filter:{
				type:"LinkableWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		image:{
			filter:{
				type:"ImageWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		content:{
			filter:{
				type:"ContentWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		category:{
			filter:{
				type:"ArticleCategoryWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	ArticleCategory:{
		articles:{
			filter:{
				type:"ArticleWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"ArticleOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		site:{
			filter:{
				type:"SiteWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		seo:{
			filter:{
				type:"SeoWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		link:{
			filter:{
				type:"LinkableWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		articlesBySeo:{
			by:{
				type:"ArticleCategoryArticlesBySeoUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"ArticleWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		articlesByLink:{
			by:{
				type:"ArticleCategoryArticlesByLinkUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"ArticleWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		articlesByContent:{
			by:{
				type:"ArticleCategoryArticlesByContentUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"ArticleWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		paginateArticles:{
			filter:{
				type:"ArticleWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"ArticleOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			first:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	ArticleCategoryArticlesBySeoUniqueWhere:{
		seo:{
			type:"SeoUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	SeoUniqueWhere:{
		id:{
			type:"UUID",
			array:false,
			arrayRequired:false,
			required:false
		},
		ogImage:{
			type:"ImageUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ImageUniqueWhere:{
		id:{
			type:"UUID",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ArticleCategoryArticlesByLinkUniqueWhere:{
		link:{
			type:"LinkableUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	LinkableUniqueWhere:{
		id:{
			type:"UUID",
			array:false,
			arrayRequired:false,
			required:false
		},
		url:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		redirect:{
			type:"RedirectUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		homepage:{
			type:"HomepageUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		article:{
			type:"ArticleUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		articleCategory:{
			type:"ArticleCategoryUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		pose:{
			type:"PoseUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		course:{
			type:"CourseUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		articleList:{
			type:"ArticleListPageUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		courseList:{
			type:"CourseListPageUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		poseList:{
			type:"PoseListPageUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		courseLecturer:{
			type:"CourseLecturerUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		courseLecturerList:{
			type:"CourseLecturerListPageUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		newsletterSubscribeSuccessPage:{
			type:"NewsletterSubscribeSuccessPageUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		genericPage:{
			type:"GenericPageUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	RedirectUniqueWhere:{
		id:{
			type:"UUID",
			array:false,
			arrayRequired:false,
			required:false
		},
		link:{
			type:"LinkableUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	HomepageUniqueWhere:{
		id:{
			type:"UUID",
			array:false,
			arrayRequired:false,
			required:false
		},
		link:{
			type:"LinkableUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		seo:{
			type:"SeoUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		content:{
			type:"ContentUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		heroTiles:{
			type:"TileListUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		site:{
			type:"SiteUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ContentUniqueWhere:{
		id:{
			type:"UUID",
			array:false,
			arrayRequired:false,
			required:false
		},
		blocks:{
			type:"ContentBlockUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ContentBlockUniqueWhere:{
		id:{
			type:"UUID",
			array:false,
			arrayRequired:false,
			required:false
		},
		references:{
			type:"ContentReferenceUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	TileListUniqueWhere:{
		id:{
			type:"UUID",
			array:false,
			arrayRequired:false,
			required:false
		},
		items:{
			type:"TileListItemUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	TileListItemUniqueWhere:{
		id:{
			type:"UUID",
			array:false,
			arrayRequired:false,
			required:false
		},
		tile:{
			type:"TileUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	SiteUniqueWhere:{
		id:{
			type:"UUID",
			array:false,
			arrayRequired:false,
			required:false
		},
		code:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		articles:{
			type:"ArticleUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		articleCategories:{
			type:"ArticleCategoryUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		poses:{
			type:"PoseUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		poseCategories:{
			type:"PoseCategoryUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		poseDifficulties:{
			type:"PoseDifficultyUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		courses:{
			type:"CourseUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		articleListPage:{
			type:"ArticleListPageUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		courseListPage:{
			type:"CourseListPageUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		poseListPage:{
			type:"PoseListPageUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		courseLecturers:{
			type:"CourseLecturerUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		courseLecturerListPage:{
			type:"CourseLecturerListPageUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		homePages:{
			type:"HomepageUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		subscribeBox:{
			type:"SubscribeBoxUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		header:{
			type:"HeaderUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		footer:{
			type:"FooterUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		genericPages:{
			type:"GenericPageUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		courseEmail:{
			type:"CourseEmailUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		translations:{
			type:"TranslationsUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ArticleUniqueWhere:{
		id:{
			type:"UUID",
			array:false,
			arrayRequired:false,
			required:false
		},
		seo:{
			type:"SeoUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		link:{
			type:"LinkableUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		content:{
			type:"ContentUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ArticleCategoryUniqueWhere:{
		id:{
			type:"UUID",
			array:false,
			arrayRequired:false,
			required:false
		},
		articles:{
			type:"ArticleUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		seo:{
			type:"SeoUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		link:{
			type:"LinkableUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	PoseUniqueWhere:{
		id:{
			type:"UUID",
			array:false,
			arrayRequired:false,
			required:false
		},
		link:{
			type:"LinkableUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		seo:{
			type:"SeoUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		content:{
			type:"ContentUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	PoseCategoryUniqueWhere:{
		id:{
			type:"UUID",
			array:false,
			arrayRequired:false,
			required:false
		},
		poses:{
			type:"PoseUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	PoseDifficultyUniqueWhere:{
		id:{
			type:"UUID",
			array:false,
			arrayRequired:false,
			required:false
		},
		poses:{
			type:"PoseUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	CourseUniqueWhere:{
		id:{
			type:"UUID",
			array:false,
			arrayRequired:false,
			required:false
		},
		seo:{
			type:"SeoUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		link:{
			type:"LinkableUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		lessons:{
			type:"CourseLessonUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		content:{
			type:"ContentUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	CourseLessonUniqueWhere:{
		id:{
			type:"UUID",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ArticleListPageUniqueWhere:{
		id:{
			type:"UUID",
			array:false,
			arrayRequired:false,
			required:false
		},
		seo:{
			type:"SeoUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		link:{
			type:"LinkableUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		site:{
			type:"SiteUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	CourseListPageUniqueWhere:{
		id:{
			type:"UUID",
			array:false,
			arrayRequired:false,
			required:false
		},
		seo:{
			type:"SeoUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		link:{
			type:"LinkableUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		site:{
			type:"SiteUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	PoseListPageUniqueWhere:{
		id:{
			type:"UUID",
			array:false,
			arrayRequired:false,
			required:false
		},
		link:{
			type:"LinkableUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		seo:{
			type:"SeoUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		site:{
			type:"SiteUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	CourseLecturerUniqueWhere:{
		id:{
			type:"UUID",
			array:false,
			arrayRequired:false,
			required:false
		},
		courses:{
			type:"CourseUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		link:{
			type:"LinkableUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		seo:{
			type:"SeoUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		content:{
			type:"ContentUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	CourseLecturerListPageUniqueWhere:{
		id:{
			type:"UUID",
			array:false,
			arrayRequired:false,
			required:false
		},
		site:{
			type:"SiteUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		seo:{
			type:"SeoUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		link:{
			type:"LinkableUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	SubscribeBoxUniqueWhere:{
		id:{
			type:"UUID",
			array:false,
			arrayRequired:false,
			required:false
		},
		site:{
			type:"SiteUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		logo:{
			type:"ImageUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	HeaderUniqueWhere:{
		id:{
			type:"UUID",
			array:false,
			arrayRequired:false,
			required:false
		},
		primaryNavigationItems:{
			type:"HeaderPrimaryNavigationItemUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		site:{
			type:"SiteUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		logo:{
			type:"ImageUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		logoOnWhite:{
			type:"ImageUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	HeaderPrimaryNavigationItemUniqueWhere:{
		id:{
			type:"UUID",
			array:false,
			arrayRequired:false,
			required:false
		},
		link:{
			type:"LinkUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		listNavigation:{
			type:"ListNavigationUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		tiles:{
			type:"TileListUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ListNavigationUniqueWhere:{
		id:{
			type:"UUID",
			array:false,
			arrayRequired:false,
			required:false
		},
		columns:{
			type:"ListNavigationColumnUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ListNavigationColumnUniqueWhere:{
		id:{
			type:"UUID",
			array:false,
			arrayRequired:false,
			required:false
		},
		items:{
			type:"ListNavigationColumnItemUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ListNavigationColumnItemUniqueWhere:{
		id:{
			type:"UUID",
			array:false,
			arrayRequired:false,
			required:false
		},
		link:{
			type:"LinkUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	FooterUniqueWhere:{
		id:{
			type:"UUID",
			array:false,
			arrayRequired:false,
			required:false
		},
		navigation:{
			type:"ListNavigationUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		site:{
			type:"SiteUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		logo:{
			type:"ImageUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	GenericPageUniqueWhere:{
		id:{
			type:"UUID",
			array:false,
			arrayRequired:false,
			required:false
		},
		content:{
			type:"ContentUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		seo:{
			type:"SeoUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		link:{
			type:"LinkableUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	CourseEmailUniqueWhere:{
		id:{
			type:"UUID",
			array:false,
			arrayRequired:false,
			required:false
		},
		logo:{
			type:"ImageUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		cover:{
			type:"ImageUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		site:{
			type:"SiteUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	TranslationsUniqueWhere:{
		id:{
			type:"UUID",
			array:false,
			arrayRequired:false,
			required:false
		},
		site:{
			type:"SiteUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	NewsletterSubscribeSuccessPageUniqueWhere:{
		id:{
			type:"UUID",
			array:false,
			arrayRequired:false,
			required:false
		},
		link:{
			type:"LinkableUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		site:{
			type:"SiteUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		seo:{
			type:"SeoUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ArticleCategoryArticlesByContentUniqueWhere:{
		content:{
			type:"ContentUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	Pose:{
		link:{
			filter:{
				type:"LinkableWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		site:{
			filter:{
				type:"SiteWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		image:{
			filter:{
				type:"ImageWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		seo:{
			filter:{
				type:"SeoWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		content:{
			filter:{
				type:"ContentWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		category:{
			filter:{
				type:"PoseCategoryWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		difficulty:{
			filter:{
				type:"PoseDifficultyWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	PoseCategory:{
		poses:{
			filter:{
				type:"PoseWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"PoseOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		site:{
			filter:{
				type:"SiteWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		icon:{
			filter:{
				type:"ImageWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		posesByLink:{
			by:{
				type:"PoseCategoryPosesByLinkUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"PoseWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		posesBySeo:{
			by:{
				type:"PoseCategoryPosesBySeoUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"PoseWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		posesByContent:{
			by:{
				type:"PoseCategoryPosesByContentUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"PoseWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		paginatePoses:{
			filter:{
				type:"PoseWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"PoseOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			first:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	PoseCategoryPosesByLinkUniqueWhere:{
		link:{
			type:"LinkableUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	PoseCategoryPosesBySeoUniqueWhere:{
		seo:{
			type:"SeoUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	PoseCategoryPosesByContentUniqueWhere:{
		content:{
			type:"ContentUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	PoseDifficulty:{
		poses:{
			filter:{
				type:"PoseWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"PoseOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		site:{
			filter:{
				type:"SiteWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		icon:{
			filter:{
				type:"ImageWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		posesByLink:{
			by:{
				type:"PoseDifficultyPosesByLinkUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"PoseWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		posesBySeo:{
			by:{
				type:"PoseDifficultyPosesBySeoUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"PoseWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		posesByContent:{
			by:{
				type:"PoseDifficultyPosesByContentUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"PoseWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		paginatePoses:{
			filter:{
				type:"PoseWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"PoseOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			first:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	PoseDifficultyPosesByLinkUniqueWhere:{
		link:{
			type:"LinkableUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	PoseDifficultyPosesBySeoUniqueWhere:{
		seo:{
			type:"SeoUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	PoseDifficultyPosesByContentUniqueWhere:{
		content:{
			type:"ContentUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	Course:{
		site:{
			filter:{
				type:"SiteWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		image:{
			filter:{
				type:"ImageWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		seo:{
			filter:{
				type:"SeoWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		link:{
			filter:{
				type:"LinkableWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		lessons:{
			filter:{
				type:"CourseLessonWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"CourseLessonOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		teaser:{
			filter:{
				type:"YoutubeVideoWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		content:{
			filter:{
				type:"ContentWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		lecturer:{
			filter:{
				type:"CourseLecturerWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		paginateLessons:{
			filter:{
				type:"CourseLessonWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"CourseLessonOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			first:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	CourseLesson:{
		course:{
			filter:{
				type:"CourseWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		video:{
			filter:{
				type:"YoutubeVideoWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	CourseLessonOrderBy:{
		_random:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		_randomSeeded:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:false
		},
		order:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:false
		},
		headline:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:false
		},
		lead:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:false
		},
		course:{
			type:"CourseOrderBy",
			array:false,
			arrayRequired:false,
			required:false
		},
		video:{
			type:"YoutubeVideoOrderBy",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	CourseLecturer:{
		courses:{
			filter:{
				type:"CourseWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"CourseOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		site:{
			filter:{
				type:"SiteWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		link:{
			filter:{
				type:"LinkableWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		seo:{
			filter:{
				type:"SeoWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		image:{
			filter:{
				type:"ImageWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		content:{
			filter:{
				type:"ContentWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		coursesBySeo:{
			by:{
				type:"CourseLecturerCoursesBySeoUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"CourseWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		coursesByLink:{
			by:{
				type:"CourseLecturerCoursesByLinkUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"CourseWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		coursesByLessons:{
			by:{
				type:"CourseLecturerCoursesByLessonsUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"CourseWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		coursesByContent:{
			by:{
				type:"CourseLecturerCoursesByContentUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"CourseWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		paginateCourses:{
			filter:{
				type:"CourseWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"CourseOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			first:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	CourseLecturerCoursesBySeoUniqueWhere:{
		seo:{
			type:"SeoUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	CourseLecturerCoursesByLinkUniqueWhere:{
		link:{
			type:"LinkableUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	CourseLecturerCoursesByLessonsUniqueWhere:{
		lessons:{
			type:"CourseLessonUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	CourseLecturerCoursesByContentUniqueWhere:{
		content:{
			type:"ContentUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ArticleListPage:{
		seo:{
			filter:{
				type:"SeoWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		link:{
			filter:{
				type:"LinkableWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		site:{
			filter:{
				type:"SiteWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	CourseListPage:{
		seo:{
			filter:{
				type:"SeoWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		link:{
			filter:{
				type:"LinkableWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		site:{
			filter:{
				type:"SiteWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	PoseListPage:{
		link:{
			filter:{
				type:"LinkableWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		seo:{
			filter:{
				type:"SeoWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		site:{
			filter:{
				type:"SiteWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	CourseLecturerListPage:{
		site:{
			filter:{
				type:"SiteWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		seo:{
			filter:{
				type:"SeoWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		link:{
			filter:{
				type:"LinkableWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	SubscribeBox:{
		site:{
			filter:{
				type:"SiteWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		logo:{
			filter:{
				type:"ImageWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	Header:{
		primaryNavigationItems:{
			filter:{
				type:"HeaderPrimaryNavigationItemWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"HeaderPrimaryNavigationItemOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		site:{
			filter:{
				type:"SiteWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		logo:{
			filter:{
				type:"ImageWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		logoOnWhite:{
			filter:{
				type:"ImageWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		primaryNavigationItemsByLink:{
			by:{
				type:"HeaderPrimaryNavigationItemsByLinkUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"HeaderPrimaryNavigationItemWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		primaryNavigationItemsByListNavigation:{
			by:{
				type:"HeaderPrimaryNavigationItemsByListNavigationUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"HeaderPrimaryNavigationItemWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		primaryNavigationItemsByTiles:{
			by:{
				type:"HeaderPrimaryNavigationItemsByTilesUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"HeaderPrimaryNavigationItemWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		paginatePrimaryNavigationItems:{
			filter:{
				type:"HeaderPrimaryNavigationItemWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"HeaderPrimaryNavigationItemOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			first:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	HeaderPrimaryNavigationItem:{
		header:{
			filter:{
				type:"HeaderWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		link:{
			filter:{
				type:"LinkWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		listNavigation:{
			filter:{
				type:"ListNavigationWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		tiles:{
			filter:{
				type:"TileListWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	ListNavigation:{
		columns:{
			filter:{
				type:"ListNavigationColumnWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"ListNavigationColumnOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		columnsByItems:{
			by:{
				type:"ListNavigationColumnsByItemsUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"ListNavigationColumnWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		paginateColumns:{
			filter:{
				type:"ListNavigationColumnWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"ListNavigationColumnOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			first:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	ListNavigationColumn:{
		listNavigation:{
			filter:{
				type:"ListNavigationWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		items:{
			filter:{
				type:"ListNavigationColumnItemWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"ListNavigationColumnItemOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		itemsByLink:{
			by:{
				type:"ListNavigationColumnItemsByLinkUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"ListNavigationColumnItemWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		paginateItems:{
			filter:{
				type:"ListNavigationColumnItemWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"ListNavigationColumnItemOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			first:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	ListNavigationColumnItem:{
		column:{
			filter:{
				type:"ListNavigationColumnWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		link:{
			filter:{
				type:"LinkWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	ListNavigationColumnItemOrderBy:{
		_random:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		_randomSeeded:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:false
		},
		order:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:false
		},
		column:{
			type:"ListNavigationColumnOrderBy",
			array:false,
			arrayRequired:false,
			required:false
		},
		link:{
			type:"LinkOrderBy",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ListNavigationColumnOrderBy:{
		_random:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		_randomSeeded:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:false
		},
		order:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:false
		},
		label:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:false
		},
		listNavigation:{
			type:"ListNavigationOrderBy",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ListNavigationColumnItemsByLinkUniqueWhere:{
		link:{
			type:"LinkUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ListNavigationColumnsByItemsUniqueWhere:{
		items:{
			type:"ListNavigationColumnItemUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	HeaderPrimaryNavigationItemOrderBy:{
		_random:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		_randomSeeded:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:false
		},
		order:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:false
		},
		header:{
			type:"HeaderOrderBy",
			array:false,
			arrayRequired:false,
			required:false
		},
		link:{
			type:"LinkOrderBy",
			array:false,
			arrayRequired:false,
			required:false
		},
		listNavigation:{
			type:"ListNavigationOrderBy",
			array:false,
			arrayRequired:false,
			required:false
		},
		tiles:{
			type:"TileListOrderBy",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	HeaderPrimaryNavigationItemsByLinkUniqueWhere:{
		link:{
			type:"LinkUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	HeaderPrimaryNavigationItemsByListNavigationUniqueWhere:{
		listNavigation:{
			type:"ListNavigationUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	HeaderPrimaryNavigationItemsByTilesUniqueWhere:{
		tiles:{
			type:"TileListUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	Footer:{
		navigation:{
			filter:{
				type:"ListNavigationWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		site:{
			filter:{
				type:"SiteWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		logo:{
			filter:{
				type:"ImageWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	GenericPage:{
		site:{
			filter:{
				type:"SiteWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		content:{
			filter:{
				type:"ContentWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		seo:{
			filter:{
				type:"SeoWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		link:{
			filter:{
				type:"LinkableWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	CourseEmail:{
		logo:{
			filter:{
				type:"ImageWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		cover:{
			filter:{
				type:"ImageWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		site:{
			filter:{
				type:"SiteWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	Translations:{
		site:{
			filter:{
				type:"SiteWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	SiteArticlesBySeoUniqueWhere:{
		seo:{
			type:"SeoUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	SiteArticlesByLinkUniqueWhere:{
		link:{
			type:"LinkableUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	SiteArticlesByContentUniqueWhere:{
		content:{
			type:"ContentUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	SiteArticleCategoriesByArticlesUniqueWhere:{
		articles:{
			type:"ArticleUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	SiteArticleCategoriesBySeoUniqueWhere:{
		seo:{
			type:"SeoUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	SiteArticleCategoriesByLinkUniqueWhere:{
		link:{
			type:"LinkableUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	SitePosesByLinkUniqueWhere:{
		link:{
			type:"LinkableUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	SitePosesBySeoUniqueWhere:{
		seo:{
			type:"SeoUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	SitePosesByContentUniqueWhere:{
		content:{
			type:"ContentUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	SitePoseCategoriesByPosesUniqueWhere:{
		poses:{
			type:"PoseUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	SitePoseDifficultiesByPosesUniqueWhere:{
		poses:{
			type:"PoseUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	SiteCoursesBySeoUniqueWhere:{
		seo:{
			type:"SeoUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	SiteCoursesByLinkUniqueWhere:{
		link:{
			type:"LinkableUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	SiteCoursesByLessonsUniqueWhere:{
		lessons:{
			type:"CourseLessonUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	SiteCoursesByContentUniqueWhere:{
		content:{
			type:"ContentUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	SiteCourseLecturersByCoursesUniqueWhere:{
		courses:{
			type:"CourseUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	SiteCourseLecturersByLinkUniqueWhere:{
		link:{
			type:"LinkableUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	SiteCourseLecturersBySeoUniqueWhere:{
		seo:{
			type:"SeoUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	SiteCourseLecturersByContentUniqueWhere:{
		content:{
			type:"ContentUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	SiteGenericPagesByContentUniqueWhere:{
		content:{
			type:"ContentUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	SiteGenericPagesBySeoUniqueWhere:{
		seo:{
			type:"SeoUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	SiteGenericPagesByLinkUniqueWhere:{
		link:{
			type:"LinkableUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	NewsletterSubscribeSuccessPage:{
		link:{
			filter:{
				type:"LinkableWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		site:{
			filter:{
				type:"SiteWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		seo:{
			filter:{
				type:"SeoWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	YoutubeVideoUniqueWhere:{
		id:{
			type:"UUID",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	SeoDefault:{
		seo:{
			filter:{
				type:"SeoWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	One: "enum",
	SeoDefaultUniqueWhere:{
		id:{
			type:"UUID",
			array:false,
			arrayRequired:false,
			required:false
		},
		unique:{
			type:"One",
			array:false,
			arrayRequired:false,
			required:false
		},
		seo:{
			type:"SeoUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	SeoDefaultWhere:{
		id:{
			type:"UUIDCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		unique:{
			type:"OneCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		seo:{
			type:"SeoWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		and:{
			type:"SeoDefaultWhere",
			array:true,
			arrayRequired:false,
			required:false
		},
		or:{
			type:"SeoDefaultWhere",
			array:true,
			arrayRequired:false,
			required:false
		},
		not:{
			type:"SeoDefaultWhere",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	OneCondition:{
		and:{
			type:"OneCondition",
			array:true,
			arrayRequired:false,
			required:true
		},
		or:{
			type:"OneCondition",
			array:true,
			arrayRequired:false,
			required:true
		},
		not:{
			type:"OneCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		null:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		isNull:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		eq:{
			type:"One",
			array:false,
			arrayRequired:false,
			required:false
		},
		notEq:{
			type:"One",
			array:false,
			arrayRequired:false,
			required:false
		},
		in:{
			type:"One",
			array:true,
			arrayRequired:false,
			required:true
		},
		notIn:{
			type:"One",
			array:true,
			arrayRequired:false,
			required:true
		},
		lt:{
			type:"One",
			array:false,
			arrayRequired:false,
			required:false
		},
		lte:{
			type:"One",
			array:false,
			arrayRequired:false,
			required:false
		},
		gt:{
			type:"One",
			array:false,
			arrayRequired:false,
			required:false
		},
		gte:{
			type:"One",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	SeoDefaultOrderBy:{
		_random:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		_randomSeeded:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:false
		},
		unique:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:false
		},
		seo:{
			type:"SeoOrderBy",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	NewsletterSubscription:{
		visitor:{
			filter:{
				type:"VisitorWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		site:{
			filter:{
				type:"SiteWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	Visitor:{
		newsletterSubscriptions:{
			filter:{
				type:"NewsletterSubscriptionWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"NewsletterSubscriptionOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		courseSubscriptions:{
			filter:{
				type:"CourseSubscriptionWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"CourseSubscriptionOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		newsletterSubscriptionsBySite:{
			by:{
				type:"VisitorNewsletterSubscriptionsBySiteUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"NewsletterSubscriptionWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		courseSubscriptionsByCourse:{
			by:{
				type:"VisitorCourseSubscriptionsByCourseUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"CourseSubscriptionWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		paginateNewsletterSubscriptions:{
			filter:{
				type:"NewsletterSubscriptionWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"NewsletterSubscriptionOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			first:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		paginateCourseSubscriptions:{
			filter:{
				type:"CourseSubscriptionWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"CourseSubscriptionOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			first:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	NewsletterSubscriptionWhere:{
		id:{
			type:"UUIDCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		startDate:{
			type:"DateCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		visitor:{
			type:"VisitorWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		site:{
			type:"SiteWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		and:{
			type:"NewsletterSubscriptionWhere",
			array:true,
			arrayRequired:false,
			required:false
		},
		or:{
			type:"NewsletterSubscriptionWhere",
			array:true,
			arrayRequired:false,
			required:false
		},
		not:{
			type:"NewsletterSubscriptionWhere",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	VisitorWhere:{
		id:{
			type:"UUIDCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		email:{
			type:"StringCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		newsletterSubscriptions:{
			type:"NewsletterSubscriptionWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		courseSubscriptions:{
			type:"CourseSubscriptionWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		and:{
			type:"VisitorWhere",
			array:true,
			arrayRequired:false,
			required:false
		},
		or:{
			type:"VisitorWhere",
			array:true,
			arrayRequired:false,
			required:false
		},
		not:{
			type:"VisitorWhere",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	CourseSubscriptionWhere:{
		id:{
			type:"UUIDCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		startDate:{
			type:"DateCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		visitor:{
			type:"VisitorWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		course:{
			type:"CourseWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		and:{
			type:"CourseSubscriptionWhere",
			array:true,
			arrayRequired:false,
			required:false
		},
		or:{
			type:"CourseSubscriptionWhere",
			array:true,
			arrayRequired:false,
			required:false
		},
		not:{
			type:"CourseSubscriptionWhere",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	NewsletterSubscriptionOrderBy:{
		_random:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		_randomSeeded:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:false
		},
		startDate:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:false
		},
		visitor:{
			type:"VisitorOrderBy",
			array:false,
			arrayRequired:false,
			required:false
		},
		site:{
			type:"SiteOrderBy",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	VisitorOrderBy:{
		_random:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		_randomSeeded:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:false
		},
		email:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	CourseSubscription:{
		visitor:{
			filter:{
				type:"VisitorWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		course:{
			filter:{
				type:"CourseWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	CourseSubscriptionOrderBy:{
		_random:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		_randomSeeded:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:false
		},
		startDate:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:false
		},
		visitor:{
			type:"VisitorOrderBy",
			array:false,
			arrayRequired:false,
			required:false
		},
		course:{
			type:"CourseOrderBy",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	VisitorNewsletterSubscriptionsBySiteUniqueWhere:{
		site:{
			type:"SiteUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	VisitorCourseSubscriptionsByCourseUniqueWhere:{
		course:{
			type:"CourseUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	NewsletterSubscriptionUniqueWhere:{
		id:{
			type:"UUID",
			array:false,
			arrayRequired:false,
			required:false
		},
		visitor:{
			type:"VisitorUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		site:{
			type:"SiteUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	VisitorUniqueWhere:{
		id:{
			type:"UUID",
			array:false,
			arrayRequired:false,
			required:false
		},
		email:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		newsletterSubscriptions:{
			type:"NewsletterSubscriptionUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		courseSubscriptions:{
			type:"CourseSubscriptionUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	CourseSubscriptionUniqueWhere:{
		id:{
			type:"UUID",
			array:false,
			arrayRequired:false,
			required:false
		},
		visitor:{
			type:"VisitorUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		course:{
			type:"CourseUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	NewsletterSubscriptionCreateInput:{
		startDate:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		},
		visitor:{
			type:"NewsletterSubscriptionCreateVisitorEntityRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		site:{
			type:"NewsletterSubscriptionCreateSiteEntityRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		_dummy_field_:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	NewsletterSubscriptionCreateVisitorEntityRelationInput:{
		connect:{
			type:"VisitorUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		create:{
			type:"VisitorWithoutNewsletterSubscriptionsCreateInput",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	VisitorWithoutNewsletterSubscriptionsCreateInput:{
		email:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		courseSubscriptions:{
			type:"VisitorCreateCourseSubscriptionsEntityRelationInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		_dummy_field_:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	VisitorCreateCourseSubscriptionsEntityRelationInput:{
		connect:{
			type:"CourseSubscriptionUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		create:{
			type:"CourseSubscriptionWithoutVisitorCreateInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		alias:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	CourseSubscriptionWithoutVisitorCreateInput:{
		startDate:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		},
		course:{
			type:"CourseSubscriptionCreateCourseEntityRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		_dummy_field_:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	CourseSubscriptionCreateCourseEntityRelationInput:{
		connect:{
			type:"CourseUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	NewsletterSubscriptionCreateSiteEntityRelationInput:{
		connect:{
			type:"SiteUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	VisitorCreateInput:{
		email:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		newsletterSubscriptions:{
			type:"VisitorCreateNewsletterSubscriptionsEntityRelationInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		courseSubscriptions:{
			type:"VisitorCreateCourseSubscriptionsEntityRelationInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		_dummy_field_:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	VisitorCreateNewsletterSubscriptionsEntityRelationInput:{
		connect:{
			type:"NewsletterSubscriptionUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		create:{
			type:"NewsletterSubscriptionWithoutVisitorCreateInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		alias:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	NewsletterSubscriptionWithoutVisitorCreateInput:{
		startDate:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		},
		site:{
			type:"NewsletterSubscriptionCreateSiteEntityRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		_dummy_field_:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	CourseSubscriptionCreateInput:{
		startDate:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		},
		visitor:{
			type:"CourseSubscriptionCreateVisitorEntityRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		course:{
			type:"CourseSubscriptionCreateCourseEntityRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		_dummy_field_:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	CourseSubscriptionCreateVisitorEntityRelationInput:{
		connect:{
			type:"VisitorUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		create:{
			type:"VisitorWithoutCourseSubscriptionsCreateInput",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	VisitorWithoutCourseSubscriptionsCreateInput:{
		email:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		newsletterSubscriptions:{
			type:"VisitorCreateNewsletterSubscriptionsEntityRelationInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		_dummy_field_:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	PersonUniqueWhere:{
		id:{
			type:"UUID",
			array:false,
			arrayRequired:false,
			required:false
		},
		image:{
			type:"ImageUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	PersonListUniqueWhere:{
		id:{
			type:"UUID",
			array:false,
			arrayRequired:false,
			required:false
		},
		items:{
			type:"PersonListItemUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	PersonListItemUniqueWhere:{
		id:{
			type:"UUID",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	QueryTransaction:{
		getLink:{
			by:{
				type:"LinkUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"LinkWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		listLink:{
			filter:{
				type:"LinkWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"LinkOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		paginateLink:{
			filter:{
				type:"LinkWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"LinkOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			first:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		getLinkable:{
			by:{
				type:"LinkableUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"LinkableWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		listLinkable:{
			filter:{
				type:"LinkableWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"LinkableOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		paginateLinkable:{
			filter:{
				type:"LinkableWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"LinkableOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			first:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		getRedirect:{
			by:{
				type:"RedirectUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"RedirectWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		listRedirect:{
			filter:{
				type:"RedirectWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"RedirectOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		paginateRedirect:{
			filter:{
				type:"RedirectWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"RedirectOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			first:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		getFooter:{
			by:{
				type:"FooterUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"FooterWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		listFooter:{
			filter:{
				type:"FooterWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"FooterOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		paginateFooter:{
			filter:{
				type:"FooterWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"FooterOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			first:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		getImage:{
			by:{
				type:"ImageUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"ImageWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		listImage:{
			filter:{
				type:"ImageWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"ImageOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		paginateImage:{
			filter:{
				type:"ImageWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"ImageOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			first:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		getSeo:{
			by:{
				type:"SeoUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"SeoWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		listSeo:{
			filter:{
				type:"SeoWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"SeoOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		paginateSeo:{
			filter:{
				type:"SeoWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"SeoOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			first:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		getHomepage:{
			by:{
				type:"HomepageUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"HomepageWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		listHomepage:{
			filter:{
				type:"HomepageWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"HomepageOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		paginateHomepage:{
			filter:{
				type:"HomepageWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"HomepageOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			first:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		getHeader:{
			by:{
				type:"HeaderUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"HeaderWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		listHeader:{
			filter:{
				type:"HeaderWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"HeaderOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		paginateHeader:{
			filter:{
				type:"HeaderWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"HeaderOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			first:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		getHeaderPrimaryNavigationItem:{
			by:{
				type:"HeaderPrimaryNavigationItemUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"HeaderPrimaryNavigationItemWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		listHeaderPrimaryNavigationItem:{
			filter:{
				type:"HeaderPrimaryNavigationItemWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"HeaderPrimaryNavigationItemOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		paginateHeaderPrimaryNavigationItem:{
			filter:{
				type:"HeaderPrimaryNavigationItemWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"HeaderPrimaryNavigationItemOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			first:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		getListNavigation:{
			by:{
				type:"ListNavigationUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"ListNavigationWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		listListNavigation:{
			filter:{
				type:"ListNavigationWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"ListNavigationOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		paginateListNavigation:{
			filter:{
				type:"ListNavigationWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"ListNavigationOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			first:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		getListNavigationColumn:{
			by:{
				type:"ListNavigationColumnUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"ListNavigationColumnWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		listListNavigationColumn:{
			filter:{
				type:"ListNavigationColumnWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"ListNavigationColumnOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		paginateListNavigationColumn:{
			filter:{
				type:"ListNavigationColumnWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"ListNavigationColumnOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			first:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		getListNavigationColumnItem:{
			by:{
				type:"ListNavigationColumnItemUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"ListNavigationColumnItemWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		listListNavigationColumnItem:{
			filter:{
				type:"ListNavigationColumnItemWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"ListNavigationColumnItemOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		paginateListNavigationColumnItem:{
			filter:{
				type:"ListNavigationColumnItemWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"ListNavigationColumnItemOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			first:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		getArticle:{
			by:{
				type:"ArticleUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"ArticleWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		listArticle:{
			filter:{
				type:"ArticleWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"ArticleOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		paginateArticle:{
			filter:{
				type:"ArticleWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"ArticleOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			first:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		getSite:{
			by:{
				type:"SiteUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"SiteWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		listSite:{
			filter:{
				type:"SiteWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"SiteOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		paginateSite:{
			filter:{
				type:"SiteWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"SiteOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			first:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		getArticleCategory:{
			by:{
				type:"ArticleCategoryUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"ArticleCategoryWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		listArticleCategory:{
			filter:{
				type:"ArticleCategoryWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"ArticleCategoryOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		paginateArticleCategory:{
			filter:{
				type:"ArticleCategoryWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"ArticleCategoryOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			first:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		getPose:{
			by:{
				type:"PoseUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"PoseWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		listPose:{
			filter:{
				type:"PoseWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"PoseOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		paginatePose:{
			filter:{
				type:"PoseWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"PoseOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			first:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		getPoseCategory:{
			by:{
				type:"PoseCategoryUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"PoseCategoryWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		listPoseCategory:{
			filter:{
				type:"PoseCategoryWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"PoseCategoryOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		paginatePoseCategory:{
			filter:{
				type:"PoseCategoryWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"PoseCategoryOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			first:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		getPoseDifficulty:{
			by:{
				type:"PoseDifficultyUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"PoseDifficultyWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		listPoseDifficulty:{
			filter:{
				type:"PoseDifficultyWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"PoseDifficultyOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		paginatePoseDifficulty:{
			filter:{
				type:"PoseDifficultyWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"PoseDifficultyOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			first:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		getCourse:{
			by:{
				type:"CourseUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"CourseWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		listCourse:{
			filter:{
				type:"CourseWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"CourseOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		paginateCourse:{
			filter:{
				type:"CourseWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"CourseOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			first:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		getCourseLesson:{
			by:{
				type:"CourseLessonUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"CourseLessonWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		listCourseLesson:{
			filter:{
				type:"CourseLessonWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"CourseLessonOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		paginateCourseLesson:{
			filter:{
				type:"CourseLessonWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"CourseLessonOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			first:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		getArticleListPage:{
			by:{
				type:"ArticleListPageUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"ArticleListPageWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		listArticleListPage:{
			filter:{
				type:"ArticleListPageWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"ArticleListPageOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		paginateArticleListPage:{
			filter:{
				type:"ArticleListPageWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"ArticleListPageOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			first:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		getCourseListPage:{
			by:{
				type:"CourseListPageUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"CourseListPageWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		listCourseListPage:{
			filter:{
				type:"CourseListPageWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"CourseListPageOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		paginateCourseListPage:{
			filter:{
				type:"CourseListPageWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"CourseListPageOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			first:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		getPoseListPage:{
			by:{
				type:"PoseListPageUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"PoseListPageWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		listPoseListPage:{
			filter:{
				type:"PoseListPageWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"PoseListPageOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		paginatePoseListPage:{
			filter:{
				type:"PoseListPageWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"PoseListPageOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			first:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		getContent:{
			by:{
				type:"ContentUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"ContentWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		listContent:{
			filter:{
				type:"ContentWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"ContentOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		paginateContent:{
			filter:{
				type:"ContentWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"ContentOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			first:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		getContentBlock:{
			by:{
				type:"ContentBlockUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"ContentBlockWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		listContentBlock:{
			filter:{
				type:"ContentBlockWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"ContentBlockOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		paginateContentBlock:{
			filter:{
				type:"ContentBlockWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"ContentBlockOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			first:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		getContentReference:{
			by:{
				type:"ContentReferenceUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"ContentReferenceWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		listContentReference:{
			filter:{
				type:"ContentReferenceWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"ContentReferenceOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		paginateContentReference:{
			filter:{
				type:"ContentReferenceWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"ContentReferenceOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			first:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		getTile:{
			by:{
				type:"TileUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"TileWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		listTile:{
			filter:{
				type:"TileWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"TileOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		paginateTile:{
			filter:{
				type:"TileWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"TileOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			first:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		getTileList:{
			by:{
				type:"TileListUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"TileListWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		listTileList:{
			filter:{
				type:"TileListWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"TileListOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		paginateTileList:{
			filter:{
				type:"TileListWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"TileListOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			first:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		getTileListItem:{
			by:{
				type:"TileListItemUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"TileListItemWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		listTileListItem:{
			filter:{
				type:"TileListItemWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"TileListItemOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		paginateTileListItem:{
			filter:{
				type:"TileListItemWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"TileListItemOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			first:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		getYoutubeVideo:{
			by:{
				type:"YoutubeVideoUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"YoutubeVideoWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		listYoutubeVideo:{
			filter:{
				type:"YoutubeVideoWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"YoutubeVideoOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		paginateYoutubeVideo:{
			filter:{
				type:"YoutubeVideoWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"YoutubeVideoOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			first:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		getSeoDefault:{
			by:{
				type:"SeoDefaultUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"SeoDefaultWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		listSeoDefault:{
			filter:{
				type:"SeoDefaultWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"SeoDefaultOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		paginateSeoDefault:{
			filter:{
				type:"SeoDefaultWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"SeoDefaultOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			first:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		getCourseLecturer:{
			by:{
				type:"CourseLecturerUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"CourseLecturerWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		listCourseLecturer:{
			filter:{
				type:"CourseLecturerWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"CourseLecturerOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		paginateCourseLecturer:{
			filter:{
				type:"CourseLecturerWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"CourseLecturerOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			first:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		getCourseLecturerListPage:{
			by:{
				type:"CourseLecturerListPageUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"CourseLecturerListPageWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		listCourseLecturerListPage:{
			filter:{
				type:"CourseLecturerListPageWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"CourseLecturerListPageOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		paginateCourseLecturerListPage:{
			filter:{
				type:"CourseLecturerListPageWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"CourseLecturerListPageOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			first:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		getNewsletterSubscription:{
			by:{
				type:"NewsletterSubscriptionUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"NewsletterSubscriptionWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		listNewsletterSubscription:{
			filter:{
				type:"NewsletterSubscriptionWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"NewsletterSubscriptionOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		paginateNewsletterSubscription:{
			filter:{
				type:"NewsletterSubscriptionWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"NewsletterSubscriptionOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			first:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		validateCreateNewsletterSubscription:{
			data:{
				type:"NewsletterSubscriptionCreateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		getVisitor:{
			by:{
				type:"VisitorUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"VisitorWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		listVisitor:{
			filter:{
				type:"VisitorWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"VisitorOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		paginateVisitor:{
			filter:{
				type:"VisitorWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"VisitorOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			first:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		validateCreateVisitor:{
			data:{
				type:"VisitorCreateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		getCourseSubscription:{
			by:{
				type:"CourseSubscriptionUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"CourseSubscriptionWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		listCourseSubscription:{
			filter:{
				type:"CourseSubscriptionWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"CourseSubscriptionOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		paginateCourseSubscription:{
			filter:{
				type:"CourseSubscriptionWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"CourseSubscriptionOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			first:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		validateCreateCourseSubscription:{
			data:{
				type:"CourseSubscriptionCreateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		getSubscribeBox:{
			by:{
				type:"SubscribeBoxUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"SubscribeBoxWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		listSubscribeBox:{
			filter:{
				type:"SubscribeBoxWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"SubscribeBoxOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		paginateSubscribeBox:{
			filter:{
				type:"SubscribeBoxWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"SubscribeBoxOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			first:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		getNewsletterSubscribeSuccessPage:{
			by:{
				type:"NewsletterSubscribeSuccessPageUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"NewsletterSubscribeSuccessPageWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		listNewsletterSubscribeSuccessPage:{
			filter:{
				type:"NewsletterSubscribeSuccessPageWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"NewsletterSubscribeSuccessPageOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		paginateNewsletterSubscribeSuccessPage:{
			filter:{
				type:"NewsletterSubscribeSuccessPageWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"NewsletterSubscribeSuccessPageOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			first:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		getGenericPage:{
			by:{
				type:"GenericPageUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"GenericPageWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		listGenericPage:{
			filter:{
				type:"GenericPageWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"GenericPageOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		paginateGenericPage:{
			filter:{
				type:"GenericPageWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"GenericPageOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			first:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		getPerson:{
			by:{
				type:"PersonUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"PersonWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		listPerson:{
			filter:{
				type:"PersonWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"PersonOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		paginatePerson:{
			filter:{
				type:"PersonWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"PersonOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			first:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		getPersonList:{
			by:{
				type:"PersonListUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"PersonListWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		listPersonList:{
			filter:{
				type:"PersonListWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"PersonListOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		paginatePersonList:{
			filter:{
				type:"PersonListWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"PersonListOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			first:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		getPersonListItem:{
			by:{
				type:"PersonListItemUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"PersonListItemWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		listPersonListItem:{
			filter:{
				type:"PersonListItemWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"PersonListItemOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		paginatePersonListItem:{
			filter:{
				type:"PersonListItemWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"PersonListItemOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			first:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		getCourseEmail:{
			by:{
				type:"CourseEmailUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"CourseEmailWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		listCourseEmail:{
			filter:{
				type:"CourseEmailWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"CourseEmailOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		paginateCourseEmail:{
			filter:{
				type:"CourseEmailWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"CourseEmailOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			first:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		getTranslations:{
			by:{
				type:"TranslationsUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"TranslationsWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		listTranslations:{
			filter:{
				type:"TranslationsWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"TranslationsOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		paginateTranslations:{
			filter:{
				type:"TranslationsWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"TranslationsOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			first:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	Mutation:{
		createNewsletterSubscription:{
			data:{
				type:"NewsletterSubscriptionCreateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		deleteNewsletterSubscription:{
			by:{
				type:"NewsletterSubscriptionUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"NewsletterSubscriptionWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		createVisitor:{
			data:{
				type:"VisitorCreateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		createCourseSubscription:{
			data:{
				type:"CourseSubscriptionCreateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		deleteCourseSubscription:{
			by:{
				type:"CourseSubscriptionUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"CourseSubscriptionWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		transaction:{
			options:{
				type:"MutationTransactionOptions",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	_MutationErrorType: "enum",
	MutationTransaction:{
		createNewsletterSubscription:{
			data:{
				type:"NewsletterSubscriptionCreateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		deleteNewsletterSubscription:{
			by:{
				type:"NewsletterSubscriptionUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"NewsletterSubscriptionWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		createVisitor:{
			data:{
				type:"VisitorCreateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		createCourseSubscription:{
			data:{
				type:"CourseSubscriptionCreateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		deleteCourseSubscription:{
			by:{
				type:"CourseSubscriptionUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"CourseSubscriptionWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	MutationTransactionOptions:{
		deferForeignKeyConstraints:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	Json: "String",
	_OnDeleteBehaviour: "enum",
	_RelationSide: "enum",
	_OrderByDirection: "enum"
}

export const ReturnTypes: Record<string,any> = {
	Query:{
		getLink:"Link",
		listLink:"Link",
		paginateLink:"LinkConnection",
		getLinkable:"Linkable",
		listLinkable:"Linkable",
		paginateLinkable:"LinkableConnection",
		getRedirect:"Redirect",
		listRedirect:"Redirect",
		paginateRedirect:"RedirectConnection",
		getFooter:"Footer",
		listFooter:"Footer",
		paginateFooter:"FooterConnection",
		getImage:"Image",
		listImage:"Image",
		paginateImage:"ImageConnection",
		getSeo:"Seo",
		listSeo:"Seo",
		paginateSeo:"SeoConnection",
		getHomepage:"Homepage",
		listHomepage:"Homepage",
		paginateHomepage:"HomepageConnection",
		getHeader:"Header",
		listHeader:"Header",
		paginateHeader:"HeaderConnection",
		getHeaderPrimaryNavigationItem:"HeaderPrimaryNavigationItem",
		listHeaderPrimaryNavigationItem:"HeaderPrimaryNavigationItem",
		paginateHeaderPrimaryNavigationItem:"HeaderPrimaryNavigationItemConnection",
		getListNavigation:"ListNavigation",
		listListNavigation:"ListNavigation",
		paginateListNavigation:"ListNavigationConnection",
		getListNavigationColumn:"ListNavigationColumn",
		listListNavigationColumn:"ListNavigationColumn",
		paginateListNavigationColumn:"ListNavigationColumnConnection",
		getListNavigationColumnItem:"ListNavigationColumnItem",
		listListNavigationColumnItem:"ListNavigationColumnItem",
		paginateListNavigationColumnItem:"ListNavigationColumnItemConnection",
		getArticle:"Article",
		listArticle:"Article",
		paginateArticle:"ArticleConnection",
		getSite:"Site",
		listSite:"Site",
		paginateSite:"SiteConnection",
		getArticleCategory:"ArticleCategory",
		listArticleCategory:"ArticleCategory",
		paginateArticleCategory:"ArticleCategoryConnection",
		getPose:"Pose",
		listPose:"Pose",
		paginatePose:"PoseConnection",
		getPoseCategory:"PoseCategory",
		listPoseCategory:"PoseCategory",
		paginatePoseCategory:"PoseCategoryConnection",
		getPoseDifficulty:"PoseDifficulty",
		listPoseDifficulty:"PoseDifficulty",
		paginatePoseDifficulty:"PoseDifficultyConnection",
		getCourse:"Course",
		listCourse:"Course",
		paginateCourse:"CourseConnection",
		getCourseLesson:"CourseLesson",
		listCourseLesson:"CourseLesson",
		paginateCourseLesson:"CourseLessonConnection",
		getArticleListPage:"ArticleListPage",
		listArticleListPage:"ArticleListPage",
		paginateArticleListPage:"ArticleListPageConnection",
		getCourseListPage:"CourseListPage",
		listCourseListPage:"CourseListPage",
		paginateCourseListPage:"CourseListPageConnection",
		getPoseListPage:"PoseListPage",
		listPoseListPage:"PoseListPage",
		paginatePoseListPage:"PoseListPageConnection",
		getContent:"Content",
		listContent:"Content",
		paginateContent:"ContentConnection",
		getContentBlock:"ContentBlock",
		listContentBlock:"ContentBlock",
		paginateContentBlock:"ContentBlockConnection",
		getContentReference:"ContentReference",
		listContentReference:"ContentReference",
		paginateContentReference:"ContentReferenceConnection",
		getTile:"Tile",
		listTile:"Tile",
		paginateTile:"TileConnection",
		getTileList:"TileList",
		listTileList:"TileList",
		paginateTileList:"TileListConnection",
		getTileListItem:"TileListItem",
		listTileListItem:"TileListItem",
		paginateTileListItem:"TileListItemConnection",
		getYoutubeVideo:"YoutubeVideo",
		listYoutubeVideo:"YoutubeVideo",
		paginateYoutubeVideo:"YoutubeVideoConnection",
		getSeoDefault:"SeoDefault",
		listSeoDefault:"SeoDefault",
		paginateSeoDefault:"SeoDefaultConnection",
		getCourseLecturer:"CourseLecturer",
		listCourseLecturer:"CourseLecturer",
		paginateCourseLecturer:"CourseLecturerConnection",
		getCourseLecturerListPage:"CourseLecturerListPage",
		listCourseLecturerListPage:"CourseLecturerListPage",
		paginateCourseLecturerListPage:"CourseLecturerListPageConnection",
		getNewsletterSubscription:"NewsletterSubscription",
		listNewsletterSubscription:"NewsletterSubscription",
		paginateNewsletterSubscription:"NewsletterSubscriptionConnection",
		validateCreateNewsletterSubscription:"_ValidationResult",
		getVisitor:"Visitor",
		listVisitor:"Visitor",
		paginateVisitor:"VisitorConnection",
		validateCreateVisitor:"_ValidationResult",
		getCourseSubscription:"CourseSubscription",
		listCourseSubscription:"CourseSubscription",
		paginateCourseSubscription:"CourseSubscriptionConnection",
		validateCreateCourseSubscription:"_ValidationResult",
		getSubscribeBox:"SubscribeBox",
		listSubscribeBox:"SubscribeBox",
		paginateSubscribeBox:"SubscribeBoxConnection",
		getNewsletterSubscribeSuccessPage:"NewsletterSubscribeSuccessPage",
		listNewsletterSubscribeSuccessPage:"NewsletterSubscribeSuccessPage",
		paginateNewsletterSubscribeSuccessPage:"NewsletterSubscribeSuccessPageConnection",
		getGenericPage:"GenericPage",
		listGenericPage:"GenericPage",
		paginateGenericPage:"GenericPageConnection",
		getPerson:"Person",
		listPerson:"Person",
		paginatePerson:"PersonConnection",
		getPersonList:"PersonList",
		listPersonList:"PersonList",
		paginatePersonList:"PersonListConnection",
		getPersonListItem:"PersonListItem",
		listPersonListItem:"PersonListItem",
		paginatePersonListItem:"PersonListItemConnection",
		getCourseEmail:"CourseEmail",
		listCourseEmail:"CourseEmail",
		paginateCourseEmail:"CourseEmailConnection",
		getTranslations:"Translations",
		listTranslations:"Translations",
		paginateTranslations:"TranslationsConnection",
		transaction:"QueryTransaction",
		_info:"Info",
		schema:"_Schema"
	},
	Link:{
		_meta:"LinkMeta",
		id:"UUID",
		type:"LinkType",
		title:"String",
		externalLink:"String",
		internalLink:"Linkable",
		isHighlighted:"Boolean",
		targetBlank:"Boolean"
	},
	LinkMeta:{
		id:"FieldMeta",
		type:"FieldMeta",
		title:"FieldMeta",
		externalLink:"FieldMeta",
		internalLink:"FieldMeta",
		isHighlighted:"FieldMeta",
		targetBlank:"FieldMeta"
	},
	FieldMeta:{
		readable:"Boolean",
		updatable:"Boolean"
	},
	Linkable:{
		_meta:"LinkableMeta",
		id:"UUID",
		url:"String",
		redirect:"Redirect",
		homepage:"Homepage",
		article:"Article",
		articleCategory:"ArticleCategory",
		pose:"Pose",
		course:"Course",
		articleList:"ArticleListPage",
		courseList:"CourseListPage",
		poseList:"PoseListPage",
		courseLecturer:"CourseLecturer",
		courseLecturerList:"CourseLecturerListPage",
		newsletterSubscribeSuccessPage:"NewsletterSubscribeSuccessPage",
		genericPage:"GenericPage"
	},
	LinkableMeta:{
		id:"FieldMeta",
		url:"FieldMeta",
		redirect:"FieldMeta",
		homepage:"FieldMeta",
		article:"FieldMeta",
		articleCategory:"FieldMeta",
		pose:"FieldMeta",
		course:"FieldMeta",
		articleList:"FieldMeta",
		courseList:"FieldMeta",
		poseList:"FieldMeta",
		courseLecturer:"FieldMeta",
		courseLecturerList:"FieldMeta",
		newsletterSubscribeSuccessPage:"FieldMeta",
		genericPage:"FieldMeta"
	},
	Redirect:{
		_meta:"RedirectMeta",
		id:"UUID",
		link:"Linkable",
		target:"Linkable"
	},
	RedirectMeta:{
		id:"FieldMeta",
		link:"FieldMeta",
		target:"FieldMeta"
	},
	Homepage:{
		_meta:"HomepageMeta",
		id:"UUID",
		link:"Linkable",
		subtitle:"String",
		seo:"Seo",
		content:"Content",
		heroTiles:"TileList",
		site:"Site",
		titlePartA:"String",
		titlePartB:"String",
		titlePartC:"String",
		titlePartD:"String"
	},
	HomepageMeta:{
		id:"FieldMeta",
		link:"FieldMeta",
		subtitle:"FieldMeta",
		seo:"FieldMeta",
		content:"FieldMeta",
		heroTiles:"FieldMeta",
		site:"FieldMeta",
		titlePartA:"FieldMeta",
		titlePartB:"FieldMeta",
		titlePartC:"FieldMeta",
		titlePartD:"FieldMeta"
	},
	Seo:{
		_meta:"SeoMeta",
		id:"UUID",
		title:"String",
		description:"String",
		ogTitle:"String",
		ogDescription:"String",
		ogImage:"Image"
	},
	SeoMeta:{
		id:"FieldMeta",
		title:"FieldMeta",
		description:"FieldMeta",
		ogTitle:"FieldMeta",
		ogDescription:"FieldMeta",
		ogImage:"FieldMeta"
	},
	Image:{
		_meta:"ImageMeta",
		id:"UUID",
		url:"String",
		width:"Int",
		height:"Int",
		size:"Int",
		type:"String",
		alt:"String"
	},
	ImageMeta:{
		id:"FieldMeta",
		url:"FieldMeta",
		width:"FieldMeta",
		height:"FieldMeta",
		size:"FieldMeta",
		type:"FieldMeta",
		alt:"FieldMeta"
	},
	Content:{
		_meta:"ContentMeta",
		id:"UUID",
		blocks:"ContentBlock",
		blocksByReferences:"ContentBlock",
		paginateBlocks:"ContentBlockConnection"
	},
	ContentMeta:{
		id:"FieldMeta",
		blocks:"FieldMeta"
	},
	ContentBlock:{
		_meta:"ContentBlockMeta",
		id:"UUID",
		order:"Int",
		json:"String",
		content:"Content",
		references:"ContentReference",
		paginateReferences:"ContentReferenceConnection"
	},
	ContentBlockMeta:{
		id:"FieldMeta",
		order:"FieldMeta",
		json:"FieldMeta",
		content:"FieldMeta",
		references:"FieldMeta"
	},
	ContentReference:{
		_meta:"ContentReferenceMeta",
		id:"UUID",
		type:"ContentReferenceType",
		primaryText:"String",
		block:"ContentBlock",
		image:"Image",
		highlightFirstArticle:"Boolean",
		linkToMoreArticles:"Boolean",
		people:"PersonList"
	},
	ContentReferenceMeta:{
		id:"FieldMeta",
		type:"FieldMeta",
		primaryText:"FieldMeta",
		block:"FieldMeta",
		image:"FieldMeta",
		highlightFirstArticle:"FieldMeta",
		linkToMoreArticles:"FieldMeta",
		people:"FieldMeta"
	},
	PersonList:{
		_meta:"PersonListMeta",
		id:"UUID",
		items:"PersonListItem",
		paginateItems:"PersonListItemConnection"
	},
	PersonListMeta:{
		id:"FieldMeta",
		items:"FieldMeta"
	},
	PersonListItem:{
		_meta:"PersonListItemMeta",
		id:"UUID",
		order:"Int",
		list:"PersonList",
		person:"Person",
		about:"String"
	},
	PersonListItemMeta:{
		id:"FieldMeta",
		order:"FieldMeta",
		list:"FieldMeta",
		person:"FieldMeta",
		about:"FieldMeta"
	},
	Person:{
		_meta:"PersonMeta",
		id:"UUID",
		name:"String",
		about:"String",
		email:"String",
		phone:"String",
		image:"Image"
	},
	PersonMeta:{
		id:"FieldMeta",
		name:"FieldMeta",
		about:"FieldMeta",
		email:"FieldMeta",
		phone:"FieldMeta",
		image:"FieldMeta"
	},
	PersonListItemConnection:{
		pageInfo:"PageInfo",
		edges:"PersonListItemEdge"
	},
	PageInfo:{
		totalCount:"Int"
	},
	PersonListItemEdge:{
		node:"PersonListItem"
	},
	ContentReferenceConnection:{
		pageInfo:"PageInfo",
		edges:"ContentReferenceEdge"
	},
	ContentReferenceEdge:{
		node:"ContentReference"
	},
	ContentBlockConnection:{
		pageInfo:"PageInfo",
		edges:"ContentBlockEdge"
	},
	ContentBlockEdge:{
		node:"ContentBlock"
	},
	TileList:{
		_meta:"TileListMeta",
		id:"UUID",
		items:"TileListItem",
		itemsByTile:"TileListItem",
		paginateItems:"TileListItemConnection"
	},
	TileListMeta:{
		id:"FieldMeta",
		items:"FieldMeta"
	},
	TileListItem:{
		_meta:"TileListItemMeta",
		id:"UUID",
		order:"Int",
		list:"TileList",
		tile:"Tile"
	},
	TileListItemMeta:{
		id:"FieldMeta",
		order:"FieldMeta",
		list:"FieldMeta",
		tile:"FieldMeta"
	},
	Tile:{
		_meta:"TileMeta",
		id:"UUID",
		image:"Image",
		link:"Link",
		isVideo:"Boolean"
	},
	TileMeta:{
		id:"FieldMeta",
		image:"FieldMeta",
		link:"FieldMeta",
		isVideo:"FieldMeta"
	},
	TileListItemConnection:{
		pageInfo:"PageInfo",
		edges:"TileListItemEdge"
	},
	TileListItemEdge:{
		node:"TileListItem"
	},
	Site:{
		_meta:"SiteMeta",
		id:"UUID",
		code:"String",
		order:"Int",
		articles:"Article",
		internalName:"String",
		articleCategories:"ArticleCategory",
		poses:"Pose",
		poseCategories:"PoseCategory",
		poseDifficulties:"PoseDifficulty",
		courses:"Course",
		articleListPage:"ArticleListPage",
		courseListPage:"CourseListPage",
		poseListPage:"PoseListPage",
		courseLecturers:"CourseLecturer",
		courseLecturerListPage:"CourseLecturerListPage",
		homePages:"Homepage",
		subscribeBox:"SubscribeBox",
		header:"Header",
		footer:"Footer",
		genericPages:"GenericPage",
		courseEmail:"CourseEmail",
		translations:"Translations",
		articlesBySeo:"Article",
		articlesByLink:"Article",
		articlesByContent:"Article",
		articleCategoriesByArticles:"ArticleCategory",
		articleCategoriesBySeo:"ArticleCategory",
		articleCategoriesByLink:"ArticleCategory",
		posesByLink:"Pose",
		posesBySeo:"Pose",
		posesByContent:"Pose",
		poseCategoriesByPoses:"PoseCategory",
		poseDifficultiesByPoses:"PoseDifficulty",
		coursesBySeo:"Course",
		coursesByLink:"Course",
		coursesByLessons:"Course",
		coursesByContent:"Course",
		courseLecturersByCourses:"CourseLecturer",
		courseLecturersByLink:"CourseLecturer",
		courseLecturersBySeo:"CourseLecturer",
		courseLecturersByContent:"CourseLecturer",
		genericPagesByContent:"GenericPage",
		genericPagesBySeo:"GenericPage",
		genericPagesByLink:"GenericPage",
		paginateArticles:"ArticleConnection",
		paginateArticleCategories:"ArticleCategoryConnection",
		paginatePoses:"PoseConnection",
		paginatePoseCategories:"PoseCategoryConnection",
		paginatePoseDifficulties:"PoseDifficultyConnection",
		paginateCourses:"CourseConnection",
		paginateCourseLecturers:"CourseLecturerConnection",
		paginateGenericPages:"GenericPageConnection"
	},
	SiteMeta:{
		id:"FieldMeta",
		code:"FieldMeta",
		order:"FieldMeta",
		articles:"FieldMeta",
		internalName:"FieldMeta",
		articleCategories:"FieldMeta",
		poses:"FieldMeta",
		poseCategories:"FieldMeta",
		poseDifficulties:"FieldMeta",
		courses:"FieldMeta",
		articleListPage:"FieldMeta",
		courseListPage:"FieldMeta",
		poseListPage:"FieldMeta",
		courseLecturers:"FieldMeta",
		courseLecturerListPage:"FieldMeta",
		homePages:"FieldMeta",
		subscribeBox:"FieldMeta",
		header:"FieldMeta",
		footer:"FieldMeta",
		genericPages:"FieldMeta",
		courseEmail:"FieldMeta",
		translations:"FieldMeta"
	},
	Article:{
		_meta:"ArticleMeta",
		id:"UUID",
		headline:"String",
		publishedAt:"Date",
		site:"Site",
		seo:"Seo",
		link:"Linkable",
		lead:"String",
		shortLead:"String",
		image:"Image",
		content:"Content",
		category:"ArticleCategory"
	},
	ArticleMeta:{
		id:"FieldMeta",
		headline:"FieldMeta",
		publishedAt:"FieldMeta",
		site:"FieldMeta",
		seo:"FieldMeta",
		link:"FieldMeta",
		lead:"FieldMeta",
		shortLead:"FieldMeta",
		image:"FieldMeta",
		content:"FieldMeta",
		category:"FieldMeta"
	},
	ArticleCategory:{
		_meta:"ArticleCategoryMeta",
		id:"UUID",
		label:"String",
		articles:"Article",
		site:"Site",
		seo:"Seo",
		link:"Linkable",
		lead:"String",
		originalPath:"String",
		articlesBySeo:"Article",
		articlesByLink:"Article",
		articlesByContent:"Article",
		paginateArticles:"ArticleConnection"
	},
	ArticleCategoryMeta:{
		id:"FieldMeta",
		label:"FieldMeta",
		articles:"FieldMeta",
		site:"FieldMeta",
		seo:"FieldMeta",
		link:"FieldMeta",
		lead:"FieldMeta",
		originalPath:"FieldMeta"
	},
	ArticleConnection:{
		pageInfo:"PageInfo",
		edges:"ArticleEdge"
	},
	ArticleEdge:{
		node:"Article"
	},
	Pose:{
		_meta:"PoseMeta",
		id:"UUID",
		headline:"String",
		publishedAt:"Date",
		link:"Linkable",
		site:"Site",
		image:"Image",
		seo:"Seo",
		subtitle:"String",
		content:"Content",
		category:"PoseCategory",
		difficulty:"PoseDifficulty"
	},
	PoseMeta:{
		id:"FieldMeta",
		headline:"FieldMeta",
		publishedAt:"FieldMeta",
		link:"FieldMeta",
		site:"FieldMeta",
		image:"FieldMeta",
		seo:"FieldMeta",
		subtitle:"FieldMeta",
		content:"FieldMeta",
		category:"FieldMeta",
		difficulty:"FieldMeta"
	},
	PoseCategory:{
		_meta:"PoseCategoryMeta",
		id:"UUID",
		label:"String",
		poses:"Pose",
		site:"Site",
		icon:"Image",
		posesByLink:"Pose",
		posesBySeo:"Pose",
		posesByContent:"Pose",
		paginatePoses:"PoseConnection"
	},
	PoseCategoryMeta:{
		id:"FieldMeta",
		label:"FieldMeta",
		poses:"FieldMeta",
		site:"FieldMeta",
		icon:"FieldMeta"
	},
	PoseConnection:{
		pageInfo:"PageInfo",
		edges:"PoseEdge"
	},
	PoseEdge:{
		node:"Pose"
	},
	PoseDifficulty:{
		_meta:"PoseDifficultyMeta",
		id:"UUID",
		label:"String",
		poses:"Pose",
		site:"Site",
		icon:"Image",
		posesByLink:"Pose",
		posesBySeo:"Pose",
		posesByContent:"Pose",
		paginatePoses:"PoseConnection"
	},
	PoseDifficultyMeta:{
		id:"FieldMeta",
		label:"FieldMeta",
		poses:"FieldMeta",
		site:"FieldMeta",
		icon:"FieldMeta"
	},
	Course:{
		_meta:"CourseMeta",
		id:"UUID",
		headline:"String",
		lead:"String",
		shortLead:"String",
		publishedAt:"Date",
		site:"Site",
		image:"Image",
		seo:"Seo",
		link:"Linkable",
		lessons:"CourseLesson",
		difficulty:"String",
		teaser:"YoutubeVideo",
		lessonIntervalInDays:"Int",
		content:"Content",
		lecturer:"CourseLecturer",
		paginateLessons:"CourseLessonConnection"
	},
	CourseMeta:{
		id:"FieldMeta",
		headline:"FieldMeta",
		lead:"FieldMeta",
		shortLead:"FieldMeta",
		publishedAt:"FieldMeta",
		site:"FieldMeta",
		image:"FieldMeta",
		seo:"FieldMeta",
		link:"FieldMeta",
		lessons:"FieldMeta",
		difficulty:"FieldMeta",
		teaser:"FieldMeta",
		lessonIntervalInDays:"FieldMeta",
		content:"FieldMeta",
		lecturer:"FieldMeta"
	},
	CourseLesson:{
		_meta:"CourseLessonMeta",
		id:"UUID",
		order:"Int",
		headline:"String",
		lead:"String",
		course:"Course",
		video:"YoutubeVideo"
	},
	CourseLessonMeta:{
		id:"FieldMeta",
		order:"FieldMeta",
		headline:"FieldMeta",
		lead:"FieldMeta",
		course:"FieldMeta",
		video:"FieldMeta"
	},
	YoutubeVideo:{
		_meta:"YoutubeVideoMeta",
		id:"UUID",
		videoId:"String"
	},
	YoutubeVideoMeta:{
		id:"FieldMeta",
		videoId:"FieldMeta"
	},
	CourseLecturer:{
		_meta:"CourseLecturerMeta",
		id:"UUID",
		name:"String",
		courses:"Course",
		site:"Site",
		link:"Linkable",
		seo:"Seo",
		image:"Image",
		content:"Content",
		coursesBySeo:"Course",
		coursesByLink:"Course",
		coursesByLessons:"Course",
		coursesByContent:"Course",
		paginateCourses:"CourseConnection"
	},
	CourseLecturerMeta:{
		id:"FieldMeta",
		name:"FieldMeta",
		courses:"FieldMeta",
		site:"FieldMeta",
		link:"FieldMeta",
		seo:"FieldMeta",
		image:"FieldMeta",
		content:"FieldMeta"
	},
	CourseConnection:{
		pageInfo:"PageInfo",
		edges:"CourseEdge"
	},
	CourseEdge:{
		node:"Course"
	},
	CourseLessonConnection:{
		pageInfo:"PageInfo",
		edges:"CourseLessonEdge"
	},
	CourseLessonEdge:{
		node:"CourseLesson"
	},
	ArticleListPage:{
		_meta:"ArticleListPageMeta",
		id:"UUID",
		seo:"Seo",
		link:"Linkable",
		title:"String",
		site:"Site"
	},
	ArticleListPageMeta:{
		id:"FieldMeta",
		seo:"FieldMeta",
		link:"FieldMeta",
		title:"FieldMeta",
		site:"FieldMeta"
	},
	CourseListPage:{
		_meta:"CourseListPageMeta",
		id:"UUID",
		seo:"Seo",
		link:"Linkable",
		title:"String",
		site:"Site"
	},
	CourseListPageMeta:{
		id:"FieldMeta",
		seo:"FieldMeta",
		link:"FieldMeta",
		title:"FieldMeta",
		site:"FieldMeta"
	},
	PoseListPage:{
		_meta:"PoseListPageMeta",
		id:"UUID",
		link:"Linkable",
		seo:"Seo",
		title:"String",
		site:"Site"
	},
	PoseListPageMeta:{
		id:"FieldMeta",
		link:"FieldMeta",
		seo:"FieldMeta",
		title:"FieldMeta",
		site:"FieldMeta"
	},
	CourseLecturerListPage:{
		_meta:"CourseLecturerListPageMeta",
		id:"UUID",
		title:"String",
		site:"Site",
		seo:"Seo",
		link:"Linkable"
	},
	CourseLecturerListPageMeta:{
		id:"FieldMeta",
		title:"FieldMeta",
		site:"FieldMeta",
		seo:"FieldMeta",
		link:"FieldMeta"
	},
	SubscribeBox:{
		_meta:"SubscribeBoxMeta",
		id:"UUID",
		title:"String",
		text:"String",
		shapeColorA:"String",
		shapeColorB:"String",
		shapeColorC:"String",
		externalMailingListId:"String",
		site:"Site",
		logo:"Image",
		emailLabel:"String",
		submitLabel:"String"
	},
	SubscribeBoxMeta:{
		id:"FieldMeta",
		title:"FieldMeta",
		text:"FieldMeta",
		shapeColorA:"FieldMeta",
		shapeColorB:"FieldMeta",
		shapeColorC:"FieldMeta",
		externalMailingListId:"FieldMeta",
		site:"FieldMeta",
		logo:"FieldMeta",
		emailLabel:"FieldMeta",
		submitLabel:"FieldMeta"
	},
	Header:{
		_meta:"HeaderMeta",
		id:"UUID",
		primaryNavigationItems:"HeaderPrimaryNavigationItem",
		site:"Site",
		logo:"Image",
		logoOnWhite:"Image",
		primaryNavigationItemsByLink:"HeaderPrimaryNavigationItem",
		primaryNavigationItemsByListNavigation:"HeaderPrimaryNavigationItem",
		primaryNavigationItemsByTiles:"HeaderPrimaryNavigationItem",
		paginatePrimaryNavigationItems:"HeaderPrimaryNavigationItemConnection"
	},
	HeaderMeta:{
		id:"FieldMeta",
		primaryNavigationItems:"FieldMeta",
		site:"FieldMeta",
		logo:"FieldMeta",
		logoOnWhite:"FieldMeta"
	},
	HeaderPrimaryNavigationItem:{
		_meta:"HeaderPrimaryNavigationItemMeta",
		id:"UUID",
		order:"Int",
		header:"Header",
		link:"Link",
		listNavigation:"ListNavigation",
		tiles:"TileList"
	},
	HeaderPrimaryNavigationItemMeta:{
		id:"FieldMeta",
		order:"FieldMeta",
		header:"FieldMeta",
		link:"FieldMeta",
		listNavigation:"FieldMeta",
		tiles:"FieldMeta"
	},
	ListNavigation:{
		_meta:"ListNavigationMeta",
		id:"UUID",
		columns:"ListNavigationColumn",
		columnsByItems:"ListNavigationColumn",
		paginateColumns:"ListNavigationColumnConnection"
	},
	ListNavigationMeta:{
		id:"FieldMeta",
		columns:"FieldMeta"
	},
	ListNavigationColumn:{
		_meta:"ListNavigationColumnMeta",
		id:"UUID",
		order:"Int",
		label:"String",
		listNavigation:"ListNavigation",
		items:"ListNavigationColumnItem",
		itemsByLink:"ListNavigationColumnItem",
		paginateItems:"ListNavigationColumnItemConnection"
	},
	ListNavigationColumnMeta:{
		id:"FieldMeta",
		order:"FieldMeta",
		label:"FieldMeta",
		listNavigation:"FieldMeta",
		items:"FieldMeta"
	},
	ListNavigationColumnItem:{
		_meta:"ListNavigationColumnItemMeta",
		id:"UUID",
		order:"Int",
		column:"ListNavigationColumn",
		link:"Link"
	},
	ListNavigationColumnItemMeta:{
		id:"FieldMeta",
		order:"FieldMeta",
		column:"FieldMeta",
		link:"FieldMeta"
	},
	ListNavigationColumnItemConnection:{
		pageInfo:"PageInfo",
		edges:"ListNavigationColumnItemEdge"
	},
	ListNavigationColumnItemEdge:{
		node:"ListNavigationColumnItem"
	},
	ListNavigationColumnConnection:{
		pageInfo:"PageInfo",
		edges:"ListNavigationColumnEdge"
	},
	ListNavigationColumnEdge:{
		node:"ListNavigationColumn"
	},
	HeaderPrimaryNavigationItemConnection:{
		pageInfo:"PageInfo",
		edges:"HeaderPrimaryNavigationItemEdge"
	},
	HeaderPrimaryNavigationItemEdge:{
		node:"HeaderPrimaryNavigationItem"
	},
	Footer:{
		_meta:"FooterMeta",
		id:"UUID",
		navigation:"ListNavigation",
		site:"Site",
		logo:"Image",
		copyright:"String",
		attribution:"String"
	},
	FooterMeta:{
		id:"FieldMeta",
		navigation:"FieldMeta",
		site:"FieldMeta",
		logo:"FieldMeta",
		copyright:"FieldMeta",
		attribution:"FieldMeta"
	},
	GenericPage:{
		_meta:"GenericPageMeta",
		id:"UUID",
		headline:"String",
		lead:"String",
		publishedAt:"Date",
		site:"Site",
		content:"Content",
		seo:"Seo",
		link:"Linkable"
	},
	GenericPageMeta:{
		id:"FieldMeta",
		headline:"FieldMeta",
		lead:"FieldMeta",
		publishedAt:"FieldMeta",
		site:"FieldMeta",
		content:"FieldMeta",
		seo:"FieldMeta",
		link:"FieldMeta"
	},
	CourseEmail:{
		_meta:"CourseEmailMeta",
		id:"UUID",
		title:"String",
		text:"String",
		buttonLabel:"String",
		logo:"Image",
		cover:"Image",
		site:"Site",
		contactInfoEmailAddress:"String",
		senderEmail:"String"
	},
	CourseEmailMeta:{
		id:"FieldMeta",
		title:"FieldMeta",
		text:"FieldMeta",
		buttonLabel:"FieldMeta",
		logo:"FieldMeta",
		cover:"FieldMeta",
		site:"FieldMeta",
		contactInfoEmailAddress:"FieldMeta",
		senderEmail:"FieldMeta"
	},
	Translations:{
		_meta:"TranslationsMeta",
		id:"UUID",
		home:"String",
		articles:"String",
		article:"String",
		articleMore:"String",
		categories:"String",
		lecturers:"String",
		lecturer:"String",
		courses:"String",
		course:"String",
		poses:"String",
		pose:"String",
		site:"Site",
		posesLong:"String",
		posesMore:"String",
		listAll:"String",
		difficulty:"String",
		skillLevel:"String",
		signIn:"String",
		error:"String"
	},
	TranslationsMeta:{
		id:"FieldMeta",
		home:"FieldMeta",
		articles:"FieldMeta",
		article:"FieldMeta",
		articleMore:"FieldMeta",
		categories:"FieldMeta",
		lecturers:"FieldMeta",
		lecturer:"FieldMeta",
		courses:"FieldMeta",
		course:"FieldMeta",
		poses:"FieldMeta",
		pose:"FieldMeta",
		site:"FieldMeta",
		posesLong:"FieldMeta",
		posesMore:"FieldMeta",
		listAll:"FieldMeta",
		difficulty:"FieldMeta",
		skillLevel:"FieldMeta",
		signIn:"FieldMeta",
		error:"FieldMeta"
	},
	ArticleCategoryConnection:{
		pageInfo:"PageInfo",
		edges:"ArticleCategoryEdge"
	},
	ArticleCategoryEdge:{
		node:"ArticleCategory"
	},
	PoseCategoryConnection:{
		pageInfo:"PageInfo",
		edges:"PoseCategoryEdge"
	},
	PoseCategoryEdge:{
		node:"PoseCategory"
	},
	PoseDifficultyConnection:{
		pageInfo:"PageInfo",
		edges:"PoseDifficultyEdge"
	},
	PoseDifficultyEdge:{
		node:"PoseDifficulty"
	},
	CourseLecturerConnection:{
		pageInfo:"PageInfo",
		edges:"CourseLecturerEdge"
	},
	CourseLecturerEdge:{
		node:"CourseLecturer"
	},
	GenericPageConnection:{
		pageInfo:"PageInfo",
		edges:"GenericPageEdge"
	},
	GenericPageEdge:{
		node:"GenericPage"
	},
	NewsletterSubscribeSuccessPage:{
		_meta:"NewsletterSubscribeSuccessPageMeta",
		id:"UUID",
		title:"String",
		returnLinkText:"String",
		link:"Linkable",
		site:"Site",
		seo:"Seo"
	},
	NewsletterSubscribeSuccessPageMeta:{
		id:"FieldMeta",
		title:"FieldMeta",
		returnLinkText:"FieldMeta",
		link:"FieldMeta",
		site:"FieldMeta",
		seo:"FieldMeta"
	},
	LinkConnection:{
		pageInfo:"PageInfo",
		edges:"LinkEdge"
	},
	LinkEdge:{
		node:"Link"
	},
	LinkableConnection:{
		pageInfo:"PageInfo",
		edges:"LinkableEdge"
	},
	LinkableEdge:{
		node:"Linkable"
	},
	RedirectConnection:{
		pageInfo:"PageInfo",
		edges:"RedirectEdge"
	},
	RedirectEdge:{
		node:"Redirect"
	},
	FooterConnection:{
		pageInfo:"PageInfo",
		edges:"FooterEdge"
	},
	FooterEdge:{
		node:"Footer"
	},
	ImageConnection:{
		pageInfo:"PageInfo",
		edges:"ImageEdge"
	},
	ImageEdge:{
		node:"Image"
	},
	SeoConnection:{
		pageInfo:"PageInfo",
		edges:"SeoEdge"
	},
	SeoEdge:{
		node:"Seo"
	},
	HomepageConnection:{
		pageInfo:"PageInfo",
		edges:"HomepageEdge"
	},
	HomepageEdge:{
		node:"Homepage"
	},
	HeaderConnection:{
		pageInfo:"PageInfo",
		edges:"HeaderEdge"
	},
	HeaderEdge:{
		node:"Header"
	},
	ListNavigationConnection:{
		pageInfo:"PageInfo",
		edges:"ListNavigationEdge"
	},
	ListNavigationEdge:{
		node:"ListNavigation"
	},
	SiteConnection:{
		pageInfo:"PageInfo",
		edges:"SiteEdge"
	},
	SiteEdge:{
		node:"Site"
	},
	ArticleListPageConnection:{
		pageInfo:"PageInfo",
		edges:"ArticleListPageEdge"
	},
	ArticleListPageEdge:{
		node:"ArticleListPage"
	},
	CourseListPageConnection:{
		pageInfo:"PageInfo",
		edges:"CourseListPageEdge"
	},
	CourseListPageEdge:{
		node:"CourseListPage"
	},
	PoseListPageConnection:{
		pageInfo:"PageInfo",
		edges:"PoseListPageEdge"
	},
	PoseListPageEdge:{
		node:"PoseListPage"
	},
	ContentConnection:{
		pageInfo:"PageInfo",
		edges:"ContentEdge"
	},
	ContentEdge:{
		node:"Content"
	},
	TileConnection:{
		pageInfo:"PageInfo",
		edges:"TileEdge"
	},
	TileEdge:{
		node:"Tile"
	},
	TileListConnection:{
		pageInfo:"PageInfo",
		edges:"TileListEdge"
	},
	TileListEdge:{
		node:"TileList"
	},
	YoutubeVideoConnection:{
		pageInfo:"PageInfo",
		edges:"YoutubeVideoEdge"
	},
	YoutubeVideoEdge:{
		node:"YoutubeVideo"
	},
	SeoDefault:{
		_meta:"SeoDefaultMeta",
		id:"UUID",
		unique:"One",
		seo:"Seo"
	},
	SeoDefaultMeta:{
		id:"FieldMeta",
		unique:"FieldMeta",
		seo:"FieldMeta"
	},
	SeoDefaultConnection:{
		pageInfo:"PageInfo",
		edges:"SeoDefaultEdge"
	},
	SeoDefaultEdge:{
		node:"SeoDefault"
	},
	CourseLecturerListPageConnection:{
		pageInfo:"PageInfo",
		edges:"CourseLecturerListPageEdge"
	},
	CourseLecturerListPageEdge:{
		node:"CourseLecturerListPage"
	},
	NewsletterSubscription:{
		_meta:"NewsletterSubscriptionMeta",
		id:"UUID",
		startDate:"Date",
		visitor:"Visitor",
		site:"Site"
	},
	NewsletterSubscriptionMeta:{
		id:"FieldMeta",
		startDate:"FieldMeta",
		visitor:"FieldMeta",
		site:"FieldMeta"
	},
	Visitor:{
		_meta:"VisitorMeta",
		id:"UUID",
		email:"String",
		newsletterSubscriptions:"NewsletterSubscription",
		courseSubscriptions:"CourseSubscription",
		newsletterSubscriptionsBySite:"NewsletterSubscription",
		courseSubscriptionsByCourse:"CourseSubscription",
		paginateNewsletterSubscriptions:"NewsletterSubscriptionConnection",
		paginateCourseSubscriptions:"CourseSubscriptionConnection"
	},
	VisitorMeta:{
		id:"FieldMeta",
		email:"FieldMeta",
		newsletterSubscriptions:"FieldMeta",
		courseSubscriptions:"FieldMeta"
	},
	CourseSubscription:{
		_meta:"CourseSubscriptionMeta",
		id:"UUID",
		startDate:"Date",
		visitor:"Visitor",
		course:"Course"
	},
	CourseSubscriptionMeta:{
		id:"FieldMeta",
		startDate:"FieldMeta",
		visitor:"FieldMeta",
		course:"FieldMeta"
	},
	NewsletterSubscriptionConnection:{
		pageInfo:"PageInfo",
		edges:"NewsletterSubscriptionEdge"
	},
	NewsletterSubscriptionEdge:{
		node:"NewsletterSubscription"
	},
	CourseSubscriptionConnection:{
		pageInfo:"PageInfo",
		edges:"CourseSubscriptionEdge"
	},
	CourseSubscriptionEdge:{
		node:"CourseSubscription"
	},
	_ValidationResult:{
		valid:"Boolean",
		errors:"_ValidationError"
	},
	_ValidationError:{
		path:"_PathFragment",
		message:"_ValidationMessage"
	},
	_PathFragment:{
		"...on _FieldPathFragment":"_FieldPathFragment",
		"...on _IndexPathFragment":"_IndexPathFragment"
	},
	_FieldPathFragment:{
		field:"String"
	},
	_IndexPathFragment:{
		index:"Int",
		alias:"String"
	},
	_ValidationMessage:{
		text:"String"
	},
	VisitorConnection:{
		pageInfo:"PageInfo",
		edges:"VisitorEdge"
	},
	VisitorEdge:{
		node:"Visitor"
	},
	SubscribeBoxConnection:{
		pageInfo:"PageInfo",
		edges:"SubscribeBoxEdge"
	},
	SubscribeBoxEdge:{
		node:"SubscribeBox"
	},
	NewsletterSubscribeSuccessPageConnection:{
		pageInfo:"PageInfo",
		edges:"NewsletterSubscribeSuccessPageEdge"
	},
	NewsletterSubscribeSuccessPageEdge:{
		node:"NewsletterSubscribeSuccessPage"
	},
	PersonConnection:{
		pageInfo:"PageInfo",
		edges:"PersonEdge"
	},
	PersonEdge:{
		node:"Person"
	},
	PersonListConnection:{
		pageInfo:"PageInfo",
		edges:"PersonListEdge"
	},
	PersonListEdge:{
		node:"PersonList"
	},
	CourseEmailConnection:{
		pageInfo:"PageInfo",
		edges:"CourseEmailEdge"
	},
	CourseEmailEdge:{
		node:"CourseEmail"
	},
	TranslationsConnection:{
		pageInfo:"PageInfo",
		edges:"TranslationsEdge"
	},
	TranslationsEdge:{
		node:"Translations"
	},
	QueryTransaction:{
		getLink:"Link",
		listLink:"Link",
		paginateLink:"LinkConnection",
		getLinkable:"Linkable",
		listLinkable:"Linkable",
		paginateLinkable:"LinkableConnection",
		getRedirect:"Redirect",
		listRedirect:"Redirect",
		paginateRedirect:"RedirectConnection",
		getFooter:"Footer",
		listFooter:"Footer",
		paginateFooter:"FooterConnection",
		getImage:"Image",
		listImage:"Image",
		paginateImage:"ImageConnection",
		getSeo:"Seo",
		listSeo:"Seo",
		paginateSeo:"SeoConnection",
		getHomepage:"Homepage",
		listHomepage:"Homepage",
		paginateHomepage:"HomepageConnection",
		getHeader:"Header",
		listHeader:"Header",
		paginateHeader:"HeaderConnection",
		getHeaderPrimaryNavigationItem:"HeaderPrimaryNavigationItem",
		listHeaderPrimaryNavigationItem:"HeaderPrimaryNavigationItem",
		paginateHeaderPrimaryNavigationItem:"HeaderPrimaryNavigationItemConnection",
		getListNavigation:"ListNavigation",
		listListNavigation:"ListNavigation",
		paginateListNavigation:"ListNavigationConnection",
		getListNavigationColumn:"ListNavigationColumn",
		listListNavigationColumn:"ListNavigationColumn",
		paginateListNavigationColumn:"ListNavigationColumnConnection",
		getListNavigationColumnItem:"ListNavigationColumnItem",
		listListNavigationColumnItem:"ListNavigationColumnItem",
		paginateListNavigationColumnItem:"ListNavigationColumnItemConnection",
		getArticle:"Article",
		listArticle:"Article",
		paginateArticle:"ArticleConnection",
		getSite:"Site",
		listSite:"Site",
		paginateSite:"SiteConnection",
		getArticleCategory:"ArticleCategory",
		listArticleCategory:"ArticleCategory",
		paginateArticleCategory:"ArticleCategoryConnection",
		getPose:"Pose",
		listPose:"Pose",
		paginatePose:"PoseConnection",
		getPoseCategory:"PoseCategory",
		listPoseCategory:"PoseCategory",
		paginatePoseCategory:"PoseCategoryConnection",
		getPoseDifficulty:"PoseDifficulty",
		listPoseDifficulty:"PoseDifficulty",
		paginatePoseDifficulty:"PoseDifficultyConnection",
		getCourse:"Course",
		listCourse:"Course",
		paginateCourse:"CourseConnection",
		getCourseLesson:"CourseLesson",
		listCourseLesson:"CourseLesson",
		paginateCourseLesson:"CourseLessonConnection",
		getArticleListPage:"ArticleListPage",
		listArticleListPage:"ArticleListPage",
		paginateArticleListPage:"ArticleListPageConnection",
		getCourseListPage:"CourseListPage",
		listCourseListPage:"CourseListPage",
		paginateCourseListPage:"CourseListPageConnection",
		getPoseListPage:"PoseListPage",
		listPoseListPage:"PoseListPage",
		paginatePoseListPage:"PoseListPageConnection",
		getContent:"Content",
		listContent:"Content",
		paginateContent:"ContentConnection",
		getContentBlock:"ContentBlock",
		listContentBlock:"ContentBlock",
		paginateContentBlock:"ContentBlockConnection",
		getContentReference:"ContentReference",
		listContentReference:"ContentReference",
		paginateContentReference:"ContentReferenceConnection",
		getTile:"Tile",
		listTile:"Tile",
		paginateTile:"TileConnection",
		getTileList:"TileList",
		listTileList:"TileList",
		paginateTileList:"TileListConnection",
		getTileListItem:"TileListItem",
		listTileListItem:"TileListItem",
		paginateTileListItem:"TileListItemConnection",
		getYoutubeVideo:"YoutubeVideo",
		listYoutubeVideo:"YoutubeVideo",
		paginateYoutubeVideo:"YoutubeVideoConnection",
		getSeoDefault:"SeoDefault",
		listSeoDefault:"SeoDefault",
		paginateSeoDefault:"SeoDefaultConnection",
		getCourseLecturer:"CourseLecturer",
		listCourseLecturer:"CourseLecturer",
		paginateCourseLecturer:"CourseLecturerConnection",
		getCourseLecturerListPage:"CourseLecturerListPage",
		listCourseLecturerListPage:"CourseLecturerListPage",
		paginateCourseLecturerListPage:"CourseLecturerListPageConnection",
		getNewsletterSubscription:"NewsletterSubscription",
		listNewsletterSubscription:"NewsletterSubscription",
		paginateNewsletterSubscription:"NewsletterSubscriptionConnection",
		validateCreateNewsletterSubscription:"_ValidationResult",
		getVisitor:"Visitor",
		listVisitor:"Visitor",
		paginateVisitor:"VisitorConnection",
		validateCreateVisitor:"_ValidationResult",
		getCourseSubscription:"CourseSubscription",
		listCourseSubscription:"CourseSubscription",
		paginateCourseSubscription:"CourseSubscriptionConnection",
		validateCreateCourseSubscription:"_ValidationResult",
		getSubscribeBox:"SubscribeBox",
		listSubscribeBox:"SubscribeBox",
		paginateSubscribeBox:"SubscribeBoxConnection",
		getNewsletterSubscribeSuccessPage:"NewsletterSubscribeSuccessPage",
		listNewsletterSubscribeSuccessPage:"NewsletterSubscribeSuccessPage",
		paginateNewsletterSubscribeSuccessPage:"NewsletterSubscribeSuccessPageConnection",
		getGenericPage:"GenericPage",
		listGenericPage:"GenericPage",
		paginateGenericPage:"GenericPageConnection",
		getPerson:"Person",
		listPerson:"Person",
		paginatePerson:"PersonConnection",
		getPersonList:"PersonList",
		listPersonList:"PersonList",
		paginatePersonList:"PersonListConnection",
		getPersonListItem:"PersonListItem",
		listPersonListItem:"PersonListItem",
		paginatePersonListItem:"PersonListItemConnection",
		getCourseEmail:"CourseEmail",
		listCourseEmail:"CourseEmail",
		paginateCourseEmail:"CourseEmailConnection",
		getTranslations:"Translations",
		listTranslations:"Translations",
		paginateTranslations:"TranslationsConnection"
	},
	Info:{
		description:"String"
	},
	Mutation:{
		createNewsletterSubscription:"NewsletterSubscriptionCreateResult",
		deleteNewsletterSubscription:"NewsletterSubscriptionDeleteResult",
		createVisitor:"VisitorCreateResult",
		createCourseSubscription:"CourseSubscriptionCreateResult",
		deleteCourseSubscription:"CourseSubscriptionDeleteResult",
		transaction:"MutationTransaction",
		query:"Query"
	},
	NewsletterSubscriptionCreateResult:{
		ok:"Boolean",
		errorMessage:"String",
		errors:"_MutationError",
		node:"NewsletterSubscription",
		validation:"_ValidationResult"
	},
	MutationResult:{
		"...on NewsletterSubscriptionCreateResult": "NewsletterSubscriptionCreateResult",
		"...on NewsletterSubscriptionDeleteResult": "NewsletterSubscriptionDeleteResult",
		"...on VisitorCreateResult": "VisitorCreateResult",
		"...on CourseSubscriptionCreateResult": "CourseSubscriptionCreateResult",
		"...on CourseSubscriptionDeleteResult": "CourseSubscriptionDeleteResult",
		ok:"Boolean",
		errorMessage:"String",
		errors:"_MutationError"
	},
	_MutationError:{
		path:"_PathFragment",
		paths:"_PathFragment",
		type:"_MutationErrorType",
		message:"String"
	},
	NewsletterSubscriptionDeleteResult:{
		ok:"Boolean",
		errorMessage:"String",
		errors:"_MutationError",
		node:"NewsletterSubscription"
	},
	VisitorCreateResult:{
		ok:"Boolean",
		errorMessage:"String",
		errors:"_MutationError",
		node:"Visitor",
		validation:"_ValidationResult"
	},
	CourseSubscriptionCreateResult:{
		ok:"Boolean",
		errorMessage:"String",
		errors:"_MutationError",
		node:"CourseSubscription",
		validation:"_ValidationResult"
	},
	CourseSubscriptionDeleteResult:{
		ok:"Boolean",
		errorMessage:"String",
		errors:"_MutationError",
		node:"CourseSubscription"
	},
	MutationTransaction:{
		ok:"Boolean",
		errorMessage:"String",
		errors:"_MutationError",
		validation:"_ValidationResult",
		createNewsletterSubscription:"NewsletterSubscriptionCreateResult",
		deleteNewsletterSubscription:"NewsletterSubscriptionDeleteResult",
		createVisitor:"VisitorCreateResult",
		createCourseSubscription:"CourseSubscriptionCreateResult",
		deleteCourseSubscription:"CourseSubscriptionDeleteResult",
		query:"Query"
	},
	_Schema:{
		enums:"_Enum",
		entities:"_Entity"
	},
	_Entity:{
		name:"String",
		customPrimaryAllowed:"Boolean",
		fields:"_Field",
		unique:"_UniqueConstraint"
	},
	_UniqueConstraint:{
		fields:"String"
	},
	_Field:{
		"...on _Column": "_Column",
		"...on _Relation": "_Relation",
		name:"String",
		type:"String",
		nullable:"Boolean",
		rules:"_Rule",
		validators:"_Validator"
	},
	_Column:{
		name:"String",
		type:"String",
		enumName:"String",
		defaultValue:"Json",
		nullable:"Boolean",
		rules:"_Rule",
		validators:"_Validator"
	},
	_OrderBy:{
		path:"String",
		direction:"_OrderByDirection"
	},
	_Relation:{
		name:"String",
		type:"String",
		side:"_RelationSide",
		targetEntity:"String",
		ownedBy:"String",
		inversedBy:"String",
		nullable:"Boolean",
		onDelete:"_OnDeleteBehaviour",
		orphanRemoval:"Boolean",
		orderBy:"_OrderBy",
		rules:"_Rule",
		validators:"_Validator"
	},
	_Rule:{
		message:"_RuleMessage",
		validator:"Int"
	},
	_Validator:{
		operation:"String",
		arguments:"_Argument"
	},
	_Argument:{
		"...on _ValidatorArgument":"_ValidatorArgument",
		"...on _PathArgument":"_PathArgument",
		"...on _LiteralArgument":"_LiteralArgument"
	},
	_ValidatorArgument:{
		validator:"Int"
	},
	_PathArgument:{
		path:"String"
	},
	_LiteralArgument:{
		value:"Json"
	},
	_RuleMessage:{
		text:"String"
	},
	_Enum:{
		name:"String",
		values:"String"
	}
}
type ZEUS_INTERFACES = GraphQLTypes["MutationResult"] | GraphQLTypes["_Field"]
type ZEUS_UNIONS = GraphQLTypes["_PathFragment"] | GraphQLTypes["_Argument"]

export type ValueTypes = {
    ["Query"]: AliasType<{
getLink?: [{	by:ValueTypes["LinkUniqueWhere"],	filter?:ValueTypes["LinkWhere"] | null},ValueTypes["Link"]],
listLink?: [{	filter?:ValueTypes["LinkWhere"] | null,	orderBy?:ValueTypes["LinkOrderBy"][],	offset?:number | null,	limit?:number | null},ValueTypes["Link"]],
paginateLink?: [{	filter?:ValueTypes["LinkWhere"] | null,	orderBy?:ValueTypes["LinkOrderBy"][],	skip?:number | null,	first?:number | null},ValueTypes["LinkConnection"]],
getLinkable?: [{	by:ValueTypes["LinkableUniqueWhere"],	filter?:ValueTypes["LinkableWhere"] | null},ValueTypes["Linkable"]],
listLinkable?: [{	filter?:ValueTypes["LinkableWhere"] | null,	orderBy?:ValueTypes["LinkableOrderBy"][],	offset?:number | null,	limit?:number | null},ValueTypes["Linkable"]],
paginateLinkable?: [{	filter?:ValueTypes["LinkableWhere"] | null,	orderBy?:ValueTypes["LinkableOrderBy"][],	skip?:number | null,	first?:number | null},ValueTypes["LinkableConnection"]],
getRedirect?: [{	by:ValueTypes["RedirectUniqueWhere"],	filter?:ValueTypes["RedirectWhere"] | null},ValueTypes["Redirect"]],
listRedirect?: [{	filter?:ValueTypes["RedirectWhere"] | null,	orderBy?:ValueTypes["RedirectOrderBy"][],	offset?:number | null,	limit?:number | null},ValueTypes["Redirect"]],
paginateRedirect?: [{	filter?:ValueTypes["RedirectWhere"] | null,	orderBy?:ValueTypes["RedirectOrderBy"][],	skip?:number | null,	first?:number | null},ValueTypes["RedirectConnection"]],
getFooter?: [{	by:ValueTypes["FooterUniqueWhere"],	filter?:ValueTypes["FooterWhere"] | null},ValueTypes["Footer"]],
listFooter?: [{	filter?:ValueTypes["FooterWhere"] | null,	orderBy?:ValueTypes["FooterOrderBy"][],	offset?:number | null,	limit?:number | null},ValueTypes["Footer"]],
paginateFooter?: [{	filter?:ValueTypes["FooterWhere"] | null,	orderBy?:ValueTypes["FooterOrderBy"][],	skip?:number | null,	first?:number | null},ValueTypes["FooterConnection"]],
getImage?: [{	by:ValueTypes["ImageUniqueWhere"],	filter?:ValueTypes["ImageWhere"] | null},ValueTypes["Image"]],
listImage?: [{	filter?:ValueTypes["ImageWhere"] | null,	orderBy?:ValueTypes["ImageOrderBy"][],	offset?:number | null,	limit?:number | null},ValueTypes["Image"]],
paginateImage?: [{	filter?:ValueTypes["ImageWhere"] | null,	orderBy?:ValueTypes["ImageOrderBy"][],	skip?:number | null,	first?:number | null},ValueTypes["ImageConnection"]],
getSeo?: [{	by:ValueTypes["SeoUniqueWhere"],	filter?:ValueTypes["SeoWhere"] | null},ValueTypes["Seo"]],
listSeo?: [{	filter?:ValueTypes["SeoWhere"] | null,	orderBy?:ValueTypes["SeoOrderBy"][],	offset?:number | null,	limit?:number | null},ValueTypes["Seo"]],
paginateSeo?: [{	filter?:ValueTypes["SeoWhere"] | null,	orderBy?:ValueTypes["SeoOrderBy"][],	skip?:number | null,	first?:number | null},ValueTypes["SeoConnection"]],
getHomepage?: [{	by:ValueTypes["HomepageUniqueWhere"],	filter?:ValueTypes["HomepageWhere"] | null},ValueTypes["Homepage"]],
listHomepage?: [{	filter?:ValueTypes["HomepageWhere"] | null,	orderBy?:ValueTypes["HomepageOrderBy"][],	offset?:number | null,	limit?:number | null},ValueTypes["Homepage"]],
paginateHomepage?: [{	filter?:ValueTypes["HomepageWhere"] | null,	orderBy?:ValueTypes["HomepageOrderBy"][],	skip?:number | null,	first?:number | null},ValueTypes["HomepageConnection"]],
getHeader?: [{	by:ValueTypes["HeaderUniqueWhere"],	filter?:ValueTypes["HeaderWhere"] | null},ValueTypes["Header"]],
listHeader?: [{	filter?:ValueTypes["HeaderWhere"] | null,	orderBy?:ValueTypes["HeaderOrderBy"][],	offset?:number | null,	limit?:number | null},ValueTypes["Header"]],
paginateHeader?: [{	filter?:ValueTypes["HeaderWhere"] | null,	orderBy?:ValueTypes["HeaderOrderBy"][],	skip?:number | null,	first?:number | null},ValueTypes["HeaderConnection"]],
getHeaderPrimaryNavigationItem?: [{	by:ValueTypes["HeaderPrimaryNavigationItemUniqueWhere"],	filter?:ValueTypes["HeaderPrimaryNavigationItemWhere"] | null},ValueTypes["HeaderPrimaryNavigationItem"]],
listHeaderPrimaryNavigationItem?: [{	filter?:ValueTypes["HeaderPrimaryNavigationItemWhere"] | null,	orderBy?:ValueTypes["HeaderPrimaryNavigationItemOrderBy"][],	offset?:number | null,	limit?:number | null},ValueTypes["HeaderPrimaryNavigationItem"]],
paginateHeaderPrimaryNavigationItem?: [{	filter?:ValueTypes["HeaderPrimaryNavigationItemWhere"] | null,	orderBy?:ValueTypes["HeaderPrimaryNavigationItemOrderBy"][],	skip?:number | null,	first?:number | null},ValueTypes["HeaderPrimaryNavigationItemConnection"]],
getListNavigation?: [{	by:ValueTypes["ListNavigationUniqueWhere"],	filter?:ValueTypes["ListNavigationWhere"] | null},ValueTypes["ListNavigation"]],
listListNavigation?: [{	filter?:ValueTypes["ListNavigationWhere"] | null,	orderBy?:ValueTypes["ListNavigationOrderBy"][],	offset?:number | null,	limit?:number | null},ValueTypes["ListNavigation"]],
paginateListNavigation?: [{	filter?:ValueTypes["ListNavigationWhere"] | null,	orderBy?:ValueTypes["ListNavigationOrderBy"][],	skip?:number | null,	first?:number | null},ValueTypes["ListNavigationConnection"]],
getListNavigationColumn?: [{	by:ValueTypes["ListNavigationColumnUniqueWhere"],	filter?:ValueTypes["ListNavigationColumnWhere"] | null},ValueTypes["ListNavigationColumn"]],
listListNavigationColumn?: [{	filter?:ValueTypes["ListNavigationColumnWhere"] | null,	orderBy?:ValueTypes["ListNavigationColumnOrderBy"][],	offset?:number | null,	limit?:number | null},ValueTypes["ListNavigationColumn"]],
paginateListNavigationColumn?: [{	filter?:ValueTypes["ListNavigationColumnWhere"] | null,	orderBy?:ValueTypes["ListNavigationColumnOrderBy"][],	skip?:number | null,	first?:number | null},ValueTypes["ListNavigationColumnConnection"]],
getListNavigationColumnItem?: [{	by:ValueTypes["ListNavigationColumnItemUniqueWhere"],	filter?:ValueTypes["ListNavigationColumnItemWhere"] | null},ValueTypes["ListNavigationColumnItem"]],
listListNavigationColumnItem?: [{	filter?:ValueTypes["ListNavigationColumnItemWhere"] | null,	orderBy?:ValueTypes["ListNavigationColumnItemOrderBy"][],	offset?:number | null,	limit?:number | null},ValueTypes["ListNavigationColumnItem"]],
paginateListNavigationColumnItem?: [{	filter?:ValueTypes["ListNavigationColumnItemWhere"] | null,	orderBy?:ValueTypes["ListNavigationColumnItemOrderBy"][],	skip?:number | null,	first?:number | null},ValueTypes["ListNavigationColumnItemConnection"]],
getArticle?: [{	by:ValueTypes["ArticleUniqueWhere"],	filter?:ValueTypes["ArticleWhere"] | null},ValueTypes["Article"]],
listArticle?: [{	filter?:ValueTypes["ArticleWhere"] | null,	orderBy?:ValueTypes["ArticleOrderBy"][],	offset?:number | null,	limit?:number | null},ValueTypes["Article"]],
paginateArticle?: [{	filter?:ValueTypes["ArticleWhere"] | null,	orderBy?:ValueTypes["ArticleOrderBy"][],	skip?:number | null,	first?:number | null},ValueTypes["ArticleConnection"]],
getSite?: [{	by:ValueTypes["SiteUniqueWhere"],	filter?:ValueTypes["SiteWhere"] | null},ValueTypes["Site"]],
listSite?: [{	filter?:ValueTypes["SiteWhere"] | null,	orderBy?:ValueTypes["SiteOrderBy"][],	offset?:number | null,	limit?:number | null},ValueTypes["Site"]],
paginateSite?: [{	filter?:ValueTypes["SiteWhere"] | null,	orderBy?:ValueTypes["SiteOrderBy"][],	skip?:number | null,	first?:number | null},ValueTypes["SiteConnection"]],
getArticleCategory?: [{	by:ValueTypes["ArticleCategoryUniqueWhere"],	filter?:ValueTypes["ArticleCategoryWhere"] | null},ValueTypes["ArticleCategory"]],
listArticleCategory?: [{	filter?:ValueTypes["ArticleCategoryWhere"] | null,	orderBy?:ValueTypes["ArticleCategoryOrderBy"][],	offset?:number | null,	limit?:number | null},ValueTypes["ArticleCategory"]],
paginateArticleCategory?: [{	filter?:ValueTypes["ArticleCategoryWhere"] | null,	orderBy?:ValueTypes["ArticleCategoryOrderBy"][],	skip?:number | null,	first?:number | null},ValueTypes["ArticleCategoryConnection"]],
getPose?: [{	by:ValueTypes["PoseUniqueWhere"],	filter?:ValueTypes["PoseWhere"] | null},ValueTypes["Pose"]],
listPose?: [{	filter?:ValueTypes["PoseWhere"] | null,	orderBy?:ValueTypes["PoseOrderBy"][],	offset?:number | null,	limit?:number | null},ValueTypes["Pose"]],
paginatePose?: [{	filter?:ValueTypes["PoseWhere"] | null,	orderBy?:ValueTypes["PoseOrderBy"][],	skip?:number | null,	first?:number | null},ValueTypes["PoseConnection"]],
getPoseCategory?: [{	by:ValueTypes["PoseCategoryUniqueWhere"],	filter?:ValueTypes["PoseCategoryWhere"] | null},ValueTypes["PoseCategory"]],
listPoseCategory?: [{	filter?:ValueTypes["PoseCategoryWhere"] | null,	orderBy?:ValueTypes["PoseCategoryOrderBy"][],	offset?:number | null,	limit?:number | null},ValueTypes["PoseCategory"]],
paginatePoseCategory?: [{	filter?:ValueTypes["PoseCategoryWhere"] | null,	orderBy?:ValueTypes["PoseCategoryOrderBy"][],	skip?:number | null,	first?:number | null},ValueTypes["PoseCategoryConnection"]],
getPoseDifficulty?: [{	by:ValueTypes["PoseDifficultyUniqueWhere"],	filter?:ValueTypes["PoseDifficultyWhere"] | null},ValueTypes["PoseDifficulty"]],
listPoseDifficulty?: [{	filter?:ValueTypes["PoseDifficultyWhere"] | null,	orderBy?:ValueTypes["PoseDifficultyOrderBy"][],	offset?:number | null,	limit?:number | null},ValueTypes["PoseDifficulty"]],
paginatePoseDifficulty?: [{	filter?:ValueTypes["PoseDifficultyWhere"] | null,	orderBy?:ValueTypes["PoseDifficultyOrderBy"][],	skip?:number | null,	first?:number | null},ValueTypes["PoseDifficultyConnection"]],
getCourse?: [{	by:ValueTypes["CourseUniqueWhere"],	filter?:ValueTypes["CourseWhere"] | null},ValueTypes["Course"]],
listCourse?: [{	filter?:ValueTypes["CourseWhere"] | null,	orderBy?:ValueTypes["CourseOrderBy"][],	offset?:number | null,	limit?:number | null},ValueTypes["Course"]],
paginateCourse?: [{	filter?:ValueTypes["CourseWhere"] | null,	orderBy?:ValueTypes["CourseOrderBy"][],	skip?:number | null,	first?:number | null},ValueTypes["CourseConnection"]],
getCourseLesson?: [{	by:ValueTypes["CourseLessonUniqueWhere"],	filter?:ValueTypes["CourseLessonWhere"] | null},ValueTypes["CourseLesson"]],
listCourseLesson?: [{	filter?:ValueTypes["CourseLessonWhere"] | null,	orderBy?:ValueTypes["CourseLessonOrderBy"][],	offset?:number | null,	limit?:number | null},ValueTypes["CourseLesson"]],
paginateCourseLesson?: [{	filter?:ValueTypes["CourseLessonWhere"] | null,	orderBy?:ValueTypes["CourseLessonOrderBy"][],	skip?:number | null,	first?:number | null},ValueTypes["CourseLessonConnection"]],
getArticleListPage?: [{	by:ValueTypes["ArticleListPageUniqueWhere"],	filter?:ValueTypes["ArticleListPageWhere"] | null},ValueTypes["ArticleListPage"]],
listArticleListPage?: [{	filter?:ValueTypes["ArticleListPageWhere"] | null,	orderBy?:ValueTypes["ArticleListPageOrderBy"][],	offset?:number | null,	limit?:number | null},ValueTypes["ArticleListPage"]],
paginateArticleListPage?: [{	filter?:ValueTypes["ArticleListPageWhere"] | null,	orderBy?:ValueTypes["ArticleListPageOrderBy"][],	skip?:number | null,	first?:number | null},ValueTypes["ArticleListPageConnection"]],
getCourseListPage?: [{	by:ValueTypes["CourseListPageUniqueWhere"],	filter?:ValueTypes["CourseListPageWhere"] | null},ValueTypes["CourseListPage"]],
listCourseListPage?: [{	filter?:ValueTypes["CourseListPageWhere"] | null,	orderBy?:ValueTypes["CourseListPageOrderBy"][],	offset?:number | null,	limit?:number | null},ValueTypes["CourseListPage"]],
paginateCourseListPage?: [{	filter?:ValueTypes["CourseListPageWhere"] | null,	orderBy?:ValueTypes["CourseListPageOrderBy"][],	skip?:number | null,	first?:number | null},ValueTypes["CourseListPageConnection"]],
getPoseListPage?: [{	by:ValueTypes["PoseListPageUniqueWhere"],	filter?:ValueTypes["PoseListPageWhere"] | null},ValueTypes["PoseListPage"]],
listPoseListPage?: [{	filter?:ValueTypes["PoseListPageWhere"] | null,	orderBy?:ValueTypes["PoseListPageOrderBy"][],	offset?:number | null,	limit?:number | null},ValueTypes["PoseListPage"]],
paginatePoseListPage?: [{	filter?:ValueTypes["PoseListPageWhere"] | null,	orderBy?:ValueTypes["PoseListPageOrderBy"][],	skip?:number | null,	first?:number | null},ValueTypes["PoseListPageConnection"]],
getContent?: [{	by:ValueTypes["ContentUniqueWhere"],	filter?:ValueTypes["ContentWhere"] | null},ValueTypes["Content"]],
listContent?: [{	filter?:ValueTypes["ContentWhere"] | null,	orderBy?:ValueTypes["ContentOrderBy"][],	offset?:number | null,	limit?:number | null},ValueTypes["Content"]],
paginateContent?: [{	filter?:ValueTypes["ContentWhere"] | null,	orderBy?:ValueTypes["ContentOrderBy"][],	skip?:number | null,	first?:number | null},ValueTypes["ContentConnection"]],
getContentBlock?: [{	by:ValueTypes["ContentBlockUniqueWhere"],	filter?:ValueTypes["ContentBlockWhere"] | null},ValueTypes["ContentBlock"]],
listContentBlock?: [{	filter?:ValueTypes["ContentBlockWhere"] | null,	orderBy?:ValueTypes["ContentBlockOrderBy"][],	offset?:number | null,	limit?:number | null},ValueTypes["ContentBlock"]],
paginateContentBlock?: [{	filter?:ValueTypes["ContentBlockWhere"] | null,	orderBy?:ValueTypes["ContentBlockOrderBy"][],	skip?:number | null,	first?:number | null},ValueTypes["ContentBlockConnection"]],
getContentReference?: [{	by:ValueTypes["ContentReferenceUniqueWhere"],	filter?:ValueTypes["ContentReferenceWhere"] | null},ValueTypes["ContentReference"]],
listContentReference?: [{	filter?:ValueTypes["ContentReferenceWhere"] | null,	orderBy?:ValueTypes["ContentReferenceOrderBy"][],	offset?:number | null,	limit?:number | null},ValueTypes["ContentReference"]],
paginateContentReference?: [{	filter?:ValueTypes["ContentReferenceWhere"] | null,	orderBy?:ValueTypes["ContentReferenceOrderBy"][],	skip?:number | null,	first?:number | null},ValueTypes["ContentReferenceConnection"]],
getTile?: [{	by:ValueTypes["TileUniqueWhere"],	filter?:ValueTypes["TileWhere"] | null},ValueTypes["Tile"]],
listTile?: [{	filter?:ValueTypes["TileWhere"] | null,	orderBy?:ValueTypes["TileOrderBy"][],	offset?:number | null,	limit?:number | null},ValueTypes["Tile"]],
paginateTile?: [{	filter?:ValueTypes["TileWhere"] | null,	orderBy?:ValueTypes["TileOrderBy"][],	skip?:number | null,	first?:number | null},ValueTypes["TileConnection"]],
getTileList?: [{	by:ValueTypes["TileListUniqueWhere"],	filter?:ValueTypes["TileListWhere"] | null},ValueTypes["TileList"]],
listTileList?: [{	filter?:ValueTypes["TileListWhere"] | null,	orderBy?:ValueTypes["TileListOrderBy"][],	offset?:number | null,	limit?:number | null},ValueTypes["TileList"]],
paginateTileList?: [{	filter?:ValueTypes["TileListWhere"] | null,	orderBy?:ValueTypes["TileListOrderBy"][],	skip?:number | null,	first?:number | null},ValueTypes["TileListConnection"]],
getTileListItem?: [{	by:ValueTypes["TileListItemUniqueWhere"],	filter?:ValueTypes["TileListItemWhere"] | null},ValueTypes["TileListItem"]],
listTileListItem?: [{	filter?:ValueTypes["TileListItemWhere"] | null,	orderBy?:ValueTypes["TileListItemOrderBy"][],	offset?:number | null,	limit?:number | null},ValueTypes["TileListItem"]],
paginateTileListItem?: [{	filter?:ValueTypes["TileListItemWhere"] | null,	orderBy?:ValueTypes["TileListItemOrderBy"][],	skip?:number | null,	first?:number | null},ValueTypes["TileListItemConnection"]],
getYoutubeVideo?: [{	by:ValueTypes["YoutubeVideoUniqueWhere"],	filter?:ValueTypes["YoutubeVideoWhere"] | null},ValueTypes["YoutubeVideo"]],
listYoutubeVideo?: [{	filter?:ValueTypes["YoutubeVideoWhere"] | null,	orderBy?:ValueTypes["YoutubeVideoOrderBy"][],	offset?:number | null,	limit?:number | null},ValueTypes["YoutubeVideo"]],
paginateYoutubeVideo?: [{	filter?:ValueTypes["YoutubeVideoWhere"] | null,	orderBy?:ValueTypes["YoutubeVideoOrderBy"][],	skip?:number | null,	first?:number | null},ValueTypes["YoutubeVideoConnection"]],
getSeoDefault?: [{	by:ValueTypes["SeoDefaultUniqueWhere"],	filter?:ValueTypes["SeoDefaultWhere"] | null},ValueTypes["SeoDefault"]],
listSeoDefault?: [{	filter?:ValueTypes["SeoDefaultWhere"] | null,	orderBy?:ValueTypes["SeoDefaultOrderBy"][],	offset?:number | null,	limit?:number | null},ValueTypes["SeoDefault"]],
paginateSeoDefault?: [{	filter?:ValueTypes["SeoDefaultWhere"] | null,	orderBy?:ValueTypes["SeoDefaultOrderBy"][],	skip?:number | null,	first?:number | null},ValueTypes["SeoDefaultConnection"]],
getCourseLecturer?: [{	by:ValueTypes["CourseLecturerUniqueWhere"],	filter?:ValueTypes["CourseLecturerWhere"] | null},ValueTypes["CourseLecturer"]],
listCourseLecturer?: [{	filter?:ValueTypes["CourseLecturerWhere"] | null,	orderBy?:ValueTypes["CourseLecturerOrderBy"][],	offset?:number | null,	limit?:number | null},ValueTypes["CourseLecturer"]],
paginateCourseLecturer?: [{	filter?:ValueTypes["CourseLecturerWhere"] | null,	orderBy?:ValueTypes["CourseLecturerOrderBy"][],	skip?:number | null,	first?:number | null},ValueTypes["CourseLecturerConnection"]],
getCourseLecturerListPage?: [{	by:ValueTypes["CourseLecturerListPageUniqueWhere"],	filter?:ValueTypes["CourseLecturerListPageWhere"] | null},ValueTypes["CourseLecturerListPage"]],
listCourseLecturerListPage?: [{	filter?:ValueTypes["CourseLecturerListPageWhere"] | null,	orderBy?:ValueTypes["CourseLecturerListPageOrderBy"][],	offset?:number | null,	limit?:number | null},ValueTypes["CourseLecturerListPage"]],
paginateCourseLecturerListPage?: [{	filter?:ValueTypes["CourseLecturerListPageWhere"] | null,	orderBy?:ValueTypes["CourseLecturerListPageOrderBy"][],	skip?:number | null,	first?:number | null},ValueTypes["CourseLecturerListPageConnection"]],
getNewsletterSubscription?: [{	by:ValueTypes["NewsletterSubscriptionUniqueWhere"],	filter?:ValueTypes["NewsletterSubscriptionWhere"] | null},ValueTypes["NewsletterSubscription"]],
listNewsletterSubscription?: [{	filter?:ValueTypes["NewsletterSubscriptionWhere"] | null,	orderBy?:ValueTypes["NewsletterSubscriptionOrderBy"][],	offset?:number | null,	limit?:number | null},ValueTypes["NewsletterSubscription"]],
paginateNewsletterSubscription?: [{	filter?:ValueTypes["NewsletterSubscriptionWhere"] | null,	orderBy?:ValueTypes["NewsletterSubscriptionOrderBy"][],	skip?:number | null,	first?:number | null},ValueTypes["NewsletterSubscriptionConnection"]],
validateCreateNewsletterSubscription?: [{	data:ValueTypes["NewsletterSubscriptionCreateInput"]},ValueTypes["_ValidationResult"]],
getVisitor?: [{	by:ValueTypes["VisitorUniqueWhere"],	filter?:ValueTypes["VisitorWhere"] | null},ValueTypes["Visitor"]],
listVisitor?: [{	filter?:ValueTypes["VisitorWhere"] | null,	orderBy?:ValueTypes["VisitorOrderBy"][],	offset?:number | null,	limit?:number | null},ValueTypes["Visitor"]],
paginateVisitor?: [{	filter?:ValueTypes["VisitorWhere"] | null,	orderBy?:ValueTypes["VisitorOrderBy"][],	skip?:number | null,	first?:number | null},ValueTypes["VisitorConnection"]],
validateCreateVisitor?: [{	data:ValueTypes["VisitorCreateInput"]},ValueTypes["_ValidationResult"]],
getCourseSubscription?: [{	by:ValueTypes["CourseSubscriptionUniqueWhere"],	filter?:ValueTypes["CourseSubscriptionWhere"] | null},ValueTypes["CourseSubscription"]],
listCourseSubscription?: [{	filter?:ValueTypes["CourseSubscriptionWhere"] | null,	orderBy?:ValueTypes["CourseSubscriptionOrderBy"][],	offset?:number | null,	limit?:number | null},ValueTypes["CourseSubscription"]],
paginateCourseSubscription?: [{	filter?:ValueTypes["CourseSubscriptionWhere"] | null,	orderBy?:ValueTypes["CourseSubscriptionOrderBy"][],	skip?:number | null,	first?:number | null},ValueTypes["CourseSubscriptionConnection"]],
validateCreateCourseSubscription?: [{	data:ValueTypes["CourseSubscriptionCreateInput"]},ValueTypes["_ValidationResult"]],
getSubscribeBox?: [{	by:ValueTypes["SubscribeBoxUniqueWhere"],	filter?:ValueTypes["SubscribeBoxWhere"] | null},ValueTypes["SubscribeBox"]],
listSubscribeBox?: [{	filter?:ValueTypes["SubscribeBoxWhere"] | null,	orderBy?:ValueTypes["SubscribeBoxOrderBy"][],	offset?:number | null,	limit?:number | null},ValueTypes["SubscribeBox"]],
paginateSubscribeBox?: [{	filter?:ValueTypes["SubscribeBoxWhere"] | null,	orderBy?:ValueTypes["SubscribeBoxOrderBy"][],	skip?:number | null,	first?:number | null},ValueTypes["SubscribeBoxConnection"]],
getNewsletterSubscribeSuccessPage?: [{	by:ValueTypes["NewsletterSubscribeSuccessPageUniqueWhere"],	filter?:ValueTypes["NewsletterSubscribeSuccessPageWhere"] | null},ValueTypes["NewsletterSubscribeSuccessPage"]],
listNewsletterSubscribeSuccessPage?: [{	filter?:ValueTypes["NewsletterSubscribeSuccessPageWhere"] | null,	orderBy?:ValueTypes["NewsletterSubscribeSuccessPageOrderBy"][],	offset?:number | null,	limit?:number | null},ValueTypes["NewsletterSubscribeSuccessPage"]],
paginateNewsletterSubscribeSuccessPage?: [{	filter?:ValueTypes["NewsletterSubscribeSuccessPageWhere"] | null,	orderBy?:ValueTypes["NewsletterSubscribeSuccessPageOrderBy"][],	skip?:number | null,	first?:number | null},ValueTypes["NewsletterSubscribeSuccessPageConnection"]],
getGenericPage?: [{	by:ValueTypes["GenericPageUniqueWhere"],	filter?:ValueTypes["GenericPageWhere"] | null},ValueTypes["GenericPage"]],
listGenericPage?: [{	filter?:ValueTypes["GenericPageWhere"] | null,	orderBy?:ValueTypes["GenericPageOrderBy"][],	offset?:number | null,	limit?:number | null},ValueTypes["GenericPage"]],
paginateGenericPage?: [{	filter?:ValueTypes["GenericPageWhere"] | null,	orderBy?:ValueTypes["GenericPageOrderBy"][],	skip?:number | null,	first?:number | null},ValueTypes["GenericPageConnection"]],
getPerson?: [{	by:ValueTypes["PersonUniqueWhere"],	filter?:ValueTypes["PersonWhere"] | null},ValueTypes["Person"]],
listPerson?: [{	filter?:ValueTypes["PersonWhere"] | null,	orderBy?:ValueTypes["PersonOrderBy"][],	offset?:number | null,	limit?:number | null},ValueTypes["Person"]],
paginatePerson?: [{	filter?:ValueTypes["PersonWhere"] | null,	orderBy?:ValueTypes["PersonOrderBy"][],	skip?:number | null,	first?:number | null},ValueTypes["PersonConnection"]],
getPersonList?: [{	by:ValueTypes["PersonListUniqueWhere"],	filter?:ValueTypes["PersonListWhere"] | null},ValueTypes["PersonList"]],
listPersonList?: [{	filter?:ValueTypes["PersonListWhere"] | null,	orderBy?:ValueTypes["PersonListOrderBy"][],	offset?:number | null,	limit?:number | null},ValueTypes["PersonList"]],
paginatePersonList?: [{	filter?:ValueTypes["PersonListWhere"] | null,	orderBy?:ValueTypes["PersonListOrderBy"][],	skip?:number | null,	first?:number | null},ValueTypes["PersonListConnection"]],
getPersonListItem?: [{	by:ValueTypes["PersonListItemUniqueWhere"],	filter?:ValueTypes["PersonListItemWhere"] | null},ValueTypes["PersonListItem"]],
listPersonListItem?: [{	filter?:ValueTypes["PersonListItemWhere"] | null,	orderBy?:ValueTypes["PersonListItemOrderBy"][],	offset?:number | null,	limit?:number | null},ValueTypes["PersonListItem"]],
paginatePersonListItem?: [{	filter?:ValueTypes["PersonListItemWhere"] | null,	orderBy?:ValueTypes["PersonListItemOrderBy"][],	skip?:number | null,	first?:number | null},ValueTypes["PersonListItemConnection"]],
getCourseEmail?: [{	by:ValueTypes["CourseEmailUniqueWhere"],	filter?:ValueTypes["CourseEmailWhere"] | null},ValueTypes["CourseEmail"]],
listCourseEmail?: [{	filter?:ValueTypes["CourseEmailWhere"] | null,	orderBy?:ValueTypes["CourseEmailOrderBy"][],	offset?:number | null,	limit?:number | null},ValueTypes["CourseEmail"]],
paginateCourseEmail?: [{	filter?:ValueTypes["CourseEmailWhere"] | null,	orderBy?:ValueTypes["CourseEmailOrderBy"][],	skip?:number | null,	first?:number | null},ValueTypes["CourseEmailConnection"]],
getTranslations?: [{	by:ValueTypes["TranslationsUniqueWhere"],	filter?:ValueTypes["TranslationsWhere"] | null},ValueTypes["Translations"]],
listTranslations?: [{	filter?:ValueTypes["TranslationsWhere"] | null,	orderBy?:ValueTypes["TranslationsOrderBy"][],	offset?:number | null,	limit?:number | null},ValueTypes["Translations"]],
paginateTranslations?: [{	filter?:ValueTypes["TranslationsWhere"] | null,	orderBy?:ValueTypes["TranslationsOrderBy"][],	skip?:number | null,	first?:number | null},ValueTypes["TranslationsConnection"]],
	transaction?:ValueTypes["QueryTransaction"],
	_info?:ValueTypes["Info"],
	schema?:ValueTypes["_Schema"],
		__typename?: true
}>;
	["Link"]: AliasType<{
	_meta?:ValueTypes["LinkMeta"],
	id?:true,
	type?:true,
	title?:true,
	externalLink?:true,
internalLink?: [{	filter?:ValueTypes["LinkableWhere"] | null},ValueTypes["Linkable"]],
	isHighlighted?:true,
	targetBlank?:true,
		__typename?: true
}>;
	["LinkMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	type?:ValueTypes["FieldMeta"],
	title?:ValueTypes["FieldMeta"],
	externalLink?:ValueTypes["FieldMeta"],
	internalLink?:ValueTypes["FieldMeta"],
	isHighlighted?:ValueTypes["FieldMeta"],
	targetBlank?:ValueTypes["FieldMeta"],
		__typename?: true
}>;
	["FieldMeta"]: AliasType<{
	readable?:true,
	updatable?:true,
		__typename?: true
}>;
	["UUID"]:string;
	["LinkType"]:LinkType;
	["Linkable"]: AliasType<{
	_meta?:ValueTypes["LinkableMeta"],
	id?:true,
	url?:true,
redirect?: [{	filter?:ValueTypes["RedirectWhere"] | null},ValueTypes["Redirect"]],
homepage?: [{	filter?:ValueTypes["HomepageWhere"] | null},ValueTypes["Homepage"]],
article?: [{	filter?:ValueTypes["ArticleWhere"] | null},ValueTypes["Article"]],
articleCategory?: [{	filter?:ValueTypes["ArticleCategoryWhere"] | null},ValueTypes["ArticleCategory"]],
pose?: [{	filter?:ValueTypes["PoseWhere"] | null},ValueTypes["Pose"]],
course?: [{	filter?:ValueTypes["CourseWhere"] | null},ValueTypes["Course"]],
articleList?: [{	filter?:ValueTypes["ArticleListPageWhere"] | null},ValueTypes["ArticleListPage"]],
courseList?: [{	filter?:ValueTypes["CourseListPageWhere"] | null},ValueTypes["CourseListPage"]],
poseList?: [{	filter?:ValueTypes["PoseListPageWhere"] | null},ValueTypes["PoseListPage"]],
courseLecturer?: [{	filter?:ValueTypes["CourseLecturerWhere"] | null},ValueTypes["CourseLecturer"]],
courseLecturerList?: [{	filter?:ValueTypes["CourseLecturerListPageWhere"] | null},ValueTypes["CourseLecturerListPage"]],
newsletterSubscribeSuccessPage?: [{	filter?:ValueTypes["NewsletterSubscribeSuccessPageWhere"] | null},ValueTypes["NewsletterSubscribeSuccessPage"]],
genericPage?: [{	filter?:ValueTypes["GenericPageWhere"] | null},ValueTypes["GenericPage"]],
		__typename?: true
}>;
	["LinkableMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	url?:ValueTypes["FieldMeta"],
	redirect?:ValueTypes["FieldMeta"],
	homepage?:ValueTypes["FieldMeta"],
	article?:ValueTypes["FieldMeta"],
	articleCategory?:ValueTypes["FieldMeta"],
	pose?:ValueTypes["FieldMeta"],
	course?:ValueTypes["FieldMeta"],
	articleList?:ValueTypes["FieldMeta"],
	courseList?:ValueTypes["FieldMeta"],
	poseList?:ValueTypes["FieldMeta"],
	courseLecturer?:ValueTypes["FieldMeta"],
	courseLecturerList?:ValueTypes["FieldMeta"],
	newsletterSubscribeSuccessPage?:ValueTypes["FieldMeta"],
	genericPage?:ValueTypes["FieldMeta"],
		__typename?: true
}>;
	["Redirect"]: AliasType<{
	_meta?:ValueTypes["RedirectMeta"],
	id?:true,
link?: [{	filter?:ValueTypes["LinkableWhere"] | null},ValueTypes["Linkable"]],
target?: [{	filter?:ValueTypes["LinkableWhere"] | null},ValueTypes["Linkable"]],
		__typename?: true
}>;
	["RedirectMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	link?:ValueTypes["FieldMeta"],
	target?:ValueTypes["FieldMeta"],
		__typename?: true
}>;
	["LinkableWhere"]: {
	id?:ValueTypes["UUIDCondition"] | null,
	url?:ValueTypes["StringCondition"] | null,
	redirect?:ValueTypes["RedirectWhere"] | null,
	homepage?:ValueTypes["HomepageWhere"] | null,
	article?:ValueTypes["ArticleWhere"] | null,
	articleCategory?:ValueTypes["ArticleCategoryWhere"] | null,
	pose?:ValueTypes["PoseWhere"] | null,
	course?:ValueTypes["CourseWhere"] | null,
	articleList?:ValueTypes["ArticleListPageWhere"] | null,
	courseList?:ValueTypes["CourseListPageWhere"] | null,
	poseList?:ValueTypes["PoseListPageWhere"] | null,
	courseLecturer?:ValueTypes["CourseLecturerWhere"] | null,
	courseLecturerList?:ValueTypes["CourseLecturerListPageWhere"] | null,
	newsletterSubscribeSuccessPage?:ValueTypes["NewsletterSubscribeSuccessPageWhere"] | null,
	genericPage?:ValueTypes["GenericPageWhere"] | null,
	and?:(ValueTypes["LinkableWhere"] | undefined | null)[],
	or?:(ValueTypes["LinkableWhere"] | undefined | null)[],
	not?:ValueTypes["LinkableWhere"] | null
};
	["UUIDCondition"]: {
	and?:ValueTypes["UUIDCondition"][],
	or?:ValueTypes["UUIDCondition"][],
	not?:ValueTypes["UUIDCondition"] | null,
	null?:boolean | null,
	isNull?:boolean | null,
	eq?:ValueTypes["UUID"] | null,
	notEq?:ValueTypes["UUID"] | null,
	in?:ValueTypes["UUID"][],
	notIn?:ValueTypes["UUID"][],
	lt?:ValueTypes["UUID"] | null,
	lte?:ValueTypes["UUID"] | null,
	gt?:ValueTypes["UUID"] | null,
	gte?:ValueTypes["UUID"] | null
};
	["StringCondition"]: {
	and?:ValueTypes["StringCondition"][],
	or?:ValueTypes["StringCondition"][],
	not?:ValueTypes["StringCondition"] | null,
	null?:boolean | null,
	isNull?:boolean | null,
	eq?:string | null,
	notEq?:string | null,
	in?:string[],
	notIn?:string[],
	lt?:string | null,
	lte?:string | null,
	gt?:string | null,
	gte?:string | null,
	contains?:string | null,
	startsWith?:string | null,
	endsWith?:string | null,
	containsCI?:string | null,
	startsWithCI?:string | null,
	endsWithCI?:string | null
};
	["RedirectWhere"]: {
	id?:ValueTypes["UUIDCondition"] | null,
	link?:ValueTypes["LinkableWhere"] | null,
	target?:ValueTypes["LinkableWhere"] | null,
	and?:(ValueTypes["RedirectWhere"] | undefined | null)[],
	or?:(ValueTypes["RedirectWhere"] | undefined | null)[],
	not?:ValueTypes["RedirectWhere"] | null
};
	["HomepageWhere"]: {
	id?:ValueTypes["UUIDCondition"] | null,
	link?:ValueTypes["LinkableWhere"] | null,
	subtitle?:ValueTypes["StringCondition"] | null,
	seo?:ValueTypes["SeoWhere"] | null,
	content?:ValueTypes["ContentWhere"] | null,
	heroTiles?:ValueTypes["TileListWhere"] | null,
	site?:ValueTypes["SiteWhere"] | null,
	titlePartA?:ValueTypes["StringCondition"] | null,
	titlePartB?:ValueTypes["StringCondition"] | null,
	titlePartC?:ValueTypes["StringCondition"] | null,
	titlePartD?:ValueTypes["StringCondition"] | null,
	and?:(ValueTypes["HomepageWhere"] | undefined | null)[],
	or?:(ValueTypes["HomepageWhere"] | undefined | null)[],
	not?:ValueTypes["HomepageWhere"] | null
};
	["SeoWhere"]: {
	id?:ValueTypes["UUIDCondition"] | null,
	title?:ValueTypes["StringCondition"] | null,
	description?:ValueTypes["StringCondition"] | null,
	ogTitle?:ValueTypes["StringCondition"] | null,
	ogDescription?:ValueTypes["StringCondition"] | null,
	ogImage?:ValueTypes["ImageWhere"] | null,
	and?:(ValueTypes["SeoWhere"] | undefined | null)[],
	or?:(ValueTypes["SeoWhere"] | undefined | null)[],
	not?:ValueTypes["SeoWhere"] | null
};
	["ImageWhere"]: {
	id?:ValueTypes["UUIDCondition"] | null,
	url?:ValueTypes["StringCondition"] | null,
	width?:ValueTypes["IntCondition"] | null,
	height?:ValueTypes["IntCondition"] | null,
	size?:ValueTypes["IntCondition"] | null,
	type?:ValueTypes["StringCondition"] | null,
	alt?:ValueTypes["StringCondition"] | null,
	and?:(ValueTypes["ImageWhere"] | undefined | null)[],
	or?:(ValueTypes["ImageWhere"] | undefined | null)[],
	not?:ValueTypes["ImageWhere"] | null
};
	["IntCondition"]: {
	and?:ValueTypes["IntCondition"][],
	or?:ValueTypes["IntCondition"][],
	not?:ValueTypes["IntCondition"] | null,
	null?:boolean | null,
	isNull?:boolean | null,
	eq?:number | null,
	notEq?:number | null,
	in?:number[],
	notIn?:number[],
	lt?:number | null,
	lte?:number | null,
	gt?:number | null,
	gte?:number | null
};
	["ContentWhere"]: {
	id?:ValueTypes["UUIDCondition"] | null,
	blocks?:ValueTypes["ContentBlockWhere"] | null,
	and?:(ValueTypes["ContentWhere"] | undefined | null)[],
	or?:(ValueTypes["ContentWhere"] | undefined | null)[],
	not?:ValueTypes["ContentWhere"] | null
};
	["ContentBlockWhere"]: {
	id?:ValueTypes["UUIDCondition"] | null,
	order?:ValueTypes["IntCondition"] | null,
	json?:ValueTypes["StringCondition"] | null,
	content?:ValueTypes["ContentWhere"] | null,
	references?:ValueTypes["ContentReferenceWhere"] | null,
	and?:(ValueTypes["ContentBlockWhere"] | undefined | null)[],
	or?:(ValueTypes["ContentBlockWhere"] | undefined | null)[],
	not?:ValueTypes["ContentBlockWhere"] | null
};
	["ContentReferenceWhere"]: {
	id?:ValueTypes["UUIDCondition"] | null,
	type?:ValueTypes["ContentReferenceTypeCondition"] | null,
	primaryText?:ValueTypes["StringCondition"] | null,
	block?:ValueTypes["ContentBlockWhere"] | null,
	image?:ValueTypes["ImageWhere"] | null,
	highlightFirstArticle?:ValueTypes["BooleanCondition"] | null,
	linkToMoreArticles?:ValueTypes["BooleanCondition"] | null,
	people?:ValueTypes["PersonListWhere"] | null,
	and?:(ValueTypes["ContentReferenceWhere"] | undefined | null)[],
	or?:(ValueTypes["ContentReferenceWhere"] | undefined | null)[],
	not?:ValueTypes["ContentReferenceWhere"] | null
};
	["ContentReferenceTypeCondition"]: {
	and?:ValueTypes["ContentReferenceTypeCondition"][],
	or?:ValueTypes["ContentReferenceTypeCondition"][],
	not?:ValueTypes["ContentReferenceTypeCondition"] | null,
	null?:boolean | null,
	isNull?:boolean | null,
	eq?:ValueTypes["ContentReferenceType"] | null,
	notEq?:ValueTypes["ContentReferenceType"] | null,
	in?:ValueTypes["ContentReferenceType"][],
	notIn?:ValueTypes["ContentReferenceType"][],
	lt?:ValueTypes["ContentReferenceType"] | null,
	lte?:ValueTypes["ContentReferenceType"] | null,
	gt?:ValueTypes["ContentReferenceType"] | null,
	gte?:ValueTypes["ContentReferenceType"] | null
};
	["ContentReferenceType"]:ContentReferenceType;
	["BooleanCondition"]: {
	and?:ValueTypes["BooleanCondition"][],
	or?:ValueTypes["BooleanCondition"][],
	not?:ValueTypes["BooleanCondition"] | null,
	null?:boolean | null,
	isNull?:boolean | null,
	eq?:boolean | null,
	notEq?:boolean | null,
	in?:boolean[],
	notIn?:boolean[],
	lt?:boolean | null,
	lte?:boolean | null,
	gt?:boolean | null,
	gte?:boolean | null
};
	["PersonListWhere"]: {
	id?:ValueTypes["UUIDCondition"] | null,
	items?:ValueTypes["PersonListItemWhere"] | null,
	and?:(ValueTypes["PersonListWhere"] | undefined | null)[],
	or?:(ValueTypes["PersonListWhere"] | undefined | null)[],
	not?:ValueTypes["PersonListWhere"] | null
};
	["PersonListItemWhere"]: {
	id?:ValueTypes["UUIDCondition"] | null,
	order?:ValueTypes["IntCondition"] | null,
	list?:ValueTypes["PersonListWhere"] | null,
	person?:ValueTypes["PersonWhere"] | null,
	about?:ValueTypes["StringCondition"] | null,
	and?:(ValueTypes["PersonListItemWhere"] | undefined | null)[],
	or?:(ValueTypes["PersonListItemWhere"] | undefined | null)[],
	not?:ValueTypes["PersonListItemWhere"] | null
};
	["PersonWhere"]: {
	id?:ValueTypes["UUIDCondition"] | null,
	name?:ValueTypes["StringCondition"] | null,
	about?:ValueTypes["StringCondition"] | null,
	email?:ValueTypes["StringCondition"] | null,
	phone?:ValueTypes["StringCondition"] | null,
	image?:ValueTypes["ImageWhere"] | null,
	and?:(ValueTypes["PersonWhere"] | undefined | null)[],
	or?:(ValueTypes["PersonWhere"] | undefined | null)[],
	not?:ValueTypes["PersonWhere"] | null
};
	["TileListWhere"]: {
	id?:ValueTypes["UUIDCondition"] | null,
	items?:ValueTypes["TileListItemWhere"] | null,
	and?:(ValueTypes["TileListWhere"] | undefined | null)[],
	or?:(ValueTypes["TileListWhere"] | undefined | null)[],
	not?:ValueTypes["TileListWhere"] | null
};
	["TileListItemWhere"]: {
	id?:ValueTypes["UUIDCondition"] | null,
	order?:ValueTypes["IntCondition"] | null,
	list?:ValueTypes["TileListWhere"] | null,
	tile?:ValueTypes["TileWhere"] | null,
	and?:(ValueTypes["TileListItemWhere"] | undefined | null)[],
	or?:(ValueTypes["TileListItemWhere"] | undefined | null)[],
	not?:ValueTypes["TileListItemWhere"] | null
};
	["TileWhere"]: {
	id?:ValueTypes["UUIDCondition"] | null,
	image?:ValueTypes["ImageWhere"] | null,
	link?:ValueTypes["LinkWhere"] | null,
	isVideo?:ValueTypes["BooleanCondition"] | null,
	and?:(ValueTypes["TileWhere"] | undefined | null)[],
	or?:(ValueTypes["TileWhere"] | undefined | null)[],
	not?:ValueTypes["TileWhere"] | null
};
	["LinkWhere"]: {
	id?:ValueTypes["UUIDCondition"] | null,
	type?:ValueTypes["LinkTypeCondition"] | null,
	title?:ValueTypes["StringCondition"] | null,
	externalLink?:ValueTypes["StringCondition"] | null,
	internalLink?:ValueTypes["LinkableWhere"] | null,
	isHighlighted?:ValueTypes["BooleanCondition"] | null,
	targetBlank?:ValueTypes["BooleanCondition"] | null,
	and?:(ValueTypes["LinkWhere"] | undefined | null)[],
	or?:(ValueTypes["LinkWhere"] | undefined | null)[],
	not?:ValueTypes["LinkWhere"] | null
};
	["LinkTypeCondition"]: {
	and?:ValueTypes["LinkTypeCondition"][],
	or?:ValueTypes["LinkTypeCondition"][],
	not?:ValueTypes["LinkTypeCondition"] | null,
	null?:boolean | null,
	isNull?:boolean | null,
	eq?:ValueTypes["LinkType"] | null,
	notEq?:ValueTypes["LinkType"] | null,
	in?:ValueTypes["LinkType"][],
	notIn?:ValueTypes["LinkType"][],
	lt?:ValueTypes["LinkType"] | null,
	lte?:ValueTypes["LinkType"] | null,
	gt?:ValueTypes["LinkType"] | null,
	gte?:ValueTypes["LinkType"] | null
};
	["SiteWhere"]: {
	id?:ValueTypes["UUIDCondition"] | null,
	code?:ValueTypes["StringCondition"] | null,
	order?:ValueTypes["IntCondition"] | null,
	articles?:ValueTypes["ArticleWhere"] | null,
	internalName?:ValueTypes["StringCondition"] | null,
	articleCategories?:ValueTypes["ArticleCategoryWhere"] | null,
	poses?:ValueTypes["PoseWhere"] | null,
	poseCategories?:ValueTypes["PoseCategoryWhere"] | null,
	poseDifficulties?:ValueTypes["PoseDifficultyWhere"] | null,
	courses?:ValueTypes["CourseWhere"] | null,
	articleListPage?:ValueTypes["ArticleListPageWhere"] | null,
	courseListPage?:ValueTypes["CourseListPageWhere"] | null,
	poseListPage?:ValueTypes["PoseListPageWhere"] | null,
	courseLecturers?:ValueTypes["CourseLecturerWhere"] | null,
	courseLecturerListPage?:ValueTypes["CourseLecturerListPageWhere"] | null,
	homePages?:ValueTypes["HomepageWhere"] | null,
	subscribeBox?:ValueTypes["SubscribeBoxWhere"] | null,
	header?:ValueTypes["HeaderWhere"] | null,
	footer?:ValueTypes["FooterWhere"] | null,
	genericPages?:ValueTypes["GenericPageWhere"] | null,
	courseEmail?:ValueTypes["CourseEmailWhere"] | null,
	translations?:ValueTypes["TranslationsWhere"] | null,
	and?:(ValueTypes["SiteWhere"] | undefined | null)[],
	or?:(ValueTypes["SiteWhere"] | undefined | null)[],
	not?:ValueTypes["SiteWhere"] | null
};
	["ArticleWhere"]: {
	id?:ValueTypes["UUIDCondition"] | null,
	headline?:ValueTypes["StringCondition"] | null,
	publishedAt?:ValueTypes["DateCondition"] | null,
	site?:ValueTypes["SiteWhere"] | null,
	seo?:ValueTypes["SeoWhere"] | null,
	link?:ValueTypes["LinkableWhere"] | null,
	lead?:ValueTypes["StringCondition"] | null,
	shortLead?:ValueTypes["StringCondition"] | null,
	image?:ValueTypes["ImageWhere"] | null,
	content?:ValueTypes["ContentWhere"] | null,
	category?:ValueTypes["ArticleCategoryWhere"] | null,
	and?:(ValueTypes["ArticleWhere"] | undefined | null)[],
	or?:(ValueTypes["ArticleWhere"] | undefined | null)[],
	not?:ValueTypes["ArticleWhere"] | null
};
	["DateCondition"]: {
	and?:ValueTypes["DateCondition"][],
	or?:ValueTypes["DateCondition"][],
	not?:ValueTypes["DateCondition"] | null,
	null?:boolean | null,
	isNull?:boolean | null,
	eq?:ValueTypes["Date"] | null,
	notEq?:ValueTypes["Date"] | null,
	in?:ValueTypes["Date"][],
	notIn?:ValueTypes["Date"][],
	lt?:ValueTypes["Date"] | null,
	lte?:ValueTypes["Date"] | null,
	gt?:ValueTypes["Date"] | null,
	gte?:ValueTypes["Date"] | null
};
	["Date"]:string;
	["ArticleCategoryWhere"]: {
	id?:ValueTypes["UUIDCondition"] | null,
	label?:ValueTypes["StringCondition"] | null,
	articles?:ValueTypes["ArticleWhere"] | null,
	site?:ValueTypes["SiteWhere"] | null,
	seo?:ValueTypes["SeoWhere"] | null,
	link?:ValueTypes["LinkableWhere"] | null,
	lead?:ValueTypes["StringCondition"] | null,
	originalPath?:ValueTypes["StringCondition"] | null,
	and?:(ValueTypes["ArticleCategoryWhere"] | undefined | null)[],
	or?:(ValueTypes["ArticleCategoryWhere"] | undefined | null)[],
	not?:ValueTypes["ArticleCategoryWhere"] | null
};
	["PoseWhere"]: {
	id?:ValueTypes["UUIDCondition"] | null,
	headline?:ValueTypes["StringCondition"] | null,
	publishedAt?:ValueTypes["DateCondition"] | null,
	link?:ValueTypes["LinkableWhere"] | null,
	site?:ValueTypes["SiteWhere"] | null,
	image?:ValueTypes["ImageWhere"] | null,
	seo?:ValueTypes["SeoWhere"] | null,
	subtitle?:ValueTypes["StringCondition"] | null,
	content?:ValueTypes["ContentWhere"] | null,
	category?:ValueTypes["PoseCategoryWhere"] | null,
	difficulty?:ValueTypes["PoseDifficultyWhere"] | null,
	and?:(ValueTypes["PoseWhere"] | undefined | null)[],
	or?:(ValueTypes["PoseWhere"] | undefined | null)[],
	not?:ValueTypes["PoseWhere"] | null
};
	["PoseCategoryWhere"]: {
	id?:ValueTypes["UUIDCondition"] | null,
	label?:ValueTypes["StringCondition"] | null,
	poses?:ValueTypes["PoseWhere"] | null,
	site?:ValueTypes["SiteWhere"] | null,
	icon?:ValueTypes["ImageWhere"] | null,
	and?:(ValueTypes["PoseCategoryWhere"] | undefined | null)[],
	or?:(ValueTypes["PoseCategoryWhere"] | undefined | null)[],
	not?:ValueTypes["PoseCategoryWhere"] | null
};
	["PoseDifficultyWhere"]: {
	id?:ValueTypes["UUIDCondition"] | null,
	label?:ValueTypes["StringCondition"] | null,
	poses?:ValueTypes["PoseWhere"] | null,
	site?:ValueTypes["SiteWhere"] | null,
	icon?:ValueTypes["ImageWhere"] | null,
	and?:(ValueTypes["PoseDifficultyWhere"] | undefined | null)[],
	or?:(ValueTypes["PoseDifficultyWhere"] | undefined | null)[],
	not?:ValueTypes["PoseDifficultyWhere"] | null
};
	["CourseWhere"]: {
	id?:ValueTypes["UUIDCondition"] | null,
	headline?:ValueTypes["StringCondition"] | null,
	lead?:ValueTypes["StringCondition"] | null,
	shortLead?:ValueTypes["StringCondition"] | null,
	publishedAt?:ValueTypes["DateCondition"] | null,
	site?:ValueTypes["SiteWhere"] | null,
	image?:ValueTypes["ImageWhere"] | null,
	seo?:ValueTypes["SeoWhere"] | null,
	link?:ValueTypes["LinkableWhere"] | null,
	lessons?:ValueTypes["CourseLessonWhere"] | null,
	difficulty?:ValueTypes["StringCondition"] | null,
	teaser?:ValueTypes["YoutubeVideoWhere"] | null,
	lessonIntervalInDays?:ValueTypes["IntCondition"] | null,
	content?:ValueTypes["ContentWhere"] | null,
	lecturer?:ValueTypes["CourseLecturerWhere"] | null,
	and?:(ValueTypes["CourseWhere"] | undefined | null)[],
	or?:(ValueTypes["CourseWhere"] | undefined | null)[],
	not?:ValueTypes["CourseWhere"] | null
};
	["CourseLessonWhere"]: {
	id?:ValueTypes["UUIDCondition"] | null,
	order?:ValueTypes["IntCondition"] | null,
	headline?:ValueTypes["StringCondition"] | null,
	lead?:ValueTypes["StringCondition"] | null,
	course?:ValueTypes["CourseWhere"] | null,
	video?:ValueTypes["YoutubeVideoWhere"] | null,
	and?:(ValueTypes["CourseLessonWhere"] | undefined | null)[],
	or?:(ValueTypes["CourseLessonWhere"] | undefined | null)[],
	not?:ValueTypes["CourseLessonWhere"] | null
};
	["YoutubeVideoWhere"]: {
	id?:ValueTypes["UUIDCondition"] | null,
	videoId?:ValueTypes["StringCondition"] | null,
	and?:(ValueTypes["YoutubeVideoWhere"] | undefined | null)[],
	or?:(ValueTypes["YoutubeVideoWhere"] | undefined | null)[],
	not?:ValueTypes["YoutubeVideoWhere"] | null
};
	["CourseLecturerWhere"]: {
	id?:ValueTypes["UUIDCondition"] | null,
	name?:ValueTypes["StringCondition"] | null,
	courses?:ValueTypes["CourseWhere"] | null,
	site?:ValueTypes["SiteWhere"] | null,
	link?:ValueTypes["LinkableWhere"] | null,
	seo?:ValueTypes["SeoWhere"] | null,
	image?:ValueTypes["ImageWhere"] | null,
	content?:ValueTypes["ContentWhere"] | null,
	and?:(ValueTypes["CourseLecturerWhere"] | undefined | null)[],
	or?:(ValueTypes["CourseLecturerWhere"] | undefined | null)[],
	not?:ValueTypes["CourseLecturerWhere"] | null
};
	["ArticleListPageWhere"]: {
	id?:ValueTypes["UUIDCondition"] | null,
	seo?:ValueTypes["SeoWhere"] | null,
	link?:ValueTypes["LinkableWhere"] | null,
	title?:ValueTypes["StringCondition"] | null,
	site?:ValueTypes["SiteWhere"] | null,
	and?:(ValueTypes["ArticleListPageWhere"] | undefined | null)[],
	or?:(ValueTypes["ArticleListPageWhere"] | undefined | null)[],
	not?:ValueTypes["ArticleListPageWhere"] | null
};
	["CourseListPageWhere"]: {
	id?:ValueTypes["UUIDCondition"] | null,
	seo?:ValueTypes["SeoWhere"] | null,
	link?:ValueTypes["LinkableWhere"] | null,
	title?:ValueTypes["StringCondition"] | null,
	site?:ValueTypes["SiteWhere"] | null,
	and?:(ValueTypes["CourseListPageWhere"] | undefined | null)[],
	or?:(ValueTypes["CourseListPageWhere"] | undefined | null)[],
	not?:ValueTypes["CourseListPageWhere"] | null
};
	["PoseListPageWhere"]: {
	id?:ValueTypes["UUIDCondition"] | null,
	link?:ValueTypes["LinkableWhere"] | null,
	seo?:ValueTypes["SeoWhere"] | null,
	title?:ValueTypes["StringCondition"] | null,
	site?:ValueTypes["SiteWhere"] | null,
	and?:(ValueTypes["PoseListPageWhere"] | undefined | null)[],
	or?:(ValueTypes["PoseListPageWhere"] | undefined | null)[],
	not?:ValueTypes["PoseListPageWhere"] | null
};
	["CourseLecturerListPageWhere"]: {
	id?:ValueTypes["UUIDCondition"] | null,
	title?:ValueTypes["StringCondition"] | null,
	site?:ValueTypes["SiteWhere"] | null,
	seo?:ValueTypes["SeoWhere"] | null,
	link?:ValueTypes["LinkableWhere"] | null,
	and?:(ValueTypes["CourseLecturerListPageWhere"] | undefined | null)[],
	or?:(ValueTypes["CourseLecturerListPageWhere"] | undefined | null)[],
	not?:ValueTypes["CourseLecturerListPageWhere"] | null
};
	["SubscribeBoxWhere"]: {
	id?:ValueTypes["UUIDCondition"] | null,
	title?:ValueTypes["StringCondition"] | null,
	text?:ValueTypes["StringCondition"] | null,
	shapeColorA?:ValueTypes["StringCondition"] | null,
	shapeColorB?:ValueTypes["StringCondition"] | null,
	shapeColorC?:ValueTypes["StringCondition"] | null,
	externalMailingListId?:ValueTypes["StringCondition"] | null,
	site?:ValueTypes["SiteWhere"] | null,
	logo?:ValueTypes["ImageWhere"] | null,
	emailLabel?:ValueTypes["StringCondition"] | null,
	submitLabel?:ValueTypes["StringCondition"] | null,
	and?:(ValueTypes["SubscribeBoxWhere"] | undefined | null)[],
	or?:(ValueTypes["SubscribeBoxWhere"] | undefined | null)[],
	not?:ValueTypes["SubscribeBoxWhere"] | null
};
	["HeaderWhere"]: {
	id?:ValueTypes["UUIDCondition"] | null,
	primaryNavigationItems?:ValueTypes["HeaderPrimaryNavigationItemWhere"] | null,
	site?:ValueTypes["SiteWhere"] | null,
	logo?:ValueTypes["ImageWhere"] | null,
	logoOnWhite?:ValueTypes["ImageWhere"] | null,
	and?:(ValueTypes["HeaderWhere"] | undefined | null)[],
	or?:(ValueTypes["HeaderWhere"] | undefined | null)[],
	not?:ValueTypes["HeaderWhere"] | null
};
	["HeaderPrimaryNavigationItemWhere"]: {
	id?:ValueTypes["UUIDCondition"] | null,
	order?:ValueTypes["IntCondition"] | null,
	header?:ValueTypes["HeaderWhere"] | null,
	link?:ValueTypes["LinkWhere"] | null,
	listNavigation?:ValueTypes["ListNavigationWhere"] | null,
	tiles?:ValueTypes["TileListWhere"] | null,
	and?:(ValueTypes["HeaderPrimaryNavigationItemWhere"] | undefined | null)[],
	or?:(ValueTypes["HeaderPrimaryNavigationItemWhere"] | undefined | null)[],
	not?:ValueTypes["HeaderPrimaryNavigationItemWhere"] | null
};
	["ListNavigationWhere"]: {
	id?:ValueTypes["UUIDCondition"] | null,
	columns?:ValueTypes["ListNavigationColumnWhere"] | null,
	and?:(ValueTypes["ListNavigationWhere"] | undefined | null)[],
	or?:(ValueTypes["ListNavigationWhere"] | undefined | null)[],
	not?:ValueTypes["ListNavigationWhere"] | null
};
	["ListNavigationColumnWhere"]: {
	id?:ValueTypes["UUIDCondition"] | null,
	order?:ValueTypes["IntCondition"] | null,
	label?:ValueTypes["StringCondition"] | null,
	listNavigation?:ValueTypes["ListNavigationWhere"] | null,
	items?:ValueTypes["ListNavigationColumnItemWhere"] | null,
	and?:(ValueTypes["ListNavigationColumnWhere"] | undefined | null)[],
	or?:(ValueTypes["ListNavigationColumnWhere"] | undefined | null)[],
	not?:ValueTypes["ListNavigationColumnWhere"] | null
};
	["ListNavigationColumnItemWhere"]: {
	id?:ValueTypes["UUIDCondition"] | null,
	order?:ValueTypes["IntCondition"] | null,
	column?:ValueTypes["ListNavigationColumnWhere"] | null,
	link?:ValueTypes["LinkWhere"] | null,
	and?:(ValueTypes["ListNavigationColumnItemWhere"] | undefined | null)[],
	or?:(ValueTypes["ListNavigationColumnItemWhere"] | undefined | null)[],
	not?:ValueTypes["ListNavigationColumnItemWhere"] | null
};
	["FooterWhere"]: {
	id?:ValueTypes["UUIDCondition"] | null,
	navigation?:ValueTypes["ListNavigationWhere"] | null,
	site?:ValueTypes["SiteWhere"] | null,
	logo?:ValueTypes["ImageWhere"] | null,
	copyright?:ValueTypes["StringCondition"] | null,
	attribution?:ValueTypes["StringCondition"] | null,
	and?:(ValueTypes["FooterWhere"] | undefined | null)[],
	or?:(ValueTypes["FooterWhere"] | undefined | null)[],
	not?:ValueTypes["FooterWhere"] | null
};
	["GenericPageWhere"]: {
	id?:ValueTypes["UUIDCondition"] | null,
	headline?:ValueTypes["StringCondition"] | null,
	lead?:ValueTypes["StringCondition"] | null,
	publishedAt?:ValueTypes["DateCondition"] | null,
	site?:ValueTypes["SiteWhere"] | null,
	content?:ValueTypes["ContentWhere"] | null,
	seo?:ValueTypes["SeoWhere"] | null,
	link?:ValueTypes["LinkableWhere"] | null,
	and?:(ValueTypes["GenericPageWhere"] | undefined | null)[],
	or?:(ValueTypes["GenericPageWhere"] | undefined | null)[],
	not?:ValueTypes["GenericPageWhere"] | null
};
	["CourseEmailWhere"]: {
	id?:ValueTypes["UUIDCondition"] | null,
	title?:ValueTypes["StringCondition"] | null,
	text?:ValueTypes["StringCondition"] | null,
	buttonLabel?:ValueTypes["StringCondition"] | null,
	logo?:ValueTypes["ImageWhere"] | null,
	cover?:ValueTypes["ImageWhere"] | null,
	site?:ValueTypes["SiteWhere"] | null,
	contactInfoEmailAddress?:ValueTypes["StringCondition"] | null,
	senderEmail?:ValueTypes["StringCondition"] | null,
	and?:(ValueTypes["CourseEmailWhere"] | undefined | null)[],
	or?:(ValueTypes["CourseEmailWhere"] | undefined | null)[],
	not?:ValueTypes["CourseEmailWhere"] | null
};
	["TranslationsWhere"]: {
	id?:ValueTypes["UUIDCondition"] | null,
	home?:ValueTypes["StringCondition"] | null,
	articles?:ValueTypes["StringCondition"] | null,
	article?:ValueTypes["StringCondition"] | null,
	articleMore?:ValueTypes["StringCondition"] | null,
	categories?:ValueTypes["StringCondition"] | null,
	lecturers?:ValueTypes["StringCondition"] | null,
	lecturer?:ValueTypes["StringCondition"] | null,
	courses?:ValueTypes["StringCondition"] | null,
	course?:ValueTypes["StringCondition"] | null,
	poses?:ValueTypes["StringCondition"] | null,
	pose?:ValueTypes["StringCondition"] | null,
	site?:ValueTypes["SiteWhere"] | null,
	posesLong?:ValueTypes["StringCondition"] | null,
	posesMore?:ValueTypes["StringCondition"] | null,
	listAll?:ValueTypes["StringCondition"] | null,
	difficulty?:ValueTypes["StringCondition"] | null,
	skillLevel?:ValueTypes["StringCondition"] | null,
	signIn?:ValueTypes["StringCondition"] | null,
	error?:ValueTypes["StringCondition"] | null,
	and?:(ValueTypes["TranslationsWhere"] | undefined | null)[],
	or?:(ValueTypes["TranslationsWhere"] | undefined | null)[],
	not?:ValueTypes["TranslationsWhere"] | null
};
	["NewsletterSubscribeSuccessPageWhere"]: {
	id?:ValueTypes["UUIDCondition"] | null,
	title?:ValueTypes["StringCondition"] | null,
	returnLinkText?:ValueTypes["StringCondition"] | null,
	link?:ValueTypes["LinkableWhere"] | null,
	site?:ValueTypes["SiteWhere"] | null,
	seo?:ValueTypes["SeoWhere"] | null,
	and?:(ValueTypes["NewsletterSubscribeSuccessPageWhere"] | undefined | null)[],
	or?:(ValueTypes["NewsletterSubscribeSuccessPageWhere"] | undefined | null)[],
	not?:ValueTypes["NewsletterSubscribeSuccessPageWhere"] | null
};
	["Homepage"]: AliasType<{
	_meta?:ValueTypes["HomepageMeta"],
	id?:true,
link?: [{	filter?:ValueTypes["LinkableWhere"] | null},ValueTypes["Linkable"]],
	subtitle?:true,
seo?: [{	filter?:ValueTypes["SeoWhere"] | null},ValueTypes["Seo"]],
content?: [{	filter?:ValueTypes["ContentWhere"] | null},ValueTypes["Content"]],
heroTiles?: [{	filter?:ValueTypes["TileListWhere"] | null},ValueTypes["TileList"]],
site?: [{	filter?:ValueTypes["SiteWhere"] | null},ValueTypes["Site"]],
	titlePartA?:true,
	titlePartB?:true,
	titlePartC?:true,
	titlePartD?:true,
		__typename?: true
}>;
	["HomepageMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	link?:ValueTypes["FieldMeta"],
	subtitle?:ValueTypes["FieldMeta"],
	seo?:ValueTypes["FieldMeta"],
	content?:ValueTypes["FieldMeta"],
	heroTiles?:ValueTypes["FieldMeta"],
	site?:ValueTypes["FieldMeta"],
	titlePartA?:ValueTypes["FieldMeta"],
	titlePartB?:ValueTypes["FieldMeta"],
	titlePartC?:ValueTypes["FieldMeta"],
	titlePartD?:ValueTypes["FieldMeta"],
		__typename?: true
}>;
	["Seo"]: AliasType<{
	_meta?:ValueTypes["SeoMeta"],
	id?:true,
	title?:true,
	description?:true,
	ogTitle?:true,
	ogDescription?:true,
ogImage?: [{	filter?:ValueTypes["ImageWhere"] | null},ValueTypes["Image"]],
		__typename?: true
}>;
	["SeoMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	title?:ValueTypes["FieldMeta"],
	description?:ValueTypes["FieldMeta"],
	ogTitle?:ValueTypes["FieldMeta"],
	ogDescription?:ValueTypes["FieldMeta"],
	ogImage?:ValueTypes["FieldMeta"],
		__typename?: true
}>;
	["Image"]: AliasType<{
	_meta?:ValueTypes["ImageMeta"],
	id?:true,
	url?:true,
	width?:true,
	height?:true,
	size?:true,
	type?:true,
	alt?:true,
		__typename?: true
}>;
	["ImageMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	url?:ValueTypes["FieldMeta"],
	width?:ValueTypes["FieldMeta"],
	height?:ValueTypes["FieldMeta"],
	size?:ValueTypes["FieldMeta"],
	type?:ValueTypes["FieldMeta"],
	alt?:ValueTypes["FieldMeta"],
		__typename?: true
}>;
	["Content"]: AliasType<{
	_meta?:ValueTypes["ContentMeta"],
	id?:true,
blocks?: [{	filter?:ValueTypes["ContentBlockWhere"] | null,	orderBy?:ValueTypes["ContentBlockOrderBy"][],	offset?:number | null,	limit?:number | null},ValueTypes["ContentBlock"]],
blocksByReferences?: [{	by:ValueTypes["ContentBlocksByReferencesUniqueWhere"],	filter?:ValueTypes["ContentBlockWhere"] | null},ValueTypes["ContentBlock"]],
paginateBlocks?: [{	filter?:ValueTypes["ContentBlockWhere"] | null,	orderBy?:ValueTypes["ContentBlockOrderBy"][],	skip?:number | null,	first?:number | null},ValueTypes["ContentBlockConnection"]],
		__typename?: true
}>;
	["ContentMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	blocks?:ValueTypes["FieldMeta"],
		__typename?: true
}>;
	["ContentBlock"]: AliasType<{
	_meta?:ValueTypes["ContentBlockMeta"],
	id?:true,
	order?:true,
	json?:true,
content?: [{	filter?:ValueTypes["ContentWhere"] | null},ValueTypes["Content"]],
references?: [{	filter?:ValueTypes["ContentReferenceWhere"] | null,	orderBy?:ValueTypes["ContentReferenceOrderBy"][],	offset?:number | null,	limit?:number | null},ValueTypes["ContentReference"]],
paginateReferences?: [{	filter?:ValueTypes["ContentReferenceWhere"] | null,	orderBy?:ValueTypes["ContentReferenceOrderBy"][],	skip?:number | null,	first?:number | null},ValueTypes["ContentReferenceConnection"]],
		__typename?: true
}>;
	["ContentBlockMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	order?:ValueTypes["FieldMeta"],
	json?:ValueTypes["FieldMeta"],
	content?:ValueTypes["FieldMeta"],
	references?:ValueTypes["FieldMeta"],
		__typename?: true
}>;
	["ContentReference"]: AliasType<{
	_meta?:ValueTypes["ContentReferenceMeta"],
	id?:true,
	type?:true,
	primaryText?:true,
block?: [{	filter?:ValueTypes["ContentBlockWhere"] | null},ValueTypes["ContentBlock"]],
image?: [{	filter?:ValueTypes["ImageWhere"] | null},ValueTypes["Image"]],
	highlightFirstArticle?:true,
	linkToMoreArticles?:true,
people?: [{	filter?:ValueTypes["PersonListWhere"] | null},ValueTypes["PersonList"]],
		__typename?: true
}>;
	["ContentReferenceMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	type?:ValueTypes["FieldMeta"],
	primaryText?:ValueTypes["FieldMeta"],
	block?:ValueTypes["FieldMeta"],
	image?:ValueTypes["FieldMeta"],
	highlightFirstArticle?:ValueTypes["FieldMeta"],
	linkToMoreArticles?:ValueTypes["FieldMeta"],
	people?:ValueTypes["FieldMeta"],
		__typename?: true
}>;
	["PersonList"]: AliasType<{
	_meta?:ValueTypes["PersonListMeta"],
	id?:true,
items?: [{	filter?:ValueTypes["PersonListItemWhere"] | null,	orderBy?:ValueTypes["PersonListItemOrderBy"][],	offset?:number | null,	limit?:number | null},ValueTypes["PersonListItem"]],
paginateItems?: [{	filter?:ValueTypes["PersonListItemWhere"] | null,	orderBy?:ValueTypes["PersonListItemOrderBy"][],	skip?:number | null,	first?:number | null},ValueTypes["PersonListItemConnection"]],
		__typename?: true
}>;
	["PersonListMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	items?:ValueTypes["FieldMeta"],
		__typename?: true
}>;
	["PersonListItem"]: AliasType<{
	_meta?:ValueTypes["PersonListItemMeta"],
	id?:true,
	order?:true,
list?: [{	filter?:ValueTypes["PersonListWhere"] | null},ValueTypes["PersonList"]],
person?: [{	filter?:ValueTypes["PersonWhere"] | null},ValueTypes["Person"]],
	about?:true,
		__typename?: true
}>;
	["PersonListItemMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	order?:ValueTypes["FieldMeta"],
	list?:ValueTypes["FieldMeta"],
	person?:ValueTypes["FieldMeta"],
	about?:ValueTypes["FieldMeta"],
		__typename?: true
}>;
	["Person"]: AliasType<{
	_meta?:ValueTypes["PersonMeta"],
	id?:true,
	name?:true,
	about?:true,
	email?:true,
	phone?:true,
image?: [{	filter?:ValueTypes["ImageWhere"] | null},ValueTypes["Image"]],
		__typename?: true
}>;
	["PersonMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	name?:ValueTypes["FieldMeta"],
	about?:ValueTypes["FieldMeta"],
	email?:ValueTypes["FieldMeta"],
	phone?:ValueTypes["FieldMeta"],
	image?:ValueTypes["FieldMeta"],
		__typename?: true
}>;
	["PersonListItemOrderBy"]: {
	_random?:boolean | null,
	_randomSeeded?:number | null,
	id?:ValueTypes["OrderDirection"] | null,
	order?:ValueTypes["OrderDirection"] | null,
	list?:ValueTypes["PersonListOrderBy"] | null,
	person?:ValueTypes["PersonOrderBy"] | null,
	about?:ValueTypes["OrderDirection"] | null
};
	["OrderDirection"]:OrderDirection;
	["PersonListOrderBy"]: {
	_random?:boolean | null,
	_randomSeeded?:number | null,
	id?:ValueTypes["OrderDirection"] | null
};
	["PersonOrderBy"]: {
	_random?:boolean | null,
	_randomSeeded?:number | null,
	id?:ValueTypes["OrderDirection"] | null,
	name?:ValueTypes["OrderDirection"] | null,
	about?:ValueTypes["OrderDirection"] | null,
	email?:ValueTypes["OrderDirection"] | null,
	phone?:ValueTypes["OrderDirection"] | null,
	image?:ValueTypes["ImageOrderBy"] | null
};
	["ImageOrderBy"]: {
	_random?:boolean | null,
	_randomSeeded?:number | null,
	id?:ValueTypes["OrderDirection"] | null,
	url?:ValueTypes["OrderDirection"] | null,
	width?:ValueTypes["OrderDirection"] | null,
	height?:ValueTypes["OrderDirection"] | null,
	size?:ValueTypes["OrderDirection"] | null,
	type?:ValueTypes["OrderDirection"] | null,
	alt?:ValueTypes["OrderDirection"] | null
};
	["PersonListItemConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["PersonListItemEdge"],
		__typename?: true
}>;
	["PageInfo"]: AliasType<{
	totalCount?:true,
		__typename?: true
}>;
	["PersonListItemEdge"]: AliasType<{
	node?:ValueTypes["PersonListItem"],
		__typename?: true
}>;
	["ContentReferenceOrderBy"]: {
	_random?:boolean | null,
	_randomSeeded?:number | null,
	id?:ValueTypes["OrderDirection"] | null,
	type?:ValueTypes["OrderDirection"] | null,
	primaryText?:ValueTypes["OrderDirection"] | null,
	block?:ValueTypes["ContentBlockOrderBy"] | null,
	image?:ValueTypes["ImageOrderBy"] | null,
	highlightFirstArticle?:ValueTypes["OrderDirection"] | null,
	linkToMoreArticles?:ValueTypes["OrderDirection"] | null,
	people?:ValueTypes["PersonListOrderBy"] | null
};
	["ContentBlockOrderBy"]: {
	_random?:boolean | null,
	_randomSeeded?:number | null,
	id?:ValueTypes["OrderDirection"] | null,
	order?:ValueTypes["OrderDirection"] | null,
	json?:ValueTypes["OrderDirection"] | null,
	content?:ValueTypes["ContentOrderBy"] | null
};
	["ContentOrderBy"]: {
	_random?:boolean | null,
	_randomSeeded?:number | null,
	id?:ValueTypes["OrderDirection"] | null
};
	["ContentReferenceConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["ContentReferenceEdge"],
		__typename?: true
}>;
	["ContentReferenceEdge"]: AliasType<{
	node?:ValueTypes["ContentReference"],
		__typename?: true
}>;
	["ContentBlocksByReferencesUniqueWhere"]: {
	references?:ValueTypes["ContentReferenceUniqueWhere"] | null
};
	["ContentReferenceUniqueWhere"]: {
	id?:ValueTypes["UUID"] | null
};
	["ContentBlockConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["ContentBlockEdge"],
		__typename?: true
}>;
	["ContentBlockEdge"]: AliasType<{
	node?:ValueTypes["ContentBlock"],
		__typename?: true
}>;
	["TileList"]: AliasType<{
	_meta?:ValueTypes["TileListMeta"],
	id?:true,
items?: [{	filter?:ValueTypes["TileListItemWhere"] | null,	orderBy?:ValueTypes["TileListItemOrderBy"][],	offset?:number | null,	limit?:number | null},ValueTypes["TileListItem"]],
itemsByTile?: [{	by:ValueTypes["TileListItemsByTileUniqueWhere"],	filter?:ValueTypes["TileListItemWhere"] | null},ValueTypes["TileListItem"]],
paginateItems?: [{	filter?:ValueTypes["TileListItemWhere"] | null,	orderBy?:ValueTypes["TileListItemOrderBy"][],	skip?:number | null,	first?:number | null},ValueTypes["TileListItemConnection"]],
		__typename?: true
}>;
	["TileListMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	items?:ValueTypes["FieldMeta"],
		__typename?: true
}>;
	["TileListItem"]: AliasType<{
	_meta?:ValueTypes["TileListItemMeta"],
	id?:true,
	order?:true,
list?: [{	filter?:ValueTypes["TileListWhere"] | null},ValueTypes["TileList"]],
tile?: [{	filter?:ValueTypes["TileWhere"] | null},ValueTypes["Tile"]],
		__typename?: true
}>;
	["TileListItemMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	order?:ValueTypes["FieldMeta"],
	list?:ValueTypes["FieldMeta"],
	tile?:ValueTypes["FieldMeta"],
		__typename?: true
}>;
	["Tile"]: AliasType<{
	_meta?:ValueTypes["TileMeta"],
	id?:true,
image?: [{	filter?:ValueTypes["ImageWhere"] | null},ValueTypes["Image"]],
link?: [{	filter?:ValueTypes["LinkWhere"] | null},ValueTypes["Link"]],
	isVideo?:true,
		__typename?: true
}>;
	["TileMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	image?:ValueTypes["FieldMeta"],
	link?:ValueTypes["FieldMeta"],
	isVideo?:ValueTypes["FieldMeta"],
		__typename?: true
}>;
	["TileListItemOrderBy"]: {
	_random?:boolean | null,
	_randomSeeded?:number | null,
	id?:ValueTypes["OrderDirection"] | null,
	order?:ValueTypes["OrderDirection"] | null,
	list?:ValueTypes["TileListOrderBy"] | null,
	tile?:ValueTypes["TileOrderBy"] | null
};
	["TileListOrderBy"]: {
	_random?:boolean | null,
	_randomSeeded?:number | null,
	id?:ValueTypes["OrderDirection"] | null
};
	["TileOrderBy"]: {
	_random?:boolean | null,
	_randomSeeded?:number | null,
	id?:ValueTypes["OrderDirection"] | null,
	image?:ValueTypes["ImageOrderBy"] | null,
	link?:ValueTypes["LinkOrderBy"] | null,
	isVideo?:ValueTypes["OrderDirection"] | null
};
	["LinkOrderBy"]: {
	_random?:boolean | null,
	_randomSeeded?:number | null,
	id?:ValueTypes["OrderDirection"] | null,
	type?:ValueTypes["OrderDirection"] | null,
	title?:ValueTypes["OrderDirection"] | null,
	externalLink?:ValueTypes["OrderDirection"] | null,
	internalLink?:ValueTypes["LinkableOrderBy"] | null,
	isHighlighted?:ValueTypes["OrderDirection"] | null,
	targetBlank?:ValueTypes["OrderDirection"] | null
};
	["LinkableOrderBy"]: {
	_random?:boolean | null,
	_randomSeeded?:number | null,
	id?:ValueTypes["OrderDirection"] | null,
	url?:ValueTypes["OrderDirection"] | null,
	redirect?:ValueTypes["RedirectOrderBy"] | null,
	homepage?:ValueTypes["HomepageOrderBy"] | null,
	article?:ValueTypes["ArticleOrderBy"] | null,
	articleCategory?:ValueTypes["ArticleCategoryOrderBy"] | null,
	pose?:ValueTypes["PoseOrderBy"] | null,
	course?:ValueTypes["CourseOrderBy"] | null,
	articleList?:ValueTypes["ArticleListPageOrderBy"] | null,
	courseList?:ValueTypes["CourseListPageOrderBy"] | null,
	poseList?:ValueTypes["PoseListPageOrderBy"] | null,
	courseLecturer?:ValueTypes["CourseLecturerOrderBy"] | null,
	courseLecturerList?:ValueTypes["CourseLecturerListPageOrderBy"] | null,
	newsletterSubscribeSuccessPage?:ValueTypes["NewsletterSubscribeSuccessPageOrderBy"] | null,
	genericPage?:ValueTypes["GenericPageOrderBy"] | null
};
	["RedirectOrderBy"]: {
	_random?:boolean | null,
	_randomSeeded?:number | null,
	id?:ValueTypes["OrderDirection"] | null,
	link?:ValueTypes["LinkableOrderBy"] | null,
	target?:ValueTypes["LinkableOrderBy"] | null
};
	["HomepageOrderBy"]: {
	_random?:boolean | null,
	_randomSeeded?:number | null,
	id?:ValueTypes["OrderDirection"] | null,
	link?:ValueTypes["LinkableOrderBy"] | null,
	subtitle?:ValueTypes["OrderDirection"] | null,
	seo?:ValueTypes["SeoOrderBy"] | null,
	content?:ValueTypes["ContentOrderBy"] | null,
	heroTiles?:ValueTypes["TileListOrderBy"] | null,
	site?:ValueTypes["SiteOrderBy"] | null,
	titlePartA?:ValueTypes["OrderDirection"] | null,
	titlePartB?:ValueTypes["OrderDirection"] | null,
	titlePartC?:ValueTypes["OrderDirection"] | null,
	titlePartD?:ValueTypes["OrderDirection"] | null
};
	["SeoOrderBy"]: {
	_random?:boolean | null,
	_randomSeeded?:number | null,
	id?:ValueTypes["OrderDirection"] | null,
	title?:ValueTypes["OrderDirection"] | null,
	description?:ValueTypes["OrderDirection"] | null,
	ogTitle?:ValueTypes["OrderDirection"] | null,
	ogDescription?:ValueTypes["OrderDirection"] | null,
	ogImage?:ValueTypes["ImageOrderBy"] | null
};
	["SiteOrderBy"]: {
	_random?:boolean | null,
	_randomSeeded?:number | null,
	id?:ValueTypes["OrderDirection"] | null,
	code?:ValueTypes["OrderDirection"] | null,
	order?:ValueTypes["OrderDirection"] | null,
	internalName?:ValueTypes["OrderDirection"] | null,
	articleListPage?:ValueTypes["ArticleListPageOrderBy"] | null,
	courseListPage?:ValueTypes["CourseListPageOrderBy"] | null,
	poseListPage?:ValueTypes["PoseListPageOrderBy"] | null,
	courseLecturerListPage?:ValueTypes["CourseLecturerListPageOrderBy"] | null,
	homePages?:ValueTypes["HomepageOrderBy"] | null,
	subscribeBox?:ValueTypes["SubscribeBoxOrderBy"] | null,
	header?:ValueTypes["HeaderOrderBy"] | null,
	footer?:ValueTypes["FooterOrderBy"] | null,
	courseEmail?:ValueTypes["CourseEmailOrderBy"] | null,
	translations?:ValueTypes["TranslationsOrderBy"] | null
};
	["ArticleListPageOrderBy"]: {
	_random?:boolean | null,
	_randomSeeded?:number | null,
	id?:ValueTypes["OrderDirection"] | null,
	seo?:ValueTypes["SeoOrderBy"] | null,
	link?:ValueTypes["LinkableOrderBy"] | null,
	title?:ValueTypes["OrderDirection"] | null,
	site?:ValueTypes["SiteOrderBy"] | null
};
	["CourseListPageOrderBy"]: {
	_random?:boolean | null,
	_randomSeeded?:number | null,
	id?:ValueTypes["OrderDirection"] | null,
	seo?:ValueTypes["SeoOrderBy"] | null,
	link?:ValueTypes["LinkableOrderBy"] | null,
	title?:ValueTypes["OrderDirection"] | null,
	site?:ValueTypes["SiteOrderBy"] | null
};
	["PoseListPageOrderBy"]: {
	_random?:boolean | null,
	_randomSeeded?:number | null,
	id?:ValueTypes["OrderDirection"] | null,
	link?:ValueTypes["LinkableOrderBy"] | null,
	seo?:ValueTypes["SeoOrderBy"] | null,
	title?:ValueTypes["OrderDirection"] | null,
	site?:ValueTypes["SiteOrderBy"] | null
};
	["CourseLecturerListPageOrderBy"]: {
	_random?:boolean | null,
	_randomSeeded?:number | null,
	id?:ValueTypes["OrderDirection"] | null,
	title?:ValueTypes["OrderDirection"] | null,
	site?:ValueTypes["SiteOrderBy"] | null,
	seo?:ValueTypes["SeoOrderBy"] | null,
	link?:ValueTypes["LinkableOrderBy"] | null
};
	["SubscribeBoxOrderBy"]: {
	_random?:boolean | null,
	_randomSeeded?:number | null,
	id?:ValueTypes["OrderDirection"] | null,
	title?:ValueTypes["OrderDirection"] | null,
	text?:ValueTypes["OrderDirection"] | null,
	shapeColorA?:ValueTypes["OrderDirection"] | null,
	shapeColorB?:ValueTypes["OrderDirection"] | null,
	shapeColorC?:ValueTypes["OrderDirection"] | null,
	externalMailingListId?:ValueTypes["OrderDirection"] | null,
	site?:ValueTypes["SiteOrderBy"] | null,
	logo?:ValueTypes["ImageOrderBy"] | null,
	emailLabel?:ValueTypes["OrderDirection"] | null,
	submitLabel?:ValueTypes["OrderDirection"] | null
};
	["HeaderOrderBy"]: {
	_random?:boolean | null,
	_randomSeeded?:number | null,
	id?:ValueTypes["OrderDirection"] | null,
	site?:ValueTypes["SiteOrderBy"] | null,
	logo?:ValueTypes["ImageOrderBy"] | null,
	logoOnWhite?:ValueTypes["ImageOrderBy"] | null
};
	["FooterOrderBy"]: {
	_random?:boolean | null,
	_randomSeeded?:number | null,
	id?:ValueTypes["OrderDirection"] | null,
	navigation?:ValueTypes["ListNavigationOrderBy"] | null,
	site?:ValueTypes["SiteOrderBy"] | null,
	logo?:ValueTypes["ImageOrderBy"] | null,
	copyright?:ValueTypes["OrderDirection"] | null,
	attribution?:ValueTypes["OrderDirection"] | null
};
	["ListNavigationOrderBy"]: {
	_random?:boolean | null,
	_randomSeeded?:number | null,
	id?:ValueTypes["OrderDirection"] | null
};
	["CourseEmailOrderBy"]: {
	_random?:boolean | null,
	_randomSeeded?:number | null,
	id?:ValueTypes["OrderDirection"] | null,
	title?:ValueTypes["OrderDirection"] | null,
	text?:ValueTypes["OrderDirection"] | null,
	buttonLabel?:ValueTypes["OrderDirection"] | null,
	logo?:ValueTypes["ImageOrderBy"] | null,
	cover?:ValueTypes["ImageOrderBy"] | null,
	site?:ValueTypes["SiteOrderBy"] | null,
	contactInfoEmailAddress?:ValueTypes["OrderDirection"] | null,
	senderEmail?:ValueTypes["OrderDirection"] | null
};
	["TranslationsOrderBy"]: {
	_random?:boolean | null,
	_randomSeeded?:number | null,
	id?:ValueTypes["OrderDirection"] | null,
	home?:ValueTypes["OrderDirection"] | null,
	articles?:ValueTypes["OrderDirection"] | null,
	article?:ValueTypes["OrderDirection"] | null,
	articleMore?:ValueTypes["OrderDirection"] | null,
	categories?:ValueTypes["OrderDirection"] | null,
	lecturers?:ValueTypes["OrderDirection"] | null,
	lecturer?:ValueTypes["OrderDirection"] | null,
	courses?:ValueTypes["OrderDirection"] | null,
	course?:ValueTypes["OrderDirection"] | null,
	poses?:ValueTypes["OrderDirection"] | null,
	pose?:ValueTypes["OrderDirection"] | null,
	site?:ValueTypes["SiteOrderBy"] | null,
	posesLong?:ValueTypes["OrderDirection"] | null,
	posesMore?:ValueTypes["OrderDirection"] | null,
	listAll?:ValueTypes["OrderDirection"] | null,
	difficulty?:ValueTypes["OrderDirection"] | null,
	skillLevel?:ValueTypes["OrderDirection"] | null,
	signIn?:ValueTypes["OrderDirection"] | null,
	error?:ValueTypes["OrderDirection"] | null
};
	["ArticleOrderBy"]: {
	_random?:boolean | null,
	_randomSeeded?:number | null,
	id?:ValueTypes["OrderDirection"] | null,
	headline?:ValueTypes["OrderDirection"] | null,
	publishedAt?:ValueTypes["OrderDirection"] | null,
	site?:ValueTypes["SiteOrderBy"] | null,
	seo?:ValueTypes["SeoOrderBy"] | null,
	link?:ValueTypes["LinkableOrderBy"] | null,
	lead?:ValueTypes["OrderDirection"] | null,
	shortLead?:ValueTypes["OrderDirection"] | null,
	image?:ValueTypes["ImageOrderBy"] | null,
	content?:ValueTypes["ContentOrderBy"] | null,
	category?:ValueTypes["ArticleCategoryOrderBy"] | null
};
	["ArticleCategoryOrderBy"]: {
	_random?:boolean | null,
	_randomSeeded?:number | null,
	id?:ValueTypes["OrderDirection"] | null,
	label?:ValueTypes["OrderDirection"] | null,
	site?:ValueTypes["SiteOrderBy"] | null,
	seo?:ValueTypes["SeoOrderBy"] | null,
	link?:ValueTypes["LinkableOrderBy"] | null,
	lead?:ValueTypes["OrderDirection"] | null,
	originalPath?:ValueTypes["OrderDirection"] | null
};
	["PoseOrderBy"]: {
	_random?:boolean | null,
	_randomSeeded?:number | null,
	id?:ValueTypes["OrderDirection"] | null,
	headline?:ValueTypes["OrderDirection"] | null,
	publishedAt?:ValueTypes["OrderDirection"] | null,
	link?:ValueTypes["LinkableOrderBy"] | null,
	site?:ValueTypes["SiteOrderBy"] | null,
	image?:ValueTypes["ImageOrderBy"] | null,
	seo?:ValueTypes["SeoOrderBy"] | null,
	subtitle?:ValueTypes["OrderDirection"] | null,
	content?:ValueTypes["ContentOrderBy"] | null,
	category?:ValueTypes["PoseCategoryOrderBy"] | null,
	difficulty?:ValueTypes["PoseDifficultyOrderBy"] | null
};
	["PoseCategoryOrderBy"]: {
	_random?:boolean | null,
	_randomSeeded?:number | null,
	id?:ValueTypes["OrderDirection"] | null,
	label?:ValueTypes["OrderDirection"] | null,
	site?:ValueTypes["SiteOrderBy"] | null,
	icon?:ValueTypes["ImageOrderBy"] | null
};
	["PoseDifficultyOrderBy"]: {
	_random?:boolean | null,
	_randomSeeded?:number | null,
	id?:ValueTypes["OrderDirection"] | null,
	label?:ValueTypes["OrderDirection"] | null,
	site?:ValueTypes["SiteOrderBy"] | null,
	icon?:ValueTypes["ImageOrderBy"] | null
};
	["CourseOrderBy"]: {
	_random?:boolean | null,
	_randomSeeded?:number | null,
	id?:ValueTypes["OrderDirection"] | null,
	headline?:ValueTypes["OrderDirection"] | null,
	lead?:ValueTypes["OrderDirection"] | null,
	shortLead?:ValueTypes["OrderDirection"] | null,
	publishedAt?:ValueTypes["OrderDirection"] | null,
	site?:ValueTypes["SiteOrderBy"] | null,
	image?:ValueTypes["ImageOrderBy"] | null,
	seo?:ValueTypes["SeoOrderBy"] | null,
	link?:ValueTypes["LinkableOrderBy"] | null,
	difficulty?:ValueTypes["OrderDirection"] | null,
	teaser?:ValueTypes["YoutubeVideoOrderBy"] | null,
	lessonIntervalInDays?:ValueTypes["OrderDirection"] | null,
	content?:ValueTypes["ContentOrderBy"] | null,
	lecturer?:ValueTypes["CourseLecturerOrderBy"] | null
};
	["YoutubeVideoOrderBy"]: {
	_random?:boolean | null,
	_randomSeeded?:number | null,
	id?:ValueTypes["OrderDirection"] | null,
	videoId?:ValueTypes["OrderDirection"] | null
};
	["CourseLecturerOrderBy"]: {
	_random?:boolean | null,
	_randomSeeded?:number | null,
	id?:ValueTypes["OrderDirection"] | null,
	name?:ValueTypes["OrderDirection"] | null,
	site?:ValueTypes["SiteOrderBy"] | null,
	link?:ValueTypes["LinkableOrderBy"] | null,
	seo?:ValueTypes["SeoOrderBy"] | null,
	image?:ValueTypes["ImageOrderBy"] | null,
	content?:ValueTypes["ContentOrderBy"] | null
};
	["NewsletterSubscribeSuccessPageOrderBy"]: {
	_random?:boolean | null,
	_randomSeeded?:number | null,
	id?:ValueTypes["OrderDirection"] | null,
	title?:ValueTypes["OrderDirection"] | null,
	returnLinkText?:ValueTypes["OrderDirection"] | null,
	link?:ValueTypes["LinkableOrderBy"] | null,
	site?:ValueTypes["SiteOrderBy"] | null,
	seo?:ValueTypes["SeoOrderBy"] | null
};
	["GenericPageOrderBy"]: {
	_random?:boolean | null,
	_randomSeeded?:number | null,
	id?:ValueTypes["OrderDirection"] | null,
	headline?:ValueTypes["OrderDirection"] | null,
	lead?:ValueTypes["OrderDirection"] | null,
	publishedAt?:ValueTypes["OrderDirection"] | null,
	site?:ValueTypes["SiteOrderBy"] | null,
	content?:ValueTypes["ContentOrderBy"] | null,
	seo?:ValueTypes["SeoOrderBy"] | null,
	link?:ValueTypes["LinkableOrderBy"] | null
};
	["TileListItemsByTileUniqueWhere"]: {
	tile?:ValueTypes["TileUniqueWhere"] | null
};
	["TileUniqueWhere"]: {
	id?:ValueTypes["UUID"] | null,
	link?:ValueTypes["LinkUniqueWhere"] | null
};
	["LinkUniqueWhere"]: {
	id?:ValueTypes["UUID"] | null
};
	["TileListItemConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["TileListItemEdge"],
		__typename?: true
}>;
	["TileListItemEdge"]: AliasType<{
	node?:ValueTypes["TileListItem"],
		__typename?: true
}>;
	["Site"]: AliasType<{
	_meta?:ValueTypes["SiteMeta"],
	id?:true,
	code?:true,
	order?:true,
articles?: [{	filter?:ValueTypes["ArticleWhere"] | null,	orderBy?:ValueTypes["ArticleOrderBy"][],	offset?:number | null,	limit?:number | null},ValueTypes["Article"]],
	internalName?:true,
articleCategories?: [{	filter?:ValueTypes["ArticleCategoryWhere"] | null,	orderBy?:ValueTypes["ArticleCategoryOrderBy"][],	offset?:number | null,	limit?:number | null},ValueTypes["ArticleCategory"]],
poses?: [{	filter?:ValueTypes["PoseWhere"] | null,	orderBy?:ValueTypes["PoseOrderBy"][],	offset?:number | null,	limit?:number | null},ValueTypes["Pose"]],
poseCategories?: [{	filter?:ValueTypes["PoseCategoryWhere"] | null,	orderBy?:ValueTypes["PoseCategoryOrderBy"][],	offset?:number | null,	limit?:number | null},ValueTypes["PoseCategory"]],
poseDifficulties?: [{	filter?:ValueTypes["PoseDifficultyWhere"] | null,	orderBy?:ValueTypes["PoseDifficultyOrderBy"][],	offset?:number | null,	limit?:number | null},ValueTypes["PoseDifficulty"]],
courses?: [{	filter?:ValueTypes["CourseWhere"] | null,	orderBy?:ValueTypes["CourseOrderBy"][],	offset?:number | null,	limit?:number | null},ValueTypes["Course"]],
articleListPage?: [{	filter?:ValueTypes["ArticleListPageWhere"] | null},ValueTypes["ArticleListPage"]],
courseListPage?: [{	filter?:ValueTypes["CourseListPageWhere"] | null},ValueTypes["CourseListPage"]],
poseListPage?: [{	filter?:ValueTypes["PoseListPageWhere"] | null},ValueTypes["PoseListPage"]],
courseLecturers?: [{	filter?:ValueTypes["CourseLecturerWhere"] | null,	orderBy?:ValueTypes["CourseLecturerOrderBy"][],	offset?:number | null,	limit?:number | null},ValueTypes["CourseLecturer"]],
courseLecturerListPage?: [{	filter?:ValueTypes["CourseLecturerListPageWhere"] | null},ValueTypes["CourseLecturerListPage"]],
homePages?: [{	filter?:ValueTypes["HomepageWhere"] | null},ValueTypes["Homepage"]],
subscribeBox?: [{	filter?:ValueTypes["SubscribeBoxWhere"] | null},ValueTypes["SubscribeBox"]],
header?: [{	filter?:ValueTypes["HeaderWhere"] | null},ValueTypes["Header"]],
footer?: [{	filter?:ValueTypes["FooterWhere"] | null},ValueTypes["Footer"]],
genericPages?: [{	filter?:ValueTypes["GenericPageWhere"] | null,	orderBy?:ValueTypes["GenericPageOrderBy"][],	offset?:number | null,	limit?:number | null},ValueTypes["GenericPage"]],
courseEmail?: [{	filter?:ValueTypes["CourseEmailWhere"] | null},ValueTypes["CourseEmail"]],
translations?: [{	filter?:ValueTypes["TranslationsWhere"] | null},ValueTypes["Translations"]],
articlesBySeo?: [{	by:ValueTypes["SiteArticlesBySeoUniqueWhere"],	filter?:ValueTypes["ArticleWhere"] | null},ValueTypes["Article"]],
articlesByLink?: [{	by:ValueTypes["SiteArticlesByLinkUniqueWhere"],	filter?:ValueTypes["ArticleWhere"] | null},ValueTypes["Article"]],
articlesByContent?: [{	by:ValueTypes["SiteArticlesByContentUniqueWhere"],	filter?:ValueTypes["ArticleWhere"] | null},ValueTypes["Article"]],
articleCategoriesByArticles?: [{	by:ValueTypes["SiteArticleCategoriesByArticlesUniqueWhere"],	filter?:ValueTypes["ArticleCategoryWhere"] | null},ValueTypes["ArticleCategory"]],
articleCategoriesBySeo?: [{	by:ValueTypes["SiteArticleCategoriesBySeoUniqueWhere"],	filter?:ValueTypes["ArticleCategoryWhere"] | null},ValueTypes["ArticleCategory"]],
articleCategoriesByLink?: [{	by:ValueTypes["SiteArticleCategoriesByLinkUniqueWhere"],	filter?:ValueTypes["ArticleCategoryWhere"] | null},ValueTypes["ArticleCategory"]],
posesByLink?: [{	by:ValueTypes["SitePosesByLinkUniqueWhere"],	filter?:ValueTypes["PoseWhere"] | null},ValueTypes["Pose"]],
posesBySeo?: [{	by:ValueTypes["SitePosesBySeoUniqueWhere"],	filter?:ValueTypes["PoseWhere"] | null},ValueTypes["Pose"]],
posesByContent?: [{	by:ValueTypes["SitePosesByContentUniqueWhere"],	filter?:ValueTypes["PoseWhere"] | null},ValueTypes["Pose"]],
poseCategoriesByPoses?: [{	by:ValueTypes["SitePoseCategoriesByPosesUniqueWhere"],	filter?:ValueTypes["PoseCategoryWhere"] | null},ValueTypes["PoseCategory"]],
poseDifficultiesByPoses?: [{	by:ValueTypes["SitePoseDifficultiesByPosesUniqueWhere"],	filter?:ValueTypes["PoseDifficultyWhere"] | null},ValueTypes["PoseDifficulty"]],
coursesBySeo?: [{	by:ValueTypes["SiteCoursesBySeoUniqueWhere"],	filter?:ValueTypes["CourseWhere"] | null},ValueTypes["Course"]],
coursesByLink?: [{	by:ValueTypes["SiteCoursesByLinkUniqueWhere"],	filter?:ValueTypes["CourseWhere"] | null},ValueTypes["Course"]],
coursesByLessons?: [{	by:ValueTypes["SiteCoursesByLessonsUniqueWhere"],	filter?:ValueTypes["CourseWhere"] | null},ValueTypes["Course"]],
coursesByContent?: [{	by:ValueTypes["SiteCoursesByContentUniqueWhere"],	filter?:ValueTypes["CourseWhere"] | null},ValueTypes["Course"]],
courseLecturersByCourses?: [{	by:ValueTypes["SiteCourseLecturersByCoursesUniqueWhere"],	filter?:ValueTypes["CourseLecturerWhere"] | null},ValueTypes["CourseLecturer"]],
courseLecturersByLink?: [{	by:ValueTypes["SiteCourseLecturersByLinkUniqueWhere"],	filter?:ValueTypes["CourseLecturerWhere"] | null},ValueTypes["CourseLecturer"]],
courseLecturersBySeo?: [{	by:ValueTypes["SiteCourseLecturersBySeoUniqueWhere"],	filter?:ValueTypes["CourseLecturerWhere"] | null},ValueTypes["CourseLecturer"]],
courseLecturersByContent?: [{	by:ValueTypes["SiteCourseLecturersByContentUniqueWhere"],	filter?:ValueTypes["CourseLecturerWhere"] | null},ValueTypes["CourseLecturer"]],
genericPagesByContent?: [{	by:ValueTypes["SiteGenericPagesByContentUniqueWhere"],	filter?:ValueTypes["GenericPageWhere"] | null},ValueTypes["GenericPage"]],
genericPagesBySeo?: [{	by:ValueTypes["SiteGenericPagesBySeoUniqueWhere"],	filter?:ValueTypes["GenericPageWhere"] | null},ValueTypes["GenericPage"]],
genericPagesByLink?: [{	by:ValueTypes["SiteGenericPagesByLinkUniqueWhere"],	filter?:ValueTypes["GenericPageWhere"] | null},ValueTypes["GenericPage"]],
paginateArticles?: [{	filter?:ValueTypes["ArticleWhere"] | null,	orderBy?:ValueTypes["ArticleOrderBy"][],	skip?:number | null,	first?:number | null},ValueTypes["ArticleConnection"]],
paginateArticleCategories?: [{	filter?:ValueTypes["ArticleCategoryWhere"] | null,	orderBy?:ValueTypes["ArticleCategoryOrderBy"][],	skip?:number | null,	first?:number | null},ValueTypes["ArticleCategoryConnection"]],
paginatePoses?: [{	filter?:ValueTypes["PoseWhere"] | null,	orderBy?:ValueTypes["PoseOrderBy"][],	skip?:number | null,	first?:number | null},ValueTypes["PoseConnection"]],
paginatePoseCategories?: [{	filter?:ValueTypes["PoseCategoryWhere"] | null,	orderBy?:ValueTypes["PoseCategoryOrderBy"][],	skip?:number | null,	first?:number | null},ValueTypes["PoseCategoryConnection"]],
paginatePoseDifficulties?: [{	filter?:ValueTypes["PoseDifficultyWhere"] | null,	orderBy?:ValueTypes["PoseDifficultyOrderBy"][],	skip?:number | null,	first?:number | null},ValueTypes["PoseDifficultyConnection"]],
paginateCourses?: [{	filter?:ValueTypes["CourseWhere"] | null,	orderBy?:ValueTypes["CourseOrderBy"][],	skip?:number | null,	first?:number | null},ValueTypes["CourseConnection"]],
paginateCourseLecturers?: [{	filter?:ValueTypes["CourseLecturerWhere"] | null,	orderBy?:ValueTypes["CourseLecturerOrderBy"][],	skip?:number | null,	first?:number | null},ValueTypes["CourseLecturerConnection"]],
paginateGenericPages?: [{	filter?:ValueTypes["GenericPageWhere"] | null,	orderBy?:ValueTypes["GenericPageOrderBy"][],	skip?:number | null,	first?:number | null},ValueTypes["GenericPageConnection"]],
		__typename?: true
}>;
	["SiteMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	code?:ValueTypes["FieldMeta"],
	order?:ValueTypes["FieldMeta"],
	articles?:ValueTypes["FieldMeta"],
	internalName?:ValueTypes["FieldMeta"],
	articleCategories?:ValueTypes["FieldMeta"],
	poses?:ValueTypes["FieldMeta"],
	poseCategories?:ValueTypes["FieldMeta"],
	poseDifficulties?:ValueTypes["FieldMeta"],
	courses?:ValueTypes["FieldMeta"],
	articleListPage?:ValueTypes["FieldMeta"],
	courseListPage?:ValueTypes["FieldMeta"],
	poseListPage?:ValueTypes["FieldMeta"],
	courseLecturers?:ValueTypes["FieldMeta"],
	courseLecturerListPage?:ValueTypes["FieldMeta"],
	homePages?:ValueTypes["FieldMeta"],
	subscribeBox?:ValueTypes["FieldMeta"],
	header?:ValueTypes["FieldMeta"],
	footer?:ValueTypes["FieldMeta"],
	genericPages?:ValueTypes["FieldMeta"],
	courseEmail?:ValueTypes["FieldMeta"],
	translations?:ValueTypes["FieldMeta"],
		__typename?: true
}>;
	["Article"]: AliasType<{
	_meta?:ValueTypes["ArticleMeta"],
	id?:true,
	headline?:true,
	publishedAt?:true,
site?: [{	filter?:ValueTypes["SiteWhere"] | null},ValueTypes["Site"]],
seo?: [{	filter?:ValueTypes["SeoWhere"] | null},ValueTypes["Seo"]],
link?: [{	filter?:ValueTypes["LinkableWhere"] | null},ValueTypes["Linkable"]],
	lead?:true,
	shortLead?:true,
image?: [{	filter?:ValueTypes["ImageWhere"] | null},ValueTypes["Image"]],
content?: [{	filter?:ValueTypes["ContentWhere"] | null},ValueTypes["Content"]],
category?: [{	filter?:ValueTypes["ArticleCategoryWhere"] | null},ValueTypes["ArticleCategory"]],
		__typename?: true
}>;
	["ArticleMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	headline?:ValueTypes["FieldMeta"],
	publishedAt?:ValueTypes["FieldMeta"],
	site?:ValueTypes["FieldMeta"],
	seo?:ValueTypes["FieldMeta"],
	link?:ValueTypes["FieldMeta"],
	lead?:ValueTypes["FieldMeta"],
	shortLead?:ValueTypes["FieldMeta"],
	image?:ValueTypes["FieldMeta"],
	content?:ValueTypes["FieldMeta"],
	category?:ValueTypes["FieldMeta"],
		__typename?: true
}>;
	["ArticleCategory"]: AliasType<{
	_meta?:ValueTypes["ArticleCategoryMeta"],
	id?:true,
	label?:true,
articles?: [{	filter?:ValueTypes["ArticleWhere"] | null,	orderBy?:ValueTypes["ArticleOrderBy"][],	offset?:number | null,	limit?:number | null},ValueTypes["Article"]],
site?: [{	filter?:ValueTypes["SiteWhere"] | null},ValueTypes["Site"]],
seo?: [{	filter?:ValueTypes["SeoWhere"] | null},ValueTypes["Seo"]],
link?: [{	filter?:ValueTypes["LinkableWhere"] | null},ValueTypes["Linkable"]],
	lead?:true,
	originalPath?:true,
articlesBySeo?: [{	by:ValueTypes["ArticleCategoryArticlesBySeoUniqueWhere"],	filter?:ValueTypes["ArticleWhere"] | null},ValueTypes["Article"]],
articlesByLink?: [{	by:ValueTypes["ArticleCategoryArticlesByLinkUniqueWhere"],	filter?:ValueTypes["ArticleWhere"] | null},ValueTypes["Article"]],
articlesByContent?: [{	by:ValueTypes["ArticleCategoryArticlesByContentUniqueWhere"],	filter?:ValueTypes["ArticleWhere"] | null},ValueTypes["Article"]],
paginateArticles?: [{	filter?:ValueTypes["ArticleWhere"] | null,	orderBy?:ValueTypes["ArticleOrderBy"][],	skip?:number | null,	first?:number | null},ValueTypes["ArticleConnection"]],
		__typename?: true
}>;
	["ArticleCategoryMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	label?:ValueTypes["FieldMeta"],
	articles?:ValueTypes["FieldMeta"],
	site?:ValueTypes["FieldMeta"],
	seo?:ValueTypes["FieldMeta"],
	link?:ValueTypes["FieldMeta"],
	lead?:ValueTypes["FieldMeta"],
	originalPath?:ValueTypes["FieldMeta"],
		__typename?: true
}>;
	["ArticleCategoryArticlesBySeoUniqueWhere"]: {
	seo?:ValueTypes["SeoUniqueWhere"] | null
};
	["SeoUniqueWhere"]: {
	id?:ValueTypes["UUID"] | null,
	ogImage?:ValueTypes["ImageUniqueWhere"] | null
};
	["ImageUniqueWhere"]: {
	id?:ValueTypes["UUID"] | null
};
	["ArticleCategoryArticlesByLinkUniqueWhere"]: {
	link?:ValueTypes["LinkableUniqueWhere"] | null
};
	["LinkableUniqueWhere"]: {
	id?:ValueTypes["UUID"] | null,
	url?:string | null,
	redirect?:ValueTypes["RedirectUniqueWhere"] | null,
	homepage?:ValueTypes["HomepageUniqueWhere"] | null,
	article?:ValueTypes["ArticleUniqueWhere"] | null,
	articleCategory?:ValueTypes["ArticleCategoryUniqueWhere"] | null,
	pose?:ValueTypes["PoseUniqueWhere"] | null,
	course?:ValueTypes["CourseUniqueWhere"] | null,
	articleList?:ValueTypes["ArticleListPageUniqueWhere"] | null,
	courseList?:ValueTypes["CourseListPageUniqueWhere"] | null,
	poseList?:ValueTypes["PoseListPageUniqueWhere"] | null,
	courseLecturer?:ValueTypes["CourseLecturerUniqueWhere"] | null,
	courseLecturerList?:ValueTypes["CourseLecturerListPageUniqueWhere"] | null,
	newsletterSubscribeSuccessPage?:ValueTypes["NewsletterSubscribeSuccessPageUniqueWhere"] | null,
	genericPage?:ValueTypes["GenericPageUniqueWhere"] | null
};
	["RedirectUniqueWhere"]: {
	id?:ValueTypes["UUID"] | null,
	link?:ValueTypes["LinkableUniqueWhere"] | null
};
	["HomepageUniqueWhere"]: {
	id?:ValueTypes["UUID"] | null,
	link?:ValueTypes["LinkableUniqueWhere"] | null,
	seo?:ValueTypes["SeoUniqueWhere"] | null,
	content?:ValueTypes["ContentUniqueWhere"] | null,
	heroTiles?:ValueTypes["TileListUniqueWhere"] | null,
	site?:ValueTypes["SiteUniqueWhere"] | null
};
	["ContentUniqueWhere"]: {
	id?:ValueTypes["UUID"] | null,
	blocks?:ValueTypes["ContentBlockUniqueWhere"] | null
};
	["ContentBlockUniqueWhere"]: {
	id?:ValueTypes["UUID"] | null,
	references?:ValueTypes["ContentReferenceUniqueWhere"] | null
};
	["TileListUniqueWhere"]: {
	id?:ValueTypes["UUID"] | null,
	items?:ValueTypes["TileListItemUniqueWhere"] | null
};
	["TileListItemUniqueWhere"]: {
	id?:ValueTypes["UUID"] | null,
	tile?:ValueTypes["TileUniqueWhere"] | null
};
	["SiteUniqueWhere"]: {
	id?:ValueTypes["UUID"] | null,
	code?:string | null,
	articles?:ValueTypes["ArticleUniqueWhere"] | null,
	articleCategories?:ValueTypes["ArticleCategoryUniqueWhere"] | null,
	poses?:ValueTypes["PoseUniqueWhere"] | null,
	poseCategories?:ValueTypes["PoseCategoryUniqueWhere"] | null,
	poseDifficulties?:ValueTypes["PoseDifficultyUniqueWhere"] | null,
	courses?:ValueTypes["CourseUniqueWhere"] | null,
	articleListPage?:ValueTypes["ArticleListPageUniqueWhere"] | null,
	courseListPage?:ValueTypes["CourseListPageUniqueWhere"] | null,
	poseListPage?:ValueTypes["PoseListPageUniqueWhere"] | null,
	courseLecturers?:ValueTypes["CourseLecturerUniqueWhere"] | null,
	courseLecturerListPage?:ValueTypes["CourseLecturerListPageUniqueWhere"] | null,
	homePages?:ValueTypes["HomepageUniqueWhere"] | null,
	subscribeBox?:ValueTypes["SubscribeBoxUniqueWhere"] | null,
	header?:ValueTypes["HeaderUniqueWhere"] | null,
	footer?:ValueTypes["FooterUniqueWhere"] | null,
	genericPages?:ValueTypes["GenericPageUniqueWhere"] | null,
	courseEmail?:ValueTypes["CourseEmailUniqueWhere"] | null,
	translations?:ValueTypes["TranslationsUniqueWhere"] | null
};
	["ArticleUniqueWhere"]: {
	id?:ValueTypes["UUID"] | null,
	seo?:ValueTypes["SeoUniqueWhere"] | null,
	link?:ValueTypes["LinkableUniqueWhere"] | null,
	content?:ValueTypes["ContentUniqueWhere"] | null
};
	["ArticleCategoryUniqueWhere"]: {
	id?:ValueTypes["UUID"] | null,
	articles?:ValueTypes["ArticleUniqueWhere"] | null,
	seo?:ValueTypes["SeoUniqueWhere"] | null,
	link?:ValueTypes["LinkableUniqueWhere"] | null
};
	["PoseUniqueWhere"]: {
	id?:ValueTypes["UUID"] | null,
	link?:ValueTypes["LinkableUniqueWhere"] | null,
	seo?:ValueTypes["SeoUniqueWhere"] | null,
	content?:ValueTypes["ContentUniqueWhere"] | null
};
	["PoseCategoryUniqueWhere"]: {
	id?:ValueTypes["UUID"] | null,
	poses?:ValueTypes["PoseUniqueWhere"] | null
};
	["PoseDifficultyUniqueWhere"]: {
	id?:ValueTypes["UUID"] | null,
	poses?:ValueTypes["PoseUniqueWhere"] | null
};
	["CourseUniqueWhere"]: {
	id?:ValueTypes["UUID"] | null,
	seo?:ValueTypes["SeoUniqueWhere"] | null,
	link?:ValueTypes["LinkableUniqueWhere"] | null,
	lessons?:ValueTypes["CourseLessonUniqueWhere"] | null,
	content?:ValueTypes["ContentUniqueWhere"] | null
};
	["CourseLessonUniqueWhere"]: {
	id?:ValueTypes["UUID"] | null
};
	["ArticleListPageUniqueWhere"]: {
	id?:ValueTypes["UUID"] | null,
	seo?:ValueTypes["SeoUniqueWhere"] | null,
	link?:ValueTypes["LinkableUniqueWhere"] | null,
	site?:ValueTypes["SiteUniqueWhere"] | null
};
	["CourseListPageUniqueWhere"]: {
	id?:ValueTypes["UUID"] | null,
	seo?:ValueTypes["SeoUniqueWhere"] | null,
	link?:ValueTypes["LinkableUniqueWhere"] | null,
	site?:ValueTypes["SiteUniqueWhere"] | null
};
	["PoseListPageUniqueWhere"]: {
	id?:ValueTypes["UUID"] | null,
	link?:ValueTypes["LinkableUniqueWhere"] | null,
	seo?:ValueTypes["SeoUniqueWhere"] | null,
	site?:ValueTypes["SiteUniqueWhere"] | null
};
	["CourseLecturerUniqueWhere"]: {
	id?:ValueTypes["UUID"] | null,
	courses?:ValueTypes["CourseUniqueWhere"] | null,
	link?:ValueTypes["LinkableUniqueWhere"] | null,
	seo?:ValueTypes["SeoUniqueWhere"] | null,
	content?:ValueTypes["ContentUniqueWhere"] | null
};
	["CourseLecturerListPageUniqueWhere"]: {
	id?:ValueTypes["UUID"] | null,
	site?:ValueTypes["SiteUniqueWhere"] | null,
	seo?:ValueTypes["SeoUniqueWhere"] | null,
	link?:ValueTypes["LinkableUniqueWhere"] | null
};
	["SubscribeBoxUniqueWhere"]: {
	id?:ValueTypes["UUID"] | null,
	site?:ValueTypes["SiteUniqueWhere"] | null,
	logo?:ValueTypes["ImageUniqueWhere"] | null
};
	["HeaderUniqueWhere"]: {
	id?:ValueTypes["UUID"] | null,
	primaryNavigationItems?:ValueTypes["HeaderPrimaryNavigationItemUniqueWhere"] | null,
	site?:ValueTypes["SiteUniqueWhere"] | null,
	logo?:ValueTypes["ImageUniqueWhere"] | null,
	logoOnWhite?:ValueTypes["ImageUniqueWhere"] | null
};
	["HeaderPrimaryNavigationItemUniqueWhere"]: {
	id?:ValueTypes["UUID"] | null,
	link?:ValueTypes["LinkUniqueWhere"] | null,
	listNavigation?:ValueTypes["ListNavigationUniqueWhere"] | null,
	tiles?:ValueTypes["TileListUniqueWhere"] | null
};
	["ListNavigationUniqueWhere"]: {
	id?:ValueTypes["UUID"] | null,
	columns?:ValueTypes["ListNavigationColumnUniqueWhere"] | null
};
	["ListNavigationColumnUniqueWhere"]: {
	id?:ValueTypes["UUID"] | null,
	items?:ValueTypes["ListNavigationColumnItemUniqueWhere"] | null
};
	["ListNavigationColumnItemUniqueWhere"]: {
	id?:ValueTypes["UUID"] | null,
	link?:ValueTypes["LinkUniqueWhere"] | null
};
	["FooterUniqueWhere"]: {
	id?:ValueTypes["UUID"] | null,
	navigation?:ValueTypes["ListNavigationUniqueWhere"] | null,
	site?:ValueTypes["SiteUniqueWhere"] | null,
	logo?:ValueTypes["ImageUniqueWhere"] | null
};
	["GenericPageUniqueWhere"]: {
	id?:ValueTypes["UUID"] | null,
	content?:ValueTypes["ContentUniqueWhere"] | null,
	seo?:ValueTypes["SeoUniqueWhere"] | null,
	link?:ValueTypes["LinkableUniqueWhere"] | null
};
	["CourseEmailUniqueWhere"]: {
	id?:ValueTypes["UUID"] | null,
	logo?:ValueTypes["ImageUniqueWhere"] | null,
	cover?:ValueTypes["ImageUniqueWhere"] | null,
	site?:ValueTypes["SiteUniqueWhere"] | null
};
	["TranslationsUniqueWhere"]: {
	id?:ValueTypes["UUID"] | null,
	site?:ValueTypes["SiteUniqueWhere"] | null
};
	["NewsletterSubscribeSuccessPageUniqueWhere"]: {
	id?:ValueTypes["UUID"] | null,
	link?:ValueTypes["LinkableUniqueWhere"] | null,
	site?:ValueTypes["SiteUniqueWhere"] | null,
	seo?:ValueTypes["SeoUniqueWhere"] | null
};
	["ArticleCategoryArticlesByContentUniqueWhere"]: {
	content?:ValueTypes["ContentUniqueWhere"] | null
};
	["ArticleConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["ArticleEdge"],
		__typename?: true
}>;
	["ArticleEdge"]: AliasType<{
	node?:ValueTypes["Article"],
		__typename?: true
}>;
	["Pose"]: AliasType<{
	_meta?:ValueTypes["PoseMeta"],
	id?:true,
	headline?:true,
	publishedAt?:true,
link?: [{	filter?:ValueTypes["LinkableWhere"] | null},ValueTypes["Linkable"]],
site?: [{	filter?:ValueTypes["SiteWhere"] | null},ValueTypes["Site"]],
image?: [{	filter?:ValueTypes["ImageWhere"] | null},ValueTypes["Image"]],
seo?: [{	filter?:ValueTypes["SeoWhere"] | null},ValueTypes["Seo"]],
	subtitle?:true,
content?: [{	filter?:ValueTypes["ContentWhere"] | null},ValueTypes["Content"]],
category?: [{	filter?:ValueTypes["PoseCategoryWhere"] | null},ValueTypes["PoseCategory"]],
difficulty?: [{	filter?:ValueTypes["PoseDifficultyWhere"] | null},ValueTypes["PoseDifficulty"]],
		__typename?: true
}>;
	["PoseMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	headline?:ValueTypes["FieldMeta"],
	publishedAt?:ValueTypes["FieldMeta"],
	link?:ValueTypes["FieldMeta"],
	site?:ValueTypes["FieldMeta"],
	image?:ValueTypes["FieldMeta"],
	seo?:ValueTypes["FieldMeta"],
	subtitle?:ValueTypes["FieldMeta"],
	content?:ValueTypes["FieldMeta"],
	category?:ValueTypes["FieldMeta"],
	difficulty?:ValueTypes["FieldMeta"],
		__typename?: true
}>;
	["PoseCategory"]: AliasType<{
	_meta?:ValueTypes["PoseCategoryMeta"],
	id?:true,
	label?:true,
poses?: [{	filter?:ValueTypes["PoseWhere"] | null,	orderBy?:ValueTypes["PoseOrderBy"][],	offset?:number | null,	limit?:number | null},ValueTypes["Pose"]],
site?: [{	filter?:ValueTypes["SiteWhere"] | null},ValueTypes["Site"]],
icon?: [{	filter?:ValueTypes["ImageWhere"] | null},ValueTypes["Image"]],
posesByLink?: [{	by:ValueTypes["PoseCategoryPosesByLinkUniqueWhere"],	filter?:ValueTypes["PoseWhere"] | null},ValueTypes["Pose"]],
posesBySeo?: [{	by:ValueTypes["PoseCategoryPosesBySeoUniqueWhere"],	filter?:ValueTypes["PoseWhere"] | null},ValueTypes["Pose"]],
posesByContent?: [{	by:ValueTypes["PoseCategoryPosesByContentUniqueWhere"],	filter?:ValueTypes["PoseWhere"] | null},ValueTypes["Pose"]],
paginatePoses?: [{	filter?:ValueTypes["PoseWhere"] | null,	orderBy?:ValueTypes["PoseOrderBy"][],	skip?:number | null,	first?:number | null},ValueTypes["PoseConnection"]],
		__typename?: true
}>;
	["PoseCategoryMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	label?:ValueTypes["FieldMeta"],
	poses?:ValueTypes["FieldMeta"],
	site?:ValueTypes["FieldMeta"],
	icon?:ValueTypes["FieldMeta"],
		__typename?: true
}>;
	["PoseCategoryPosesByLinkUniqueWhere"]: {
	link?:ValueTypes["LinkableUniqueWhere"] | null
};
	["PoseCategoryPosesBySeoUniqueWhere"]: {
	seo?:ValueTypes["SeoUniqueWhere"] | null
};
	["PoseCategoryPosesByContentUniqueWhere"]: {
	content?:ValueTypes["ContentUniqueWhere"] | null
};
	["PoseConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["PoseEdge"],
		__typename?: true
}>;
	["PoseEdge"]: AliasType<{
	node?:ValueTypes["Pose"],
		__typename?: true
}>;
	["PoseDifficulty"]: AliasType<{
	_meta?:ValueTypes["PoseDifficultyMeta"],
	id?:true,
	label?:true,
poses?: [{	filter?:ValueTypes["PoseWhere"] | null,	orderBy?:ValueTypes["PoseOrderBy"][],	offset?:number | null,	limit?:number | null},ValueTypes["Pose"]],
site?: [{	filter?:ValueTypes["SiteWhere"] | null},ValueTypes["Site"]],
icon?: [{	filter?:ValueTypes["ImageWhere"] | null},ValueTypes["Image"]],
posesByLink?: [{	by:ValueTypes["PoseDifficultyPosesByLinkUniqueWhere"],	filter?:ValueTypes["PoseWhere"] | null},ValueTypes["Pose"]],
posesBySeo?: [{	by:ValueTypes["PoseDifficultyPosesBySeoUniqueWhere"],	filter?:ValueTypes["PoseWhere"] | null},ValueTypes["Pose"]],
posesByContent?: [{	by:ValueTypes["PoseDifficultyPosesByContentUniqueWhere"],	filter?:ValueTypes["PoseWhere"] | null},ValueTypes["Pose"]],
paginatePoses?: [{	filter?:ValueTypes["PoseWhere"] | null,	orderBy?:ValueTypes["PoseOrderBy"][],	skip?:number | null,	first?:number | null},ValueTypes["PoseConnection"]],
		__typename?: true
}>;
	["PoseDifficultyMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	label?:ValueTypes["FieldMeta"],
	poses?:ValueTypes["FieldMeta"],
	site?:ValueTypes["FieldMeta"],
	icon?:ValueTypes["FieldMeta"],
		__typename?: true
}>;
	["PoseDifficultyPosesByLinkUniqueWhere"]: {
	link?:ValueTypes["LinkableUniqueWhere"] | null
};
	["PoseDifficultyPosesBySeoUniqueWhere"]: {
	seo?:ValueTypes["SeoUniqueWhere"] | null
};
	["PoseDifficultyPosesByContentUniqueWhere"]: {
	content?:ValueTypes["ContentUniqueWhere"] | null
};
	["Course"]: AliasType<{
	_meta?:ValueTypes["CourseMeta"],
	id?:true,
	headline?:true,
	lead?:true,
	shortLead?:true,
	publishedAt?:true,
site?: [{	filter?:ValueTypes["SiteWhere"] | null},ValueTypes["Site"]],
image?: [{	filter?:ValueTypes["ImageWhere"] | null},ValueTypes["Image"]],
seo?: [{	filter?:ValueTypes["SeoWhere"] | null},ValueTypes["Seo"]],
link?: [{	filter?:ValueTypes["LinkableWhere"] | null},ValueTypes["Linkable"]],
lessons?: [{	filter?:ValueTypes["CourseLessonWhere"] | null,	orderBy?:ValueTypes["CourseLessonOrderBy"][],	offset?:number | null,	limit?:number | null},ValueTypes["CourseLesson"]],
	difficulty?:true,
teaser?: [{	filter?:ValueTypes["YoutubeVideoWhere"] | null},ValueTypes["YoutubeVideo"]],
	lessonIntervalInDays?:true,
content?: [{	filter?:ValueTypes["ContentWhere"] | null},ValueTypes["Content"]],
lecturer?: [{	filter?:ValueTypes["CourseLecturerWhere"] | null},ValueTypes["CourseLecturer"]],
paginateLessons?: [{	filter?:ValueTypes["CourseLessonWhere"] | null,	orderBy?:ValueTypes["CourseLessonOrderBy"][],	skip?:number | null,	first?:number | null},ValueTypes["CourseLessonConnection"]],
		__typename?: true
}>;
	["CourseMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	headline?:ValueTypes["FieldMeta"],
	lead?:ValueTypes["FieldMeta"],
	shortLead?:ValueTypes["FieldMeta"],
	publishedAt?:ValueTypes["FieldMeta"],
	site?:ValueTypes["FieldMeta"],
	image?:ValueTypes["FieldMeta"],
	seo?:ValueTypes["FieldMeta"],
	link?:ValueTypes["FieldMeta"],
	lessons?:ValueTypes["FieldMeta"],
	difficulty?:ValueTypes["FieldMeta"],
	teaser?:ValueTypes["FieldMeta"],
	lessonIntervalInDays?:ValueTypes["FieldMeta"],
	content?:ValueTypes["FieldMeta"],
	lecturer?:ValueTypes["FieldMeta"],
		__typename?: true
}>;
	["CourseLesson"]: AliasType<{
	_meta?:ValueTypes["CourseLessonMeta"],
	id?:true,
	order?:true,
	headline?:true,
	lead?:true,
course?: [{	filter?:ValueTypes["CourseWhere"] | null},ValueTypes["Course"]],
video?: [{	filter?:ValueTypes["YoutubeVideoWhere"] | null},ValueTypes["YoutubeVideo"]],
		__typename?: true
}>;
	["CourseLessonMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	order?:ValueTypes["FieldMeta"],
	headline?:ValueTypes["FieldMeta"],
	lead?:ValueTypes["FieldMeta"],
	course?:ValueTypes["FieldMeta"],
	video?:ValueTypes["FieldMeta"],
		__typename?: true
}>;
	["YoutubeVideo"]: AliasType<{
	_meta?:ValueTypes["YoutubeVideoMeta"],
	id?:true,
	videoId?:true,
		__typename?: true
}>;
	["YoutubeVideoMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	videoId?:ValueTypes["FieldMeta"],
		__typename?: true
}>;
	["CourseLessonOrderBy"]: {
	_random?:boolean | null,
	_randomSeeded?:number | null,
	id?:ValueTypes["OrderDirection"] | null,
	order?:ValueTypes["OrderDirection"] | null,
	headline?:ValueTypes["OrderDirection"] | null,
	lead?:ValueTypes["OrderDirection"] | null,
	course?:ValueTypes["CourseOrderBy"] | null,
	video?:ValueTypes["YoutubeVideoOrderBy"] | null
};
	["CourseLecturer"]: AliasType<{
	_meta?:ValueTypes["CourseLecturerMeta"],
	id?:true,
	name?:true,
courses?: [{	filter?:ValueTypes["CourseWhere"] | null,	orderBy?:ValueTypes["CourseOrderBy"][],	offset?:number | null,	limit?:number | null},ValueTypes["Course"]],
site?: [{	filter?:ValueTypes["SiteWhere"] | null},ValueTypes["Site"]],
link?: [{	filter?:ValueTypes["LinkableWhere"] | null},ValueTypes["Linkable"]],
seo?: [{	filter?:ValueTypes["SeoWhere"] | null},ValueTypes["Seo"]],
image?: [{	filter?:ValueTypes["ImageWhere"] | null},ValueTypes["Image"]],
content?: [{	filter?:ValueTypes["ContentWhere"] | null},ValueTypes["Content"]],
coursesBySeo?: [{	by:ValueTypes["CourseLecturerCoursesBySeoUniqueWhere"],	filter?:ValueTypes["CourseWhere"] | null},ValueTypes["Course"]],
coursesByLink?: [{	by:ValueTypes["CourseLecturerCoursesByLinkUniqueWhere"],	filter?:ValueTypes["CourseWhere"] | null},ValueTypes["Course"]],
coursesByLessons?: [{	by:ValueTypes["CourseLecturerCoursesByLessonsUniqueWhere"],	filter?:ValueTypes["CourseWhere"] | null},ValueTypes["Course"]],
coursesByContent?: [{	by:ValueTypes["CourseLecturerCoursesByContentUniqueWhere"],	filter?:ValueTypes["CourseWhere"] | null},ValueTypes["Course"]],
paginateCourses?: [{	filter?:ValueTypes["CourseWhere"] | null,	orderBy?:ValueTypes["CourseOrderBy"][],	skip?:number | null,	first?:number | null},ValueTypes["CourseConnection"]],
		__typename?: true
}>;
	["CourseLecturerMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	name?:ValueTypes["FieldMeta"],
	courses?:ValueTypes["FieldMeta"],
	site?:ValueTypes["FieldMeta"],
	link?:ValueTypes["FieldMeta"],
	seo?:ValueTypes["FieldMeta"],
	image?:ValueTypes["FieldMeta"],
	content?:ValueTypes["FieldMeta"],
		__typename?: true
}>;
	["CourseLecturerCoursesBySeoUniqueWhere"]: {
	seo?:ValueTypes["SeoUniqueWhere"] | null
};
	["CourseLecturerCoursesByLinkUniqueWhere"]: {
	link?:ValueTypes["LinkableUniqueWhere"] | null
};
	["CourseLecturerCoursesByLessonsUniqueWhere"]: {
	lessons?:ValueTypes["CourseLessonUniqueWhere"] | null
};
	["CourseLecturerCoursesByContentUniqueWhere"]: {
	content?:ValueTypes["ContentUniqueWhere"] | null
};
	["CourseConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["CourseEdge"],
		__typename?: true
}>;
	["CourseEdge"]: AliasType<{
	node?:ValueTypes["Course"],
		__typename?: true
}>;
	["CourseLessonConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["CourseLessonEdge"],
		__typename?: true
}>;
	["CourseLessonEdge"]: AliasType<{
	node?:ValueTypes["CourseLesson"],
		__typename?: true
}>;
	["ArticleListPage"]: AliasType<{
	_meta?:ValueTypes["ArticleListPageMeta"],
	id?:true,
seo?: [{	filter?:ValueTypes["SeoWhere"] | null},ValueTypes["Seo"]],
link?: [{	filter?:ValueTypes["LinkableWhere"] | null},ValueTypes["Linkable"]],
	title?:true,
site?: [{	filter?:ValueTypes["SiteWhere"] | null},ValueTypes["Site"]],
		__typename?: true
}>;
	["ArticleListPageMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	seo?:ValueTypes["FieldMeta"],
	link?:ValueTypes["FieldMeta"],
	title?:ValueTypes["FieldMeta"],
	site?:ValueTypes["FieldMeta"],
		__typename?: true
}>;
	["CourseListPage"]: AliasType<{
	_meta?:ValueTypes["CourseListPageMeta"],
	id?:true,
seo?: [{	filter?:ValueTypes["SeoWhere"] | null},ValueTypes["Seo"]],
link?: [{	filter?:ValueTypes["LinkableWhere"] | null},ValueTypes["Linkable"]],
	title?:true,
site?: [{	filter?:ValueTypes["SiteWhere"] | null},ValueTypes["Site"]],
		__typename?: true
}>;
	["CourseListPageMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	seo?:ValueTypes["FieldMeta"],
	link?:ValueTypes["FieldMeta"],
	title?:ValueTypes["FieldMeta"],
	site?:ValueTypes["FieldMeta"],
		__typename?: true
}>;
	["PoseListPage"]: AliasType<{
	_meta?:ValueTypes["PoseListPageMeta"],
	id?:true,
link?: [{	filter?:ValueTypes["LinkableWhere"] | null},ValueTypes["Linkable"]],
seo?: [{	filter?:ValueTypes["SeoWhere"] | null},ValueTypes["Seo"]],
	title?:true,
site?: [{	filter?:ValueTypes["SiteWhere"] | null},ValueTypes["Site"]],
		__typename?: true
}>;
	["PoseListPageMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	link?:ValueTypes["FieldMeta"],
	seo?:ValueTypes["FieldMeta"],
	title?:ValueTypes["FieldMeta"],
	site?:ValueTypes["FieldMeta"],
		__typename?: true
}>;
	["CourseLecturerListPage"]: AliasType<{
	_meta?:ValueTypes["CourseLecturerListPageMeta"],
	id?:true,
	title?:true,
site?: [{	filter?:ValueTypes["SiteWhere"] | null},ValueTypes["Site"]],
seo?: [{	filter?:ValueTypes["SeoWhere"] | null},ValueTypes["Seo"]],
link?: [{	filter?:ValueTypes["LinkableWhere"] | null},ValueTypes["Linkable"]],
		__typename?: true
}>;
	["CourseLecturerListPageMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	title?:ValueTypes["FieldMeta"],
	site?:ValueTypes["FieldMeta"],
	seo?:ValueTypes["FieldMeta"],
	link?:ValueTypes["FieldMeta"],
		__typename?: true
}>;
	["SubscribeBox"]: AliasType<{
	_meta?:ValueTypes["SubscribeBoxMeta"],
	id?:true,
	title?:true,
	text?:true,
	shapeColorA?:true,
	shapeColorB?:true,
	shapeColorC?:true,
	externalMailingListId?:true,
site?: [{	filter?:ValueTypes["SiteWhere"] | null},ValueTypes["Site"]],
logo?: [{	filter?:ValueTypes["ImageWhere"] | null},ValueTypes["Image"]],
	emailLabel?:true,
	submitLabel?:true,
		__typename?: true
}>;
	["SubscribeBoxMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	title?:ValueTypes["FieldMeta"],
	text?:ValueTypes["FieldMeta"],
	shapeColorA?:ValueTypes["FieldMeta"],
	shapeColorB?:ValueTypes["FieldMeta"],
	shapeColorC?:ValueTypes["FieldMeta"],
	externalMailingListId?:ValueTypes["FieldMeta"],
	site?:ValueTypes["FieldMeta"],
	logo?:ValueTypes["FieldMeta"],
	emailLabel?:ValueTypes["FieldMeta"],
	submitLabel?:ValueTypes["FieldMeta"],
		__typename?: true
}>;
	["Header"]: AliasType<{
	_meta?:ValueTypes["HeaderMeta"],
	id?:true,
primaryNavigationItems?: [{	filter?:ValueTypes["HeaderPrimaryNavigationItemWhere"] | null,	orderBy?:ValueTypes["HeaderPrimaryNavigationItemOrderBy"][],	offset?:number | null,	limit?:number | null},ValueTypes["HeaderPrimaryNavigationItem"]],
site?: [{	filter?:ValueTypes["SiteWhere"] | null},ValueTypes["Site"]],
logo?: [{	filter?:ValueTypes["ImageWhere"] | null},ValueTypes["Image"]],
logoOnWhite?: [{	filter?:ValueTypes["ImageWhere"] | null},ValueTypes["Image"]],
primaryNavigationItemsByLink?: [{	by:ValueTypes["HeaderPrimaryNavigationItemsByLinkUniqueWhere"],	filter?:ValueTypes["HeaderPrimaryNavigationItemWhere"] | null},ValueTypes["HeaderPrimaryNavigationItem"]],
primaryNavigationItemsByListNavigation?: [{	by:ValueTypes["HeaderPrimaryNavigationItemsByListNavigationUniqueWhere"],	filter?:ValueTypes["HeaderPrimaryNavigationItemWhere"] | null},ValueTypes["HeaderPrimaryNavigationItem"]],
primaryNavigationItemsByTiles?: [{	by:ValueTypes["HeaderPrimaryNavigationItemsByTilesUniqueWhere"],	filter?:ValueTypes["HeaderPrimaryNavigationItemWhere"] | null},ValueTypes["HeaderPrimaryNavigationItem"]],
paginatePrimaryNavigationItems?: [{	filter?:ValueTypes["HeaderPrimaryNavigationItemWhere"] | null,	orderBy?:ValueTypes["HeaderPrimaryNavigationItemOrderBy"][],	skip?:number | null,	first?:number | null},ValueTypes["HeaderPrimaryNavigationItemConnection"]],
		__typename?: true
}>;
	["HeaderMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	primaryNavigationItems?:ValueTypes["FieldMeta"],
	site?:ValueTypes["FieldMeta"],
	logo?:ValueTypes["FieldMeta"],
	logoOnWhite?:ValueTypes["FieldMeta"],
		__typename?: true
}>;
	["HeaderPrimaryNavigationItem"]: AliasType<{
	_meta?:ValueTypes["HeaderPrimaryNavigationItemMeta"],
	id?:true,
	order?:true,
header?: [{	filter?:ValueTypes["HeaderWhere"] | null},ValueTypes["Header"]],
link?: [{	filter?:ValueTypes["LinkWhere"] | null},ValueTypes["Link"]],
listNavigation?: [{	filter?:ValueTypes["ListNavigationWhere"] | null},ValueTypes["ListNavigation"]],
tiles?: [{	filter?:ValueTypes["TileListWhere"] | null},ValueTypes["TileList"]],
		__typename?: true
}>;
	["HeaderPrimaryNavigationItemMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	order?:ValueTypes["FieldMeta"],
	header?:ValueTypes["FieldMeta"],
	link?:ValueTypes["FieldMeta"],
	listNavigation?:ValueTypes["FieldMeta"],
	tiles?:ValueTypes["FieldMeta"],
		__typename?: true
}>;
	["ListNavigation"]: AliasType<{
	_meta?:ValueTypes["ListNavigationMeta"],
	id?:true,
columns?: [{	filter?:ValueTypes["ListNavigationColumnWhere"] | null,	orderBy?:ValueTypes["ListNavigationColumnOrderBy"][],	offset?:number | null,	limit?:number | null},ValueTypes["ListNavigationColumn"]],
columnsByItems?: [{	by:ValueTypes["ListNavigationColumnsByItemsUniqueWhere"],	filter?:ValueTypes["ListNavigationColumnWhere"] | null},ValueTypes["ListNavigationColumn"]],
paginateColumns?: [{	filter?:ValueTypes["ListNavigationColumnWhere"] | null,	orderBy?:ValueTypes["ListNavigationColumnOrderBy"][],	skip?:number | null,	first?:number | null},ValueTypes["ListNavigationColumnConnection"]],
		__typename?: true
}>;
	["ListNavigationMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	columns?:ValueTypes["FieldMeta"],
		__typename?: true
}>;
	["ListNavigationColumn"]: AliasType<{
	_meta?:ValueTypes["ListNavigationColumnMeta"],
	id?:true,
	order?:true,
	label?:true,
listNavigation?: [{	filter?:ValueTypes["ListNavigationWhere"] | null},ValueTypes["ListNavigation"]],
items?: [{	filter?:ValueTypes["ListNavigationColumnItemWhere"] | null,	orderBy?:ValueTypes["ListNavigationColumnItemOrderBy"][],	offset?:number | null,	limit?:number | null},ValueTypes["ListNavigationColumnItem"]],
itemsByLink?: [{	by:ValueTypes["ListNavigationColumnItemsByLinkUniqueWhere"],	filter?:ValueTypes["ListNavigationColumnItemWhere"] | null},ValueTypes["ListNavigationColumnItem"]],
paginateItems?: [{	filter?:ValueTypes["ListNavigationColumnItemWhere"] | null,	orderBy?:ValueTypes["ListNavigationColumnItemOrderBy"][],	skip?:number | null,	first?:number | null},ValueTypes["ListNavigationColumnItemConnection"]],
		__typename?: true
}>;
	["ListNavigationColumnMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	order?:ValueTypes["FieldMeta"],
	label?:ValueTypes["FieldMeta"],
	listNavigation?:ValueTypes["FieldMeta"],
	items?:ValueTypes["FieldMeta"],
		__typename?: true
}>;
	["ListNavigationColumnItem"]: AliasType<{
	_meta?:ValueTypes["ListNavigationColumnItemMeta"],
	id?:true,
	order?:true,
column?: [{	filter?:ValueTypes["ListNavigationColumnWhere"] | null},ValueTypes["ListNavigationColumn"]],
link?: [{	filter?:ValueTypes["LinkWhere"] | null},ValueTypes["Link"]],
		__typename?: true
}>;
	["ListNavigationColumnItemMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	order?:ValueTypes["FieldMeta"],
	column?:ValueTypes["FieldMeta"],
	link?:ValueTypes["FieldMeta"],
		__typename?: true
}>;
	["ListNavigationColumnItemOrderBy"]: {
	_random?:boolean | null,
	_randomSeeded?:number | null,
	id?:ValueTypes["OrderDirection"] | null,
	order?:ValueTypes["OrderDirection"] | null,
	column?:ValueTypes["ListNavigationColumnOrderBy"] | null,
	link?:ValueTypes["LinkOrderBy"] | null
};
	["ListNavigationColumnOrderBy"]: {
	_random?:boolean | null,
	_randomSeeded?:number | null,
	id?:ValueTypes["OrderDirection"] | null,
	order?:ValueTypes["OrderDirection"] | null,
	label?:ValueTypes["OrderDirection"] | null,
	listNavigation?:ValueTypes["ListNavigationOrderBy"] | null
};
	["ListNavigationColumnItemsByLinkUniqueWhere"]: {
	link?:ValueTypes["LinkUniqueWhere"] | null
};
	["ListNavigationColumnItemConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["ListNavigationColumnItemEdge"],
		__typename?: true
}>;
	["ListNavigationColumnItemEdge"]: AliasType<{
	node?:ValueTypes["ListNavigationColumnItem"],
		__typename?: true
}>;
	["ListNavigationColumnsByItemsUniqueWhere"]: {
	items?:ValueTypes["ListNavigationColumnItemUniqueWhere"] | null
};
	["ListNavigationColumnConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["ListNavigationColumnEdge"],
		__typename?: true
}>;
	["ListNavigationColumnEdge"]: AliasType<{
	node?:ValueTypes["ListNavigationColumn"],
		__typename?: true
}>;
	["HeaderPrimaryNavigationItemOrderBy"]: {
	_random?:boolean | null,
	_randomSeeded?:number | null,
	id?:ValueTypes["OrderDirection"] | null,
	order?:ValueTypes["OrderDirection"] | null,
	header?:ValueTypes["HeaderOrderBy"] | null,
	link?:ValueTypes["LinkOrderBy"] | null,
	listNavigation?:ValueTypes["ListNavigationOrderBy"] | null,
	tiles?:ValueTypes["TileListOrderBy"] | null
};
	["HeaderPrimaryNavigationItemsByLinkUniqueWhere"]: {
	link?:ValueTypes["LinkUniqueWhere"] | null
};
	["HeaderPrimaryNavigationItemsByListNavigationUniqueWhere"]: {
	listNavigation?:ValueTypes["ListNavigationUniqueWhere"] | null
};
	["HeaderPrimaryNavigationItemsByTilesUniqueWhere"]: {
	tiles?:ValueTypes["TileListUniqueWhere"] | null
};
	["HeaderPrimaryNavigationItemConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["HeaderPrimaryNavigationItemEdge"],
		__typename?: true
}>;
	["HeaderPrimaryNavigationItemEdge"]: AliasType<{
	node?:ValueTypes["HeaderPrimaryNavigationItem"],
		__typename?: true
}>;
	["Footer"]: AliasType<{
	_meta?:ValueTypes["FooterMeta"],
	id?:true,
navigation?: [{	filter?:ValueTypes["ListNavigationWhere"] | null},ValueTypes["ListNavigation"]],
site?: [{	filter?:ValueTypes["SiteWhere"] | null},ValueTypes["Site"]],
logo?: [{	filter?:ValueTypes["ImageWhere"] | null},ValueTypes["Image"]],
	copyright?:true,
	attribution?:true,
		__typename?: true
}>;
	["FooterMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	navigation?:ValueTypes["FieldMeta"],
	site?:ValueTypes["FieldMeta"],
	logo?:ValueTypes["FieldMeta"],
	copyright?:ValueTypes["FieldMeta"],
	attribution?:ValueTypes["FieldMeta"],
		__typename?: true
}>;
	["GenericPage"]: AliasType<{
	_meta?:ValueTypes["GenericPageMeta"],
	id?:true,
	headline?:true,
	lead?:true,
	publishedAt?:true,
site?: [{	filter?:ValueTypes["SiteWhere"] | null},ValueTypes["Site"]],
content?: [{	filter?:ValueTypes["ContentWhere"] | null},ValueTypes["Content"]],
seo?: [{	filter?:ValueTypes["SeoWhere"] | null},ValueTypes["Seo"]],
link?: [{	filter?:ValueTypes["LinkableWhere"] | null},ValueTypes["Linkable"]],
		__typename?: true
}>;
	["GenericPageMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	headline?:ValueTypes["FieldMeta"],
	lead?:ValueTypes["FieldMeta"],
	publishedAt?:ValueTypes["FieldMeta"],
	site?:ValueTypes["FieldMeta"],
	content?:ValueTypes["FieldMeta"],
	seo?:ValueTypes["FieldMeta"],
	link?:ValueTypes["FieldMeta"],
		__typename?: true
}>;
	["CourseEmail"]: AliasType<{
	_meta?:ValueTypes["CourseEmailMeta"],
	id?:true,
	title?:true,
	text?:true,
	buttonLabel?:true,
logo?: [{	filter?:ValueTypes["ImageWhere"] | null},ValueTypes["Image"]],
cover?: [{	filter?:ValueTypes["ImageWhere"] | null},ValueTypes["Image"]],
site?: [{	filter?:ValueTypes["SiteWhere"] | null},ValueTypes["Site"]],
	contactInfoEmailAddress?:true,
	senderEmail?:true,
		__typename?: true
}>;
	["CourseEmailMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	title?:ValueTypes["FieldMeta"],
	text?:ValueTypes["FieldMeta"],
	buttonLabel?:ValueTypes["FieldMeta"],
	logo?:ValueTypes["FieldMeta"],
	cover?:ValueTypes["FieldMeta"],
	site?:ValueTypes["FieldMeta"],
	contactInfoEmailAddress?:ValueTypes["FieldMeta"],
	senderEmail?:ValueTypes["FieldMeta"],
		__typename?: true
}>;
	["Translations"]: AliasType<{
	_meta?:ValueTypes["TranslationsMeta"],
	id?:true,
	home?:true,
	articles?:true,
	article?:true,
	articleMore?:true,
	categories?:true,
	lecturers?:true,
	lecturer?:true,
	courses?:true,
	course?:true,
	poses?:true,
	pose?:true,
site?: [{	filter?:ValueTypes["SiteWhere"] | null},ValueTypes["Site"]],
	posesLong?:true,
	posesMore?:true,
	listAll?:true,
	difficulty?:true,
	skillLevel?:true,
	signIn?:true,
	error?:true,
		__typename?: true
}>;
	["TranslationsMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	home?:ValueTypes["FieldMeta"],
	articles?:ValueTypes["FieldMeta"],
	article?:ValueTypes["FieldMeta"],
	articleMore?:ValueTypes["FieldMeta"],
	categories?:ValueTypes["FieldMeta"],
	lecturers?:ValueTypes["FieldMeta"],
	lecturer?:ValueTypes["FieldMeta"],
	courses?:ValueTypes["FieldMeta"],
	course?:ValueTypes["FieldMeta"],
	poses?:ValueTypes["FieldMeta"],
	pose?:ValueTypes["FieldMeta"],
	site?:ValueTypes["FieldMeta"],
	posesLong?:ValueTypes["FieldMeta"],
	posesMore?:ValueTypes["FieldMeta"],
	listAll?:ValueTypes["FieldMeta"],
	difficulty?:ValueTypes["FieldMeta"],
	skillLevel?:ValueTypes["FieldMeta"],
	signIn?:ValueTypes["FieldMeta"],
	error?:ValueTypes["FieldMeta"],
		__typename?: true
}>;
	["SiteArticlesBySeoUniqueWhere"]: {
	seo?:ValueTypes["SeoUniqueWhere"] | null
};
	["SiteArticlesByLinkUniqueWhere"]: {
	link?:ValueTypes["LinkableUniqueWhere"] | null
};
	["SiteArticlesByContentUniqueWhere"]: {
	content?:ValueTypes["ContentUniqueWhere"] | null
};
	["SiteArticleCategoriesByArticlesUniqueWhere"]: {
	articles?:ValueTypes["ArticleUniqueWhere"] | null
};
	["SiteArticleCategoriesBySeoUniqueWhere"]: {
	seo?:ValueTypes["SeoUniqueWhere"] | null
};
	["SiteArticleCategoriesByLinkUniqueWhere"]: {
	link?:ValueTypes["LinkableUniqueWhere"] | null
};
	["SitePosesByLinkUniqueWhere"]: {
	link?:ValueTypes["LinkableUniqueWhere"] | null
};
	["SitePosesBySeoUniqueWhere"]: {
	seo?:ValueTypes["SeoUniqueWhere"] | null
};
	["SitePosesByContentUniqueWhere"]: {
	content?:ValueTypes["ContentUniqueWhere"] | null
};
	["SitePoseCategoriesByPosesUniqueWhere"]: {
	poses?:ValueTypes["PoseUniqueWhere"] | null
};
	["SitePoseDifficultiesByPosesUniqueWhere"]: {
	poses?:ValueTypes["PoseUniqueWhere"] | null
};
	["SiteCoursesBySeoUniqueWhere"]: {
	seo?:ValueTypes["SeoUniqueWhere"] | null
};
	["SiteCoursesByLinkUniqueWhere"]: {
	link?:ValueTypes["LinkableUniqueWhere"] | null
};
	["SiteCoursesByLessonsUniqueWhere"]: {
	lessons?:ValueTypes["CourseLessonUniqueWhere"] | null
};
	["SiteCoursesByContentUniqueWhere"]: {
	content?:ValueTypes["ContentUniqueWhere"] | null
};
	["SiteCourseLecturersByCoursesUniqueWhere"]: {
	courses?:ValueTypes["CourseUniqueWhere"] | null
};
	["SiteCourseLecturersByLinkUniqueWhere"]: {
	link?:ValueTypes["LinkableUniqueWhere"] | null
};
	["SiteCourseLecturersBySeoUniqueWhere"]: {
	seo?:ValueTypes["SeoUniqueWhere"] | null
};
	["SiteCourseLecturersByContentUniqueWhere"]: {
	content?:ValueTypes["ContentUniqueWhere"] | null
};
	["SiteGenericPagesByContentUniqueWhere"]: {
	content?:ValueTypes["ContentUniqueWhere"] | null
};
	["SiteGenericPagesBySeoUniqueWhere"]: {
	seo?:ValueTypes["SeoUniqueWhere"] | null
};
	["SiteGenericPagesByLinkUniqueWhere"]: {
	link?:ValueTypes["LinkableUniqueWhere"] | null
};
	["ArticleCategoryConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["ArticleCategoryEdge"],
		__typename?: true
}>;
	["ArticleCategoryEdge"]: AliasType<{
	node?:ValueTypes["ArticleCategory"],
		__typename?: true
}>;
	["PoseCategoryConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["PoseCategoryEdge"],
		__typename?: true
}>;
	["PoseCategoryEdge"]: AliasType<{
	node?:ValueTypes["PoseCategory"],
		__typename?: true
}>;
	["PoseDifficultyConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["PoseDifficultyEdge"],
		__typename?: true
}>;
	["PoseDifficultyEdge"]: AliasType<{
	node?:ValueTypes["PoseDifficulty"],
		__typename?: true
}>;
	["CourseLecturerConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["CourseLecturerEdge"],
		__typename?: true
}>;
	["CourseLecturerEdge"]: AliasType<{
	node?:ValueTypes["CourseLecturer"],
		__typename?: true
}>;
	["GenericPageConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["GenericPageEdge"],
		__typename?: true
}>;
	["GenericPageEdge"]: AliasType<{
	node?:ValueTypes["GenericPage"],
		__typename?: true
}>;
	["NewsletterSubscribeSuccessPage"]: AliasType<{
	_meta?:ValueTypes["NewsletterSubscribeSuccessPageMeta"],
	id?:true,
	title?:true,
	returnLinkText?:true,
link?: [{	filter?:ValueTypes["LinkableWhere"] | null},ValueTypes["Linkable"]],
site?: [{	filter?:ValueTypes["SiteWhere"] | null},ValueTypes["Site"]],
seo?: [{	filter?:ValueTypes["SeoWhere"] | null},ValueTypes["Seo"]],
		__typename?: true
}>;
	["NewsletterSubscribeSuccessPageMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	title?:ValueTypes["FieldMeta"],
	returnLinkText?:ValueTypes["FieldMeta"],
	link?:ValueTypes["FieldMeta"],
	site?:ValueTypes["FieldMeta"],
	seo?:ValueTypes["FieldMeta"],
		__typename?: true
}>;
	["LinkConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["LinkEdge"],
		__typename?: true
}>;
	["LinkEdge"]: AliasType<{
	node?:ValueTypes["Link"],
		__typename?: true
}>;
	["LinkableConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["LinkableEdge"],
		__typename?: true
}>;
	["LinkableEdge"]: AliasType<{
	node?:ValueTypes["Linkable"],
		__typename?: true
}>;
	["RedirectConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["RedirectEdge"],
		__typename?: true
}>;
	["RedirectEdge"]: AliasType<{
	node?:ValueTypes["Redirect"],
		__typename?: true
}>;
	["FooterConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["FooterEdge"],
		__typename?: true
}>;
	["FooterEdge"]: AliasType<{
	node?:ValueTypes["Footer"],
		__typename?: true
}>;
	["ImageConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["ImageEdge"],
		__typename?: true
}>;
	["ImageEdge"]: AliasType<{
	node?:ValueTypes["Image"],
		__typename?: true
}>;
	["SeoConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["SeoEdge"],
		__typename?: true
}>;
	["SeoEdge"]: AliasType<{
	node?:ValueTypes["Seo"],
		__typename?: true
}>;
	["HomepageConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["HomepageEdge"],
		__typename?: true
}>;
	["HomepageEdge"]: AliasType<{
	node?:ValueTypes["Homepage"],
		__typename?: true
}>;
	["HeaderConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["HeaderEdge"],
		__typename?: true
}>;
	["HeaderEdge"]: AliasType<{
	node?:ValueTypes["Header"],
		__typename?: true
}>;
	["ListNavigationConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["ListNavigationEdge"],
		__typename?: true
}>;
	["ListNavigationEdge"]: AliasType<{
	node?:ValueTypes["ListNavigation"],
		__typename?: true
}>;
	["SiteConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["SiteEdge"],
		__typename?: true
}>;
	["SiteEdge"]: AliasType<{
	node?:ValueTypes["Site"],
		__typename?: true
}>;
	["ArticleListPageConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["ArticleListPageEdge"],
		__typename?: true
}>;
	["ArticleListPageEdge"]: AliasType<{
	node?:ValueTypes["ArticleListPage"],
		__typename?: true
}>;
	["CourseListPageConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["CourseListPageEdge"],
		__typename?: true
}>;
	["CourseListPageEdge"]: AliasType<{
	node?:ValueTypes["CourseListPage"],
		__typename?: true
}>;
	["PoseListPageConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["PoseListPageEdge"],
		__typename?: true
}>;
	["PoseListPageEdge"]: AliasType<{
	node?:ValueTypes["PoseListPage"],
		__typename?: true
}>;
	["ContentConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["ContentEdge"],
		__typename?: true
}>;
	["ContentEdge"]: AliasType<{
	node?:ValueTypes["Content"],
		__typename?: true
}>;
	["TileConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["TileEdge"],
		__typename?: true
}>;
	["TileEdge"]: AliasType<{
	node?:ValueTypes["Tile"],
		__typename?: true
}>;
	["TileListConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["TileListEdge"],
		__typename?: true
}>;
	["TileListEdge"]: AliasType<{
	node?:ValueTypes["TileList"],
		__typename?: true
}>;
	["YoutubeVideoUniqueWhere"]: {
	id?:ValueTypes["UUID"] | null
};
	["YoutubeVideoConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["YoutubeVideoEdge"],
		__typename?: true
}>;
	["YoutubeVideoEdge"]: AliasType<{
	node?:ValueTypes["YoutubeVideo"],
		__typename?: true
}>;
	["SeoDefault"]: AliasType<{
	_meta?:ValueTypes["SeoDefaultMeta"],
	id?:true,
	unique?:true,
seo?: [{	filter?:ValueTypes["SeoWhere"] | null},ValueTypes["Seo"]],
		__typename?: true
}>;
	["SeoDefaultMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	unique?:ValueTypes["FieldMeta"],
	seo?:ValueTypes["FieldMeta"],
		__typename?: true
}>;
	["One"]:One;
	["SeoDefaultUniqueWhere"]: {
	id?:ValueTypes["UUID"] | null,
	unique?:ValueTypes["One"] | null,
	seo?:ValueTypes["SeoUniqueWhere"] | null
};
	["SeoDefaultWhere"]: {
	id?:ValueTypes["UUIDCondition"] | null,
	unique?:ValueTypes["OneCondition"] | null,
	seo?:ValueTypes["SeoWhere"] | null,
	and?:(ValueTypes["SeoDefaultWhere"] | undefined | null)[],
	or?:(ValueTypes["SeoDefaultWhere"] | undefined | null)[],
	not?:ValueTypes["SeoDefaultWhere"] | null
};
	["OneCondition"]: {
	and?:ValueTypes["OneCondition"][],
	or?:ValueTypes["OneCondition"][],
	not?:ValueTypes["OneCondition"] | null,
	null?:boolean | null,
	isNull?:boolean | null,
	eq?:ValueTypes["One"] | null,
	notEq?:ValueTypes["One"] | null,
	in?:ValueTypes["One"][],
	notIn?:ValueTypes["One"][],
	lt?:ValueTypes["One"] | null,
	lte?:ValueTypes["One"] | null,
	gt?:ValueTypes["One"] | null,
	gte?:ValueTypes["One"] | null
};
	["SeoDefaultOrderBy"]: {
	_random?:boolean | null,
	_randomSeeded?:number | null,
	id?:ValueTypes["OrderDirection"] | null,
	unique?:ValueTypes["OrderDirection"] | null,
	seo?:ValueTypes["SeoOrderBy"] | null
};
	["SeoDefaultConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["SeoDefaultEdge"],
		__typename?: true
}>;
	["SeoDefaultEdge"]: AliasType<{
	node?:ValueTypes["SeoDefault"],
		__typename?: true
}>;
	["CourseLecturerListPageConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["CourseLecturerListPageEdge"],
		__typename?: true
}>;
	["CourseLecturerListPageEdge"]: AliasType<{
	node?:ValueTypes["CourseLecturerListPage"],
		__typename?: true
}>;
	["NewsletterSubscription"]: AliasType<{
	_meta?:ValueTypes["NewsletterSubscriptionMeta"],
	id?:true,
	startDate?:true,
visitor?: [{	filter?:ValueTypes["VisitorWhere"] | null},ValueTypes["Visitor"]],
site?: [{	filter?:ValueTypes["SiteWhere"] | null},ValueTypes["Site"]],
		__typename?: true
}>;
	["NewsletterSubscriptionMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	startDate?:ValueTypes["FieldMeta"],
	visitor?:ValueTypes["FieldMeta"],
	site?:ValueTypes["FieldMeta"],
		__typename?: true
}>;
	["Visitor"]: AliasType<{
	_meta?:ValueTypes["VisitorMeta"],
	id?:true,
	email?:true,
newsletterSubscriptions?: [{	filter?:ValueTypes["NewsletterSubscriptionWhere"] | null,	orderBy?:ValueTypes["NewsletterSubscriptionOrderBy"][],	offset?:number | null,	limit?:number | null},ValueTypes["NewsletterSubscription"]],
courseSubscriptions?: [{	filter?:ValueTypes["CourseSubscriptionWhere"] | null,	orderBy?:ValueTypes["CourseSubscriptionOrderBy"][],	offset?:number | null,	limit?:number | null},ValueTypes["CourseSubscription"]],
newsletterSubscriptionsBySite?: [{	by:ValueTypes["VisitorNewsletterSubscriptionsBySiteUniqueWhere"],	filter?:ValueTypes["NewsletterSubscriptionWhere"] | null},ValueTypes["NewsletterSubscription"]],
courseSubscriptionsByCourse?: [{	by:ValueTypes["VisitorCourseSubscriptionsByCourseUniqueWhere"],	filter?:ValueTypes["CourseSubscriptionWhere"] | null},ValueTypes["CourseSubscription"]],
paginateNewsletterSubscriptions?: [{	filter?:ValueTypes["NewsletterSubscriptionWhere"] | null,	orderBy?:ValueTypes["NewsletterSubscriptionOrderBy"][],	skip?:number | null,	first?:number | null},ValueTypes["NewsletterSubscriptionConnection"]],
paginateCourseSubscriptions?: [{	filter?:ValueTypes["CourseSubscriptionWhere"] | null,	orderBy?:ValueTypes["CourseSubscriptionOrderBy"][],	skip?:number | null,	first?:number | null},ValueTypes["CourseSubscriptionConnection"]],
		__typename?: true
}>;
	["VisitorMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	email?:ValueTypes["FieldMeta"],
	newsletterSubscriptions?:ValueTypes["FieldMeta"],
	courseSubscriptions?:ValueTypes["FieldMeta"],
		__typename?: true
}>;
	["NewsletterSubscriptionWhere"]: {
	id?:ValueTypes["UUIDCondition"] | null,
	startDate?:ValueTypes["DateCondition"] | null,
	visitor?:ValueTypes["VisitorWhere"] | null,
	site?:ValueTypes["SiteWhere"] | null,
	and?:(ValueTypes["NewsletterSubscriptionWhere"] | undefined | null)[],
	or?:(ValueTypes["NewsletterSubscriptionWhere"] | undefined | null)[],
	not?:ValueTypes["NewsletterSubscriptionWhere"] | null
};
	["VisitorWhere"]: {
	id?:ValueTypes["UUIDCondition"] | null,
	email?:ValueTypes["StringCondition"] | null,
	newsletterSubscriptions?:ValueTypes["NewsletterSubscriptionWhere"] | null,
	courseSubscriptions?:ValueTypes["CourseSubscriptionWhere"] | null,
	and?:(ValueTypes["VisitorWhere"] | undefined | null)[],
	or?:(ValueTypes["VisitorWhere"] | undefined | null)[],
	not?:ValueTypes["VisitorWhere"] | null
};
	["CourseSubscriptionWhere"]: {
	id?:ValueTypes["UUIDCondition"] | null,
	startDate?:ValueTypes["DateCondition"] | null,
	visitor?:ValueTypes["VisitorWhere"] | null,
	course?:ValueTypes["CourseWhere"] | null,
	and?:(ValueTypes["CourseSubscriptionWhere"] | undefined | null)[],
	or?:(ValueTypes["CourseSubscriptionWhere"] | undefined | null)[],
	not?:ValueTypes["CourseSubscriptionWhere"] | null
};
	["NewsletterSubscriptionOrderBy"]: {
	_random?:boolean | null,
	_randomSeeded?:number | null,
	id?:ValueTypes["OrderDirection"] | null,
	startDate?:ValueTypes["OrderDirection"] | null,
	visitor?:ValueTypes["VisitorOrderBy"] | null,
	site?:ValueTypes["SiteOrderBy"] | null
};
	["VisitorOrderBy"]: {
	_random?:boolean | null,
	_randomSeeded?:number | null,
	id?:ValueTypes["OrderDirection"] | null,
	email?:ValueTypes["OrderDirection"] | null
};
	["CourseSubscription"]: AliasType<{
	_meta?:ValueTypes["CourseSubscriptionMeta"],
	id?:true,
	startDate?:true,
visitor?: [{	filter?:ValueTypes["VisitorWhere"] | null},ValueTypes["Visitor"]],
course?: [{	filter?:ValueTypes["CourseWhere"] | null},ValueTypes["Course"]],
		__typename?: true
}>;
	["CourseSubscriptionMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	startDate?:ValueTypes["FieldMeta"],
	visitor?:ValueTypes["FieldMeta"],
	course?:ValueTypes["FieldMeta"],
		__typename?: true
}>;
	["CourseSubscriptionOrderBy"]: {
	_random?:boolean | null,
	_randomSeeded?:number | null,
	id?:ValueTypes["OrderDirection"] | null,
	startDate?:ValueTypes["OrderDirection"] | null,
	visitor?:ValueTypes["VisitorOrderBy"] | null,
	course?:ValueTypes["CourseOrderBy"] | null
};
	["VisitorNewsletterSubscriptionsBySiteUniqueWhere"]: {
	site?:ValueTypes["SiteUniqueWhere"] | null
};
	["VisitorCourseSubscriptionsByCourseUniqueWhere"]: {
	course?:ValueTypes["CourseUniqueWhere"] | null
};
	["NewsletterSubscriptionConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["NewsletterSubscriptionEdge"],
		__typename?: true
}>;
	["NewsletterSubscriptionEdge"]: AliasType<{
	node?:ValueTypes["NewsletterSubscription"],
		__typename?: true
}>;
	["CourseSubscriptionConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["CourseSubscriptionEdge"],
		__typename?: true
}>;
	["CourseSubscriptionEdge"]: AliasType<{
	node?:ValueTypes["CourseSubscription"],
		__typename?: true
}>;
	["NewsletterSubscriptionUniqueWhere"]: {
	id?:ValueTypes["UUID"] | null,
	visitor?:ValueTypes["VisitorUniqueWhere"] | null,
	site?:ValueTypes["SiteUniqueWhere"] | null
};
	["VisitorUniqueWhere"]: {
	id?:ValueTypes["UUID"] | null,
	email?:string | null,
	newsletterSubscriptions?:ValueTypes["NewsletterSubscriptionUniqueWhere"] | null,
	courseSubscriptions?:ValueTypes["CourseSubscriptionUniqueWhere"] | null
};
	["CourseSubscriptionUniqueWhere"]: {
	id?:ValueTypes["UUID"] | null,
	visitor?:ValueTypes["VisitorUniqueWhere"] | null,
	course?:ValueTypes["CourseUniqueWhere"] | null
};
	["_ValidationResult"]: AliasType<{
	valid?:true,
	errors?:ValueTypes["_ValidationError"],
		__typename?: true
}>;
	["_ValidationError"]: AliasType<{
	path?:ValueTypes["_PathFragment"],
	message?:ValueTypes["_ValidationMessage"],
		__typename?: true
}>;
	["_PathFragment"]: AliasType<{		["...on _FieldPathFragment"] : ValueTypes["_FieldPathFragment"],
		["...on _IndexPathFragment"] : ValueTypes["_IndexPathFragment"]
		__typename?: true
}>;
	["_FieldPathFragment"]: AliasType<{
	field?:true,
		__typename?: true
}>;
	["_IndexPathFragment"]: AliasType<{
	index?:true,
	alias?:true,
		__typename?: true
}>;
	["_ValidationMessage"]: AliasType<{
	text?:true,
		__typename?: true
}>;
	["NewsletterSubscriptionCreateInput"]: {
	startDate?:ValueTypes["Date"] | null,
	visitor?:ValueTypes["NewsletterSubscriptionCreateVisitorEntityRelationInput"] | null,
	site?:ValueTypes["NewsletterSubscriptionCreateSiteEntityRelationInput"] | null,
	_dummy_field_?:boolean | null
};
	["NewsletterSubscriptionCreateVisitorEntityRelationInput"]: {
	connect?:ValueTypes["VisitorUniqueWhere"] | null,
	create?:ValueTypes["VisitorWithoutNewsletterSubscriptionsCreateInput"] | null
};
	["VisitorWithoutNewsletterSubscriptionsCreateInput"]: {
	email?:string | null,
	courseSubscriptions?:ValueTypes["VisitorCreateCourseSubscriptionsEntityRelationInput"][],
	_dummy_field_?:boolean | null
};
	["VisitorCreateCourseSubscriptionsEntityRelationInput"]: {
	connect?:ValueTypes["CourseSubscriptionUniqueWhere"] | null,
	create?:ValueTypes["CourseSubscriptionWithoutVisitorCreateInput"] | null,
	alias?:string | null
};
	["CourseSubscriptionWithoutVisitorCreateInput"]: {
	startDate?:ValueTypes["Date"] | null,
	course?:ValueTypes["CourseSubscriptionCreateCourseEntityRelationInput"] | null,
	_dummy_field_?:boolean | null
};
	["CourseSubscriptionCreateCourseEntityRelationInput"]: {
	connect?:ValueTypes["CourseUniqueWhere"] | null
};
	["NewsletterSubscriptionCreateSiteEntityRelationInput"]: {
	connect?:ValueTypes["SiteUniqueWhere"] | null
};
	["VisitorConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["VisitorEdge"],
		__typename?: true
}>;
	["VisitorEdge"]: AliasType<{
	node?:ValueTypes["Visitor"],
		__typename?: true
}>;
	["VisitorCreateInput"]: {
	email?:string | null,
	newsletterSubscriptions?:ValueTypes["VisitorCreateNewsletterSubscriptionsEntityRelationInput"][],
	courseSubscriptions?:ValueTypes["VisitorCreateCourseSubscriptionsEntityRelationInput"][],
	_dummy_field_?:boolean | null
};
	["VisitorCreateNewsletterSubscriptionsEntityRelationInput"]: {
	connect?:ValueTypes["NewsletterSubscriptionUniqueWhere"] | null,
	create?:ValueTypes["NewsletterSubscriptionWithoutVisitorCreateInput"] | null,
	alias?:string | null
};
	["NewsletterSubscriptionWithoutVisitorCreateInput"]: {
	startDate?:ValueTypes["Date"] | null,
	site?:ValueTypes["NewsletterSubscriptionCreateSiteEntityRelationInput"] | null,
	_dummy_field_?:boolean | null
};
	["CourseSubscriptionCreateInput"]: {
	startDate?:ValueTypes["Date"] | null,
	visitor?:ValueTypes["CourseSubscriptionCreateVisitorEntityRelationInput"] | null,
	course?:ValueTypes["CourseSubscriptionCreateCourseEntityRelationInput"] | null,
	_dummy_field_?:boolean | null
};
	["CourseSubscriptionCreateVisitorEntityRelationInput"]: {
	connect?:ValueTypes["VisitorUniqueWhere"] | null,
	create?:ValueTypes["VisitorWithoutCourseSubscriptionsCreateInput"] | null
};
	["VisitorWithoutCourseSubscriptionsCreateInput"]: {
	email?:string | null,
	newsletterSubscriptions?:ValueTypes["VisitorCreateNewsletterSubscriptionsEntityRelationInput"][],
	_dummy_field_?:boolean | null
};
	["SubscribeBoxConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["SubscribeBoxEdge"],
		__typename?: true
}>;
	["SubscribeBoxEdge"]: AliasType<{
	node?:ValueTypes["SubscribeBox"],
		__typename?: true
}>;
	["NewsletterSubscribeSuccessPageConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["NewsletterSubscribeSuccessPageEdge"],
		__typename?: true
}>;
	["NewsletterSubscribeSuccessPageEdge"]: AliasType<{
	node?:ValueTypes["NewsletterSubscribeSuccessPage"],
		__typename?: true
}>;
	["PersonUniqueWhere"]: {
	id?:ValueTypes["UUID"] | null,
	image?:ValueTypes["ImageUniqueWhere"] | null
};
	["PersonConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["PersonEdge"],
		__typename?: true
}>;
	["PersonEdge"]: AliasType<{
	node?:ValueTypes["Person"],
		__typename?: true
}>;
	["PersonListUniqueWhere"]: {
	id?:ValueTypes["UUID"] | null,
	items?:ValueTypes["PersonListItemUniqueWhere"] | null
};
	["PersonListItemUniqueWhere"]: {
	id?:ValueTypes["UUID"] | null
};
	["PersonListConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["PersonListEdge"],
		__typename?: true
}>;
	["PersonListEdge"]: AliasType<{
	node?:ValueTypes["PersonList"],
		__typename?: true
}>;
	["CourseEmailConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["CourseEmailEdge"],
		__typename?: true
}>;
	["CourseEmailEdge"]: AliasType<{
	node?:ValueTypes["CourseEmail"],
		__typename?: true
}>;
	["TranslationsConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["TranslationsEdge"],
		__typename?: true
}>;
	["TranslationsEdge"]: AliasType<{
	node?:ValueTypes["Translations"],
		__typename?: true
}>;
	["QueryTransaction"]: AliasType<{
getLink?: [{	by:ValueTypes["LinkUniqueWhere"],	filter?:ValueTypes["LinkWhere"] | null},ValueTypes["Link"]],
listLink?: [{	filter?:ValueTypes["LinkWhere"] | null,	orderBy?:ValueTypes["LinkOrderBy"][],	offset?:number | null,	limit?:number | null},ValueTypes["Link"]],
paginateLink?: [{	filter?:ValueTypes["LinkWhere"] | null,	orderBy?:ValueTypes["LinkOrderBy"][],	skip?:number | null,	first?:number | null},ValueTypes["LinkConnection"]],
getLinkable?: [{	by:ValueTypes["LinkableUniqueWhere"],	filter?:ValueTypes["LinkableWhere"] | null},ValueTypes["Linkable"]],
listLinkable?: [{	filter?:ValueTypes["LinkableWhere"] | null,	orderBy?:ValueTypes["LinkableOrderBy"][],	offset?:number | null,	limit?:number | null},ValueTypes["Linkable"]],
paginateLinkable?: [{	filter?:ValueTypes["LinkableWhere"] | null,	orderBy?:ValueTypes["LinkableOrderBy"][],	skip?:number | null,	first?:number | null},ValueTypes["LinkableConnection"]],
getRedirect?: [{	by:ValueTypes["RedirectUniqueWhere"],	filter?:ValueTypes["RedirectWhere"] | null},ValueTypes["Redirect"]],
listRedirect?: [{	filter?:ValueTypes["RedirectWhere"] | null,	orderBy?:ValueTypes["RedirectOrderBy"][],	offset?:number | null,	limit?:number | null},ValueTypes["Redirect"]],
paginateRedirect?: [{	filter?:ValueTypes["RedirectWhere"] | null,	orderBy?:ValueTypes["RedirectOrderBy"][],	skip?:number | null,	first?:number | null},ValueTypes["RedirectConnection"]],
getFooter?: [{	by:ValueTypes["FooterUniqueWhere"],	filter?:ValueTypes["FooterWhere"] | null},ValueTypes["Footer"]],
listFooter?: [{	filter?:ValueTypes["FooterWhere"] | null,	orderBy?:ValueTypes["FooterOrderBy"][],	offset?:number | null,	limit?:number | null},ValueTypes["Footer"]],
paginateFooter?: [{	filter?:ValueTypes["FooterWhere"] | null,	orderBy?:ValueTypes["FooterOrderBy"][],	skip?:number | null,	first?:number | null},ValueTypes["FooterConnection"]],
getImage?: [{	by:ValueTypes["ImageUniqueWhere"],	filter?:ValueTypes["ImageWhere"] | null},ValueTypes["Image"]],
listImage?: [{	filter?:ValueTypes["ImageWhere"] | null,	orderBy?:ValueTypes["ImageOrderBy"][],	offset?:number | null,	limit?:number | null},ValueTypes["Image"]],
paginateImage?: [{	filter?:ValueTypes["ImageWhere"] | null,	orderBy?:ValueTypes["ImageOrderBy"][],	skip?:number | null,	first?:number | null},ValueTypes["ImageConnection"]],
getSeo?: [{	by:ValueTypes["SeoUniqueWhere"],	filter?:ValueTypes["SeoWhere"] | null},ValueTypes["Seo"]],
listSeo?: [{	filter?:ValueTypes["SeoWhere"] | null,	orderBy?:ValueTypes["SeoOrderBy"][],	offset?:number | null,	limit?:number | null},ValueTypes["Seo"]],
paginateSeo?: [{	filter?:ValueTypes["SeoWhere"] | null,	orderBy?:ValueTypes["SeoOrderBy"][],	skip?:number | null,	first?:number | null},ValueTypes["SeoConnection"]],
getHomepage?: [{	by:ValueTypes["HomepageUniqueWhere"],	filter?:ValueTypes["HomepageWhere"] | null},ValueTypes["Homepage"]],
listHomepage?: [{	filter?:ValueTypes["HomepageWhere"] | null,	orderBy?:ValueTypes["HomepageOrderBy"][],	offset?:number | null,	limit?:number | null},ValueTypes["Homepage"]],
paginateHomepage?: [{	filter?:ValueTypes["HomepageWhere"] | null,	orderBy?:ValueTypes["HomepageOrderBy"][],	skip?:number | null,	first?:number | null},ValueTypes["HomepageConnection"]],
getHeader?: [{	by:ValueTypes["HeaderUniqueWhere"],	filter?:ValueTypes["HeaderWhere"] | null},ValueTypes["Header"]],
listHeader?: [{	filter?:ValueTypes["HeaderWhere"] | null,	orderBy?:ValueTypes["HeaderOrderBy"][],	offset?:number | null,	limit?:number | null},ValueTypes["Header"]],
paginateHeader?: [{	filter?:ValueTypes["HeaderWhere"] | null,	orderBy?:ValueTypes["HeaderOrderBy"][],	skip?:number | null,	first?:number | null},ValueTypes["HeaderConnection"]],
getHeaderPrimaryNavigationItem?: [{	by:ValueTypes["HeaderPrimaryNavigationItemUniqueWhere"],	filter?:ValueTypes["HeaderPrimaryNavigationItemWhere"] | null},ValueTypes["HeaderPrimaryNavigationItem"]],
listHeaderPrimaryNavigationItem?: [{	filter?:ValueTypes["HeaderPrimaryNavigationItemWhere"] | null,	orderBy?:ValueTypes["HeaderPrimaryNavigationItemOrderBy"][],	offset?:number | null,	limit?:number | null},ValueTypes["HeaderPrimaryNavigationItem"]],
paginateHeaderPrimaryNavigationItem?: [{	filter?:ValueTypes["HeaderPrimaryNavigationItemWhere"] | null,	orderBy?:ValueTypes["HeaderPrimaryNavigationItemOrderBy"][],	skip?:number | null,	first?:number | null},ValueTypes["HeaderPrimaryNavigationItemConnection"]],
getListNavigation?: [{	by:ValueTypes["ListNavigationUniqueWhere"],	filter?:ValueTypes["ListNavigationWhere"] | null},ValueTypes["ListNavigation"]],
listListNavigation?: [{	filter?:ValueTypes["ListNavigationWhere"] | null,	orderBy?:ValueTypes["ListNavigationOrderBy"][],	offset?:number | null,	limit?:number | null},ValueTypes["ListNavigation"]],
paginateListNavigation?: [{	filter?:ValueTypes["ListNavigationWhere"] | null,	orderBy?:ValueTypes["ListNavigationOrderBy"][],	skip?:number | null,	first?:number | null},ValueTypes["ListNavigationConnection"]],
getListNavigationColumn?: [{	by:ValueTypes["ListNavigationColumnUniqueWhere"],	filter?:ValueTypes["ListNavigationColumnWhere"] | null},ValueTypes["ListNavigationColumn"]],
listListNavigationColumn?: [{	filter?:ValueTypes["ListNavigationColumnWhere"] | null,	orderBy?:ValueTypes["ListNavigationColumnOrderBy"][],	offset?:number | null,	limit?:number | null},ValueTypes["ListNavigationColumn"]],
paginateListNavigationColumn?: [{	filter?:ValueTypes["ListNavigationColumnWhere"] | null,	orderBy?:ValueTypes["ListNavigationColumnOrderBy"][],	skip?:number | null,	first?:number | null},ValueTypes["ListNavigationColumnConnection"]],
getListNavigationColumnItem?: [{	by:ValueTypes["ListNavigationColumnItemUniqueWhere"],	filter?:ValueTypes["ListNavigationColumnItemWhere"] | null},ValueTypes["ListNavigationColumnItem"]],
listListNavigationColumnItem?: [{	filter?:ValueTypes["ListNavigationColumnItemWhere"] | null,	orderBy?:ValueTypes["ListNavigationColumnItemOrderBy"][],	offset?:number | null,	limit?:number | null},ValueTypes["ListNavigationColumnItem"]],
paginateListNavigationColumnItem?: [{	filter?:ValueTypes["ListNavigationColumnItemWhere"] | null,	orderBy?:ValueTypes["ListNavigationColumnItemOrderBy"][],	skip?:number | null,	first?:number | null},ValueTypes["ListNavigationColumnItemConnection"]],
getArticle?: [{	by:ValueTypes["ArticleUniqueWhere"],	filter?:ValueTypes["ArticleWhere"] | null},ValueTypes["Article"]],
listArticle?: [{	filter?:ValueTypes["ArticleWhere"] | null,	orderBy?:ValueTypes["ArticleOrderBy"][],	offset?:number | null,	limit?:number | null},ValueTypes["Article"]],
paginateArticle?: [{	filter?:ValueTypes["ArticleWhere"] | null,	orderBy?:ValueTypes["ArticleOrderBy"][],	skip?:number | null,	first?:number | null},ValueTypes["ArticleConnection"]],
getSite?: [{	by:ValueTypes["SiteUniqueWhere"],	filter?:ValueTypes["SiteWhere"] | null},ValueTypes["Site"]],
listSite?: [{	filter?:ValueTypes["SiteWhere"] | null,	orderBy?:ValueTypes["SiteOrderBy"][],	offset?:number | null,	limit?:number | null},ValueTypes["Site"]],
paginateSite?: [{	filter?:ValueTypes["SiteWhere"] | null,	orderBy?:ValueTypes["SiteOrderBy"][],	skip?:number | null,	first?:number | null},ValueTypes["SiteConnection"]],
getArticleCategory?: [{	by:ValueTypes["ArticleCategoryUniqueWhere"],	filter?:ValueTypes["ArticleCategoryWhere"] | null},ValueTypes["ArticleCategory"]],
listArticleCategory?: [{	filter?:ValueTypes["ArticleCategoryWhere"] | null,	orderBy?:ValueTypes["ArticleCategoryOrderBy"][],	offset?:number | null,	limit?:number | null},ValueTypes["ArticleCategory"]],
paginateArticleCategory?: [{	filter?:ValueTypes["ArticleCategoryWhere"] | null,	orderBy?:ValueTypes["ArticleCategoryOrderBy"][],	skip?:number | null,	first?:number | null},ValueTypes["ArticleCategoryConnection"]],
getPose?: [{	by:ValueTypes["PoseUniqueWhere"],	filter?:ValueTypes["PoseWhere"] | null},ValueTypes["Pose"]],
listPose?: [{	filter?:ValueTypes["PoseWhere"] | null,	orderBy?:ValueTypes["PoseOrderBy"][],	offset?:number | null,	limit?:number | null},ValueTypes["Pose"]],
paginatePose?: [{	filter?:ValueTypes["PoseWhere"] | null,	orderBy?:ValueTypes["PoseOrderBy"][],	skip?:number | null,	first?:number | null},ValueTypes["PoseConnection"]],
getPoseCategory?: [{	by:ValueTypes["PoseCategoryUniqueWhere"],	filter?:ValueTypes["PoseCategoryWhere"] | null},ValueTypes["PoseCategory"]],
listPoseCategory?: [{	filter?:ValueTypes["PoseCategoryWhere"] | null,	orderBy?:ValueTypes["PoseCategoryOrderBy"][],	offset?:number | null,	limit?:number | null},ValueTypes["PoseCategory"]],
paginatePoseCategory?: [{	filter?:ValueTypes["PoseCategoryWhere"] | null,	orderBy?:ValueTypes["PoseCategoryOrderBy"][],	skip?:number | null,	first?:number | null},ValueTypes["PoseCategoryConnection"]],
getPoseDifficulty?: [{	by:ValueTypes["PoseDifficultyUniqueWhere"],	filter?:ValueTypes["PoseDifficultyWhere"] | null},ValueTypes["PoseDifficulty"]],
listPoseDifficulty?: [{	filter?:ValueTypes["PoseDifficultyWhere"] | null,	orderBy?:ValueTypes["PoseDifficultyOrderBy"][],	offset?:number | null,	limit?:number | null},ValueTypes["PoseDifficulty"]],
paginatePoseDifficulty?: [{	filter?:ValueTypes["PoseDifficultyWhere"] | null,	orderBy?:ValueTypes["PoseDifficultyOrderBy"][],	skip?:number | null,	first?:number | null},ValueTypes["PoseDifficultyConnection"]],
getCourse?: [{	by:ValueTypes["CourseUniqueWhere"],	filter?:ValueTypes["CourseWhere"] | null},ValueTypes["Course"]],
listCourse?: [{	filter?:ValueTypes["CourseWhere"] | null,	orderBy?:ValueTypes["CourseOrderBy"][],	offset?:number | null,	limit?:number | null},ValueTypes["Course"]],
paginateCourse?: [{	filter?:ValueTypes["CourseWhere"] | null,	orderBy?:ValueTypes["CourseOrderBy"][],	skip?:number | null,	first?:number | null},ValueTypes["CourseConnection"]],
getCourseLesson?: [{	by:ValueTypes["CourseLessonUniqueWhere"],	filter?:ValueTypes["CourseLessonWhere"] | null},ValueTypes["CourseLesson"]],
listCourseLesson?: [{	filter?:ValueTypes["CourseLessonWhere"] | null,	orderBy?:ValueTypes["CourseLessonOrderBy"][],	offset?:number | null,	limit?:number | null},ValueTypes["CourseLesson"]],
paginateCourseLesson?: [{	filter?:ValueTypes["CourseLessonWhere"] | null,	orderBy?:ValueTypes["CourseLessonOrderBy"][],	skip?:number | null,	first?:number | null},ValueTypes["CourseLessonConnection"]],
getArticleListPage?: [{	by:ValueTypes["ArticleListPageUniqueWhere"],	filter?:ValueTypes["ArticleListPageWhere"] | null},ValueTypes["ArticleListPage"]],
listArticleListPage?: [{	filter?:ValueTypes["ArticleListPageWhere"] | null,	orderBy?:ValueTypes["ArticleListPageOrderBy"][],	offset?:number | null,	limit?:number | null},ValueTypes["ArticleListPage"]],
paginateArticleListPage?: [{	filter?:ValueTypes["ArticleListPageWhere"] | null,	orderBy?:ValueTypes["ArticleListPageOrderBy"][],	skip?:number | null,	first?:number | null},ValueTypes["ArticleListPageConnection"]],
getCourseListPage?: [{	by:ValueTypes["CourseListPageUniqueWhere"],	filter?:ValueTypes["CourseListPageWhere"] | null},ValueTypes["CourseListPage"]],
listCourseListPage?: [{	filter?:ValueTypes["CourseListPageWhere"] | null,	orderBy?:ValueTypes["CourseListPageOrderBy"][],	offset?:number | null,	limit?:number | null},ValueTypes["CourseListPage"]],
paginateCourseListPage?: [{	filter?:ValueTypes["CourseListPageWhere"] | null,	orderBy?:ValueTypes["CourseListPageOrderBy"][],	skip?:number | null,	first?:number | null},ValueTypes["CourseListPageConnection"]],
getPoseListPage?: [{	by:ValueTypes["PoseListPageUniqueWhere"],	filter?:ValueTypes["PoseListPageWhere"] | null},ValueTypes["PoseListPage"]],
listPoseListPage?: [{	filter?:ValueTypes["PoseListPageWhere"] | null,	orderBy?:ValueTypes["PoseListPageOrderBy"][],	offset?:number | null,	limit?:number | null},ValueTypes["PoseListPage"]],
paginatePoseListPage?: [{	filter?:ValueTypes["PoseListPageWhere"] | null,	orderBy?:ValueTypes["PoseListPageOrderBy"][],	skip?:number | null,	first?:number | null},ValueTypes["PoseListPageConnection"]],
getContent?: [{	by:ValueTypes["ContentUniqueWhere"],	filter?:ValueTypes["ContentWhere"] | null},ValueTypes["Content"]],
listContent?: [{	filter?:ValueTypes["ContentWhere"] | null,	orderBy?:ValueTypes["ContentOrderBy"][],	offset?:number | null,	limit?:number | null},ValueTypes["Content"]],
paginateContent?: [{	filter?:ValueTypes["ContentWhere"] | null,	orderBy?:ValueTypes["ContentOrderBy"][],	skip?:number | null,	first?:number | null},ValueTypes["ContentConnection"]],
getContentBlock?: [{	by:ValueTypes["ContentBlockUniqueWhere"],	filter?:ValueTypes["ContentBlockWhere"] | null},ValueTypes["ContentBlock"]],
listContentBlock?: [{	filter?:ValueTypes["ContentBlockWhere"] | null,	orderBy?:ValueTypes["ContentBlockOrderBy"][],	offset?:number | null,	limit?:number | null},ValueTypes["ContentBlock"]],
paginateContentBlock?: [{	filter?:ValueTypes["ContentBlockWhere"] | null,	orderBy?:ValueTypes["ContentBlockOrderBy"][],	skip?:number | null,	first?:number | null},ValueTypes["ContentBlockConnection"]],
getContentReference?: [{	by:ValueTypes["ContentReferenceUniqueWhere"],	filter?:ValueTypes["ContentReferenceWhere"] | null},ValueTypes["ContentReference"]],
listContentReference?: [{	filter?:ValueTypes["ContentReferenceWhere"] | null,	orderBy?:ValueTypes["ContentReferenceOrderBy"][],	offset?:number | null,	limit?:number | null},ValueTypes["ContentReference"]],
paginateContentReference?: [{	filter?:ValueTypes["ContentReferenceWhere"] | null,	orderBy?:ValueTypes["ContentReferenceOrderBy"][],	skip?:number | null,	first?:number | null},ValueTypes["ContentReferenceConnection"]],
getTile?: [{	by:ValueTypes["TileUniqueWhere"],	filter?:ValueTypes["TileWhere"] | null},ValueTypes["Tile"]],
listTile?: [{	filter?:ValueTypes["TileWhere"] | null,	orderBy?:ValueTypes["TileOrderBy"][],	offset?:number | null,	limit?:number | null},ValueTypes["Tile"]],
paginateTile?: [{	filter?:ValueTypes["TileWhere"] | null,	orderBy?:ValueTypes["TileOrderBy"][],	skip?:number | null,	first?:number | null},ValueTypes["TileConnection"]],
getTileList?: [{	by:ValueTypes["TileListUniqueWhere"],	filter?:ValueTypes["TileListWhere"] | null},ValueTypes["TileList"]],
listTileList?: [{	filter?:ValueTypes["TileListWhere"] | null,	orderBy?:ValueTypes["TileListOrderBy"][],	offset?:number | null,	limit?:number | null},ValueTypes["TileList"]],
paginateTileList?: [{	filter?:ValueTypes["TileListWhere"] | null,	orderBy?:ValueTypes["TileListOrderBy"][],	skip?:number | null,	first?:number | null},ValueTypes["TileListConnection"]],
getTileListItem?: [{	by:ValueTypes["TileListItemUniqueWhere"],	filter?:ValueTypes["TileListItemWhere"] | null},ValueTypes["TileListItem"]],
listTileListItem?: [{	filter?:ValueTypes["TileListItemWhere"] | null,	orderBy?:ValueTypes["TileListItemOrderBy"][],	offset?:number | null,	limit?:number | null},ValueTypes["TileListItem"]],
paginateTileListItem?: [{	filter?:ValueTypes["TileListItemWhere"] | null,	orderBy?:ValueTypes["TileListItemOrderBy"][],	skip?:number | null,	first?:number | null},ValueTypes["TileListItemConnection"]],
getYoutubeVideo?: [{	by:ValueTypes["YoutubeVideoUniqueWhere"],	filter?:ValueTypes["YoutubeVideoWhere"] | null},ValueTypes["YoutubeVideo"]],
listYoutubeVideo?: [{	filter?:ValueTypes["YoutubeVideoWhere"] | null,	orderBy?:ValueTypes["YoutubeVideoOrderBy"][],	offset?:number | null,	limit?:number | null},ValueTypes["YoutubeVideo"]],
paginateYoutubeVideo?: [{	filter?:ValueTypes["YoutubeVideoWhere"] | null,	orderBy?:ValueTypes["YoutubeVideoOrderBy"][],	skip?:number | null,	first?:number | null},ValueTypes["YoutubeVideoConnection"]],
getSeoDefault?: [{	by:ValueTypes["SeoDefaultUniqueWhere"],	filter?:ValueTypes["SeoDefaultWhere"] | null},ValueTypes["SeoDefault"]],
listSeoDefault?: [{	filter?:ValueTypes["SeoDefaultWhere"] | null,	orderBy?:ValueTypes["SeoDefaultOrderBy"][],	offset?:number | null,	limit?:number | null},ValueTypes["SeoDefault"]],
paginateSeoDefault?: [{	filter?:ValueTypes["SeoDefaultWhere"] | null,	orderBy?:ValueTypes["SeoDefaultOrderBy"][],	skip?:number | null,	first?:number | null},ValueTypes["SeoDefaultConnection"]],
getCourseLecturer?: [{	by:ValueTypes["CourseLecturerUniqueWhere"],	filter?:ValueTypes["CourseLecturerWhere"] | null},ValueTypes["CourseLecturer"]],
listCourseLecturer?: [{	filter?:ValueTypes["CourseLecturerWhere"] | null,	orderBy?:ValueTypes["CourseLecturerOrderBy"][],	offset?:number | null,	limit?:number | null},ValueTypes["CourseLecturer"]],
paginateCourseLecturer?: [{	filter?:ValueTypes["CourseLecturerWhere"] | null,	orderBy?:ValueTypes["CourseLecturerOrderBy"][],	skip?:number | null,	first?:number | null},ValueTypes["CourseLecturerConnection"]],
getCourseLecturerListPage?: [{	by:ValueTypes["CourseLecturerListPageUniqueWhere"],	filter?:ValueTypes["CourseLecturerListPageWhere"] | null},ValueTypes["CourseLecturerListPage"]],
listCourseLecturerListPage?: [{	filter?:ValueTypes["CourseLecturerListPageWhere"] | null,	orderBy?:ValueTypes["CourseLecturerListPageOrderBy"][],	offset?:number | null,	limit?:number | null},ValueTypes["CourseLecturerListPage"]],
paginateCourseLecturerListPage?: [{	filter?:ValueTypes["CourseLecturerListPageWhere"] | null,	orderBy?:ValueTypes["CourseLecturerListPageOrderBy"][],	skip?:number | null,	first?:number | null},ValueTypes["CourseLecturerListPageConnection"]],
getNewsletterSubscription?: [{	by:ValueTypes["NewsletterSubscriptionUniqueWhere"],	filter?:ValueTypes["NewsletterSubscriptionWhere"] | null},ValueTypes["NewsletterSubscription"]],
listNewsletterSubscription?: [{	filter?:ValueTypes["NewsletterSubscriptionWhere"] | null,	orderBy?:ValueTypes["NewsletterSubscriptionOrderBy"][],	offset?:number | null,	limit?:number | null},ValueTypes["NewsletterSubscription"]],
paginateNewsletterSubscription?: [{	filter?:ValueTypes["NewsletterSubscriptionWhere"] | null,	orderBy?:ValueTypes["NewsletterSubscriptionOrderBy"][],	skip?:number | null,	first?:number | null},ValueTypes["NewsletterSubscriptionConnection"]],
validateCreateNewsletterSubscription?: [{	data:ValueTypes["NewsletterSubscriptionCreateInput"]},ValueTypes["_ValidationResult"]],
getVisitor?: [{	by:ValueTypes["VisitorUniqueWhere"],	filter?:ValueTypes["VisitorWhere"] | null},ValueTypes["Visitor"]],
listVisitor?: [{	filter?:ValueTypes["VisitorWhere"] | null,	orderBy?:ValueTypes["VisitorOrderBy"][],	offset?:number | null,	limit?:number | null},ValueTypes["Visitor"]],
paginateVisitor?: [{	filter?:ValueTypes["VisitorWhere"] | null,	orderBy?:ValueTypes["VisitorOrderBy"][],	skip?:number | null,	first?:number | null},ValueTypes["VisitorConnection"]],
validateCreateVisitor?: [{	data:ValueTypes["VisitorCreateInput"]},ValueTypes["_ValidationResult"]],
getCourseSubscription?: [{	by:ValueTypes["CourseSubscriptionUniqueWhere"],	filter?:ValueTypes["CourseSubscriptionWhere"] | null},ValueTypes["CourseSubscription"]],
listCourseSubscription?: [{	filter?:ValueTypes["CourseSubscriptionWhere"] | null,	orderBy?:ValueTypes["CourseSubscriptionOrderBy"][],	offset?:number | null,	limit?:number | null},ValueTypes["CourseSubscription"]],
paginateCourseSubscription?: [{	filter?:ValueTypes["CourseSubscriptionWhere"] | null,	orderBy?:ValueTypes["CourseSubscriptionOrderBy"][],	skip?:number | null,	first?:number | null},ValueTypes["CourseSubscriptionConnection"]],
validateCreateCourseSubscription?: [{	data:ValueTypes["CourseSubscriptionCreateInput"]},ValueTypes["_ValidationResult"]],
getSubscribeBox?: [{	by:ValueTypes["SubscribeBoxUniqueWhere"],	filter?:ValueTypes["SubscribeBoxWhere"] | null},ValueTypes["SubscribeBox"]],
listSubscribeBox?: [{	filter?:ValueTypes["SubscribeBoxWhere"] | null,	orderBy?:ValueTypes["SubscribeBoxOrderBy"][],	offset?:number | null,	limit?:number | null},ValueTypes["SubscribeBox"]],
paginateSubscribeBox?: [{	filter?:ValueTypes["SubscribeBoxWhere"] | null,	orderBy?:ValueTypes["SubscribeBoxOrderBy"][],	skip?:number | null,	first?:number | null},ValueTypes["SubscribeBoxConnection"]],
getNewsletterSubscribeSuccessPage?: [{	by:ValueTypes["NewsletterSubscribeSuccessPageUniqueWhere"],	filter?:ValueTypes["NewsletterSubscribeSuccessPageWhere"] | null},ValueTypes["NewsletterSubscribeSuccessPage"]],
listNewsletterSubscribeSuccessPage?: [{	filter?:ValueTypes["NewsletterSubscribeSuccessPageWhere"] | null,	orderBy?:ValueTypes["NewsletterSubscribeSuccessPageOrderBy"][],	offset?:number | null,	limit?:number | null},ValueTypes["NewsletterSubscribeSuccessPage"]],
paginateNewsletterSubscribeSuccessPage?: [{	filter?:ValueTypes["NewsletterSubscribeSuccessPageWhere"] | null,	orderBy?:ValueTypes["NewsletterSubscribeSuccessPageOrderBy"][],	skip?:number | null,	first?:number | null},ValueTypes["NewsletterSubscribeSuccessPageConnection"]],
getGenericPage?: [{	by:ValueTypes["GenericPageUniqueWhere"],	filter?:ValueTypes["GenericPageWhere"] | null},ValueTypes["GenericPage"]],
listGenericPage?: [{	filter?:ValueTypes["GenericPageWhere"] | null,	orderBy?:ValueTypes["GenericPageOrderBy"][],	offset?:number | null,	limit?:number | null},ValueTypes["GenericPage"]],
paginateGenericPage?: [{	filter?:ValueTypes["GenericPageWhere"] | null,	orderBy?:ValueTypes["GenericPageOrderBy"][],	skip?:number | null,	first?:number | null},ValueTypes["GenericPageConnection"]],
getPerson?: [{	by:ValueTypes["PersonUniqueWhere"],	filter?:ValueTypes["PersonWhere"] | null},ValueTypes["Person"]],
listPerson?: [{	filter?:ValueTypes["PersonWhere"] | null,	orderBy?:ValueTypes["PersonOrderBy"][],	offset?:number | null,	limit?:number | null},ValueTypes["Person"]],
paginatePerson?: [{	filter?:ValueTypes["PersonWhere"] | null,	orderBy?:ValueTypes["PersonOrderBy"][],	skip?:number | null,	first?:number | null},ValueTypes["PersonConnection"]],
getPersonList?: [{	by:ValueTypes["PersonListUniqueWhere"],	filter?:ValueTypes["PersonListWhere"] | null},ValueTypes["PersonList"]],
listPersonList?: [{	filter?:ValueTypes["PersonListWhere"] | null,	orderBy?:ValueTypes["PersonListOrderBy"][],	offset?:number | null,	limit?:number | null},ValueTypes["PersonList"]],
paginatePersonList?: [{	filter?:ValueTypes["PersonListWhere"] | null,	orderBy?:ValueTypes["PersonListOrderBy"][],	skip?:number | null,	first?:number | null},ValueTypes["PersonListConnection"]],
getPersonListItem?: [{	by:ValueTypes["PersonListItemUniqueWhere"],	filter?:ValueTypes["PersonListItemWhere"] | null},ValueTypes["PersonListItem"]],
listPersonListItem?: [{	filter?:ValueTypes["PersonListItemWhere"] | null,	orderBy?:ValueTypes["PersonListItemOrderBy"][],	offset?:number | null,	limit?:number | null},ValueTypes["PersonListItem"]],
paginatePersonListItem?: [{	filter?:ValueTypes["PersonListItemWhere"] | null,	orderBy?:ValueTypes["PersonListItemOrderBy"][],	skip?:number | null,	first?:number | null},ValueTypes["PersonListItemConnection"]],
getCourseEmail?: [{	by:ValueTypes["CourseEmailUniqueWhere"],	filter?:ValueTypes["CourseEmailWhere"] | null},ValueTypes["CourseEmail"]],
listCourseEmail?: [{	filter?:ValueTypes["CourseEmailWhere"] | null,	orderBy?:ValueTypes["CourseEmailOrderBy"][],	offset?:number | null,	limit?:number | null},ValueTypes["CourseEmail"]],
paginateCourseEmail?: [{	filter?:ValueTypes["CourseEmailWhere"] | null,	orderBy?:ValueTypes["CourseEmailOrderBy"][],	skip?:number | null,	first?:number | null},ValueTypes["CourseEmailConnection"]],
getTranslations?: [{	by:ValueTypes["TranslationsUniqueWhere"],	filter?:ValueTypes["TranslationsWhere"] | null},ValueTypes["Translations"]],
listTranslations?: [{	filter?:ValueTypes["TranslationsWhere"] | null,	orderBy?:ValueTypes["TranslationsOrderBy"][],	offset?:number | null,	limit?:number | null},ValueTypes["Translations"]],
paginateTranslations?: [{	filter?:ValueTypes["TranslationsWhere"] | null,	orderBy?:ValueTypes["TranslationsOrderBy"][],	skip?:number | null,	first?:number | null},ValueTypes["TranslationsConnection"]],
		__typename?: true
}>;
	["Info"]: AliasType<{
	description?:true,
		__typename?: true
}>;
	["Mutation"]: AliasType<{
createNewsletterSubscription?: [{	data:ValueTypes["NewsletterSubscriptionCreateInput"]},ValueTypes["NewsletterSubscriptionCreateResult"]],
deleteNewsletterSubscription?: [{	by:ValueTypes["NewsletterSubscriptionUniqueWhere"],	filter?:ValueTypes["NewsletterSubscriptionWhere"] | null},ValueTypes["NewsletterSubscriptionDeleteResult"]],
createVisitor?: [{	data:ValueTypes["VisitorCreateInput"]},ValueTypes["VisitorCreateResult"]],
createCourseSubscription?: [{	data:ValueTypes["CourseSubscriptionCreateInput"]},ValueTypes["CourseSubscriptionCreateResult"]],
deleteCourseSubscription?: [{	by:ValueTypes["CourseSubscriptionUniqueWhere"],	filter?:ValueTypes["CourseSubscriptionWhere"] | null},ValueTypes["CourseSubscriptionDeleteResult"]],
transaction?: [{	options?:ValueTypes["MutationTransactionOptions"] | null},ValueTypes["MutationTransaction"]],
	query?:ValueTypes["Query"],
		__typename?: true
}>;
	["NewsletterSubscriptionCreateResult"]: AliasType<{
	ok?:true,
	errorMessage?:true,
	errors?:ValueTypes["_MutationError"],
	node?:ValueTypes["NewsletterSubscription"],
	validation?:ValueTypes["_ValidationResult"],
		__typename?: true
}>;
	["MutationResult"]:AliasType<{
		ok?:true,
	errorMessage?:true,
	errors?:ValueTypes["_MutationError"];
		['...on NewsletterSubscriptionCreateResult']?: Omit<ValueTypes["NewsletterSubscriptionCreateResult"],keyof ValueTypes["MutationResult"]>;
		['...on NewsletterSubscriptionDeleteResult']?: Omit<ValueTypes["NewsletterSubscriptionDeleteResult"],keyof ValueTypes["MutationResult"]>;
		['...on VisitorCreateResult']?: Omit<ValueTypes["VisitorCreateResult"],keyof ValueTypes["MutationResult"]>;
		['...on CourseSubscriptionCreateResult']?: Omit<ValueTypes["CourseSubscriptionCreateResult"],keyof ValueTypes["MutationResult"]>;
		['...on CourseSubscriptionDeleteResult']?: Omit<ValueTypes["CourseSubscriptionDeleteResult"],keyof ValueTypes["MutationResult"]>;
		__typename?: true
}>;
	["_MutationError"]: AliasType<{
	path?:ValueTypes["_PathFragment"],
	paths?:ValueTypes["_PathFragment"],
	type?:true,
	message?:true,
		__typename?: true
}>;
	["_MutationErrorType"]:_MutationErrorType;
	["NewsletterSubscriptionDeleteResult"]: AliasType<{
	ok?:true,
	errorMessage?:true,
	errors?:ValueTypes["_MutationError"],
	node?:ValueTypes["NewsletterSubscription"],
		__typename?: true
}>;
	["VisitorCreateResult"]: AliasType<{
	ok?:true,
	errorMessage?:true,
	errors?:ValueTypes["_MutationError"],
	node?:ValueTypes["Visitor"],
	validation?:ValueTypes["_ValidationResult"],
		__typename?: true
}>;
	["CourseSubscriptionCreateResult"]: AliasType<{
	ok?:true,
	errorMessage?:true,
	errors?:ValueTypes["_MutationError"],
	node?:ValueTypes["CourseSubscription"],
	validation?:ValueTypes["_ValidationResult"],
		__typename?: true
}>;
	["CourseSubscriptionDeleteResult"]: AliasType<{
	ok?:true,
	errorMessage?:true,
	errors?:ValueTypes["_MutationError"],
	node?:ValueTypes["CourseSubscription"],
		__typename?: true
}>;
	["MutationTransaction"]: AliasType<{
	ok?:true,
	errorMessage?:true,
	errors?:ValueTypes["_MutationError"],
	validation?:ValueTypes["_ValidationResult"],
createNewsletterSubscription?: [{	data:ValueTypes["NewsletterSubscriptionCreateInput"]},ValueTypes["NewsletterSubscriptionCreateResult"]],
deleteNewsletterSubscription?: [{	by:ValueTypes["NewsletterSubscriptionUniqueWhere"],	filter?:ValueTypes["NewsletterSubscriptionWhere"] | null},ValueTypes["NewsletterSubscriptionDeleteResult"]],
createVisitor?: [{	data:ValueTypes["VisitorCreateInput"]},ValueTypes["VisitorCreateResult"]],
createCourseSubscription?: [{	data:ValueTypes["CourseSubscriptionCreateInput"]},ValueTypes["CourseSubscriptionCreateResult"]],
deleteCourseSubscription?: [{	by:ValueTypes["CourseSubscriptionUniqueWhere"],	filter?:ValueTypes["CourseSubscriptionWhere"] | null},ValueTypes["CourseSubscriptionDeleteResult"]],
	query?:ValueTypes["Query"],
		__typename?: true
}>;
	["MutationTransactionOptions"]: {
	deferForeignKeyConstraints?:boolean | null
};
	/** Json custom scalar type */
["Json"]:unknown;
	["_Schema"]: AliasType<{
	enums?:ValueTypes["_Enum"],
	entities?:ValueTypes["_Entity"],
		__typename?: true
}>;
	["_Entity"]: AliasType<{
	name?:true,
	customPrimaryAllowed?:true,
	fields?:ValueTypes["_Field"],
	unique?:ValueTypes["_UniqueConstraint"],
		__typename?: true
}>;
	["_UniqueConstraint"]: AliasType<{
	fields?:true,
		__typename?: true
}>;
	["_Field"]:AliasType<{
		name?:true,
	type?:true,
	nullable?:true,
	rules?:ValueTypes["_Rule"],
	validators?:ValueTypes["_Validator"];
		['...on _Column']?: Omit<ValueTypes["_Column"],keyof ValueTypes["_Field"]>;
		['...on _Relation']?: Omit<ValueTypes["_Relation"],keyof ValueTypes["_Field"]>;
		__typename?: true
}>;
	["_Column"]: AliasType<{
	name?:true,
	type?:true,
	enumName?:true,
	defaultValue?:true,
	nullable?:true,
	rules?:ValueTypes["_Rule"],
	validators?:ValueTypes["_Validator"],
		__typename?: true
}>;
	["_OnDeleteBehaviour"]:_OnDeleteBehaviour;
	["_RelationSide"]:_RelationSide;
	["_OrderByDirection"]:_OrderByDirection;
	["_OrderBy"]: AliasType<{
	path?:true,
	direction?:true,
		__typename?: true
}>;
	["_Relation"]: AliasType<{
	name?:true,
	type?:true,
	side?:true,
	targetEntity?:true,
	ownedBy?:true,
	inversedBy?:true,
	nullable?:true,
	onDelete?:true,
	orphanRemoval?:true,
	orderBy?:ValueTypes["_OrderBy"],
	rules?:ValueTypes["_Rule"],
	validators?:ValueTypes["_Validator"],
		__typename?: true
}>;
	["_Rule"]: AliasType<{
	message?:ValueTypes["_RuleMessage"],
	validator?:true,
		__typename?: true
}>;
	["_Validator"]: AliasType<{
	operation?:true,
	arguments?:ValueTypes["_Argument"],
		__typename?: true
}>;
	["_Argument"]: AliasType<{		["...on _ValidatorArgument"] : ValueTypes["_ValidatorArgument"],
		["...on _PathArgument"] : ValueTypes["_PathArgument"],
		["...on _LiteralArgument"] : ValueTypes["_LiteralArgument"]
		__typename?: true
}>;
	["_ValidatorArgument"]: AliasType<{
	validator?:true,
		__typename?: true
}>;
	["_PathArgument"]: AliasType<{
	path?:true,
		__typename?: true
}>;
	["_LiteralArgument"]: AliasType<{
	value?:true,
		__typename?: true
}>;
	["_RuleMessage"]: AliasType<{
	text?:true,
		__typename?: true
}>;
	["_Enum"]: AliasType<{
	name?:true,
	values?:true,
		__typename?: true
}>
  }

export type ModelTypes = {
    ["Query"]: {
		getLink?:ModelTypes["Link"],
	listLink:ModelTypes["Link"][],
	paginateLink:ModelTypes["LinkConnection"],
	getLinkable?:ModelTypes["Linkable"],
	listLinkable:ModelTypes["Linkable"][],
	paginateLinkable:ModelTypes["LinkableConnection"],
	getRedirect?:ModelTypes["Redirect"],
	listRedirect:ModelTypes["Redirect"][],
	paginateRedirect:ModelTypes["RedirectConnection"],
	getFooter?:ModelTypes["Footer"],
	listFooter:ModelTypes["Footer"][],
	paginateFooter:ModelTypes["FooterConnection"],
	getImage?:ModelTypes["Image"],
	listImage:ModelTypes["Image"][],
	paginateImage:ModelTypes["ImageConnection"],
	getSeo?:ModelTypes["Seo"],
	listSeo:ModelTypes["Seo"][],
	paginateSeo:ModelTypes["SeoConnection"],
	getHomepage?:ModelTypes["Homepage"],
	listHomepage:ModelTypes["Homepage"][],
	paginateHomepage:ModelTypes["HomepageConnection"],
	getHeader?:ModelTypes["Header"],
	listHeader:ModelTypes["Header"][],
	paginateHeader:ModelTypes["HeaderConnection"],
	getHeaderPrimaryNavigationItem?:ModelTypes["HeaderPrimaryNavigationItem"],
	listHeaderPrimaryNavigationItem:ModelTypes["HeaderPrimaryNavigationItem"][],
	paginateHeaderPrimaryNavigationItem:ModelTypes["HeaderPrimaryNavigationItemConnection"],
	getListNavigation?:ModelTypes["ListNavigation"],
	listListNavigation:ModelTypes["ListNavigation"][],
	paginateListNavigation:ModelTypes["ListNavigationConnection"],
	getListNavigationColumn?:ModelTypes["ListNavigationColumn"],
	listListNavigationColumn:ModelTypes["ListNavigationColumn"][],
	paginateListNavigationColumn:ModelTypes["ListNavigationColumnConnection"],
	getListNavigationColumnItem?:ModelTypes["ListNavigationColumnItem"],
	listListNavigationColumnItem:ModelTypes["ListNavigationColumnItem"][],
	paginateListNavigationColumnItem:ModelTypes["ListNavigationColumnItemConnection"],
	getArticle?:ModelTypes["Article"],
	listArticle:ModelTypes["Article"][],
	paginateArticle:ModelTypes["ArticleConnection"],
	getSite?:ModelTypes["Site"],
	listSite:ModelTypes["Site"][],
	paginateSite:ModelTypes["SiteConnection"],
	getArticleCategory?:ModelTypes["ArticleCategory"],
	listArticleCategory:ModelTypes["ArticleCategory"][],
	paginateArticleCategory:ModelTypes["ArticleCategoryConnection"],
	getPose?:ModelTypes["Pose"],
	listPose:ModelTypes["Pose"][],
	paginatePose:ModelTypes["PoseConnection"],
	getPoseCategory?:ModelTypes["PoseCategory"],
	listPoseCategory:ModelTypes["PoseCategory"][],
	paginatePoseCategory:ModelTypes["PoseCategoryConnection"],
	getPoseDifficulty?:ModelTypes["PoseDifficulty"],
	listPoseDifficulty:ModelTypes["PoseDifficulty"][],
	paginatePoseDifficulty:ModelTypes["PoseDifficultyConnection"],
	getCourse?:ModelTypes["Course"],
	listCourse:ModelTypes["Course"][],
	paginateCourse:ModelTypes["CourseConnection"],
	getCourseLesson?:ModelTypes["CourseLesson"],
	listCourseLesson:ModelTypes["CourseLesson"][],
	paginateCourseLesson:ModelTypes["CourseLessonConnection"],
	getArticleListPage?:ModelTypes["ArticleListPage"],
	listArticleListPage:ModelTypes["ArticleListPage"][],
	paginateArticleListPage:ModelTypes["ArticleListPageConnection"],
	getCourseListPage?:ModelTypes["CourseListPage"],
	listCourseListPage:ModelTypes["CourseListPage"][],
	paginateCourseListPage:ModelTypes["CourseListPageConnection"],
	getPoseListPage?:ModelTypes["PoseListPage"],
	listPoseListPage:ModelTypes["PoseListPage"][],
	paginatePoseListPage:ModelTypes["PoseListPageConnection"],
	getContent?:ModelTypes["Content"],
	listContent:ModelTypes["Content"][],
	paginateContent:ModelTypes["ContentConnection"],
	getContentBlock?:ModelTypes["ContentBlock"],
	listContentBlock:ModelTypes["ContentBlock"][],
	paginateContentBlock:ModelTypes["ContentBlockConnection"],
	getContentReference?:ModelTypes["ContentReference"],
	listContentReference:ModelTypes["ContentReference"][],
	paginateContentReference:ModelTypes["ContentReferenceConnection"],
	getTile?:ModelTypes["Tile"],
	listTile:ModelTypes["Tile"][],
	paginateTile:ModelTypes["TileConnection"],
	getTileList?:ModelTypes["TileList"],
	listTileList:ModelTypes["TileList"][],
	paginateTileList:ModelTypes["TileListConnection"],
	getTileListItem?:ModelTypes["TileListItem"],
	listTileListItem:ModelTypes["TileListItem"][],
	paginateTileListItem:ModelTypes["TileListItemConnection"],
	getYoutubeVideo?:ModelTypes["YoutubeVideo"],
	listYoutubeVideo:ModelTypes["YoutubeVideo"][],
	paginateYoutubeVideo:ModelTypes["YoutubeVideoConnection"],
	getSeoDefault?:ModelTypes["SeoDefault"],
	listSeoDefault:ModelTypes["SeoDefault"][],
	paginateSeoDefault:ModelTypes["SeoDefaultConnection"],
	getCourseLecturer?:ModelTypes["CourseLecturer"],
	listCourseLecturer:ModelTypes["CourseLecturer"][],
	paginateCourseLecturer:ModelTypes["CourseLecturerConnection"],
	getCourseLecturerListPage?:ModelTypes["CourseLecturerListPage"],
	listCourseLecturerListPage:ModelTypes["CourseLecturerListPage"][],
	paginateCourseLecturerListPage:ModelTypes["CourseLecturerListPageConnection"],
	getNewsletterSubscription?:ModelTypes["NewsletterSubscription"],
	listNewsletterSubscription:ModelTypes["NewsletterSubscription"][],
	paginateNewsletterSubscription:ModelTypes["NewsletterSubscriptionConnection"],
	validateCreateNewsletterSubscription:ModelTypes["_ValidationResult"],
	getVisitor?:ModelTypes["Visitor"],
	listVisitor:ModelTypes["Visitor"][],
	paginateVisitor:ModelTypes["VisitorConnection"],
	validateCreateVisitor:ModelTypes["_ValidationResult"],
	getCourseSubscription?:ModelTypes["CourseSubscription"],
	listCourseSubscription:ModelTypes["CourseSubscription"][],
	paginateCourseSubscription:ModelTypes["CourseSubscriptionConnection"],
	validateCreateCourseSubscription:ModelTypes["_ValidationResult"],
	getSubscribeBox?:ModelTypes["SubscribeBox"],
	listSubscribeBox:ModelTypes["SubscribeBox"][],
	paginateSubscribeBox:ModelTypes["SubscribeBoxConnection"],
	getNewsletterSubscribeSuccessPage?:ModelTypes["NewsletterSubscribeSuccessPage"],
	listNewsletterSubscribeSuccessPage:ModelTypes["NewsletterSubscribeSuccessPage"][],
	paginateNewsletterSubscribeSuccessPage:ModelTypes["NewsletterSubscribeSuccessPageConnection"],
	getGenericPage?:ModelTypes["GenericPage"],
	listGenericPage:ModelTypes["GenericPage"][],
	paginateGenericPage:ModelTypes["GenericPageConnection"],
	getPerson?:ModelTypes["Person"],
	listPerson:ModelTypes["Person"][],
	paginatePerson:ModelTypes["PersonConnection"],
	getPersonList?:ModelTypes["PersonList"],
	listPersonList:ModelTypes["PersonList"][],
	paginatePersonList:ModelTypes["PersonListConnection"],
	getPersonListItem?:ModelTypes["PersonListItem"],
	listPersonListItem:ModelTypes["PersonListItem"][],
	paginatePersonListItem:ModelTypes["PersonListItemConnection"],
	getCourseEmail?:ModelTypes["CourseEmail"],
	listCourseEmail:ModelTypes["CourseEmail"][],
	paginateCourseEmail:ModelTypes["CourseEmailConnection"],
	getTranslations?:ModelTypes["Translations"],
	listTranslations:ModelTypes["Translations"][],
	paginateTranslations:ModelTypes["TranslationsConnection"],
	transaction?:ModelTypes["QueryTransaction"],
	_info?:ModelTypes["Info"],
	schema?:ModelTypes["_Schema"]
};
	["Link"]: {
		_meta?:ModelTypes["LinkMeta"],
	id:ModelTypes["UUID"],
	type:ModelTypes["LinkType"],
	title?:string,
	externalLink?:string,
	internalLink?:ModelTypes["Linkable"],
	isHighlighted?:boolean,
	targetBlank?:boolean
};
	["LinkMeta"]: {
		id?:ModelTypes["FieldMeta"],
	type?:ModelTypes["FieldMeta"],
	title?:ModelTypes["FieldMeta"],
	externalLink?:ModelTypes["FieldMeta"],
	internalLink?:ModelTypes["FieldMeta"],
	isHighlighted?:ModelTypes["FieldMeta"],
	targetBlank?:ModelTypes["FieldMeta"]
};
	["FieldMeta"]: {
		readable?:boolean,
	updatable?:boolean
};
	["UUID"]:string;
	["LinkType"]: GraphQLTypes["LinkType"];
	["Linkable"]: {
		_meta?:ModelTypes["LinkableMeta"],
	id:ModelTypes["UUID"],
	url:string,
	redirect?:ModelTypes["Redirect"],
	homepage?:ModelTypes["Homepage"],
	article?:ModelTypes["Article"],
	articleCategory?:ModelTypes["ArticleCategory"],
	pose?:ModelTypes["Pose"],
	course?:ModelTypes["Course"],
	articleList?:ModelTypes["ArticleListPage"],
	courseList?:ModelTypes["CourseListPage"],
	poseList?:ModelTypes["PoseListPage"],
	courseLecturer?:ModelTypes["CourseLecturer"],
	courseLecturerList?:ModelTypes["CourseLecturerListPage"],
	newsletterSubscribeSuccessPage?:ModelTypes["NewsletterSubscribeSuccessPage"],
	genericPage?:ModelTypes["GenericPage"]
};
	["LinkableMeta"]: {
		id?:ModelTypes["FieldMeta"],
	url?:ModelTypes["FieldMeta"],
	redirect?:ModelTypes["FieldMeta"],
	homepage?:ModelTypes["FieldMeta"],
	article?:ModelTypes["FieldMeta"],
	articleCategory?:ModelTypes["FieldMeta"],
	pose?:ModelTypes["FieldMeta"],
	course?:ModelTypes["FieldMeta"],
	articleList?:ModelTypes["FieldMeta"],
	courseList?:ModelTypes["FieldMeta"],
	poseList?:ModelTypes["FieldMeta"],
	courseLecturer?:ModelTypes["FieldMeta"],
	courseLecturerList?:ModelTypes["FieldMeta"],
	newsletterSubscribeSuccessPage?:ModelTypes["FieldMeta"],
	genericPage?:ModelTypes["FieldMeta"]
};
	["Redirect"]: {
		_meta?:ModelTypes["RedirectMeta"],
	id:ModelTypes["UUID"],
	link?:ModelTypes["Linkable"],
	target?:ModelTypes["Linkable"]
};
	["RedirectMeta"]: {
		id?:ModelTypes["FieldMeta"],
	link?:ModelTypes["FieldMeta"],
	target?:ModelTypes["FieldMeta"]
};
	["LinkableWhere"]: GraphQLTypes["LinkableWhere"];
	["UUIDCondition"]: GraphQLTypes["UUIDCondition"];
	["StringCondition"]: GraphQLTypes["StringCondition"];
	["RedirectWhere"]: GraphQLTypes["RedirectWhere"];
	["HomepageWhere"]: GraphQLTypes["HomepageWhere"];
	["SeoWhere"]: GraphQLTypes["SeoWhere"];
	["ImageWhere"]: GraphQLTypes["ImageWhere"];
	["IntCondition"]: GraphQLTypes["IntCondition"];
	["ContentWhere"]: GraphQLTypes["ContentWhere"];
	["ContentBlockWhere"]: GraphQLTypes["ContentBlockWhere"];
	["ContentReferenceWhere"]: GraphQLTypes["ContentReferenceWhere"];
	["ContentReferenceTypeCondition"]: GraphQLTypes["ContentReferenceTypeCondition"];
	["ContentReferenceType"]: GraphQLTypes["ContentReferenceType"];
	["BooleanCondition"]: GraphQLTypes["BooleanCondition"];
	["PersonListWhere"]: GraphQLTypes["PersonListWhere"];
	["PersonListItemWhere"]: GraphQLTypes["PersonListItemWhere"];
	["PersonWhere"]: GraphQLTypes["PersonWhere"];
	["TileListWhere"]: GraphQLTypes["TileListWhere"];
	["TileListItemWhere"]: GraphQLTypes["TileListItemWhere"];
	["TileWhere"]: GraphQLTypes["TileWhere"];
	["LinkWhere"]: GraphQLTypes["LinkWhere"];
	["LinkTypeCondition"]: GraphQLTypes["LinkTypeCondition"];
	["SiteWhere"]: GraphQLTypes["SiteWhere"];
	["ArticleWhere"]: GraphQLTypes["ArticleWhere"];
	["DateCondition"]: GraphQLTypes["DateCondition"];
	["Date"]:string;
	["ArticleCategoryWhere"]: GraphQLTypes["ArticleCategoryWhere"];
	["PoseWhere"]: GraphQLTypes["PoseWhere"];
	["PoseCategoryWhere"]: GraphQLTypes["PoseCategoryWhere"];
	["PoseDifficultyWhere"]: GraphQLTypes["PoseDifficultyWhere"];
	["CourseWhere"]: GraphQLTypes["CourseWhere"];
	["CourseLessonWhere"]: GraphQLTypes["CourseLessonWhere"];
	["YoutubeVideoWhere"]: GraphQLTypes["YoutubeVideoWhere"];
	["CourseLecturerWhere"]: GraphQLTypes["CourseLecturerWhere"];
	["ArticleListPageWhere"]: GraphQLTypes["ArticleListPageWhere"];
	["CourseListPageWhere"]: GraphQLTypes["CourseListPageWhere"];
	["PoseListPageWhere"]: GraphQLTypes["PoseListPageWhere"];
	["CourseLecturerListPageWhere"]: GraphQLTypes["CourseLecturerListPageWhere"];
	["SubscribeBoxWhere"]: GraphQLTypes["SubscribeBoxWhere"];
	["HeaderWhere"]: GraphQLTypes["HeaderWhere"];
	["HeaderPrimaryNavigationItemWhere"]: GraphQLTypes["HeaderPrimaryNavigationItemWhere"];
	["ListNavigationWhere"]: GraphQLTypes["ListNavigationWhere"];
	["ListNavigationColumnWhere"]: GraphQLTypes["ListNavigationColumnWhere"];
	["ListNavigationColumnItemWhere"]: GraphQLTypes["ListNavigationColumnItemWhere"];
	["FooterWhere"]: GraphQLTypes["FooterWhere"];
	["GenericPageWhere"]: GraphQLTypes["GenericPageWhere"];
	["CourseEmailWhere"]: GraphQLTypes["CourseEmailWhere"];
	["TranslationsWhere"]: GraphQLTypes["TranslationsWhere"];
	["NewsletterSubscribeSuccessPageWhere"]: GraphQLTypes["NewsletterSubscribeSuccessPageWhere"];
	["Homepage"]: {
		_meta?:ModelTypes["HomepageMeta"],
	id:ModelTypes["UUID"],
	link?:ModelTypes["Linkable"],
	subtitle?:string,
	seo?:ModelTypes["Seo"],
	content?:ModelTypes["Content"],
	heroTiles?:ModelTypes["TileList"],
	site?:ModelTypes["Site"],
	titlePartA?:string,
	titlePartB?:string,
	titlePartC?:string,
	titlePartD?:string
};
	["HomepageMeta"]: {
		id?:ModelTypes["FieldMeta"],
	link?:ModelTypes["FieldMeta"],
	subtitle?:ModelTypes["FieldMeta"],
	seo?:ModelTypes["FieldMeta"],
	content?:ModelTypes["FieldMeta"],
	heroTiles?:ModelTypes["FieldMeta"],
	site?:ModelTypes["FieldMeta"],
	titlePartA?:ModelTypes["FieldMeta"],
	titlePartB?:ModelTypes["FieldMeta"],
	titlePartC?:ModelTypes["FieldMeta"],
	titlePartD?:ModelTypes["FieldMeta"]
};
	["Seo"]: {
		_meta?:ModelTypes["SeoMeta"],
	id:ModelTypes["UUID"],
	title?:string,
	description?:string,
	ogTitle?:string,
	ogDescription?:string,
	ogImage?:ModelTypes["Image"]
};
	["SeoMeta"]: {
		id?:ModelTypes["FieldMeta"],
	title?:ModelTypes["FieldMeta"],
	description?:ModelTypes["FieldMeta"],
	ogTitle?:ModelTypes["FieldMeta"],
	ogDescription?:ModelTypes["FieldMeta"],
	ogImage?:ModelTypes["FieldMeta"]
};
	["Image"]: {
		_meta?:ModelTypes["ImageMeta"],
	id:ModelTypes["UUID"],
	url:string,
	width?:number,
	height?:number,
	size?:number,
	type?:string,
	alt?:string
};
	["ImageMeta"]: {
		id?:ModelTypes["FieldMeta"],
	url?:ModelTypes["FieldMeta"],
	width?:ModelTypes["FieldMeta"],
	height?:ModelTypes["FieldMeta"],
	size?:ModelTypes["FieldMeta"],
	type?:ModelTypes["FieldMeta"],
	alt?:ModelTypes["FieldMeta"]
};
	["Content"]: {
		_meta?:ModelTypes["ContentMeta"],
	id:ModelTypes["UUID"],
	blocks:ModelTypes["ContentBlock"][],
	blocksByReferences?:ModelTypes["ContentBlock"],
	paginateBlocks:ModelTypes["ContentBlockConnection"]
};
	["ContentMeta"]: {
		id?:ModelTypes["FieldMeta"],
	blocks?:ModelTypes["FieldMeta"]
};
	["ContentBlock"]: {
		_meta?:ModelTypes["ContentBlockMeta"],
	id:ModelTypes["UUID"],
	order:number,
	json:string,
	content?:ModelTypes["Content"],
	references:ModelTypes["ContentReference"][],
	paginateReferences:ModelTypes["ContentReferenceConnection"]
};
	["ContentBlockMeta"]: {
		id?:ModelTypes["FieldMeta"],
	order?:ModelTypes["FieldMeta"],
	json?:ModelTypes["FieldMeta"],
	content?:ModelTypes["FieldMeta"],
	references?:ModelTypes["FieldMeta"]
};
	["ContentReference"]: {
		_meta?:ModelTypes["ContentReferenceMeta"],
	id:ModelTypes["UUID"],
	type:ModelTypes["ContentReferenceType"],
	primaryText?:string,
	block?:ModelTypes["ContentBlock"],
	image?:ModelTypes["Image"],
	highlightFirstArticle?:boolean,
	linkToMoreArticles?:boolean,
	people?:ModelTypes["PersonList"]
};
	["ContentReferenceMeta"]: {
		id?:ModelTypes["FieldMeta"],
	type?:ModelTypes["FieldMeta"],
	primaryText?:ModelTypes["FieldMeta"],
	block?:ModelTypes["FieldMeta"],
	image?:ModelTypes["FieldMeta"],
	highlightFirstArticle?:ModelTypes["FieldMeta"],
	linkToMoreArticles?:ModelTypes["FieldMeta"],
	people?:ModelTypes["FieldMeta"]
};
	["PersonList"]: {
		_meta?:ModelTypes["PersonListMeta"],
	id:ModelTypes["UUID"],
	items:ModelTypes["PersonListItem"][],
	paginateItems:ModelTypes["PersonListItemConnection"]
};
	["PersonListMeta"]: {
		id?:ModelTypes["FieldMeta"],
	items?:ModelTypes["FieldMeta"]
};
	["PersonListItem"]: {
		_meta?:ModelTypes["PersonListItemMeta"],
	id:ModelTypes["UUID"],
	order:number,
	list?:ModelTypes["PersonList"],
	person?:ModelTypes["Person"],
	about?:string
};
	["PersonListItemMeta"]: {
		id?:ModelTypes["FieldMeta"],
	order?:ModelTypes["FieldMeta"],
	list?:ModelTypes["FieldMeta"],
	person?:ModelTypes["FieldMeta"],
	about?:ModelTypes["FieldMeta"]
};
	["Person"]: {
		_meta?:ModelTypes["PersonMeta"],
	id:ModelTypes["UUID"],
	name?:string,
	about?:string,
	email?:string,
	phone?:string,
	image?:ModelTypes["Image"]
};
	["PersonMeta"]: {
		id?:ModelTypes["FieldMeta"],
	name?:ModelTypes["FieldMeta"],
	about?:ModelTypes["FieldMeta"],
	email?:ModelTypes["FieldMeta"],
	phone?:ModelTypes["FieldMeta"],
	image?:ModelTypes["FieldMeta"]
};
	["PersonListItemOrderBy"]: GraphQLTypes["PersonListItemOrderBy"];
	["OrderDirection"]: GraphQLTypes["OrderDirection"];
	["PersonListOrderBy"]: GraphQLTypes["PersonListOrderBy"];
	["PersonOrderBy"]: GraphQLTypes["PersonOrderBy"];
	["ImageOrderBy"]: GraphQLTypes["ImageOrderBy"];
	["PersonListItemConnection"]: {
		pageInfo:ModelTypes["PageInfo"],
	edges:ModelTypes["PersonListItemEdge"][]
};
	["PageInfo"]: {
		totalCount:number
};
	["PersonListItemEdge"]: {
		node:ModelTypes["PersonListItem"]
};
	["ContentReferenceOrderBy"]: GraphQLTypes["ContentReferenceOrderBy"];
	["ContentBlockOrderBy"]: GraphQLTypes["ContentBlockOrderBy"];
	["ContentOrderBy"]: GraphQLTypes["ContentOrderBy"];
	["ContentReferenceConnection"]: {
		pageInfo:ModelTypes["PageInfo"],
	edges:ModelTypes["ContentReferenceEdge"][]
};
	["ContentReferenceEdge"]: {
		node:ModelTypes["ContentReference"]
};
	["ContentBlocksByReferencesUniqueWhere"]: GraphQLTypes["ContentBlocksByReferencesUniqueWhere"];
	["ContentReferenceUniqueWhere"]: GraphQLTypes["ContentReferenceUniqueWhere"];
	["ContentBlockConnection"]: {
		pageInfo:ModelTypes["PageInfo"],
	edges:ModelTypes["ContentBlockEdge"][]
};
	["ContentBlockEdge"]: {
		node:ModelTypes["ContentBlock"]
};
	["TileList"]: {
		_meta?:ModelTypes["TileListMeta"],
	id:ModelTypes["UUID"],
	items:ModelTypes["TileListItem"][],
	itemsByTile?:ModelTypes["TileListItem"],
	paginateItems:ModelTypes["TileListItemConnection"]
};
	["TileListMeta"]: {
		id?:ModelTypes["FieldMeta"],
	items?:ModelTypes["FieldMeta"]
};
	["TileListItem"]: {
		_meta?:ModelTypes["TileListItemMeta"],
	id:ModelTypes["UUID"],
	order:number,
	list?:ModelTypes["TileList"],
	tile?:ModelTypes["Tile"]
};
	["TileListItemMeta"]: {
		id?:ModelTypes["FieldMeta"],
	order?:ModelTypes["FieldMeta"],
	list?:ModelTypes["FieldMeta"],
	tile?:ModelTypes["FieldMeta"]
};
	["Tile"]: {
		_meta?:ModelTypes["TileMeta"],
	id:ModelTypes["UUID"],
	image?:ModelTypes["Image"],
	link?:ModelTypes["Link"],
	isVideo?:boolean
};
	["TileMeta"]: {
		id?:ModelTypes["FieldMeta"],
	image?:ModelTypes["FieldMeta"],
	link?:ModelTypes["FieldMeta"],
	isVideo?:ModelTypes["FieldMeta"]
};
	["TileListItemOrderBy"]: GraphQLTypes["TileListItemOrderBy"];
	["TileListOrderBy"]: GraphQLTypes["TileListOrderBy"];
	["TileOrderBy"]: GraphQLTypes["TileOrderBy"];
	["LinkOrderBy"]: GraphQLTypes["LinkOrderBy"];
	["LinkableOrderBy"]: GraphQLTypes["LinkableOrderBy"];
	["RedirectOrderBy"]: GraphQLTypes["RedirectOrderBy"];
	["HomepageOrderBy"]: GraphQLTypes["HomepageOrderBy"];
	["SeoOrderBy"]: GraphQLTypes["SeoOrderBy"];
	["SiteOrderBy"]: GraphQLTypes["SiteOrderBy"];
	["ArticleListPageOrderBy"]: GraphQLTypes["ArticleListPageOrderBy"];
	["CourseListPageOrderBy"]: GraphQLTypes["CourseListPageOrderBy"];
	["PoseListPageOrderBy"]: GraphQLTypes["PoseListPageOrderBy"];
	["CourseLecturerListPageOrderBy"]: GraphQLTypes["CourseLecturerListPageOrderBy"];
	["SubscribeBoxOrderBy"]: GraphQLTypes["SubscribeBoxOrderBy"];
	["HeaderOrderBy"]: GraphQLTypes["HeaderOrderBy"];
	["FooterOrderBy"]: GraphQLTypes["FooterOrderBy"];
	["ListNavigationOrderBy"]: GraphQLTypes["ListNavigationOrderBy"];
	["CourseEmailOrderBy"]: GraphQLTypes["CourseEmailOrderBy"];
	["TranslationsOrderBy"]: GraphQLTypes["TranslationsOrderBy"];
	["ArticleOrderBy"]: GraphQLTypes["ArticleOrderBy"];
	["ArticleCategoryOrderBy"]: GraphQLTypes["ArticleCategoryOrderBy"];
	["PoseOrderBy"]: GraphQLTypes["PoseOrderBy"];
	["PoseCategoryOrderBy"]: GraphQLTypes["PoseCategoryOrderBy"];
	["PoseDifficultyOrderBy"]: GraphQLTypes["PoseDifficultyOrderBy"];
	["CourseOrderBy"]: GraphQLTypes["CourseOrderBy"];
	["YoutubeVideoOrderBy"]: GraphQLTypes["YoutubeVideoOrderBy"];
	["CourseLecturerOrderBy"]: GraphQLTypes["CourseLecturerOrderBy"];
	["NewsletterSubscribeSuccessPageOrderBy"]: GraphQLTypes["NewsletterSubscribeSuccessPageOrderBy"];
	["GenericPageOrderBy"]: GraphQLTypes["GenericPageOrderBy"];
	["TileListItemsByTileUniqueWhere"]: GraphQLTypes["TileListItemsByTileUniqueWhere"];
	["TileUniqueWhere"]: GraphQLTypes["TileUniqueWhere"];
	["LinkUniqueWhere"]: GraphQLTypes["LinkUniqueWhere"];
	["TileListItemConnection"]: {
		pageInfo:ModelTypes["PageInfo"],
	edges:ModelTypes["TileListItemEdge"][]
};
	["TileListItemEdge"]: {
		node:ModelTypes["TileListItem"]
};
	["Site"]: {
		_meta?:ModelTypes["SiteMeta"],
	id:ModelTypes["UUID"],
	code:string,
	order:number,
	articles:ModelTypes["Article"][],
	internalName?:string,
	articleCategories:ModelTypes["ArticleCategory"][],
	poses:ModelTypes["Pose"][],
	poseCategories:ModelTypes["PoseCategory"][],
	poseDifficulties:ModelTypes["PoseDifficulty"][],
	courses:ModelTypes["Course"][],
	articleListPage?:ModelTypes["ArticleListPage"],
	courseListPage?:ModelTypes["CourseListPage"],
	poseListPage?:ModelTypes["PoseListPage"],
	courseLecturers:ModelTypes["CourseLecturer"][],
	courseLecturerListPage?:ModelTypes["CourseLecturerListPage"],
	homePages?:ModelTypes["Homepage"],
	subscribeBox?:ModelTypes["SubscribeBox"],
	header?:ModelTypes["Header"],
	footer?:ModelTypes["Footer"],
	genericPages:ModelTypes["GenericPage"][],
	courseEmail?:ModelTypes["CourseEmail"],
	translations?:ModelTypes["Translations"],
	articlesBySeo?:ModelTypes["Article"],
	articlesByLink?:ModelTypes["Article"],
	articlesByContent?:ModelTypes["Article"],
	articleCategoriesByArticles?:ModelTypes["ArticleCategory"],
	articleCategoriesBySeo?:ModelTypes["ArticleCategory"],
	articleCategoriesByLink?:ModelTypes["ArticleCategory"],
	posesByLink?:ModelTypes["Pose"],
	posesBySeo?:ModelTypes["Pose"],
	posesByContent?:ModelTypes["Pose"],
	poseCategoriesByPoses?:ModelTypes["PoseCategory"],
	poseDifficultiesByPoses?:ModelTypes["PoseDifficulty"],
	coursesBySeo?:ModelTypes["Course"],
	coursesByLink?:ModelTypes["Course"],
	coursesByLessons?:ModelTypes["Course"],
	coursesByContent?:ModelTypes["Course"],
	courseLecturersByCourses?:ModelTypes["CourseLecturer"],
	courseLecturersByLink?:ModelTypes["CourseLecturer"],
	courseLecturersBySeo?:ModelTypes["CourseLecturer"],
	courseLecturersByContent?:ModelTypes["CourseLecturer"],
	genericPagesByContent?:ModelTypes["GenericPage"],
	genericPagesBySeo?:ModelTypes["GenericPage"],
	genericPagesByLink?:ModelTypes["GenericPage"],
	paginateArticles:ModelTypes["ArticleConnection"],
	paginateArticleCategories:ModelTypes["ArticleCategoryConnection"],
	paginatePoses:ModelTypes["PoseConnection"],
	paginatePoseCategories:ModelTypes["PoseCategoryConnection"],
	paginatePoseDifficulties:ModelTypes["PoseDifficultyConnection"],
	paginateCourses:ModelTypes["CourseConnection"],
	paginateCourseLecturers:ModelTypes["CourseLecturerConnection"],
	paginateGenericPages:ModelTypes["GenericPageConnection"]
};
	["SiteMeta"]: {
		id?:ModelTypes["FieldMeta"],
	code?:ModelTypes["FieldMeta"],
	order?:ModelTypes["FieldMeta"],
	articles?:ModelTypes["FieldMeta"],
	internalName?:ModelTypes["FieldMeta"],
	articleCategories?:ModelTypes["FieldMeta"],
	poses?:ModelTypes["FieldMeta"],
	poseCategories?:ModelTypes["FieldMeta"],
	poseDifficulties?:ModelTypes["FieldMeta"],
	courses?:ModelTypes["FieldMeta"],
	articleListPage?:ModelTypes["FieldMeta"],
	courseListPage?:ModelTypes["FieldMeta"],
	poseListPage?:ModelTypes["FieldMeta"],
	courseLecturers?:ModelTypes["FieldMeta"],
	courseLecturerListPage?:ModelTypes["FieldMeta"],
	homePages?:ModelTypes["FieldMeta"],
	subscribeBox?:ModelTypes["FieldMeta"],
	header?:ModelTypes["FieldMeta"],
	footer?:ModelTypes["FieldMeta"],
	genericPages?:ModelTypes["FieldMeta"],
	courseEmail?:ModelTypes["FieldMeta"],
	translations?:ModelTypes["FieldMeta"]
};
	["Article"]: {
		_meta?:ModelTypes["ArticleMeta"],
	id:ModelTypes["UUID"],
	headline?:string,
	publishedAt?:ModelTypes["Date"],
	site?:ModelTypes["Site"],
	seo?:ModelTypes["Seo"],
	link?:ModelTypes["Linkable"],
	lead?:string,
	shortLead?:string,
	image?:ModelTypes["Image"],
	content?:ModelTypes["Content"],
	category?:ModelTypes["ArticleCategory"]
};
	["ArticleMeta"]: {
		id?:ModelTypes["FieldMeta"],
	headline?:ModelTypes["FieldMeta"],
	publishedAt?:ModelTypes["FieldMeta"],
	site?:ModelTypes["FieldMeta"],
	seo?:ModelTypes["FieldMeta"],
	link?:ModelTypes["FieldMeta"],
	lead?:ModelTypes["FieldMeta"],
	shortLead?:ModelTypes["FieldMeta"],
	image?:ModelTypes["FieldMeta"],
	content?:ModelTypes["FieldMeta"],
	category?:ModelTypes["FieldMeta"]
};
	["ArticleCategory"]: {
		_meta?:ModelTypes["ArticleCategoryMeta"],
	id:ModelTypes["UUID"],
	label?:string,
	articles:ModelTypes["Article"][],
	site?:ModelTypes["Site"],
	seo?:ModelTypes["Seo"],
	link?:ModelTypes["Linkable"],
	lead?:string,
	originalPath?:string,
	articlesBySeo?:ModelTypes["Article"],
	articlesByLink?:ModelTypes["Article"],
	articlesByContent?:ModelTypes["Article"],
	paginateArticles:ModelTypes["ArticleConnection"]
};
	["ArticleCategoryMeta"]: {
		id?:ModelTypes["FieldMeta"],
	label?:ModelTypes["FieldMeta"],
	articles?:ModelTypes["FieldMeta"],
	site?:ModelTypes["FieldMeta"],
	seo?:ModelTypes["FieldMeta"],
	link?:ModelTypes["FieldMeta"],
	lead?:ModelTypes["FieldMeta"],
	originalPath?:ModelTypes["FieldMeta"]
};
	["ArticleCategoryArticlesBySeoUniqueWhere"]: GraphQLTypes["ArticleCategoryArticlesBySeoUniqueWhere"];
	["SeoUniqueWhere"]: GraphQLTypes["SeoUniqueWhere"];
	["ImageUniqueWhere"]: GraphQLTypes["ImageUniqueWhere"];
	["ArticleCategoryArticlesByLinkUniqueWhere"]: GraphQLTypes["ArticleCategoryArticlesByLinkUniqueWhere"];
	["LinkableUniqueWhere"]: GraphQLTypes["LinkableUniqueWhere"];
	["RedirectUniqueWhere"]: GraphQLTypes["RedirectUniqueWhere"];
	["HomepageUniqueWhere"]: GraphQLTypes["HomepageUniqueWhere"];
	["ContentUniqueWhere"]: GraphQLTypes["ContentUniqueWhere"];
	["ContentBlockUniqueWhere"]: GraphQLTypes["ContentBlockUniqueWhere"];
	["TileListUniqueWhere"]: GraphQLTypes["TileListUniqueWhere"];
	["TileListItemUniqueWhere"]: GraphQLTypes["TileListItemUniqueWhere"];
	["SiteUniqueWhere"]: GraphQLTypes["SiteUniqueWhere"];
	["ArticleUniqueWhere"]: GraphQLTypes["ArticleUniqueWhere"];
	["ArticleCategoryUniqueWhere"]: GraphQLTypes["ArticleCategoryUniqueWhere"];
	["PoseUniqueWhere"]: GraphQLTypes["PoseUniqueWhere"];
	["PoseCategoryUniqueWhere"]: GraphQLTypes["PoseCategoryUniqueWhere"];
	["PoseDifficultyUniqueWhere"]: GraphQLTypes["PoseDifficultyUniqueWhere"];
	["CourseUniqueWhere"]: GraphQLTypes["CourseUniqueWhere"];
	["CourseLessonUniqueWhere"]: GraphQLTypes["CourseLessonUniqueWhere"];
	["ArticleListPageUniqueWhere"]: GraphQLTypes["ArticleListPageUniqueWhere"];
	["CourseListPageUniqueWhere"]: GraphQLTypes["CourseListPageUniqueWhere"];
	["PoseListPageUniqueWhere"]: GraphQLTypes["PoseListPageUniqueWhere"];
	["CourseLecturerUniqueWhere"]: GraphQLTypes["CourseLecturerUniqueWhere"];
	["CourseLecturerListPageUniqueWhere"]: GraphQLTypes["CourseLecturerListPageUniqueWhere"];
	["SubscribeBoxUniqueWhere"]: GraphQLTypes["SubscribeBoxUniqueWhere"];
	["HeaderUniqueWhere"]: GraphQLTypes["HeaderUniqueWhere"];
	["HeaderPrimaryNavigationItemUniqueWhere"]: GraphQLTypes["HeaderPrimaryNavigationItemUniqueWhere"];
	["ListNavigationUniqueWhere"]: GraphQLTypes["ListNavigationUniqueWhere"];
	["ListNavigationColumnUniqueWhere"]: GraphQLTypes["ListNavigationColumnUniqueWhere"];
	["ListNavigationColumnItemUniqueWhere"]: GraphQLTypes["ListNavigationColumnItemUniqueWhere"];
	["FooterUniqueWhere"]: GraphQLTypes["FooterUniqueWhere"];
	["GenericPageUniqueWhere"]: GraphQLTypes["GenericPageUniqueWhere"];
	["CourseEmailUniqueWhere"]: GraphQLTypes["CourseEmailUniqueWhere"];
	["TranslationsUniqueWhere"]: GraphQLTypes["TranslationsUniqueWhere"];
	["NewsletterSubscribeSuccessPageUniqueWhere"]: GraphQLTypes["NewsletterSubscribeSuccessPageUniqueWhere"];
	["ArticleCategoryArticlesByContentUniqueWhere"]: GraphQLTypes["ArticleCategoryArticlesByContentUniqueWhere"];
	["ArticleConnection"]: {
		pageInfo:ModelTypes["PageInfo"],
	edges:ModelTypes["ArticleEdge"][]
};
	["ArticleEdge"]: {
		node:ModelTypes["Article"]
};
	["Pose"]: {
		_meta?:ModelTypes["PoseMeta"],
	id:ModelTypes["UUID"],
	headline?:string,
	publishedAt?:ModelTypes["Date"],
	link?:ModelTypes["Linkable"],
	site?:ModelTypes["Site"],
	image?:ModelTypes["Image"],
	seo?:ModelTypes["Seo"],
	subtitle?:string,
	content?:ModelTypes["Content"],
	category?:ModelTypes["PoseCategory"],
	difficulty?:ModelTypes["PoseDifficulty"]
};
	["PoseMeta"]: {
		id?:ModelTypes["FieldMeta"],
	headline?:ModelTypes["FieldMeta"],
	publishedAt?:ModelTypes["FieldMeta"],
	link?:ModelTypes["FieldMeta"],
	site?:ModelTypes["FieldMeta"],
	image?:ModelTypes["FieldMeta"],
	seo?:ModelTypes["FieldMeta"],
	subtitle?:ModelTypes["FieldMeta"],
	content?:ModelTypes["FieldMeta"],
	category?:ModelTypes["FieldMeta"],
	difficulty?:ModelTypes["FieldMeta"]
};
	["PoseCategory"]: {
		_meta?:ModelTypes["PoseCategoryMeta"],
	id:ModelTypes["UUID"],
	label?:string,
	poses:ModelTypes["Pose"][],
	site?:ModelTypes["Site"],
	icon?:ModelTypes["Image"],
	posesByLink?:ModelTypes["Pose"],
	posesBySeo?:ModelTypes["Pose"],
	posesByContent?:ModelTypes["Pose"],
	paginatePoses:ModelTypes["PoseConnection"]
};
	["PoseCategoryMeta"]: {
		id?:ModelTypes["FieldMeta"],
	label?:ModelTypes["FieldMeta"],
	poses?:ModelTypes["FieldMeta"],
	site?:ModelTypes["FieldMeta"],
	icon?:ModelTypes["FieldMeta"]
};
	["PoseCategoryPosesByLinkUniqueWhere"]: GraphQLTypes["PoseCategoryPosesByLinkUniqueWhere"];
	["PoseCategoryPosesBySeoUniqueWhere"]: GraphQLTypes["PoseCategoryPosesBySeoUniqueWhere"];
	["PoseCategoryPosesByContentUniqueWhere"]: GraphQLTypes["PoseCategoryPosesByContentUniqueWhere"];
	["PoseConnection"]: {
		pageInfo:ModelTypes["PageInfo"],
	edges:ModelTypes["PoseEdge"][]
};
	["PoseEdge"]: {
		node:ModelTypes["Pose"]
};
	["PoseDifficulty"]: {
		_meta?:ModelTypes["PoseDifficultyMeta"],
	id:ModelTypes["UUID"],
	label?:string,
	poses:ModelTypes["Pose"][],
	site?:ModelTypes["Site"],
	icon?:ModelTypes["Image"],
	posesByLink?:ModelTypes["Pose"],
	posesBySeo?:ModelTypes["Pose"],
	posesByContent?:ModelTypes["Pose"],
	paginatePoses:ModelTypes["PoseConnection"]
};
	["PoseDifficultyMeta"]: {
		id?:ModelTypes["FieldMeta"],
	label?:ModelTypes["FieldMeta"],
	poses?:ModelTypes["FieldMeta"],
	site?:ModelTypes["FieldMeta"],
	icon?:ModelTypes["FieldMeta"]
};
	["PoseDifficultyPosesByLinkUniqueWhere"]: GraphQLTypes["PoseDifficultyPosesByLinkUniqueWhere"];
	["PoseDifficultyPosesBySeoUniqueWhere"]: GraphQLTypes["PoseDifficultyPosesBySeoUniqueWhere"];
	["PoseDifficultyPosesByContentUniqueWhere"]: GraphQLTypes["PoseDifficultyPosesByContentUniqueWhere"];
	["Course"]: {
		_meta?:ModelTypes["CourseMeta"],
	id:ModelTypes["UUID"],
	headline?:string,
	lead?:string,
	shortLead?:string,
	publishedAt?:ModelTypes["Date"],
	site?:ModelTypes["Site"],
	image?:ModelTypes["Image"],
	seo?:ModelTypes["Seo"],
	link?:ModelTypes["Linkable"],
	lessons:ModelTypes["CourseLesson"][],
	difficulty?:string,
	teaser?:ModelTypes["YoutubeVideo"],
	lessonIntervalInDays:number,
	content?:ModelTypes["Content"],
	lecturer?:ModelTypes["CourseLecturer"],
	paginateLessons:ModelTypes["CourseLessonConnection"]
};
	["CourseMeta"]: {
		id?:ModelTypes["FieldMeta"],
	headline?:ModelTypes["FieldMeta"],
	lead?:ModelTypes["FieldMeta"],
	shortLead?:ModelTypes["FieldMeta"],
	publishedAt?:ModelTypes["FieldMeta"],
	site?:ModelTypes["FieldMeta"],
	image?:ModelTypes["FieldMeta"],
	seo?:ModelTypes["FieldMeta"],
	link?:ModelTypes["FieldMeta"],
	lessons?:ModelTypes["FieldMeta"],
	difficulty?:ModelTypes["FieldMeta"],
	teaser?:ModelTypes["FieldMeta"],
	lessonIntervalInDays?:ModelTypes["FieldMeta"],
	content?:ModelTypes["FieldMeta"],
	lecturer?:ModelTypes["FieldMeta"]
};
	["CourseLesson"]: {
		_meta?:ModelTypes["CourseLessonMeta"],
	id:ModelTypes["UUID"],
	order:number,
	headline?:string,
	lead?:string,
	course?:ModelTypes["Course"],
	video?:ModelTypes["YoutubeVideo"]
};
	["CourseLessonMeta"]: {
		id?:ModelTypes["FieldMeta"],
	order?:ModelTypes["FieldMeta"],
	headline?:ModelTypes["FieldMeta"],
	lead?:ModelTypes["FieldMeta"],
	course?:ModelTypes["FieldMeta"],
	video?:ModelTypes["FieldMeta"]
};
	["YoutubeVideo"]: {
		_meta?:ModelTypes["YoutubeVideoMeta"],
	id:ModelTypes["UUID"],
	videoId:string
};
	["YoutubeVideoMeta"]: {
		id?:ModelTypes["FieldMeta"],
	videoId?:ModelTypes["FieldMeta"]
};
	["CourseLessonOrderBy"]: GraphQLTypes["CourseLessonOrderBy"];
	["CourseLecturer"]: {
		_meta?:ModelTypes["CourseLecturerMeta"],
	id:ModelTypes["UUID"],
	name?:string,
	courses:ModelTypes["Course"][],
	site?:ModelTypes["Site"],
	link?:ModelTypes["Linkable"],
	seo?:ModelTypes["Seo"],
	image?:ModelTypes["Image"],
	content?:ModelTypes["Content"],
	coursesBySeo?:ModelTypes["Course"],
	coursesByLink?:ModelTypes["Course"],
	coursesByLessons?:ModelTypes["Course"],
	coursesByContent?:ModelTypes["Course"],
	paginateCourses:ModelTypes["CourseConnection"]
};
	["CourseLecturerMeta"]: {
		id?:ModelTypes["FieldMeta"],
	name?:ModelTypes["FieldMeta"],
	courses?:ModelTypes["FieldMeta"],
	site?:ModelTypes["FieldMeta"],
	link?:ModelTypes["FieldMeta"],
	seo?:ModelTypes["FieldMeta"],
	image?:ModelTypes["FieldMeta"],
	content?:ModelTypes["FieldMeta"]
};
	["CourseLecturerCoursesBySeoUniqueWhere"]: GraphQLTypes["CourseLecturerCoursesBySeoUniqueWhere"];
	["CourseLecturerCoursesByLinkUniqueWhere"]: GraphQLTypes["CourseLecturerCoursesByLinkUniqueWhere"];
	["CourseLecturerCoursesByLessonsUniqueWhere"]: GraphQLTypes["CourseLecturerCoursesByLessonsUniqueWhere"];
	["CourseLecturerCoursesByContentUniqueWhere"]: GraphQLTypes["CourseLecturerCoursesByContentUniqueWhere"];
	["CourseConnection"]: {
		pageInfo:ModelTypes["PageInfo"],
	edges:ModelTypes["CourseEdge"][]
};
	["CourseEdge"]: {
		node:ModelTypes["Course"]
};
	["CourseLessonConnection"]: {
		pageInfo:ModelTypes["PageInfo"],
	edges:ModelTypes["CourseLessonEdge"][]
};
	["CourseLessonEdge"]: {
		node:ModelTypes["CourseLesson"]
};
	["ArticleListPage"]: {
		_meta?:ModelTypes["ArticleListPageMeta"],
	id:ModelTypes["UUID"],
	seo?:ModelTypes["Seo"],
	link?:ModelTypes["Linkable"],
	title?:string,
	site?:ModelTypes["Site"]
};
	["ArticleListPageMeta"]: {
		id?:ModelTypes["FieldMeta"],
	seo?:ModelTypes["FieldMeta"],
	link?:ModelTypes["FieldMeta"],
	title?:ModelTypes["FieldMeta"],
	site?:ModelTypes["FieldMeta"]
};
	["CourseListPage"]: {
		_meta?:ModelTypes["CourseListPageMeta"],
	id:ModelTypes["UUID"],
	seo?:ModelTypes["Seo"],
	link?:ModelTypes["Linkable"],
	title?:string,
	site?:ModelTypes["Site"]
};
	["CourseListPageMeta"]: {
		id?:ModelTypes["FieldMeta"],
	seo?:ModelTypes["FieldMeta"],
	link?:ModelTypes["FieldMeta"],
	title?:ModelTypes["FieldMeta"],
	site?:ModelTypes["FieldMeta"]
};
	["PoseListPage"]: {
		_meta?:ModelTypes["PoseListPageMeta"],
	id:ModelTypes["UUID"],
	link?:ModelTypes["Linkable"],
	seo?:ModelTypes["Seo"],
	title?:string,
	site?:ModelTypes["Site"]
};
	["PoseListPageMeta"]: {
		id?:ModelTypes["FieldMeta"],
	link?:ModelTypes["FieldMeta"],
	seo?:ModelTypes["FieldMeta"],
	title?:ModelTypes["FieldMeta"],
	site?:ModelTypes["FieldMeta"]
};
	["CourseLecturerListPage"]: {
		_meta?:ModelTypes["CourseLecturerListPageMeta"],
	id:ModelTypes["UUID"],
	title?:string,
	site?:ModelTypes["Site"],
	seo?:ModelTypes["Seo"],
	link?:ModelTypes["Linkable"]
};
	["CourseLecturerListPageMeta"]: {
		id?:ModelTypes["FieldMeta"],
	title?:ModelTypes["FieldMeta"],
	site?:ModelTypes["FieldMeta"],
	seo?:ModelTypes["FieldMeta"],
	link?:ModelTypes["FieldMeta"]
};
	["SubscribeBox"]: {
		_meta?:ModelTypes["SubscribeBoxMeta"],
	id:ModelTypes["UUID"],
	title?:string,
	text?:string,
	shapeColorA:string,
	shapeColorB:string,
	shapeColorC:string,
	externalMailingListId:string,
	site?:ModelTypes["Site"],
	logo?:ModelTypes["Image"],
	emailLabel?:string,
	submitLabel?:string
};
	["SubscribeBoxMeta"]: {
		id?:ModelTypes["FieldMeta"],
	title?:ModelTypes["FieldMeta"],
	text?:ModelTypes["FieldMeta"],
	shapeColorA?:ModelTypes["FieldMeta"],
	shapeColorB?:ModelTypes["FieldMeta"],
	shapeColorC?:ModelTypes["FieldMeta"],
	externalMailingListId?:ModelTypes["FieldMeta"],
	site?:ModelTypes["FieldMeta"],
	logo?:ModelTypes["FieldMeta"],
	emailLabel?:ModelTypes["FieldMeta"],
	submitLabel?:ModelTypes["FieldMeta"]
};
	["Header"]: {
		_meta?:ModelTypes["HeaderMeta"],
	id:ModelTypes["UUID"],
	primaryNavigationItems:ModelTypes["HeaderPrimaryNavigationItem"][],
	site?:ModelTypes["Site"],
	logo?:ModelTypes["Image"],
	logoOnWhite?:ModelTypes["Image"],
	primaryNavigationItemsByLink?:ModelTypes["HeaderPrimaryNavigationItem"],
	primaryNavigationItemsByListNavigation?:ModelTypes["HeaderPrimaryNavigationItem"],
	primaryNavigationItemsByTiles?:ModelTypes["HeaderPrimaryNavigationItem"],
	paginatePrimaryNavigationItems:ModelTypes["HeaderPrimaryNavigationItemConnection"]
};
	["HeaderMeta"]: {
		id?:ModelTypes["FieldMeta"],
	primaryNavigationItems?:ModelTypes["FieldMeta"],
	site?:ModelTypes["FieldMeta"],
	logo?:ModelTypes["FieldMeta"],
	logoOnWhite?:ModelTypes["FieldMeta"]
};
	["HeaderPrimaryNavigationItem"]: {
		_meta?:ModelTypes["HeaderPrimaryNavigationItemMeta"],
	id:ModelTypes["UUID"],
	order?:number,
	header?:ModelTypes["Header"],
	link?:ModelTypes["Link"],
	listNavigation?:ModelTypes["ListNavigation"],
	tiles?:ModelTypes["TileList"]
};
	["HeaderPrimaryNavigationItemMeta"]: {
		id?:ModelTypes["FieldMeta"],
	order?:ModelTypes["FieldMeta"],
	header?:ModelTypes["FieldMeta"],
	link?:ModelTypes["FieldMeta"],
	listNavigation?:ModelTypes["FieldMeta"],
	tiles?:ModelTypes["FieldMeta"]
};
	["ListNavigation"]: {
		_meta?:ModelTypes["ListNavigationMeta"],
	id:ModelTypes["UUID"],
	columns:ModelTypes["ListNavigationColumn"][],
	columnsByItems?:ModelTypes["ListNavigationColumn"],
	paginateColumns:ModelTypes["ListNavigationColumnConnection"]
};
	["ListNavigationMeta"]: {
		id?:ModelTypes["FieldMeta"],
	columns?:ModelTypes["FieldMeta"]
};
	["ListNavigationColumn"]: {
		_meta?:ModelTypes["ListNavigationColumnMeta"],
	id:ModelTypes["UUID"],
	order?:number,
	label?:string,
	listNavigation?:ModelTypes["ListNavigation"],
	items:ModelTypes["ListNavigationColumnItem"][],
	itemsByLink?:ModelTypes["ListNavigationColumnItem"],
	paginateItems:ModelTypes["ListNavigationColumnItemConnection"]
};
	["ListNavigationColumnMeta"]: {
		id?:ModelTypes["FieldMeta"],
	order?:ModelTypes["FieldMeta"],
	label?:ModelTypes["FieldMeta"],
	listNavigation?:ModelTypes["FieldMeta"],
	items?:ModelTypes["FieldMeta"]
};
	["ListNavigationColumnItem"]: {
		_meta?:ModelTypes["ListNavigationColumnItemMeta"],
	id:ModelTypes["UUID"],
	order?:number,
	column?:ModelTypes["ListNavigationColumn"],
	link?:ModelTypes["Link"]
};
	["ListNavigationColumnItemMeta"]: {
		id?:ModelTypes["FieldMeta"],
	order?:ModelTypes["FieldMeta"],
	column?:ModelTypes["FieldMeta"],
	link?:ModelTypes["FieldMeta"]
};
	["ListNavigationColumnItemOrderBy"]: GraphQLTypes["ListNavigationColumnItemOrderBy"];
	["ListNavigationColumnOrderBy"]: GraphQLTypes["ListNavigationColumnOrderBy"];
	["ListNavigationColumnItemsByLinkUniqueWhere"]: GraphQLTypes["ListNavigationColumnItemsByLinkUniqueWhere"];
	["ListNavigationColumnItemConnection"]: {
		pageInfo:ModelTypes["PageInfo"],
	edges:ModelTypes["ListNavigationColumnItemEdge"][]
};
	["ListNavigationColumnItemEdge"]: {
		node:ModelTypes["ListNavigationColumnItem"]
};
	["ListNavigationColumnsByItemsUniqueWhere"]: GraphQLTypes["ListNavigationColumnsByItemsUniqueWhere"];
	["ListNavigationColumnConnection"]: {
		pageInfo:ModelTypes["PageInfo"],
	edges:ModelTypes["ListNavigationColumnEdge"][]
};
	["ListNavigationColumnEdge"]: {
		node:ModelTypes["ListNavigationColumn"]
};
	["HeaderPrimaryNavigationItemOrderBy"]: GraphQLTypes["HeaderPrimaryNavigationItemOrderBy"];
	["HeaderPrimaryNavigationItemsByLinkUniqueWhere"]: GraphQLTypes["HeaderPrimaryNavigationItemsByLinkUniqueWhere"];
	["HeaderPrimaryNavigationItemsByListNavigationUniqueWhere"]: GraphQLTypes["HeaderPrimaryNavigationItemsByListNavigationUniqueWhere"];
	["HeaderPrimaryNavigationItemsByTilesUniqueWhere"]: GraphQLTypes["HeaderPrimaryNavigationItemsByTilesUniqueWhere"];
	["HeaderPrimaryNavigationItemConnection"]: {
		pageInfo:ModelTypes["PageInfo"],
	edges:ModelTypes["HeaderPrimaryNavigationItemEdge"][]
};
	["HeaderPrimaryNavigationItemEdge"]: {
		node:ModelTypes["HeaderPrimaryNavigationItem"]
};
	["Footer"]: {
		_meta?:ModelTypes["FooterMeta"],
	id:ModelTypes["UUID"],
	navigation?:ModelTypes["ListNavigation"],
	site?:ModelTypes["Site"],
	logo?:ModelTypes["Image"],
	copyright?:string,
	attribution?:string
};
	["FooterMeta"]: {
		id?:ModelTypes["FieldMeta"],
	navigation?:ModelTypes["FieldMeta"],
	site?:ModelTypes["FieldMeta"],
	logo?:ModelTypes["FieldMeta"],
	copyright?:ModelTypes["FieldMeta"],
	attribution?:ModelTypes["FieldMeta"]
};
	["GenericPage"]: {
		_meta?:ModelTypes["GenericPageMeta"],
	id:ModelTypes["UUID"],
	headline?:string,
	lead?:string,
	publishedAt?:ModelTypes["Date"],
	site?:ModelTypes["Site"],
	content?:ModelTypes["Content"],
	seo?:ModelTypes["Seo"],
	link?:ModelTypes["Linkable"]
};
	["GenericPageMeta"]: {
		id?:ModelTypes["FieldMeta"],
	headline?:ModelTypes["FieldMeta"],
	lead?:ModelTypes["FieldMeta"],
	publishedAt?:ModelTypes["FieldMeta"],
	site?:ModelTypes["FieldMeta"],
	content?:ModelTypes["FieldMeta"],
	seo?:ModelTypes["FieldMeta"],
	link?:ModelTypes["FieldMeta"]
};
	["CourseEmail"]: {
		_meta?:ModelTypes["CourseEmailMeta"],
	id:ModelTypes["UUID"],
	title?:string,
	text?:string,
	buttonLabel?:string,
	logo?:ModelTypes["Image"],
	cover?:ModelTypes["Image"],
	site?:ModelTypes["Site"],
	contactInfoEmailAddress?:string,
	senderEmail?:string
};
	["CourseEmailMeta"]: {
		id?:ModelTypes["FieldMeta"],
	title?:ModelTypes["FieldMeta"],
	text?:ModelTypes["FieldMeta"],
	buttonLabel?:ModelTypes["FieldMeta"],
	logo?:ModelTypes["FieldMeta"],
	cover?:ModelTypes["FieldMeta"],
	site?:ModelTypes["FieldMeta"],
	contactInfoEmailAddress?:ModelTypes["FieldMeta"],
	senderEmail?:ModelTypes["FieldMeta"]
};
	["Translations"]: {
		_meta?:ModelTypes["TranslationsMeta"],
	id:ModelTypes["UUID"],
	home?:string,
	articles?:string,
	article?:string,
	articleMore?:string,
	categories?:string,
	lecturers?:string,
	lecturer?:string,
	courses?:string,
	course?:string,
	poses?:string,
	pose?:string,
	site?:ModelTypes["Site"],
	posesLong?:string,
	posesMore?:string,
	listAll?:string,
	difficulty?:string,
	skillLevel?:string,
	signIn?:string,
	error?:string
};
	["TranslationsMeta"]: {
		id?:ModelTypes["FieldMeta"],
	home?:ModelTypes["FieldMeta"],
	articles?:ModelTypes["FieldMeta"],
	article?:ModelTypes["FieldMeta"],
	articleMore?:ModelTypes["FieldMeta"],
	categories?:ModelTypes["FieldMeta"],
	lecturers?:ModelTypes["FieldMeta"],
	lecturer?:ModelTypes["FieldMeta"],
	courses?:ModelTypes["FieldMeta"],
	course?:ModelTypes["FieldMeta"],
	poses?:ModelTypes["FieldMeta"],
	pose?:ModelTypes["FieldMeta"],
	site?:ModelTypes["FieldMeta"],
	posesLong?:ModelTypes["FieldMeta"],
	posesMore?:ModelTypes["FieldMeta"],
	listAll?:ModelTypes["FieldMeta"],
	difficulty?:ModelTypes["FieldMeta"],
	skillLevel?:ModelTypes["FieldMeta"],
	signIn?:ModelTypes["FieldMeta"],
	error?:ModelTypes["FieldMeta"]
};
	["SiteArticlesBySeoUniqueWhere"]: GraphQLTypes["SiteArticlesBySeoUniqueWhere"];
	["SiteArticlesByLinkUniqueWhere"]: GraphQLTypes["SiteArticlesByLinkUniqueWhere"];
	["SiteArticlesByContentUniqueWhere"]: GraphQLTypes["SiteArticlesByContentUniqueWhere"];
	["SiteArticleCategoriesByArticlesUniqueWhere"]: GraphQLTypes["SiteArticleCategoriesByArticlesUniqueWhere"];
	["SiteArticleCategoriesBySeoUniqueWhere"]: GraphQLTypes["SiteArticleCategoriesBySeoUniqueWhere"];
	["SiteArticleCategoriesByLinkUniqueWhere"]: GraphQLTypes["SiteArticleCategoriesByLinkUniqueWhere"];
	["SitePosesByLinkUniqueWhere"]: GraphQLTypes["SitePosesByLinkUniqueWhere"];
	["SitePosesBySeoUniqueWhere"]: GraphQLTypes["SitePosesBySeoUniqueWhere"];
	["SitePosesByContentUniqueWhere"]: GraphQLTypes["SitePosesByContentUniqueWhere"];
	["SitePoseCategoriesByPosesUniqueWhere"]: GraphQLTypes["SitePoseCategoriesByPosesUniqueWhere"];
	["SitePoseDifficultiesByPosesUniqueWhere"]: GraphQLTypes["SitePoseDifficultiesByPosesUniqueWhere"];
	["SiteCoursesBySeoUniqueWhere"]: GraphQLTypes["SiteCoursesBySeoUniqueWhere"];
	["SiteCoursesByLinkUniqueWhere"]: GraphQLTypes["SiteCoursesByLinkUniqueWhere"];
	["SiteCoursesByLessonsUniqueWhere"]: GraphQLTypes["SiteCoursesByLessonsUniqueWhere"];
	["SiteCoursesByContentUniqueWhere"]: GraphQLTypes["SiteCoursesByContentUniqueWhere"];
	["SiteCourseLecturersByCoursesUniqueWhere"]: GraphQLTypes["SiteCourseLecturersByCoursesUniqueWhere"];
	["SiteCourseLecturersByLinkUniqueWhere"]: GraphQLTypes["SiteCourseLecturersByLinkUniqueWhere"];
	["SiteCourseLecturersBySeoUniqueWhere"]: GraphQLTypes["SiteCourseLecturersBySeoUniqueWhere"];
	["SiteCourseLecturersByContentUniqueWhere"]: GraphQLTypes["SiteCourseLecturersByContentUniqueWhere"];
	["SiteGenericPagesByContentUniqueWhere"]: GraphQLTypes["SiteGenericPagesByContentUniqueWhere"];
	["SiteGenericPagesBySeoUniqueWhere"]: GraphQLTypes["SiteGenericPagesBySeoUniqueWhere"];
	["SiteGenericPagesByLinkUniqueWhere"]: GraphQLTypes["SiteGenericPagesByLinkUniqueWhere"];
	["ArticleCategoryConnection"]: {
		pageInfo:ModelTypes["PageInfo"],
	edges:ModelTypes["ArticleCategoryEdge"][]
};
	["ArticleCategoryEdge"]: {
		node:ModelTypes["ArticleCategory"]
};
	["PoseCategoryConnection"]: {
		pageInfo:ModelTypes["PageInfo"],
	edges:ModelTypes["PoseCategoryEdge"][]
};
	["PoseCategoryEdge"]: {
		node:ModelTypes["PoseCategory"]
};
	["PoseDifficultyConnection"]: {
		pageInfo:ModelTypes["PageInfo"],
	edges:ModelTypes["PoseDifficultyEdge"][]
};
	["PoseDifficultyEdge"]: {
		node:ModelTypes["PoseDifficulty"]
};
	["CourseLecturerConnection"]: {
		pageInfo:ModelTypes["PageInfo"],
	edges:ModelTypes["CourseLecturerEdge"][]
};
	["CourseLecturerEdge"]: {
		node:ModelTypes["CourseLecturer"]
};
	["GenericPageConnection"]: {
		pageInfo:ModelTypes["PageInfo"],
	edges:ModelTypes["GenericPageEdge"][]
};
	["GenericPageEdge"]: {
		node:ModelTypes["GenericPage"]
};
	["NewsletterSubscribeSuccessPage"]: {
		_meta?:ModelTypes["NewsletterSubscribeSuccessPageMeta"],
	id:ModelTypes["UUID"],
	title?:string,
	returnLinkText?:string,
	link?:ModelTypes["Linkable"],
	site?:ModelTypes["Site"],
	seo?:ModelTypes["Seo"]
};
	["NewsletterSubscribeSuccessPageMeta"]: {
		id?:ModelTypes["FieldMeta"],
	title?:ModelTypes["FieldMeta"],
	returnLinkText?:ModelTypes["FieldMeta"],
	link?:ModelTypes["FieldMeta"],
	site?:ModelTypes["FieldMeta"],
	seo?:ModelTypes["FieldMeta"]
};
	["LinkConnection"]: {
		pageInfo:ModelTypes["PageInfo"],
	edges:ModelTypes["LinkEdge"][]
};
	["LinkEdge"]: {
		node:ModelTypes["Link"]
};
	["LinkableConnection"]: {
		pageInfo:ModelTypes["PageInfo"],
	edges:ModelTypes["LinkableEdge"][]
};
	["LinkableEdge"]: {
		node:ModelTypes["Linkable"]
};
	["RedirectConnection"]: {
		pageInfo:ModelTypes["PageInfo"],
	edges:ModelTypes["RedirectEdge"][]
};
	["RedirectEdge"]: {
		node:ModelTypes["Redirect"]
};
	["FooterConnection"]: {
		pageInfo:ModelTypes["PageInfo"],
	edges:ModelTypes["FooterEdge"][]
};
	["FooterEdge"]: {
		node:ModelTypes["Footer"]
};
	["ImageConnection"]: {
		pageInfo:ModelTypes["PageInfo"],
	edges:ModelTypes["ImageEdge"][]
};
	["ImageEdge"]: {
		node:ModelTypes["Image"]
};
	["SeoConnection"]: {
		pageInfo:ModelTypes["PageInfo"],
	edges:ModelTypes["SeoEdge"][]
};
	["SeoEdge"]: {
		node:ModelTypes["Seo"]
};
	["HomepageConnection"]: {
		pageInfo:ModelTypes["PageInfo"],
	edges:ModelTypes["HomepageEdge"][]
};
	["HomepageEdge"]: {
		node:ModelTypes["Homepage"]
};
	["HeaderConnection"]: {
		pageInfo:ModelTypes["PageInfo"],
	edges:ModelTypes["HeaderEdge"][]
};
	["HeaderEdge"]: {
		node:ModelTypes["Header"]
};
	["ListNavigationConnection"]: {
		pageInfo:ModelTypes["PageInfo"],
	edges:ModelTypes["ListNavigationEdge"][]
};
	["ListNavigationEdge"]: {
		node:ModelTypes["ListNavigation"]
};
	["SiteConnection"]: {
		pageInfo:ModelTypes["PageInfo"],
	edges:ModelTypes["SiteEdge"][]
};
	["SiteEdge"]: {
		node:ModelTypes["Site"]
};
	["ArticleListPageConnection"]: {
		pageInfo:ModelTypes["PageInfo"],
	edges:ModelTypes["ArticleListPageEdge"][]
};
	["ArticleListPageEdge"]: {
		node:ModelTypes["ArticleListPage"]
};
	["CourseListPageConnection"]: {
		pageInfo:ModelTypes["PageInfo"],
	edges:ModelTypes["CourseListPageEdge"][]
};
	["CourseListPageEdge"]: {
		node:ModelTypes["CourseListPage"]
};
	["PoseListPageConnection"]: {
		pageInfo:ModelTypes["PageInfo"],
	edges:ModelTypes["PoseListPageEdge"][]
};
	["PoseListPageEdge"]: {
		node:ModelTypes["PoseListPage"]
};
	["ContentConnection"]: {
		pageInfo:ModelTypes["PageInfo"],
	edges:ModelTypes["ContentEdge"][]
};
	["ContentEdge"]: {
		node:ModelTypes["Content"]
};
	["TileConnection"]: {
		pageInfo:ModelTypes["PageInfo"],
	edges:ModelTypes["TileEdge"][]
};
	["TileEdge"]: {
		node:ModelTypes["Tile"]
};
	["TileListConnection"]: {
		pageInfo:ModelTypes["PageInfo"],
	edges:ModelTypes["TileListEdge"][]
};
	["TileListEdge"]: {
		node:ModelTypes["TileList"]
};
	["YoutubeVideoUniqueWhere"]: GraphQLTypes["YoutubeVideoUniqueWhere"];
	["YoutubeVideoConnection"]: {
		pageInfo:ModelTypes["PageInfo"],
	edges:ModelTypes["YoutubeVideoEdge"][]
};
	["YoutubeVideoEdge"]: {
		node:ModelTypes["YoutubeVideo"]
};
	["SeoDefault"]: {
		_meta?:ModelTypes["SeoDefaultMeta"],
	id:ModelTypes["UUID"],
	unique:ModelTypes["One"],
	seo?:ModelTypes["Seo"]
};
	["SeoDefaultMeta"]: {
		id?:ModelTypes["FieldMeta"],
	unique?:ModelTypes["FieldMeta"],
	seo?:ModelTypes["FieldMeta"]
};
	["One"]: GraphQLTypes["One"];
	["SeoDefaultUniqueWhere"]: GraphQLTypes["SeoDefaultUniqueWhere"];
	["SeoDefaultWhere"]: GraphQLTypes["SeoDefaultWhere"];
	["OneCondition"]: GraphQLTypes["OneCondition"];
	["SeoDefaultOrderBy"]: GraphQLTypes["SeoDefaultOrderBy"];
	["SeoDefaultConnection"]: {
		pageInfo:ModelTypes["PageInfo"],
	edges:ModelTypes["SeoDefaultEdge"][]
};
	["SeoDefaultEdge"]: {
		node:ModelTypes["SeoDefault"]
};
	["CourseLecturerListPageConnection"]: {
		pageInfo:ModelTypes["PageInfo"],
	edges:ModelTypes["CourseLecturerListPageEdge"][]
};
	["CourseLecturerListPageEdge"]: {
		node:ModelTypes["CourseLecturerListPage"]
};
	["NewsletterSubscription"]: {
		_meta?:ModelTypes["NewsletterSubscriptionMeta"],
	id:ModelTypes["UUID"],
	startDate?:ModelTypes["Date"],
	visitor?:ModelTypes["Visitor"],
	site?:ModelTypes["Site"]
};
	["NewsletterSubscriptionMeta"]: {
		id?:ModelTypes["FieldMeta"],
	startDate?:ModelTypes["FieldMeta"],
	visitor?:ModelTypes["FieldMeta"],
	site?:ModelTypes["FieldMeta"]
};
	["Visitor"]: {
		_meta?:ModelTypes["VisitorMeta"],
	id:ModelTypes["UUID"],
	email:string,
	newsletterSubscriptions:ModelTypes["NewsletterSubscription"][],
	courseSubscriptions:ModelTypes["CourseSubscription"][],
	newsletterSubscriptionsBySite?:ModelTypes["NewsletterSubscription"],
	courseSubscriptionsByCourse?:ModelTypes["CourseSubscription"],
	paginateNewsletterSubscriptions:ModelTypes["NewsletterSubscriptionConnection"],
	paginateCourseSubscriptions:ModelTypes["CourseSubscriptionConnection"]
};
	["VisitorMeta"]: {
		id?:ModelTypes["FieldMeta"],
	email?:ModelTypes["FieldMeta"],
	newsletterSubscriptions?:ModelTypes["FieldMeta"],
	courseSubscriptions?:ModelTypes["FieldMeta"]
};
	["NewsletterSubscriptionWhere"]: GraphQLTypes["NewsletterSubscriptionWhere"];
	["VisitorWhere"]: GraphQLTypes["VisitorWhere"];
	["CourseSubscriptionWhere"]: GraphQLTypes["CourseSubscriptionWhere"];
	["NewsletterSubscriptionOrderBy"]: GraphQLTypes["NewsletterSubscriptionOrderBy"];
	["VisitorOrderBy"]: GraphQLTypes["VisitorOrderBy"];
	["CourseSubscription"]: {
		_meta?:ModelTypes["CourseSubscriptionMeta"],
	id:ModelTypes["UUID"],
	startDate?:ModelTypes["Date"],
	visitor?:ModelTypes["Visitor"],
	course?:ModelTypes["Course"]
};
	["CourseSubscriptionMeta"]: {
		id?:ModelTypes["FieldMeta"],
	startDate?:ModelTypes["FieldMeta"],
	visitor?:ModelTypes["FieldMeta"],
	course?:ModelTypes["FieldMeta"]
};
	["CourseSubscriptionOrderBy"]: GraphQLTypes["CourseSubscriptionOrderBy"];
	["VisitorNewsletterSubscriptionsBySiteUniqueWhere"]: GraphQLTypes["VisitorNewsletterSubscriptionsBySiteUniqueWhere"];
	["VisitorCourseSubscriptionsByCourseUniqueWhere"]: GraphQLTypes["VisitorCourseSubscriptionsByCourseUniqueWhere"];
	["NewsletterSubscriptionConnection"]: {
		pageInfo:ModelTypes["PageInfo"],
	edges:ModelTypes["NewsletterSubscriptionEdge"][]
};
	["NewsletterSubscriptionEdge"]: {
		node:ModelTypes["NewsletterSubscription"]
};
	["CourseSubscriptionConnection"]: {
		pageInfo:ModelTypes["PageInfo"],
	edges:ModelTypes["CourseSubscriptionEdge"][]
};
	["CourseSubscriptionEdge"]: {
		node:ModelTypes["CourseSubscription"]
};
	["NewsletterSubscriptionUniqueWhere"]: GraphQLTypes["NewsletterSubscriptionUniqueWhere"];
	["VisitorUniqueWhere"]: GraphQLTypes["VisitorUniqueWhere"];
	["CourseSubscriptionUniqueWhere"]: GraphQLTypes["CourseSubscriptionUniqueWhere"];
	["_ValidationResult"]: {
		valid:boolean,
	errors:ModelTypes["_ValidationError"][]
};
	["_ValidationError"]: {
		path:ModelTypes["_PathFragment"][],
	message:ModelTypes["_ValidationMessage"]
};
	["_PathFragment"]:ModelTypes["_FieldPathFragment"] | ModelTypes["_IndexPathFragment"];
	["_FieldPathFragment"]: {
		field:string
};
	["_IndexPathFragment"]: {
		index:number,
	alias?:string
};
	["_ValidationMessage"]: {
		text:string
};
	["NewsletterSubscriptionCreateInput"]: GraphQLTypes["NewsletterSubscriptionCreateInput"];
	["NewsletterSubscriptionCreateVisitorEntityRelationInput"]: GraphQLTypes["NewsletterSubscriptionCreateVisitorEntityRelationInput"];
	["VisitorWithoutNewsletterSubscriptionsCreateInput"]: GraphQLTypes["VisitorWithoutNewsletterSubscriptionsCreateInput"];
	["VisitorCreateCourseSubscriptionsEntityRelationInput"]: GraphQLTypes["VisitorCreateCourseSubscriptionsEntityRelationInput"];
	["CourseSubscriptionWithoutVisitorCreateInput"]: GraphQLTypes["CourseSubscriptionWithoutVisitorCreateInput"];
	["CourseSubscriptionCreateCourseEntityRelationInput"]: GraphQLTypes["CourseSubscriptionCreateCourseEntityRelationInput"];
	["NewsletterSubscriptionCreateSiteEntityRelationInput"]: GraphQLTypes["NewsletterSubscriptionCreateSiteEntityRelationInput"];
	["VisitorConnection"]: {
		pageInfo:ModelTypes["PageInfo"],
	edges:ModelTypes["VisitorEdge"][]
};
	["VisitorEdge"]: {
		node:ModelTypes["Visitor"]
};
	["VisitorCreateInput"]: GraphQLTypes["VisitorCreateInput"];
	["VisitorCreateNewsletterSubscriptionsEntityRelationInput"]: GraphQLTypes["VisitorCreateNewsletterSubscriptionsEntityRelationInput"];
	["NewsletterSubscriptionWithoutVisitorCreateInput"]: GraphQLTypes["NewsletterSubscriptionWithoutVisitorCreateInput"];
	["CourseSubscriptionCreateInput"]: GraphQLTypes["CourseSubscriptionCreateInput"];
	["CourseSubscriptionCreateVisitorEntityRelationInput"]: GraphQLTypes["CourseSubscriptionCreateVisitorEntityRelationInput"];
	["VisitorWithoutCourseSubscriptionsCreateInput"]: GraphQLTypes["VisitorWithoutCourseSubscriptionsCreateInput"];
	["SubscribeBoxConnection"]: {
		pageInfo:ModelTypes["PageInfo"],
	edges:ModelTypes["SubscribeBoxEdge"][]
};
	["SubscribeBoxEdge"]: {
		node:ModelTypes["SubscribeBox"]
};
	["NewsletterSubscribeSuccessPageConnection"]: {
		pageInfo:ModelTypes["PageInfo"],
	edges:ModelTypes["NewsletterSubscribeSuccessPageEdge"][]
};
	["NewsletterSubscribeSuccessPageEdge"]: {
		node:ModelTypes["NewsletterSubscribeSuccessPage"]
};
	["PersonUniqueWhere"]: GraphQLTypes["PersonUniqueWhere"];
	["PersonConnection"]: {
		pageInfo:ModelTypes["PageInfo"],
	edges:ModelTypes["PersonEdge"][]
};
	["PersonEdge"]: {
		node:ModelTypes["Person"]
};
	["PersonListUniqueWhere"]: GraphQLTypes["PersonListUniqueWhere"];
	["PersonListItemUniqueWhere"]: GraphQLTypes["PersonListItemUniqueWhere"];
	["PersonListConnection"]: {
		pageInfo:ModelTypes["PageInfo"],
	edges:ModelTypes["PersonListEdge"][]
};
	["PersonListEdge"]: {
		node:ModelTypes["PersonList"]
};
	["CourseEmailConnection"]: {
		pageInfo:ModelTypes["PageInfo"],
	edges:ModelTypes["CourseEmailEdge"][]
};
	["CourseEmailEdge"]: {
		node:ModelTypes["CourseEmail"]
};
	["TranslationsConnection"]: {
		pageInfo:ModelTypes["PageInfo"],
	edges:ModelTypes["TranslationsEdge"][]
};
	["TranslationsEdge"]: {
		node:ModelTypes["Translations"]
};
	["QueryTransaction"]: {
		getLink?:ModelTypes["Link"],
	listLink:ModelTypes["Link"][],
	paginateLink:ModelTypes["LinkConnection"],
	getLinkable?:ModelTypes["Linkable"],
	listLinkable:ModelTypes["Linkable"][],
	paginateLinkable:ModelTypes["LinkableConnection"],
	getRedirect?:ModelTypes["Redirect"],
	listRedirect:ModelTypes["Redirect"][],
	paginateRedirect:ModelTypes["RedirectConnection"],
	getFooter?:ModelTypes["Footer"],
	listFooter:ModelTypes["Footer"][],
	paginateFooter:ModelTypes["FooterConnection"],
	getImage?:ModelTypes["Image"],
	listImage:ModelTypes["Image"][],
	paginateImage:ModelTypes["ImageConnection"],
	getSeo?:ModelTypes["Seo"],
	listSeo:ModelTypes["Seo"][],
	paginateSeo:ModelTypes["SeoConnection"],
	getHomepage?:ModelTypes["Homepage"],
	listHomepage:ModelTypes["Homepage"][],
	paginateHomepage:ModelTypes["HomepageConnection"],
	getHeader?:ModelTypes["Header"],
	listHeader:ModelTypes["Header"][],
	paginateHeader:ModelTypes["HeaderConnection"],
	getHeaderPrimaryNavigationItem?:ModelTypes["HeaderPrimaryNavigationItem"],
	listHeaderPrimaryNavigationItem:ModelTypes["HeaderPrimaryNavigationItem"][],
	paginateHeaderPrimaryNavigationItem:ModelTypes["HeaderPrimaryNavigationItemConnection"],
	getListNavigation?:ModelTypes["ListNavigation"],
	listListNavigation:ModelTypes["ListNavigation"][],
	paginateListNavigation:ModelTypes["ListNavigationConnection"],
	getListNavigationColumn?:ModelTypes["ListNavigationColumn"],
	listListNavigationColumn:ModelTypes["ListNavigationColumn"][],
	paginateListNavigationColumn:ModelTypes["ListNavigationColumnConnection"],
	getListNavigationColumnItem?:ModelTypes["ListNavigationColumnItem"],
	listListNavigationColumnItem:ModelTypes["ListNavigationColumnItem"][],
	paginateListNavigationColumnItem:ModelTypes["ListNavigationColumnItemConnection"],
	getArticle?:ModelTypes["Article"],
	listArticle:ModelTypes["Article"][],
	paginateArticle:ModelTypes["ArticleConnection"],
	getSite?:ModelTypes["Site"],
	listSite:ModelTypes["Site"][],
	paginateSite:ModelTypes["SiteConnection"],
	getArticleCategory?:ModelTypes["ArticleCategory"],
	listArticleCategory:ModelTypes["ArticleCategory"][],
	paginateArticleCategory:ModelTypes["ArticleCategoryConnection"],
	getPose?:ModelTypes["Pose"],
	listPose:ModelTypes["Pose"][],
	paginatePose:ModelTypes["PoseConnection"],
	getPoseCategory?:ModelTypes["PoseCategory"],
	listPoseCategory:ModelTypes["PoseCategory"][],
	paginatePoseCategory:ModelTypes["PoseCategoryConnection"],
	getPoseDifficulty?:ModelTypes["PoseDifficulty"],
	listPoseDifficulty:ModelTypes["PoseDifficulty"][],
	paginatePoseDifficulty:ModelTypes["PoseDifficultyConnection"],
	getCourse?:ModelTypes["Course"],
	listCourse:ModelTypes["Course"][],
	paginateCourse:ModelTypes["CourseConnection"],
	getCourseLesson?:ModelTypes["CourseLesson"],
	listCourseLesson:ModelTypes["CourseLesson"][],
	paginateCourseLesson:ModelTypes["CourseLessonConnection"],
	getArticleListPage?:ModelTypes["ArticleListPage"],
	listArticleListPage:ModelTypes["ArticleListPage"][],
	paginateArticleListPage:ModelTypes["ArticleListPageConnection"],
	getCourseListPage?:ModelTypes["CourseListPage"],
	listCourseListPage:ModelTypes["CourseListPage"][],
	paginateCourseListPage:ModelTypes["CourseListPageConnection"],
	getPoseListPage?:ModelTypes["PoseListPage"],
	listPoseListPage:ModelTypes["PoseListPage"][],
	paginatePoseListPage:ModelTypes["PoseListPageConnection"],
	getContent?:ModelTypes["Content"],
	listContent:ModelTypes["Content"][],
	paginateContent:ModelTypes["ContentConnection"],
	getContentBlock?:ModelTypes["ContentBlock"],
	listContentBlock:ModelTypes["ContentBlock"][],
	paginateContentBlock:ModelTypes["ContentBlockConnection"],
	getContentReference?:ModelTypes["ContentReference"],
	listContentReference:ModelTypes["ContentReference"][],
	paginateContentReference:ModelTypes["ContentReferenceConnection"],
	getTile?:ModelTypes["Tile"],
	listTile:ModelTypes["Tile"][],
	paginateTile:ModelTypes["TileConnection"],
	getTileList?:ModelTypes["TileList"],
	listTileList:ModelTypes["TileList"][],
	paginateTileList:ModelTypes["TileListConnection"],
	getTileListItem?:ModelTypes["TileListItem"],
	listTileListItem:ModelTypes["TileListItem"][],
	paginateTileListItem:ModelTypes["TileListItemConnection"],
	getYoutubeVideo?:ModelTypes["YoutubeVideo"],
	listYoutubeVideo:ModelTypes["YoutubeVideo"][],
	paginateYoutubeVideo:ModelTypes["YoutubeVideoConnection"],
	getSeoDefault?:ModelTypes["SeoDefault"],
	listSeoDefault:ModelTypes["SeoDefault"][],
	paginateSeoDefault:ModelTypes["SeoDefaultConnection"],
	getCourseLecturer?:ModelTypes["CourseLecturer"],
	listCourseLecturer:ModelTypes["CourseLecturer"][],
	paginateCourseLecturer:ModelTypes["CourseLecturerConnection"],
	getCourseLecturerListPage?:ModelTypes["CourseLecturerListPage"],
	listCourseLecturerListPage:ModelTypes["CourseLecturerListPage"][],
	paginateCourseLecturerListPage:ModelTypes["CourseLecturerListPageConnection"],
	getNewsletterSubscription?:ModelTypes["NewsletterSubscription"],
	listNewsletterSubscription:ModelTypes["NewsletterSubscription"][],
	paginateNewsletterSubscription:ModelTypes["NewsletterSubscriptionConnection"],
	validateCreateNewsletterSubscription:ModelTypes["_ValidationResult"],
	getVisitor?:ModelTypes["Visitor"],
	listVisitor:ModelTypes["Visitor"][],
	paginateVisitor:ModelTypes["VisitorConnection"],
	validateCreateVisitor:ModelTypes["_ValidationResult"],
	getCourseSubscription?:ModelTypes["CourseSubscription"],
	listCourseSubscription:ModelTypes["CourseSubscription"][],
	paginateCourseSubscription:ModelTypes["CourseSubscriptionConnection"],
	validateCreateCourseSubscription:ModelTypes["_ValidationResult"],
	getSubscribeBox?:ModelTypes["SubscribeBox"],
	listSubscribeBox:ModelTypes["SubscribeBox"][],
	paginateSubscribeBox:ModelTypes["SubscribeBoxConnection"],
	getNewsletterSubscribeSuccessPage?:ModelTypes["NewsletterSubscribeSuccessPage"],
	listNewsletterSubscribeSuccessPage:ModelTypes["NewsletterSubscribeSuccessPage"][],
	paginateNewsletterSubscribeSuccessPage:ModelTypes["NewsletterSubscribeSuccessPageConnection"],
	getGenericPage?:ModelTypes["GenericPage"],
	listGenericPage:ModelTypes["GenericPage"][],
	paginateGenericPage:ModelTypes["GenericPageConnection"],
	getPerson?:ModelTypes["Person"],
	listPerson:ModelTypes["Person"][],
	paginatePerson:ModelTypes["PersonConnection"],
	getPersonList?:ModelTypes["PersonList"],
	listPersonList:ModelTypes["PersonList"][],
	paginatePersonList:ModelTypes["PersonListConnection"],
	getPersonListItem?:ModelTypes["PersonListItem"],
	listPersonListItem:ModelTypes["PersonListItem"][],
	paginatePersonListItem:ModelTypes["PersonListItemConnection"],
	getCourseEmail?:ModelTypes["CourseEmail"],
	listCourseEmail:ModelTypes["CourseEmail"][],
	paginateCourseEmail:ModelTypes["CourseEmailConnection"],
	getTranslations?:ModelTypes["Translations"],
	listTranslations:ModelTypes["Translations"][],
	paginateTranslations:ModelTypes["TranslationsConnection"]
};
	["Info"]: {
		description?:string
};
	["Mutation"]: {
		createNewsletterSubscription:ModelTypes["NewsletterSubscriptionCreateResult"],
	deleteNewsletterSubscription:ModelTypes["NewsletterSubscriptionDeleteResult"],
	createVisitor:ModelTypes["VisitorCreateResult"],
	createCourseSubscription:ModelTypes["CourseSubscriptionCreateResult"],
	deleteCourseSubscription:ModelTypes["CourseSubscriptionDeleteResult"],
	transaction:ModelTypes["MutationTransaction"],
	query:ModelTypes["Query"]
};
	["NewsletterSubscriptionCreateResult"]: {
		ok:boolean,
	errorMessage?:string,
	errors:ModelTypes["_MutationError"][],
	node?:ModelTypes["NewsletterSubscription"],
	validation:ModelTypes["_ValidationResult"]
};
	["MutationResult"]: ModelTypes["NewsletterSubscriptionCreateResult"] | ModelTypes["NewsletterSubscriptionDeleteResult"] | ModelTypes["VisitorCreateResult"] | ModelTypes["CourseSubscriptionCreateResult"] | ModelTypes["CourseSubscriptionDeleteResult"];
	["_MutationError"]: {
		path:ModelTypes["_PathFragment"][],
	paths:ModelTypes["_PathFragment"][],
	type:ModelTypes["_MutationErrorType"],
	message?:string
};
	["_MutationErrorType"]: GraphQLTypes["_MutationErrorType"];
	["NewsletterSubscriptionDeleteResult"]: {
		ok:boolean,
	errorMessage?:string,
	errors:ModelTypes["_MutationError"][],
	node?:ModelTypes["NewsletterSubscription"]
};
	["VisitorCreateResult"]: {
		ok:boolean,
	errorMessage?:string,
	errors:ModelTypes["_MutationError"][],
	node?:ModelTypes["Visitor"],
	validation:ModelTypes["_ValidationResult"]
};
	["CourseSubscriptionCreateResult"]: {
		ok:boolean,
	errorMessage?:string,
	errors:ModelTypes["_MutationError"][],
	node?:ModelTypes["CourseSubscription"],
	validation:ModelTypes["_ValidationResult"]
};
	["CourseSubscriptionDeleteResult"]: {
		ok:boolean,
	errorMessage?:string,
	errors:ModelTypes["_MutationError"][],
	node?:ModelTypes["CourseSubscription"]
};
	["MutationTransaction"]: {
		ok:boolean,
	errorMessage?:string,
	errors:ModelTypes["_MutationError"][],
	validation:ModelTypes["_ValidationResult"],
	createNewsletterSubscription:ModelTypes["NewsletterSubscriptionCreateResult"],
	deleteNewsletterSubscription:ModelTypes["NewsletterSubscriptionDeleteResult"],
	createVisitor:ModelTypes["VisitorCreateResult"],
	createCourseSubscription:ModelTypes["CourseSubscriptionCreateResult"],
	deleteCourseSubscription:ModelTypes["CourseSubscriptionDeleteResult"],
	query?:ModelTypes["Query"]
};
	["MutationTransactionOptions"]: GraphQLTypes["MutationTransactionOptions"];
	/** Json custom scalar type */
["Json"]:any;
	["_Schema"]: {
		enums:ModelTypes["_Enum"][],
	entities:ModelTypes["_Entity"][]
};
	["_Entity"]: {
		name:string,
	customPrimaryAllowed:boolean,
	fields:ModelTypes["_Field"][],
	unique:ModelTypes["_UniqueConstraint"][]
};
	["_UniqueConstraint"]: {
		fields:string[]
};
	["_Field"]: ModelTypes["_Column"] | ModelTypes["_Relation"];
	["_Column"]: {
		name:string,
	type:string,
	enumName?:string,
	defaultValue?:ModelTypes["Json"],
	nullable:boolean,
	rules:ModelTypes["_Rule"][],
	validators:ModelTypes["_Validator"][]
};
	["_OnDeleteBehaviour"]: GraphQLTypes["_OnDeleteBehaviour"];
	["_RelationSide"]: GraphQLTypes["_RelationSide"];
	["_OrderByDirection"]: GraphQLTypes["_OrderByDirection"];
	["_OrderBy"]: {
		path:string[],
	direction:ModelTypes["_OrderByDirection"]
};
	["_Relation"]: {
		name:string,
	type:string,
	side:ModelTypes["_RelationSide"],
	targetEntity:string,
	ownedBy?:string,
	inversedBy?:string,
	nullable?:boolean,
	onDelete?:ModelTypes["_OnDeleteBehaviour"],
	orphanRemoval?:boolean,
	orderBy?:ModelTypes["_OrderBy"][],
	rules:ModelTypes["_Rule"][],
	validators:ModelTypes["_Validator"][]
};
	["_Rule"]: {
		message?:ModelTypes["_RuleMessage"],
	validator:number
};
	["_Validator"]: {
		operation:string,
	arguments:ModelTypes["_Argument"][]
};
	["_Argument"]:ModelTypes["_ValidatorArgument"] | ModelTypes["_PathArgument"] | ModelTypes["_LiteralArgument"];
	["_ValidatorArgument"]: {
		validator:number
};
	["_PathArgument"]: {
		path:string[]
};
	["_LiteralArgument"]: {
		value?:ModelTypes["Json"]
};
	["_RuleMessage"]: {
		text?:string
};
	["_Enum"]: {
		name:string,
	values:string[]
}
    }

export type GraphQLTypes = {
    ["Query"]: {
	__typename: "Query",
	getLink?: GraphQLTypes["Link"],
	listLink: Array<GraphQLTypes["Link"]>,
	paginateLink: GraphQLTypes["LinkConnection"],
	getLinkable?: GraphQLTypes["Linkable"],
	listLinkable: Array<GraphQLTypes["Linkable"]>,
	paginateLinkable: GraphQLTypes["LinkableConnection"],
	getRedirect?: GraphQLTypes["Redirect"],
	listRedirect: Array<GraphQLTypes["Redirect"]>,
	paginateRedirect: GraphQLTypes["RedirectConnection"],
	getFooter?: GraphQLTypes["Footer"],
	listFooter: Array<GraphQLTypes["Footer"]>,
	paginateFooter: GraphQLTypes["FooterConnection"],
	getImage?: GraphQLTypes["Image"],
	listImage: Array<GraphQLTypes["Image"]>,
	paginateImage: GraphQLTypes["ImageConnection"],
	getSeo?: GraphQLTypes["Seo"],
	listSeo: Array<GraphQLTypes["Seo"]>,
	paginateSeo: GraphQLTypes["SeoConnection"],
	getHomepage?: GraphQLTypes["Homepage"],
	listHomepage: Array<GraphQLTypes["Homepage"]>,
	paginateHomepage: GraphQLTypes["HomepageConnection"],
	getHeader?: GraphQLTypes["Header"],
	listHeader: Array<GraphQLTypes["Header"]>,
	paginateHeader: GraphQLTypes["HeaderConnection"],
	getHeaderPrimaryNavigationItem?: GraphQLTypes["HeaderPrimaryNavigationItem"],
	listHeaderPrimaryNavigationItem: Array<GraphQLTypes["HeaderPrimaryNavigationItem"]>,
	paginateHeaderPrimaryNavigationItem: GraphQLTypes["HeaderPrimaryNavigationItemConnection"],
	getListNavigation?: GraphQLTypes["ListNavigation"],
	listListNavigation: Array<GraphQLTypes["ListNavigation"]>,
	paginateListNavigation: GraphQLTypes["ListNavigationConnection"],
	getListNavigationColumn?: GraphQLTypes["ListNavigationColumn"],
	listListNavigationColumn: Array<GraphQLTypes["ListNavigationColumn"]>,
	paginateListNavigationColumn: GraphQLTypes["ListNavigationColumnConnection"],
	getListNavigationColumnItem?: GraphQLTypes["ListNavigationColumnItem"],
	listListNavigationColumnItem: Array<GraphQLTypes["ListNavigationColumnItem"]>,
	paginateListNavigationColumnItem: GraphQLTypes["ListNavigationColumnItemConnection"],
	getArticle?: GraphQLTypes["Article"],
	listArticle: Array<GraphQLTypes["Article"]>,
	paginateArticle: GraphQLTypes["ArticleConnection"],
	getSite?: GraphQLTypes["Site"],
	listSite: Array<GraphQLTypes["Site"]>,
	paginateSite: GraphQLTypes["SiteConnection"],
	getArticleCategory?: GraphQLTypes["ArticleCategory"],
	listArticleCategory: Array<GraphQLTypes["ArticleCategory"]>,
	paginateArticleCategory: GraphQLTypes["ArticleCategoryConnection"],
	getPose?: GraphQLTypes["Pose"],
	listPose: Array<GraphQLTypes["Pose"]>,
	paginatePose: GraphQLTypes["PoseConnection"],
	getPoseCategory?: GraphQLTypes["PoseCategory"],
	listPoseCategory: Array<GraphQLTypes["PoseCategory"]>,
	paginatePoseCategory: GraphQLTypes["PoseCategoryConnection"],
	getPoseDifficulty?: GraphQLTypes["PoseDifficulty"],
	listPoseDifficulty: Array<GraphQLTypes["PoseDifficulty"]>,
	paginatePoseDifficulty: GraphQLTypes["PoseDifficultyConnection"],
	getCourse?: GraphQLTypes["Course"],
	listCourse: Array<GraphQLTypes["Course"]>,
	paginateCourse: GraphQLTypes["CourseConnection"],
	getCourseLesson?: GraphQLTypes["CourseLesson"],
	listCourseLesson: Array<GraphQLTypes["CourseLesson"]>,
	paginateCourseLesson: GraphQLTypes["CourseLessonConnection"],
	getArticleListPage?: GraphQLTypes["ArticleListPage"],
	listArticleListPage: Array<GraphQLTypes["ArticleListPage"]>,
	paginateArticleListPage: GraphQLTypes["ArticleListPageConnection"],
	getCourseListPage?: GraphQLTypes["CourseListPage"],
	listCourseListPage: Array<GraphQLTypes["CourseListPage"]>,
	paginateCourseListPage: GraphQLTypes["CourseListPageConnection"],
	getPoseListPage?: GraphQLTypes["PoseListPage"],
	listPoseListPage: Array<GraphQLTypes["PoseListPage"]>,
	paginatePoseListPage: GraphQLTypes["PoseListPageConnection"],
	getContent?: GraphQLTypes["Content"],
	listContent: Array<GraphQLTypes["Content"]>,
	paginateContent: GraphQLTypes["ContentConnection"],
	getContentBlock?: GraphQLTypes["ContentBlock"],
	listContentBlock: Array<GraphQLTypes["ContentBlock"]>,
	paginateContentBlock: GraphQLTypes["ContentBlockConnection"],
	getContentReference?: GraphQLTypes["ContentReference"],
	listContentReference: Array<GraphQLTypes["ContentReference"]>,
	paginateContentReference: GraphQLTypes["ContentReferenceConnection"],
	getTile?: GraphQLTypes["Tile"],
	listTile: Array<GraphQLTypes["Tile"]>,
	paginateTile: GraphQLTypes["TileConnection"],
	getTileList?: GraphQLTypes["TileList"],
	listTileList: Array<GraphQLTypes["TileList"]>,
	paginateTileList: GraphQLTypes["TileListConnection"],
	getTileListItem?: GraphQLTypes["TileListItem"],
	listTileListItem: Array<GraphQLTypes["TileListItem"]>,
	paginateTileListItem: GraphQLTypes["TileListItemConnection"],
	getYoutubeVideo?: GraphQLTypes["YoutubeVideo"],
	listYoutubeVideo: Array<GraphQLTypes["YoutubeVideo"]>,
	paginateYoutubeVideo: GraphQLTypes["YoutubeVideoConnection"],
	getSeoDefault?: GraphQLTypes["SeoDefault"],
	listSeoDefault: Array<GraphQLTypes["SeoDefault"]>,
	paginateSeoDefault: GraphQLTypes["SeoDefaultConnection"],
	getCourseLecturer?: GraphQLTypes["CourseLecturer"],
	listCourseLecturer: Array<GraphQLTypes["CourseLecturer"]>,
	paginateCourseLecturer: GraphQLTypes["CourseLecturerConnection"],
	getCourseLecturerListPage?: GraphQLTypes["CourseLecturerListPage"],
	listCourseLecturerListPage: Array<GraphQLTypes["CourseLecturerListPage"]>,
	paginateCourseLecturerListPage: GraphQLTypes["CourseLecturerListPageConnection"],
	getNewsletterSubscription?: GraphQLTypes["NewsletterSubscription"],
	listNewsletterSubscription: Array<GraphQLTypes["NewsletterSubscription"]>,
	paginateNewsletterSubscription: GraphQLTypes["NewsletterSubscriptionConnection"],
	validateCreateNewsletterSubscription: GraphQLTypes["_ValidationResult"],
	getVisitor?: GraphQLTypes["Visitor"],
	listVisitor: Array<GraphQLTypes["Visitor"]>,
	paginateVisitor: GraphQLTypes["VisitorConnection"],
	validateCreateVisitor: GraphQLTypes["_ValidationResult"],
	getCourseSubscription?: GraphQLTypes["CourseSubscription"],
	listCourseSubscription: Array<GraphQLTypes["CourseSubscription"]>,
	paginateCourseSubscription: GraphQLTypes["CourseSubscriptionConnection"],
	validateCreateCourseSubscription: GraphQLTypes["_ValidationResult"],
	getSubscribeBox?: GraphQLTypes["SubscribeBox"],
	listSubscribeBox: Array<GraphQLTypes["SubscribeBox"]>,
	paginateSubscribeBox: GraphQLTypes["SubscribeBoxConnection"],
	getNewsletterSubscribeSuccessPage?: GraphQLTypes["NewsletterSubscribeSuccessPage"],
	listNewsletterSubscribeSuccessPage: Array<GraphQLTypes["NewsletterSubscribeSuccessPage"]>,
	paginateNewsletterSubscribeSuccessPage: GraphQLTypes["NewsletterSubscribeSuccessPageConnection"],
	getGenericPage?: GraphQLTypes["GenericPage"],
	listGenericPage: Array<GraphQLTypes["GenericPage"]>,
	paginateGenericPage: GraphQLTypes["GenericPageConnection"],
	getPerson?: GraphQLTypes["Person"],
	listPerson: Array<GraphQLTypes["Person"]>,
	paginatePerson: GraphQLTypes["PersonConnection"],
	getPersonList?: GraphQLTypes["PersonList"],
	listPersonList: Array<GraphQLTypes["PersonList"]>,
	paginatePersonList: GraphQLTypes["PersonListConnection"],
	getPersonListItem?: GraphQLTypes["PersonListItem"],
	listPersonListItem: Array<GraphQLTypes["PersonListItem"]>,
	paginatePersonListItem: GraphQLTypes["PersonListItemConnection"],
	getCourseEmail?: GraphQLTypes["CourseEmail"],
	listCourseEmail: Array<GraphQLTypes["CourseEmail"]>,
	paginateCourseEmail: GraphQLTypes["CourseEmailConnection"],
	getTranslations?: GraphQLTypes["Translations"],
	listTranslations: Array<GraphQLTypes["Translations"]>,
	paginateTranslations: GraphQLTypes["TranslationsConnection"],
	transaction?: GraphQLTypes["QueryTransaction"],
	_info?: GraphQLTypes["Info"],
	schema?: GraphQLTypes["_Schema"]
};
	["Link"]: {
	__typename: "Link",
	_meta?: GraphQLTypes["LinkMeta"],
	id: GraphQLTypes["UUID"],
	type: GraphQLTypes["LinkType"],
	title?: string,
	externalLink?: string,
	internalLink?: GraphQLTypes["Linkable"],
	isHighlighted?: boolean,
	targetBlank?: boolean
};
	["LinkMeta"]: {
	__typename: "LinkMeta",
	id?: GraphQLTypes["FieldMeta"],
	type?: GraphQLTypes["FieldMeta"],
	title?: GraphQLTypes["FieldMeta"],
	externalLink?: GraphQLTypes["FieldMeta"],
	internalLink?: GraphQLTypes["FieldMeta"],
	isHighlighted?: GraphQLTypes["FieldMeta"],
	targetBlank?: GraphQLTypes["FieldMeta"]
};
	["FieldMeta"]: {
	__typename: "FieldMeta",
	readable?: boolean,
	updatable?: boolean
};
	["UUID"]:string;
	["LinkType"]: LinkType;
	["Linkable"]: {
	__typename: "Linkable",
	_meta?: GraphQLTypes["LinkableMeta"],
	id: GraphQLTypes["UUID"],
	url: string,
	redirect?: GraphQLTypes["Redirect"],
	homepage?: GraphQLTypes["Homepage"],
	article?: GraphQLTypes["Article"],
	articleCategory?: GraphQLTypes["ArticleCategory"],
	pose?: GraphQLTypes["Pose"],
	course?: GraphQLTypes["Course"],
	articleList?: GraphQLTypes["ArticleListPage"],
	courseList?: GraphQLTypes["CourseListPage"],
	poseList?: GraphQLTypes["PoseListPage"],
	courseLecturer?: GraphQLTypes["CourseLecturer"],
	courseLecturerList?: GraphQLTypes["CourseLecturerListPage"],
	newsletterSubscribeSuccessPage?: GraphQLTypes["NewsletterSubscribeSuccessPage"],
	genericPage?: GraphQLTypes["GenericPage"]
};
	["LinkableMeta"]: {
	__typename: "LinkableMeta",
	id?: GraphQLTypes["FieldMeta"],
	url?: GraphQLTypes["FieldMeta"],
	redirect?: GraphQLTypes["FieldMeta"],
	homepage?: GraphQLTypes["FieldMeta"],
	article?: GraphQLTypes["FieldMeta"],
	articleCategory?: GraphQLTypes["FieldMeta"],
	pose?: GraphQLTypes["FieldMeta"],
	course?: GraphQLTypes["FieldMeta"],
	articleList?: GraphQLTypes["FieldMeta"],
	courseList?: GraphQLTypes["FieldMeta"],
	poseList?: GraphQLTypes["FieldMeta"],
	courseLecturer?: GraphQLTypes["FieldMeta"],
	courseLecturerList?: GraphQLTypes["FieldMeta"],
	newsletterSubscribeSuccessPage?: GraphQLTypes["FieldMeta"],
	genericPage?: GraphQLTypes["FieldMeta"]
};
	["Redirect"]: {
	__typename: "Redirect",
	_meta?: GraphQLTypes["RedirectMeta"],
	id: GraphQLTypes["UUID"],
	link?: GraphQLTypes["Linkable"],
	target?: GraphQLTypes["Linkable"]
};
	["RedirectMeta"]: {
	__typename: "RedirectMeta",
	id?: GraphQLTypes["FieldMeta"],
	link?: GraphQLTypes["FieldMeta"],
	target?: GraphQLTypes["FieldMeta"]
};
	["LinkableWhere"]: {
		id?: GraphQLTypes["UUIDCondition"],
	url?: GraphQLTypes["StringCondition"],
	redirect?: GraphQLTypes["RedirectWhere"],
	homepage?: GraphQLTypes["HomepageWhere"],
	article?: GraphQLTypes["ArticleWhere"],
	articleCategory?: GraphQLTypes["ArticleCategoryWhere"],
	pose?: GraphQLTypes["PoseWhere"],
	course?: GraphQLTypes["CourseWhere"],
	articleList?: GraphQLTypes["ArticleListPageWhere"],
	courseList?: GraphQLTypes["CourseListPageWhere"],
	poseList?: GraphQLTypes["PoseListPageWhere"],
	courseLecturer?: GraphQLTypes["CourseLecturerWhere"],
	courseLecturerList?: GraphQLTypes["CourseLecturerListPageWhere"],
	newsletterSubscribeSuccessPage?: GraphQLTypes["NewsletterSubscribeSuccessPageWhere"],
	genericPage?: GraphQLTypes["GenericPageWhere"],
	and?: Array<GraphQLTypes["LinkableWhere"] | undefined>,
	or?: Array<GraphQLTypes["LinkableWhere"] | undefined>,
	not?: GraphQLTypes["LinkableWhere"]
};
	["UUIDCondition"]: {
		and?: Array<GraphQLTypes["UUIDCondition"]>,
	or?: Array<GraphQLTypes["UUIDCondition"]>,
	not?: GraphQLTypes["UUIDCondition"],
	null?: boolean,
	isNull?: boolean,
	eq?: GraphQLTypes["UUID"],
	notEq?: GraphQLTypes["UUID"],
	in?: Array<GraphQLTypes["UUID"]>,
	notIn?: Array<GraphQLTypes["UUID"]>,
	lt?: GraphQLTypes["UUID"],
	lte?: GraphQLTypes["UUID"],
	gt?: GraphQLTypes["UUID"],
	gte?: GraphQLTypes["UUID"]
};
	["StringCondition"]: {
		and?: Array<GraphQLTypes["StringCondition"]>,
	or?: Array<GraphQLTypes["StringCondition"]>,
	not?: GraphQLTypes["StringCondition"],
	null?: boolean,
	isNull?: boolean,
	eq?: string,
	notEq?: string,
	in?: Array<string>,
	notIn?: Array<string>,
	lt?: string,
	lte?: string,
	gt?: string,
	gte?: string,
	contains?: string,
	startsWith?: string,
	endsWith?: string,
	containsCI?: string,
	startsWithCI?: string,
	endsWithCI?: string
};
	["RedirectWhere"]: {
		id?: GraphQLTypes["UUIDCondition"],
	link?: GraphQLTypes["LinkableWhere"],
	target?: GraphQLTypes["LinkableWhere"],
	and?: Array<GraphQLTypes["RedirectWhere"] | undefined>,
	or?: Array<GraphQLTypes["RedirectWhere"] | undefined>,
	not?: GraphQLTypes["RedirectWhere"]
};
	["HomepageWhere"]: {
		id?: GraphQLTypes["UUIDCondition"],
	link?: GraphQLTypes["LinkableWhere"],
	subtitle?: GraphQLTypes["StringCondition"],
	seo?: GraphQLTypes["SeoWhere"],
	content?: GraphQLTypes["ContentWhere"],
	heroTiles?: GraphQLTypes["TileListWhere"],
	site?: GraphQLTypes["SiteWhere"],
	titlePartA?: GraphQLTypes["StringCondition"],
	titlePartB?: GraphQLTypes["StringCondition"],
	titlePartC?: GraphQLTypes["StringCondition"],
	titlePartD?: GraphQLTypes["StringCondition"],
	and?: Array<GraphQLTypes["HomepageWhere"] | undefined>,
	or?: Array<GraphQLTypes["HomepageWhere"] | undefined>,
	not?: GraphQLTypes["HomepageWhere"]
};
	["SeoWhere"]: {
		id?: GraphQLTypes["UUIDCondition"],
	title?: GraphQLTypes["StringCondition"],
	description?: GraphQLTypes["StringCondition"],
	ogTitle?: GraphQLTypes["StringCondition"],
	ogDescription?: GraphQLTypes["StringCondition"],
	ogImage?: GraphQLTypes["ImageWhere"],
	and?: Array<GraphQLTypes["SeoWhere"] | undefined>,
	or?: Array<GraphQLTypes["SeoWhere"] | undefined>,
	not?: GraphQLTypes["SeoWhere"]
};
	["ImageWhere"]: {
		id?: GraphQLTypes["UUIDCondition"],
	url?: GraphQLTypes["StringCondition"],
	width?: GraphQLTypes["IntCondition"],
	height?: GraphQLTypes["IntCondition"],
	size?: GraphQLTypes["IntCondition"],
	type?: GraphQLTypes["StringCondition"],
	alt?: GraphQLTypes["StringCondition"],
	and?: Array<GraphQLTypes["ImageWhere"] | undefined>,
	or?: Array<GraphQLTypes["ImageWhere"] | undefined>,
	not?: GraphQLTypes["ImageWhere"]
};
	["IntCondition"]: {
		and?: Array<GraphQLTypes["IntCondition"]>,
	or?: Array<GraphQLTypes["IntCondition"]>,
	not?: GraphQLTypes["IntCondition"],
	null?: boolean,
	isNull?: boolean,
	eq?: number,
	notEq?: number,
	in?: Array<number>,
	notIn?: Array<number>,
	lt?: number,
	lte?: number,
	gt?: number,
	gte?: number
};
	["ContentWhere"]: {
		id?: GraphQLTypes["UUIDCondition"],
	blocks?: GraphQLTypes["ContentBlockWhere"],
	and?: Array<GraphQLTypes["ContentWhere"] | undefined>,
	or?: Array<GraphQLTypes["ContentWhere"] | undefined>,
	not?: GraphQLTypes["ContentWhere"]
};
	["ContentBlockWhere"]: {
		id?: GraphQLTypes["UUIDCondition"],
	order?: GraphQLTypes["IntCondition"],
	json?: GraphQLTypes["StringCondition"],
	content?: GraphQLTypes["ContentWhere"],
	references?: GraphQLTypes["ContentReferenceWhere"],
	and?: Array<GraphQLTypes["ContentBlockWhere"] | undefined>,
	or?: Array<GraphQLTypes["ContentBlockWhere"] | undefined>,
	not?: GraphQLTypes["ContentBlockWhere"]
};
	["ContentReferenceWhere"]: {
		id?: GraphQLTypes["UUIDCondition"],
	type?: GraphQLTypes["ContentReferenceTypeCondition"],
	primaryText?: GraphQLTypes["StringCondition"],
	block?: GraphQLTypes["ContentBlockWhere"],
	image?: GraphQLTypes["ImageWhere"],
	highlightFirstArticle?: GraphQLTypes["BooleanCondition"],
	linkToMoreArticles?: GraphQLTypes["BooleanCondition"],
	people?: GraphQLTypes["PersonListWhere"],
	and?: Array<GraphQLTypes["ContentReferenceWhere"] | undefined>,
	or?: Array<GraphQLTypes["ContentReferenceWhere"] | undefined>,
	not?: GraphQLTypes["ContentReferenceWhere"]
};
	["ContentReferenceTypeCondition"]: {
		and?: Array<GraphQLTypes["ContentReferenceTypeCondition"]>,
	or?: Array<GraphQLTypes["ContentReferenceTypeCondition"]>,
	not?: GraphQLTypes["ContentReferenceTypeCondition"],
	null?: boolean,
	isNull?: boolean,
	eq?: GraphQLTypes["ContentReferenceType"],
	notEq?: GraphQLTypes["ContentReferenceType"],
	in?: Array<GraphQLTypes["ContentReferenceType"]>,
	notIn?: Array<GraphQLTypes["ContentReferenceType"]>,
	lt?: GraphQLTypes["ContentReferenceType"],
	lte?: GraphQLTypes["ContentReferenceType"],
	gt?: GraphQLTypes["ContentReferenceType"],
	gte?: GraphQLTypes["ContentReferenceType"]
};
	["ContentReferenceType"]: ContentReferenceType;
	["BooleanCondition"]: {
		and?: Array<GraphQLTypes["BooleanCondition"]>,
	or?: Array<GraphQLTypes["BooleanCondition"]>,
	not?: GraphQLTypes["BooleanCondition"],
	null?: boolean,
	isNull?: boolean,
	eq?: boolean,
	notEq?: boolean,
	in?: Array<boolean>,
	notIn?: Array<boolean>,
	lt?: boolean,
	lte?: boolean,
	gt?: boolean,
	gte?: boolean
};
	["PersonListWhere"]: {
		id?: GraphQLTypes["UUIDCondition"],
	items?: GraphQLTypes["PersonListItemWhere"],
	and?: Array<GraphQLTypes["PersonListWhere"] | undefined>,
	or?: Array<GraphQLTypes["PersonListWhere"] | undefined>,
	not?: GraphQLTypes["PersonListWhere"]
};
	["PersonListItemWhere"]: {
		id?: GraphQLTypes["UUIDCondition"],
	order?: GraphQLTypes["IntCondition"],
	list?: GraphQLTypes["PersonListWhere"],
	person?: GraphQLTypes["PersonWhere"],
	about?: GraphQLTypes["StringCondition"],
	and?: Array<GraphQLTypes["PersonListItemWhere"] | undefined>,
	or?: Array<GraphQLTypes["PersonListItemWhere"] | undefined>,
	not?: GraphQLTypes["PersonListItemWhere"]
};
	["PersonWhere"]: {
		id?: GraphQLTypes["UUIDCondition"],
	name?: GraphQLTypes["StringCondition"],
	about?: GraphQLTypes["StringCondition"],
	email?: GraphQLTypes["StringCondition"],
	phone?: GraphQLTypes["StringCondition"],
	image?: GraphQLTypes["ImageWhere"],
	and?: Array<GraphQLTypes["PersonWhere"] | undefined>,
	or?: Array<GraphQLTypes["PersonWhere"] | undefined>,
	not?: GraphQLTypes["PersonWhere"]
};
	["TileListWhere"]: {
		id?: GraphQLTypes["UUIDCondition"],
	items?: GraphQLTypes["TileListItemWhere"],
	and?: Array<GraphQLTypes["TileListWhere"] | undefined>,
	or?: Array<GraphQLTypes["TileListWhere"] | undefined>,
	not?: GraphQLTypes["TileListWhere"]
};
	["TileListItemWhere"]: {
		id?: GraphQLTypes["UUIDCondition"],
	order?: GraphQLTypes["IntCondition"],
	list?: GraphQLTypes["TileListWhere"],
	tile?: GraphQLTypes["TileWhere"],
	and?: Array<GraphQLTypes["TileListItemWhere"] | undefined>,
	or?: Array<GraphQLTypes["TileListItemWhere"] | undefined>,
	not?: GraphQLTypes["TileListItemWhere"]
};
	["TileWhere"]: {
		id?: GraphQLTypes["UUIDCondition"],
	image?: GraphQLTypes["ImageWhere"],
	link?: GraphQLTypes["LinkWhere"],
	isVideo?: GraphQLTypes["BooleanCondition"],
	and?: Array<GraphQLTypes["TileWhere"] | undefined>,
	or?: Array<GraphQLTypes["TileWhere"] | undefined>,
	not?: GraphQLTypes["TileWhere"]
};
	["LinkWhere"]: {
		id?: GraphQLTypes["UUIDCondition"],
	type?: GraphQLTypes["LinkTypeCondition"],
	title?: GraphQLTypes["StringCondition"],
	externalLink?: GraphQLTypes["StringCondition"],
	internalLink?: GraphQLTypes["LinkableWhere"],
	isHighlighted?: GraphQLTypes["BooleanCondition"],
	targetBlank?: GraphQLTypes["BooleanCondition"],
	and?: Array<GraphQLTypes["LinkWhere"] | undefined>,
	or?: Array<GraphQLTypes["LinkWhere"] | undefined>,
	not?: GraphQLTypes["LinkWhere"]
};
	["LinkTypeCondition"]: {
		and?: Array<GraphQLTypes["LinkTypeCondition"]>,
	or?: Array<GraphQLTypes["LinkTypeCondition"]>,
	not?: GraphQLTypes["LinkTypeCondition"],
	null?: boolean,
	isNull?: boolean,
	eq?: GraphQLTypes["LinkType"],
	notEq?: GraphQLTypes["LinkType"],
	in?: Array<GraphQLTypes["LinkType"]>,
	notIn?: Array<GraphQLTypes["LinkType"]>,
	lt?: GraphQLTypes["LinkType"],
	lte?: GraphQLTypes["LinkType"],
	gt?: GraphQLTypes["LinkType"],
	gte?: GraphQLTypes["LinkType"]
};
	["SiteWhere"]: {
		id?: GraphQLTypes["UUIDCondition"],
	code?: GraphQLTypes["StringCondition"],
	order?: GraphQLTypes["IntCondition"],
	articles?: GraphQLTypes["ArticleWhere"],
	internalName?: GraphQLTypes["StringCondition"],
	articleCategories?: GraphQLTypes["ArticleCategoryWhere"],
	poses?: GraphQLTypes["PoseWhere"],
	poseCategories?: GraphQLTypes["PoseCategoryWhere"],
	poseDifficulties?: GraphQLTypes["PoseDifficultyWhere"],
	courses?: GraphQLTypes["CourseWhere"],
	articleListPage?: GraphQLTypes["ArticleListPageWhere"],
	courseListPage?: GraphQLTypes["CourseListPageWhere"],
	poseListPage?: GraphQLTypes["PoseListPageWhere"],
	courseLecturers?: GraphQLTypes["CourseLecturerWhere"],
	courseLecturerListPage?: GraphQLTypes["CourseLecturerListPageWhere"],
	homePages?: GraphQLTypes["HomepageWhere"],
	subscribeBox?: GraphQLTypes["SubscribeBoxWhere"],
	header?: GraphQLTypes["HeaderWhere"],
	footer?: GraphQLTypes["FooterWhere"],
	genericPages?: GraphQLTypes["GenericPageWhere"],
	courseEmail?: GraphQLTypes["CourseEmailWhere"],
	translations?: GraphQLTypes["TranslationsWhere"],
	and?: Array<GraphQLTypes["SiteWhere"] | undefined>,
	or?: Array<GraphQLTypes["SiteWhere"] | undefined>,
	not?: GraphQLTypes["SiteWhere"]
};
	["ArticleWhere"]: {
		id?: GraphQLTypes["UUIDCondition"],
	headline?: GraphQLTypes["StringCondition"],
	publishedAt?: GraphQLTypes["DateCondition"],
	site?: GraphQLTypes["SiteWhere"],
	seo?: GraphQLTypes["SeoWhere"],
	link?: GraphQLTypes["LinkableWhere"],
	lead?: GraphQLTypes["StringCondition"],
	shortLead?: GraphQLTypes["StringCondition"],
	image?: GraphQLTypes["ImageWhere"],
	content?: GraphQLTypes["ContentWhere"],
	category?: GraphQLTypes["ArticleCategoryWhere"],
	and?: Array<GraphQLTypes["ArticleWhere"] | undefined>,
	or?: Array<GraphQLTypes["ArticleWhere"] | undefined>,
	not?: GraphQLTypes["ArticleWhere"]
};
	["DateCondition"]: {
		and?: Array<GraphQLTypes["DateCondition"]>,
	or?: Array<GraphQLTypes["DateCondition"]>,
	not?: GraphQLTypes["DateCondition"],
	null?: boolean,
	isNull?: boolean,
	eq?: GraphQLTypes["Date"],
	notEq?: GraphQLTypes["Date"],
	in?: Array<GraphQLTypes["Date"]>,
	notIn?: Array<GraphQLTypes["Date"]>,
	lt?: GraphQLTypes["Date"],
	lte?: GraphQLTypes["Date"],
	gt?: GraphQLTypes["Date"],
	gte?: GraphQLTypes["Date"]
};
	["Date"]:string;
	["ArticleCategoryWhere"]: {
		id?: GraphQLTypes["UUIDCondition"],
	label?: GraphQLTypes["StringCondition"],
	articles?: GraphQLTypes["ArticleWhere"],
	site?: GraphQLTypes["SiteWhere"],
	seo?: GraphQLTypes["SeoWhere"],
	link?: GraphQLTypes["LinkableWhere"],
	lead?: GraphQLTypes["StringCondition"],
	originalPath?: GraphQLTypes["StringCondition"],
	and?: Array<GraphQLTypes["ArticleCategoryWhere"] | undefined>,
	or?: Array<GraphQLTypes["ArticleCategoryWhere"] | undefined>,
	not?: GraphQLTypes["ArticleCategoryWhere"]
};
	["PoseWhere"]: {
		id?: GraphQLTypes["UUIDCondition"],
	headline?: GraphQLTypes["StringCondition"],
	publishedAt?: GraphQLTypes["DateCondition"],
	link?: GraphQLTypes["LinkableWhere"],
	site?: GraphQLTypes["SiteWhere"],
	image?: GraphQLTypes["ImageWhere"],
	seo?: GraphQLTypes["SeoWhere"],
	subtitle?: GraphQLTypes["StringCondition"],
	content?: GraphQLTypes["ContentWhere"],
	category?: GraphQLTypes["PoseCategoryWhere"],
	difficulty?: GraphQLTypes["PoseDifficultyWhere"],
	and?: Array<GraphQLTypes["PoseWhere"] | undefined>,
	or?: Array<GraphQLTypes["PoseWhere"] | undefined>,
	not?: GraphQLTypes["PoseWhere"]
};
	["PoseCategoryWhere"]: {
		id?: GraphQLTypes["UUIDCondition"],
	label?: GraphQLTypes["StringCondition"],
	poses?: GraphQLTypes["PoseWhere"],
	site?: GraphQLTypes["SiteWhere"],
	icon?: GraphQLTypes["ImageWhere"],
	and?: Array<GraphQLTypes["PoseCategoryWhere"] | undefined>,
	or?: Array<GraphQLTypes["PoseCategoryWhere"] | undefined>,
	not?: GraphQLTypes["PoseCategoryWhere"]
};
	["PoseDifficultyWhere"]: {
		id?: GraphQLTypes["UUIDCondition"],
	label?: GraphQLTypes["StringCondition"],
	poses?: GraphQLTypes["PoseWhere"],
	site?: GraphQLTypes["SiteWhere"],
	icon?: GraphQLTypes["ImageWhere"],
	and?: Array<GraphQLTypes["PoseDifficultyWhere"] | undefined>,
	or?: Array<GraphQLTypes["PoseDifficultyWhere"] | undefined>,
	not?: GraphQLTypes["PoseDifficultyWhere"]
};
	["CourseWhere"]: {
		id?: GraphQLTypes["UUIDCondition"],
	headline?: GraphQLTypes["StringCondition"],
	lead?: GraphQLTypes["StringCondition"],
	shortLead?: GraphQLTypes["StringCondition"],
	publishedAt?: GraphQLTypes["DateCondition"],
	site?: GraphQLTypes["SiteWhere"],
	image?: GraphQLTypes["ImageWhere"],
	seo?: GraphQLTypes["SeoWhere"],
	link?: GraphQLTypes["LinkableWhere"],
	lessons?: GraphQLTypes["CourseLessonWhere"],
	difficulty?: GraphQLTypes["StringCondition"],
	teaser?: GraphQLTypes["YoutubeVideoWhere"],
	lessonIntervalInDays?: GraphQLTypes["IntCondition"],
	content?: GraphQLTypes["ContentWhere"],
	lecturer?: GraphQLTypes["CourseLecturerWhere"],
	and?: Array<GraphQLTypes["CourseWhere"] | undefined>,
	or?: Array<GraphQLTypes["CourseWhere"] | undefined>,
	not?: GraphQLTypes["CourseWhere"]
};
	["CourseLessonWhere"]: {
		id?: GraphQLTypes["UUIDCondition"],
	order?: GraphQLTypes["IntCondition"],
	headline?: GraphQLTypes["StringCondition"],
	lead?: GraphQLTypes["StringCondition"],
	course?: GraphQLTypes["CourseWhere"],
	video?: GraphQLTypes["YoutubeVideoWhere"],
	and?: Array<GraphQLTypes["CourseLessonWhere"] | undefined>,
	or?: Array<GraphQLTypes["CourseLessonWhere"] | undefined>,
	not?: GraphQLTypes["CourseLessonWhere"]
};
	["YoutubeVideoWhere"]: {
		id?: GraphQLTypes["UUIDCondition"],
	videoId?: GraphQLTypes["StringCondition"],
	and?: Array<GraphQLTypes["YoutubeVideoWhere"] | undefined>,
	or?: Array<GraphQLTypes["YoutubeVideoWhere"] | undefined>,
	not?: GraphQLTypes["YoutubeVideoWhere"]
};
	["CourseLecturerWhere"]: {
		id?: GraphQLTypes["UUIDCondition"],
	name?: GraphQLTypes["StringCondition"],
	courses?: GraphQLTypes["CourseWhere"],
	site?: GraphQLTypes["SiteWhere"],
	link?: GraphQLTypes["LinkableWhere"],
	seo?: GraphQLTypes["SeoWhere"],
	image?: GraphQLTypes["ImageWhere"],
	content?: GraphQLTypes["ContentWhere"],
	and?: Array<GraphQLTypes["CourseLecturerWhere"] | undefined>,
	or?: Array<GraphQLTypes["CourseLecturerWhere"] | undefined>,
	not?: GraphQLTypes["CourseLecturerWhere"]
};
	["ArticleListPageWhere"]: {
		id?: GraphQLTypes["UUIDCondition"],
	seo?: GraphQLTypes["SeoWhere"],
	link?: GraphQLTypes["LinkableWhere"],
	title?: GraphQLTypes["StringCondition"],
	site?: GraphQLTypes["SiteWhere"],
	and?: Array<GraphQLTypes["ArticleListPageWhere"] | undefined>,
	or?: Array<GraphQLTypes["ArticleListPageWhere"] | undefined>,
	not?: GraphQLTypes["ArticleListPageWhere"]
};
	["CourseListPageWhere"]: {
		id?: GraphQLTypes["UUIDCondition"],
	seo?: GraphQLTypes["SeoWhere"],
	link?: GraphQLTypes["LinkableWhere"],
	title?: GraphQLTypes["StringCondition"],
	site?: GraphQLTypes["SiteWhere"],
	and?: Array<GraphQLTypes["CourseListPageWhere"] | undefined>,
	or?: Array<GraphQLTypes["CourseListPageWhere"] | undefined>,
	not?: GraphQLTypes["CourseListPageWhere"]
};
	["PoseListPageWhere"]: {
		id?: GraphQLTypes["UUIDCondition"],
	link?: GraphQLTypes["LinkableWhere"],
	seo?: GraphQLTypes["SeoWhere"],
	title?: GraphQLTypes["StringCondition"],
	site?: GraphQLTypes["SiteWhere"],
	and?: Array<GraphQLTypes["PoseListPageWhere"] | undefined>,
	or?: Array<GraphQLTypes["PoseListPageWhere"] | undefined>,
	not?: GraphQLTypes["PoseListPageWhere"]
};
	["CourseLecturerListPageWhere"]: {
		id?: GraphQLTypes["UUIDCondition"],
	title?: GraphQLTypes["StringCondition"],
	site?: GraphQLTypes["SiteWhere"],
	seo?: GraphQLTypes["SeoWhere"],
	link?: GraphQLTypes["LinkableWhere"],
	and?: Array<GraphQLTypes["CourseLecturerListPageWhere"] | undefined>,
	or?: Array<GraphQLTypes["CourseLecturerListPageWhere"] | undefined>,
	not?: GraphQLTypes["CourseLecturerListPageWhere"]
};
	["SubscribeBoxWhere"]: {
		id?: GraphQLTypes["UUIDCondition"],
	title?: GraphQLTypes["StringCondition"],
	text?: GraphQLTypes["StringCondition"],
	shapeColorA?: GraphQLTypes["StringCondition"],
	shapeColorB?: GraphQLTypes["StringCondition"],
	shapeColorC?: GraphQLTypes["StringCondition"],
	externalMailingListId?: GraphQLTypes["StringCondition"],
	site?: GraphQLTypes["SiteWhere"],
	logo?: GraphQLTypes["ImageWhere"],
	emailLabel?: GraphQLTypes["StringCondition"],
	submitLabel?: GraphQLTypes["StringCondition"],
	and?: Array<GraphQLTypes["SubscribeBoxWhere"] | undefined>,
	or?: Array<GraphQLTypes["SubscribeBoxWhere"] | undefined>,
	not?: GraphQLTypes["SubscribeBoxWhere"]
};
	["HeaderWhere"]: {
		id?: GraphQLTypes["UUIDCondition"],
	primaryNavigationItems?: GraphQLTypes["HeaderPrimaryNavigationItemWhere"],
	site?: GraphQLTypes["SiteWhere"],
	logo?: GraphQLTypes["ImageWhere"],
	logoOnWhite?: GraphQLTypes["ImageWhere"],
	and?: Array<GraphQLTypes["HeaderWhere"] | undefined>,
	or?: Array<GraphQLTypes["HeaderWhere"] | undefined>,
	not?: GraphQLTypes["HeaderWhere"]
};
	["HeaderPrimaryNavigationItemWhere"]: {
		id?: GraphQLTypes["UUIDCondition"],
	order?: GraphQLTypes["IntCondition"],
	header?: GraphQLTypes["HeaderWhere"],
	link?: GraphQLTypes["LinkWhere"],
	listNavigation?: GraphQLTypes["ListNavigationWhere"],
	tiles?: GraphQLTypes["TileListWhere"],
	and?: Array<GraphQLTypes["HeaderPrimaryNavigationItemWhere"] | undefined>,
	or?: Array<GraphQLTypes["HeaderPrimaryNavigationItemWhere"] | undefined>,
	not?: GraphQLTypes["HeaderPrimaryNavigationItemWhere"]
};
	["ListNavigationWhere"]: {
		id?: GraphQLTypes["UUIDCondition"],
	columns?: GraphQLTypes["ListNavigationColumnWhere"],
	and?: Array<GraphQLTypes["ListNavigationWhere"] | undefined>,
	or?: Array<GraphQLTypes["ListNavigationWhere"] | undefined>,
	not?: GraphQLTypes["ListNavigationWhere"]
};
	["ListNavigationColumnWhere"]: {
		id?: GraphQLTypes["UUIDCondition"],
	order?: GraphQLTypes["IntCondition"],
	label?: GraphQLTypes["StringCondition"],
	listNavigation?: GraphQLTypes["ListNavigationWhere"],
	items?: GraphQLTypes["ListNavigationColumnItemWhere"],
	and?: Array<GraphQLTypes["ListNavigationColumnWhere"] | undefined>,
	or?: Array<GraphQLTypes["ListNavigationColumnWhere"] | undefined>,
	not?: GraphQLTypes["ListNavigationColumnWhere"]
};
	["ListNavigationColumnItemWhere"]: {
		id?: GraphQLTypes["UUIDCondition"],
	order?: GraphQLTypes["IntCondition"],
	column?: GraphQLTypes["ListNavigationColumnWhere"],
	link?: GraphQLTypes["LinkWhere"],
	and?: Array<GraphQLTypes["ListNavigationColumnItemWhere"] | undefined>,
	or?: Array<GraphQLTypes["ListNavigationColumnItemWhere"] | undefined>,
	not?: GraphQLTypes["ListNavigationColumnItemWhere"]
};
	["FooterWhere"]: {
		id?: GraphQLTypes["UUIDCondition"],
	navigation?: GraphQLTypes["ListNavigationWhere"],
	site?: GraphQLTypes["SiteWhere"],
	logo?: GraphQLTypes["ImageWhere"],
	copyright?: GraphQLTypes["StringCondition"],
	attribution?: GraphQLTypes["StringCondition"],
	and?: Array<GraphQLTypes["FooterWhere"] | undefined>,
	or?: Array<GraphQLTypes["FooterWhere"] | undefined>,
	not?: GraphQLTypes["FooterWhere"]
};
	["GenericPageWhere"]: {
		id?: GraphQLTypes["UUIDCondition"],
	headline?: GraphQLTypes["StringCondition"],
	lead?: GraphQLTypes["StringCondition"],
	publishedAt?: GraphQLTypes["DateCondition"],
	site?: GraphQLTypes["SiteWhere"],
	content?: GraphQLTypes["ContentWhere"],
	seo?: GraphQLTypes["SeoWhere"],
	link?: GraphQLTypes["LinkableWhere"],
	and?: Array<GraphQLTypes["GenericPageWhere"] | undefined>,
	or?: Array<GraphQLTypes["GenericPageWhere"] | undefined>,
	not?: GraphQLTypes["GenericPageWhere"]
};
	["CourseEmailWhere"]: {
		id?: GraphQLTypes["UUIDCondition"],
	title?: GraphQLTypes["StringCondition"],
	text?: GraphQLTypes["StringCondition"],
	buttonLabel?: GraphQLTypes["StringCondition"],
	logo?: GraphQLTypes["ImageWhere"],
	cover?: GraphQLTypes["ImageWhere"],
	site?: GraphQLTypes["SiteWhere"],
	contactInfoEmailAddress?: GraphQLTypes["StringCondition"],
	senderEmail?: GraphQLTypes["StringCondition"],
	and?: Array<GraphQLTypes["CourseEmailWhere"] | undefined>,
	or?: Array<GraphQLTypes["CourseEmailWhere"] | undefined>,
	not?: GraphQLTypes["CourseEmailWhere"]
};
	["TranslationsWhere"]: {
		id?: GraphQLTypes["UUIDCondition"],
	home?: GraphQLTypes["StringCondition"],
	articles?: GraphQLTypes["StringCondition"],
	article?: GraphQLTypes["StringCondition"],
	articleMore?: GraphQLTypes["StringCondition"],
	categories?: GraphQLTypes["StringCondition"],
	lecturers?: GraphQLTypes["StringCondition"],
	lecturer?: GraphQLTypes["StringCondition"],
	courses?: GraphQLTypes["StringCondition"],
	course?: GraphQLTypes["StringCondition"],
	poses?: GraphQLTypes["StringCondition"],
	pose?: GraphQLTypes["StringCondition"],
	site?: GraphQLTypes["SiteWhere"],
	posesLong?: GraphQLTypes["StringCondition"],
	posesMore?: GraphQLTypes["StringCondition"],
	listAll?: GraphQLTypes["StringCondition"],
	difficulty?: GraphQLTypes["StringCondition"],
	skillLevel?: GraphQLTypes["StringCondition"],
	signIn?: GraphQLTypes["StringCondition"],
	error?: GraphQLTypes["StringCondition"],
	and?: Array<GraphQLTypes["TranslationsWhere"] | undefined>,
	or?: Array<GraphQLTypes["TranslationsWhere"] | undefined>,
	not?: GraphQLTypes["TranslationsWhere"]
};
	["NewsletterSubscribeSuccessPageWhere"]: {
		id?: GraphQLTypes["UUIDCondition"],
	title?: GraphQLTypes["StringCondition"],
	returnLinkText?: GraphQLTypes["StringCondition"],
	link?: GraphQLTypes["LinkableWhere"],
	site?: GraphQLTypes["SiteWhere"],
	seo?: GraphQLTypes["SeoWhere"],
	and?: Array<GraphQLTypes["NewsletterSubscribeSuccessPageWhere"] | undefined>,
	or?: Array<GraphQLTypes["NewsletterSubscribeSuccessPageWhere"] | undefined>,
	not?: GraphQLTypes["NewsletterSubscribeSuccessPageWhere"]
};
	["Homepage"]: {
	__typename: "Homepage",
	_meta?: GraphQLTypes["HomepageMeta"],
	id: GraphQLTypes["UUID"],
	link?: GraphQLTypes["Linkable"],
	subtitle?: string,
	seo?: GraphQLTypes["Seo"],
	content?: GraphQLTypes["Content"],
	heroTiles?: GraphQLTypes["TileList"],
	site?: GraphQLTypes["Site"],
	titlePartA?: string,
	titlePartB?: string,
	titlePartC?: string,
	titlePartD?: string
};
	["HomepageMeta"]: {
	__typename: "HomepageMeta",
	id?: GraphQLTypes["FieldMeta"],
	link?: GraphQLTypes["FieldMeta"],
	subtitle?: GraphQLTypes["FieldMeta"],
	seo?: GraphQLTypes["FieldMeta"],
	content?: GraphQLTypes["FieldMeta"],
	heroTiles?: GraphQLTypes["FieldMeta"],
	site?: GraphQLTypes["FieldMeta"],
	titlePartA?: GraphQLTypes["FieldMeta"],
	titlePartB?: GraphQLTypes["FieldMeta"],
	titlePartC?: GraphQLTypes["FieldMeta"],
	titlePartD?: GraphQLTypes["FieldMeta"]
};
	["Seo"]: {
	__typename: "Seo",
	_meta?: GraphQLTypes["SeoMeta"],
	id: GraphQLTypes["UUID"],
	title?: string,
	description?: string,
	ogTitle?: string,
	ogDescription?: string,
	ogImage?: GraphQLTypes["Image"]
};
	["SeoMeta"]: {
	__typename: "SeoMeta",
	id?: GraphQLTypes["FieldMeta"],
	title?: GraphQLTypes["FieldMeta"],
	description?: GraphQLTypes["FieldMeta"],
	ogTitle?: GraphQLTypes["FieldMeta"],
	ogDescription?: GraphQLTypes["FieldMeta"],
	ogImage?: GraphQLTypes["FieldMeta"]
};
	["Image"]: {
	__typename: "Image",
	_meta?: GraphQLTypes["ImageMeta"],
	id: GraphQLTypes["UUID"],
	url: string,
	width?: number,
	height?: number,
	size?: number,
	type?: string,
	alt?: string
};
	["ImageMeta"]: {
	__typename: "ImageMeta",
	id?: GraphQLTypes["FieldMeta"],
	url?: GraphQLTypes["FieldMeta"],
	width?: GraphQLTypes["FieldMeta"],
	height?: GraphQLTypes["FieldMeta"],
	size?: GraphQLTypes["FieldMeta"],
	type?: GraphQLTypes["FieldMeta"],
	alt?: GraphQLTypes["FieldMeta"]
};
	["Content"]: {
	__typename: "Content",
	_meta?: GraphQLTypes["ContentMeta"],
	id: GraphQLTypes["UUID"],
	blocks: Array<GraphQLTypes["ContentBlock"]>,
	blocksByReferences?: GraphQLTypes["ContentBlock"],
	paginateBlocks: GraphQLTypes["ContentBlockConnection"]
};
	["ContentMeta"]: {
	__typename: "ContentMeta",
	id?: GraphQLTypes["FieldMeta"],
	blocks?: GraphQLTypes["FieldMeta"]
};
	["ContentBlock"]: {
	__typename: "ContentBlock",
	_meta?: GraphQLTypes["ContentBlockMeta"],
	id: GraphQLTypes["UUID"],
	order: number,
	json: string,
	content?: GraphQLTypes["Content"],
	references: Array<GraphQLTypes["ContentReference"]>,
	paginateReferences: GraphQLTypes["ContentReferenceConnection"]
};
	["ContentBlockMeta"]: {
	__typename: "ContentBlockMeta",
	id?: GraphQLTypes["FieldMeta"],
	order?: GraphQLTypes["FieldMeta"],
	json?: GraphQLTypes["FieldMeta"],
	content?: GraphQLTypes["FieldMeta"],
	references?: GraphQLTypes["FieldMeta"]
};
	["ContentReference"]: {
	__typename: "ContentReference",
	_meta?: GraphQLTypes["ContentReferenceMeta"],
	id: GraphQLTypes["UUID"],
	type: GraphQLTypes["ContentReferenceType"],
	primaryText?: string,
	block?: GraphQLTypes["ContentBlock"],
	image?: GraphQLTypes["Image"],
	highlightFirstArticle?: boolean,
	linkToMoreArticles?: boolean,
	people?: GraphQLTypes["PersonList"]
};
	["ContentReferenceMeta"]: {
	__typename: "ContentReferenceMeta",
	id?: GraphQLTypes["FieldMeta"],
	type?: GraphQLTypes["FieldMeta"],
	primaryText?: GraphQLTypes["FieldMeta"],
	block?: GraphQLTypes["FieldMeta"],
	image?: GraphQLTypes["FieldMeta"],
	highlightFirstArticle?: GraphQLTypes["FieldMeta"],
	linkToMoreArticles?: GraphQLTypes["FieldMeta"],
	people?: GraphQLTypes["FieldMeta"]
};
	["PersonList"]: {
	__typename: "PersonList",
	_meta?: GraphQLTypes["PersonListMeta"],
	id: GraphQLTypes["UUID"],
	items: Array<GraphQLTypes["PersonListItem"]>,
	paginateItems: GraphQLTypes["PersonListItemConnection"]
};
	["PersonListMeta"]: {
	__typename: "PersonListMeta",
	id?: GraphQLTypes["FieldMeta"],
	items?: GraphQLTypes["FieldMeta"]
};
	["PersonListItem"]: {
	__typename: "PersonListItem",
	_meta?: GraphQLTypes["PersonListItemMeta"],
	id: GraphQLTypes["UUID"],
	order: number,
	list?: GraphQLTypes["PersonList"],
	person?: GraphQLTypes["Person"],
	about?: string
};
	["PersonListItemMeta"]: {
	__typename: "PersonListItemMeta",
	id?: GraphQLTypes["FieldMeta"],
	order?: GraphQLTypes["FieldMeta"],
	list?: GraphQLTypes["FieldMeta"],
	person?: GraphQLTypes["FieldMeta"],
	about?: GraphQLTypes["FieldMeta"]
};
	["Person"]: {
	__typename: "Person",
	_meta?: GraphQLTypes["PersonMeta"],
	id: GraphQLTypes["UUID"],
	name?: string,
	about?: string,
	email?: string,
	phone?: string,
	image?: GraphQLTypes["Image"]
};
	["PersonMeta"]: {
	__typename: "PersonMeta",
	id?: GraphQLTypes["FieldMeta"],
	name?: GraphQLTypes["FieldMeta"],
	about?: GraphQLTypes["FieldMeta"],
	email?: GraphQLTypes["FieldMeta"],
	phone?: GraphQLTypes["FieldMeta"],
	image?: GraphQLTypes["FieldMeta"]
};
	["PersonListItemOrderBy"]: {
		_random?: boolean,
	_randomSeeded?: number,
	id?: GraphQLTypes["OrderDirection"],
	order?: GraphQLTypes["OrderDirection"],
	list?: GraphQLTypes["PersonListOrderBy"],
	person?: GraphQLTypes["PersonOrderBy"],
	about?: GraphQLTypes["OrderDirection"]
};
	["OrderDirection"]: OrderDirection;
	["PersonListOrderBy"]: {
		_random?: boolean,
	_randomSeeded?: number,
	id?: GraphQLTypes["OrderDirection"]
};
	["PersonOrderBy"]: {
		_random?: boolean,
	_randomSeeded?: number,
	id?: GraphQLTypes["OrderDirection"],
	name?: GraphQLTypes["OrderDirection"],
	about?: GraphQLTypes["OrderDirection"],
	email?: GraphQLTypes["OrderDirection"],
	phone?: GraphQLTypes["OrderDirection"],
	image?: GraphQLTypes["ImageOrderBy"]
};
	["ImageOrderBy"]: {
		_random?: boolean,
	_randomSeeded?: number,
	id?: GraphQLTypes["OrderDirection"],
	url?: GraphQLTypes["OrderDirection"],
	width?: GraphQLTypes["OrderDirection"],
	height?: GraphQLTypes["OrderDirection"],
	size?: GraphQLTypes["OrderDirection"],
	type?: GraphQLTypes["OrderDirection"],
	alt?: GraphQLTypes["OrderDirection"]
};
	["PersonListItemConnection"]: {
	__typename: "PersonListItemConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["PersonListItemEdge"]>
};
	["PageInfo"]: {
	__typename: "PageInfo",
	totalCount: number
};
	["PersonListItemEdge"]: {
	__typename: "PersonListItemEdge",
	node: GraphQLTypes["PersonListItem"]
};
	["ContentReferenceOrderBy"]: {
		_random?: boolean,
	_randomSeeded?: number,
	id?: GraphQLTypes["OrderDirection"],
	type?: GraphQLTypes["OrderDirection"],
	primaryText?: GraphQLTypes["OrderDirection"],
	block?: GraphQLTypes["ContentBlockOrderBy"],
	image?: GraphQLTypes["ImageOrderBy"],
	highlightFirstArticle?: GraphQLTypes["OrderDirection"],
	linkToMoreArticles?: GraphQLTypes["OrderDirection"],
	people?: GraphQLTypes["PersonListOrderBy"]
};
	["ContentBlockOrderBy"]: {
		_random?: boolean,
	_randomSeeded?: number,
	id?: GraphQLTypes["OrderDirection"],
	order?: GraphQLTypes["OrderDirection"],
	json?: GraphQLTypes["OrderDirection"],
	content?: GraphQLTypes["ContentOrderBy"]
};
	["ContentOrderBy"]: {
		_random?: boolean,
	_randomSeeded?: number,
	id?: GraphQLTypes["OrderDirection"]
};
	["ContentReferenceConnection"]: {
	__typename: "ContentReferenceConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["ContentReferenceEdge"]>
};
	["ContentReferenceEdge"]: {
	__typename: "ContentReferenceEdge",
	node: GraphQLTypes["ContentReference"]
};
	["ContentBlocksByReferencesUniqueWhere"]: {
		references?: GraphQLTypes["ContentReferenceUniqueWhere"]
};
	["ContentReferenceUniqueWhere"]: {
		id?: GraphQLTypes["UUID"]
};
	["ContentBlockConnection"]: {
	__typename: "ContentBlockConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["ContentBlockEdge"]>
};
	["ContentBlockEdge"]: {
	__typename: "ContentBlockEdge",
	node: GraphQLTypes["ContentBlock"]
};
	["TileList"]: {
	__typename: "TileList",
	_meta?: GraphQLTypes["TileListMeta"],
	id: GraphQLTypes["UUID"],
	items: Array<GraphQLTypes["TileListItem"]>,
	itemsByTile?: GraphQLTypes["TileListItem"],
	paginateItems: GraphQLTypes["TileListItemConnection"]
};
	["TileListMeta"]: {
	__typename: "TileListMeta",
	id?: GraphQLTypes["FieldMeta"],
	items?: GraphQLTypes["FieldMeta"]
};
	["TileListItem"]: {
	__typename: "TileListItem",
	_meta?: GraphQLTypes["TileListItemMeta"],
	id: GraphQLTypes["UUID"],
	order: number,
	list?: GraphQLTypes["TileList"],
	tile?: GraphQLTypes["Tile"]
};
	["TileListItemMeta"]: {
	__typename: "TileListItemMeta",
	id?: GraphQLTypes["FieldMeta"],
	order?: GraphQLTypes["FieldMeta"],
	list?: GraphQLTypes["FieldMeta"],
	tile?: GraphQLTypes["FieldMeta"]
};
	["Tile"]: {
	__typename: "Tile",
	_meta?: GraphQLTypes["TileMeta"],
	id: GraphQLTypes["UUID"],
	image?: GraphQLTypes["Image"],
	link?: GraphQLTypes["Link"],
	isVideo?: boolean
};
	["TileMeta"]: {
	__typename: "TileMeta",
	id?: GraphQLTypes["FieldMeta"],
	image?: GraphQLTypes["FieldMeta"],
	link?: GraphQLTypes["FieldMeta"],
	isVideo?: GraphQLTypes["FieldMeta"]
};
	["TileListItemOrderBy"]: {
		_random?: boolean,
	_randomSeeded?: number,
	id?: GraphQLTypes["OrderDirection"],
	order?: GraphQLTypes["OrderDirection"],
	list?: GraphQLTypes["TileListOrderBy"],
	tile?: GraphQLTypes["TileOrderBy"]
};
	["TileListOrderBy"]: {
		_random?: boolean,
	_randomSeeded?: number,
	id?: GraphQLTypes["OrderDirection"]
};
	["TileOrderBy"]: {
		_random?: boolean,
	_randomSeeded?: number,
	id?: GraphQLTypes["OrderDirection"],
	image?: GraphQLTypes["ImageOrderBy"],
	link?: GraphQLTypes["LinkOrderBy"],
	isVideo?: GraphQLTypes["OrderDirection"]
};
	["LinkOrderBy"]: {
		_random?: boolean,
	_randomSeeded?: number,
	id?: GraphQLTypes["OrderDirection"],
	type?: GraphQLTypes["OrderDirection"],
	title?: GraphQLTypes["OrderDirection"],
	externalLink?: GraphQLTypes["OrderDirection"],
	internalLink?: GraphQLTypes["LinkableOrderBy"],
	isHighlighted?: GraphQLTypes["OrderDirection"],
	targetBlank?: GraphQLTypes["OrderDirection"]
};
	["LinkableOrderBy"]: {
		_random?: boolean,
	_randomSeeded?: number,
	id?: GraphQLTypes["OrderDirection"],
	url?: GraphQLTypes["OrderDirection"],
	redirect?: GraphQLTypes["RedirectOrderBy"],
	homepage?: GraphQLTypes["HomepageOrderBy"],
	article?: GraphQLTypes["ArticleOrderBy"],
	articleCategory?: GraphQLTypes["ArticleCategoryOrderBy"],
	pose?: GraphQLTypes["PoseOrderBy"],
	course?: GraphQLTypes["CourseOrderBy"],
	articleList?: GraphQLTypes["ArticleListPageOrderBy"],
	courseList?: GraphQLTypes["CourseListPageOrderBy"],
	poseList?: GraphQLTypes["PoseListPageOrderBy"],
	courseLecturer?: GraphQLTypes["CourseLecturerOrderBy"],
	courseLecturerList?: GraphQLTypes["CourseLecturerListPageOrderBy"],
	newsletterSubscribeSuccessPage?: GraphQLTypes["NewsletterSubscribeSuccessPageOrderBy"],
	genericPage?: GraphQLTypes["GenericPageOrderBy"]
};
	["RedirectOrderBy"]: {
		_random?: boolean,
	_randomSeeded?: number,
	id?: GraphQLTypes["OrderDirection"],
	link?: GraphQLTypes["LinkableOrderBy"],
	target?: GraphQLTypes["LinkableOrderBy"]
};
	["HomepageOrderBy"]: {
		_random?: boolean,
	_randomSeeded?: number,
	id?: GraphQLTypes["OrderDirection"],
	link?: GraphQLTypes["LinkableOrderBy"],
	subtitle?: GraphQLTypes["OrderDirection"],
	seo?: GraphQLTypes["SeoOrderBy"],
	content?: GraphQLTypes["ContentOrderBy"],
	heroTiles?: GraphQLTypes["TileListOrderBy"],
	site?: GraphQLTypes["SiteOrderBy"],
	titlePartA?: GraphQLTypes["OrderDirection"],
	titlePartB?: GraphQLTypes["OrderDirection"],
	titlePartC?: GraphQLTypes["OrderDirection"],
	titlePartD?: GraphQLTypes["OrderDirection"]
};
	["SeoOrderBy"]: {
		_random?: boolean,
	_randomSeeded?: number,
	id?: GraphQLTypes["OrderDirection"],
	title?: GraphQLTypes["OrderDirection"],
	description?: GraphQLTypes["OrderDirection"],
	ogTitle?: GraphQLTypes["OrderDirection"],
	ogDescription?: GraphQLTypes["OrderDirection"],
	ogImage?: GraphQLTypes["ImageOrderBy"]
};
	["SiteOrderBy"]: {
		_random?: boolean,
	_randomSeeded?: number,
	id?: GraphQLTypes["OrderDirection"],
	code?: GraphQLTypes["OrderDirection"],
	order?: GraphQLTypes["OrderDirection"],
	internalName?: GraphQLTypes["OrderDirection"],
	articleListPage?: GraphQLTypes["ArticleListPageOrderBy"],
	courseListPage?: GraphQLTypes["CourseListPageOrderBy"],
	poseListPage?: GraphQLTypes["PoseListPageOrderBy"],
	courseLecturerListPage?: GraphQLTypes["CourseLecturerListPageOrderBy"],
	homePages?: GraphQLTypes["HomepageOrderBy"],
	subscribeBox?: GraphQLTypes["SubscribeBoxOrderBy"],
	header?: GraphQLTypes["HeaderOrderBy"],
	footer?: GraphQLTypes["FooterOrderBy"],
	courseEmail?: GraphQLTypes["CourseEmailOrderBy"],
	translations?: GraphQLTypes["TranslationsOrderBy"]
};
	["ArticleListPageOrderBy"]: {
		_random?: boolean,
	_randomSeeded?: number,
	id?: GraphQLTypes["OrderDirection"],
	seo?: GraphQLTypes["SeoOrderBy"],
	link?: GraphQLTypes["LinkableOrderBy"],
	title?: GraphQLTypes["OrderDirection"],
	site?: GraphQLTypes["SiteOrderBy"]
};
	["CourseListPageOrderBy"]: {
		_random?: boolean,
	_randomSeeded?: number,
	id?: GraphQLTypes["OrderDirection"],
	seo?: GraphQLTypes["SeoOrderBy"],
	link?: GraphQLTypes["LinkableOrderBy"],
	title?: GraphQLTypes["OrderDirection"],
	site?: GraphQLTypes["SiteOrderBy"]
};
	["PoseListPageOrderBy"]: {
		_random?: boolean,
	_randomSeeded?: number,
	id?: GraphQLTypes["OrderDirection"],
	link?: GraphQLTypes["LinkableOrderBy"],
	seo?: GraphQLTypes["SeoOrderBy"],
	title?: GraphQLTypes["OrderDirection"],
	site?: GraphQLTypes["SiteOrderBy"]
};
	["CourseLecturerListPageOrderBy"]: {
		_random?: boolean,
	_randomSeeded?: number,
	id?: GraphQLTypes["OrderDirection"],
	title?: GraphQLTypes["OrderDirection"],
	site?: GraphQLTypes["SiteOrderBy"],
	seo?: GraphQLTypes["SeoOrderBy"],
	link?: GraphQLTypes["LinkableOrderBy"]
};
	["SubscribeBoxOrderBy"]: {
		_random?: boolean,
	_randomSeeded?: number,
	id?: GraphQLTypes["OrderDirection"],
	title?: GraphQLTypes["OrderDirection"],
	text?: GraphQLTypes["OrderDirection"],
	shapeColorA?: GraphQLTypes["OrderDirection"],
	shapeColorB?: GraphQLTypes["OrderDirection"],
	shapeColorC?: GraphQLTypes["OrderDirection"],
	externalMailingListId?: GraphQLTypes["OrderDirection"],
	site?: GraphQLTypes["SiteOrderBy"],
	logo?: GraphQLTypes["ImageOrderBy"],
	emailLabel?: GraphQLTypes["OrderDirection"],
	submitLabel?: GraphQLTypes["OrderDirection"]
};
	["HeaderOrderBy"]: {
		_random?: boolean,
	_randomSeeded?: number,
	id?: GraphQLTypes["OrderDirection"],
	site?: GraphQLTypes["SiteOrderBy"],
	logo?: GraphQLTypes["ImageOrderBy"],
	logoOnWhite?: GraphQLTypes["ImageOrderBy"]
};
	["FooterOrderBy"]: {
		_random?: boolean,
	_randomSeeded?: number,
	id?: GraphQLTypes["OrderDirection"],
	navigation?: GraphQLTypes["ListNavigationOrderBy"],
	site?: GraphQLTypes["SiteOrderBy"],
	logo?: GraphQLTypes["ImageOrderBy"],
	copyright?: GraphQLTypes["OrderDirection"],
	attribution?: GraphQLTypes["OrderDirection"]
};
	["ListNavigationOrderBy"]: {
		_random?: boolean,
	_randomSeeded?: number,
	id?: GraphQLTypes["OrderDirection"]
};
	["CourseEmailOrderBy"]: {
		_random?: boolean,
	_randomSeeded?: number,
	id?: GraphQLTypes["OrderDirection"],
	title?: GraphQLTypes["OrderDirection"],
	text?: GraphQLTypes["OrderDirection"],
	buttonLabel?: GraphQLTypes["OrderDirection"],
	logo?: GraphQLTypes["ImageOrderBy"],
	cover?: GraphQLTypes["ImageOrderBy"],
	site?: GraphQLTypes["SiteOrderBy"],
	contactInfoEmailAddress?: GraphQLTypes["OrderDirection"],
	senderEmail?: GraphQLTypes["OrderDirection"]
};
	["TranslationsOrderBy"]: {
		_random?: boolean,
	_randomSeeded?: number,
	id?: GraphQLTypes["OrderDirection"],
	home?: GraphQLTypes["OrderDirection"],
	articles?: GraphQLTypes["OrderDirection"],
	article?: GraphQLTypes["OrderDirection"],
	articleMore?: GraphQLTypes["OrderDirection"],
	categories?: GraphQLTypes["OrderDirection"],
	lecturers?: GraphQLTypes["OrderDirection"],
	lecturer?: GraphQLTypes["OrderDirection"],
	courses?: GraphQLTypes["OrderDirection"],
	course?: GraphQLTypes["OrderDirection"],
	poses?: GraphQLTypes["OrderDirection"],
	pose?: GraphQLTypes["OrderDirection"],
	site?: GraphQLTypes["SiteOrderBy"],
	posesLong?: GraphQLTypes["OrderDirection"],
	posesMore?: GraphQLTypes["OrderDirection"],
	listAll?: GraphQLTypes["OrderDirection"],
	difficulty?: GraphQLTypes["OrderDirection"],
	skillLevel?: GraphQLTypes["OrderDirection"],
	signIn?: GraphQLTypes["OrderDirection"],
	error?: GraphQLTypes["OrderDirection"]
};
	["ArticleOrderBy"]: {
		_random?: boolean,
	_randomSeeded?: number,
	id?: GraphQLTypes["OrderDirection"],
	headline?: GraphQLTypes["OrderDirection"],
	publishedAt?: GraphQLTypes["OrderDirection"],
	site?: GraphQLTypes["SiteOrderBy"],
	seo?: GraphQLTypes["SeoOrderBy"],
	link?: GraphQLTypes["LinkableOrderBy"],
	lead?: GraphQLTypes["OrderDirection"],
	shortLead?: GraphQLTypes["OrderDirection"],
	image?: GraphQLTypes["ImageOrderBy"],
	content?: GraphQLTypes["ContentOrderBy"],
	category?: GraphQLTypes["ArticleCategoryOrderBy"]
};
	["ArticleCategoryOrderBy"]: {
		_random?: boolean,
	_randomSeeded?: number,
	id?: GraphQLTypes["OrderDirection"],
	label?: GraphQLTypes["OrderDirection"],
	site?: GraphQLTypes["SiteOrderBy"],
	seo?: GraphQLTypes["SeoOrderBy"],
	link?: GraphQLTypes["LinkableOrderBy"],
	lead?: GraphQLTypes["OrderDirection"],
	originalPath?: GraphQLTypes["OrderDirection"]
};
	["PoseOrderBy"]: {
		_random?: boolean,
	_randomSeeded?: number,
	id?: GraphQLTypes["OrderDirection"],
	headline?: GraphQLTypes["OrderDirection"],
	publishedAt?: GraphQLTypes["OrderDirection"],
	link?: GraphQLTypes["LinkableOrderBy"],
	site?: GraphQLTypes["SiteOrderBy"],
	image?: GraphQLTypes["ImageOrderBy"],
	seo?: GraphQLTypes["SeoOrderBy"],
	subtitle?: GraphQLTypes["OrderDirection"],
	content?: GraphQLTypes["ContentOrderBy"],
	category?: GraphQLTypes["PoseCategoryOrderBy"],
	difficulty?: GraphQLTypes["PoseDifficultyOrderBy"]
};
	["PoseCategoryOrderBy"]: {
		_random?: boolean,
	_randomSeeded?: number,
	id?: GraphQLTypes["OrderDirection"],
	label?: GraphQLTypes["OrderDirection"],
	site?: GraphQLTypes["SiteOrderBy"],
	icon?: GraphQLTypes["ImageOrderBy"]
};
	["PoseDifficultyOrderBy"]: {
		_random?: boolean,
	_randomSeeded?: number,
	id?: GraphQLTypes["OrderDirection"],
	label?: GraphQLTypes["OrderDirection"],
	site?: GraphQLTypes["SiteOrderBy"],
	icon?: GraphQLTypes["ImageOrderBy"]
};
	["CourseOrderBy"]: {
		_random?: boolean,
	_randomSeeded?: number,
	id?: GraphQLTypes["OrderDirection"],
	headline?: GraphQLTypes["OrderDirection"],
	lead?: GraphQLTypes["OrderDirection"],
	shortLead?: GraphQLTypes["OrderDirection"],
	publishedAt?: GraphQLTypes["OrderDirection"],
	site?: GraphQLTypes["SiteOrderBy"],
	image?: GraphQLTypes["ImageOrderBy"],
	seo?: GraphQLTypes["SeoOrderBy"],
	link?: GraphQLTypes["LinkableOrderBy"],
	difficulty?: GraphQLTypes["OrderDirection"],
	teaser?: GraphQLTypes["YoutubeVideoOrderBy"],
	lessonIntervalInDays?: GraphQLTypes["OrderDirection"],
	content?: GraphQLTypes["ContentOrderBy"],
	lecturer?: GraphQLTypes["CourseLecturerOrderBy"]
};
	["YoutubeVideoOrderBy"]: {
		_random?: boolean,
	_randomSeeded?: number,
	id?: GraphQLTypes["OrderDirection"],
	videoId?: GraphQLTypes["OrderDirection"]
};
	["CourseLecturerOrderBy"]: {
		_random?: boolean,
	_randomSeeded?: number,
	id?: GraphQLTypes["OrderDirection"],
	name?: GraphQLTypes["OrderDirection"],
	site?: GraphQLTypes["SiteOrderBy"],
	link?: GraphQLTypes["LinkableOrderBy"],
	seo?: GraphQLTypes["SeoOrderBy"],
	image?: GraphQLTypes["ImageOrderBy"],
	content?: GraphQLTypes["ContentOrderBy"]
};
	["NewsletterSubscribeSuccessPageOrderBy"]: {
		_random?: boolean,
	_randomSeeded?: number,
	id?: GraphQLTypes["OrderDirection"],
	title?: GraphQLTypes["OrderDirection"],
	returnLinkText?: GraphQLTypes["OrderDirection"],
	link?: GraphQLTypes["LinkableOrderBy"],
	site?: GraphQLTypes["SiteOrderBy"],
	seo?: GraphQLTypes["SeoOrderBy"]
};
	["GenericPageOrderBy"]: {
		_random?: boolean,
	_randomSeeded?: number,
	id?: GraphQLTypes["OrderDirection"],
	headline?: GraphQLTypes["OrderDirection"],
	lead?: GraphQLTypes["OrderDirection"],
	publishedAt?: GraphQLTypes["OrderDirection"],
	site?: GraphQLTypes["SiteOrderBy"],
	content?: GraphQLTypes["ContentOrderBy"],
	seo?: GraphQLTypes["SeoOrderBy"],
	link?: GraphQLTypes["LinkableOrderBy"]
};
	["TileListItemsByTileUniqueWhere"]: {
		tile?: GraphQLTypes["TileUniqueWhere"]
};
	["TileUniqueWhere"]: {
		id?: GraphQLTypes["UUID"],
	link?: GraphQLTypes["LinkUniqueWhere"]
};
	["LinkUniqueWhere"]: {
		id?: GraphQLTypes["UUID"]
};
	["TileListItemConnection"]: {
	__typename: "TileListItemConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["TileListItemEdge"]>
};
	["TileListItemEdge"]: {
	__typename: "TileListItemEdge",
	node: GraphQLTypes["TileListItem"]
};
	["Site"]: {
	__typename: "Site",
	_meta?: GraphQLTypes["SiteMeta"],
	id: GraphQLTypes["UUID"],
	code: string,
	order: number,
	articles: Array<GraphQLTypes["Article"]>,
	internalName?: string,
	articleCategories: Array<GraphQLTypes["ArticleCategory"]>,
	poses: Array<GraphQLTypes["Pose"]>,
	poseCategories: Array<GraphQLTypes["PoseCategory"]>,
	poseDifficulties: Array<GraphQLTypes["PoseDifficulty"]>,
	courses: Array<GraphQLTypes["Course"]>,
	articleListPage?: GraphQLTypes["ArticleListPage"],
	courseListPage?: GraphQLTypes["CourseListPage"],
	poseListPage?: GraphQLTypes["PoseListPage"],
	courseLecturers: Array<GraphQLTypes["CourseLecturer"]>,
	courseLecturerListPage?: GraphQLTypes["CourseLecturerListPage"],
	homePages?: GraphQLTypes["Homepage"],
	subscribeBox?: GraphQLTypes["SubscribeBox"],
	header?: GraphQLTypes["Header"],
	footer?: GraphQLTypes["Footer"],
	genericPages: Array<GraphQLTypes["GenericPage"]>,
	courseEmail?: GraphQLTypes["CourseEmail"],
	translations?: GraphQLTypes["Translations"],
	articlesBySeo?: GraphQLTypes["Article"],
	articlesByLink?: GraphQLTypes["Article"],
	articlesByContent?: GraphQLTypes["Article"],
	articleCategoriesByArticles?: GraphQLTypes["ArticleCategory"],
	articleCategoriesBySeo?: GraphQLTypes["ArticleCategory"],
	articleCategoriesByLink?: GraphQLTypes["ArticleCategory"],
	posesByLink?: GraphQLTypes["Pose"],
	posesBySeo?: GraphQLTypes["Pose"],
	posesByContent?: GraphQLTypes["Pose"],
	poseCategoriesByPoses?: GraphQLTypes["PoseCategory"],
	poseDifficultiesByPoses?: GraphQLTypes["PoseDifficulty"],
	coursesBySeo?: GraphQLTypes["Course"],
	coursesByLink?: GraphQLTypes["Course"],
	coursesByLessons?: GraphQLTypes["Course"],
	coursesByContent?: GraphQLTypes["Course"],
	courseLecturersByCourses?: GraphQLTypes["CourseLecturer"],
	courseLecturersByLink?: GraphQLTypes["CourseLecturer"],
	courseLecturersBySeo?: GraphQLTypes["CourseLecturer"],
	courseLecturersByContent?: GraphQLTypes["CourseLecturer"],
	genericPagesByContent?: GraphQLTypes["GenericPage"],
	genericPagesBySeo?: GraphQLTypes["GenericPage"],
	genericPagesByLink?: GraphQLTypes["GenericPage"],
	paginateArticles: GraphQLTypes["ArticleConnection"],
	paginateArticleCategories: GraphQLTypes["ArticleCategoryConnection"],
	paginatePoses: GraphQLTypes["PoseConnection"],
	paginatePoseCategories: GraphQLTypes["PoseCategoryConnection"],
	paginatePoseDifficulties: GraphQLTypes["PoseDifficultyConnection"],
	paginateCourses: GraphQLTypes["CourseConnection"],
	paginateCourseLecturers: GraphQLTypes["CourseLecturerConnection"],
	paginateGenericPages: GraphQLTypes["GenericPageConnection"]
};
	["SiteMeta"]: {
	__typename: "SiteMeta",
	id?: GraphQLTypes["FieldMeta"],
	code?: GraphQLTypes["FieldMeta"],
	order?: GraphQLTypes["FieldMeta"],
	articles?: GraphQLTypes["FieldMeta"],
	internalName?: GraphQLTypes["FieldMeta"],
	articleCategories?: GraphQLTypes["FieldMeta"],
	poses?: GraphQLTypes["FieldMeta"],
	poseCategories?: GraphQLTypes["FieldMeta"],
	poseDifficulties?: GraphQLTypes["FieldMeta"],
	courses?: GraphQLTypes["FieldMeta"],
	articleListPage?: GraphQLTypes["FieldMeta"],
	courseListPage?: GraphQLTypes["FieldMeta"],
	poseListPage?: GraphQLTypes["FieldMeta"],
	courseLecturers?: GraphQLTypes["FieldMeta"],
	courseLecturerListPage?: GraphQLTypes["FieldMeta"],
	homePages?: GraphQLTypes["FieldMeta"],
	subscribeBox?: GraphQLTypes["FieldMeta"],
	header?: GraphQLTypes["FieldMeta"],
	footer?: GraphQLTypes["FieldMeta"],
	genericPages?: GraphQLTypes["FieldMeta"],
	courseEmail?: GraphQLTypes["FieldMeta"],
	translations?: GraphQLTypes["FieldMeta"]
};
	["Article"]: {
	__typename: "Article",
	_meta?: GraphQLTypes["ArticleMeta"],
	id: GraphQLTypes["UUID"],
	headline?: string,
	publishedAt?: GraphQLTypes["Date"],
	site?: GraphQLTypes["Site"],
	seo?: GraphQLTypes["Seo"],
	link?: GraphQLTypes["Linkable"],
	lead?: string,
	shortLead?: string,
	image?: GraphQLTypes["Image"],
	content?: GraphQLTypes["Content"],
	category?: GraphQLTypes["ArticleCategory"]
};
	["ArticleMeta"]: {
	__typename: "ArticleMeta",
	id?: GraphQLTypes["FieldMeta"],
	headline?: GraphQLTypes["FieldMeta"],
	publishedAt?: GraphQLTypes["FieldMeta"],
	site?: GraphQLTypes["FieldMeta"],
	seo?: GraphQLTypes["FieldMeta"],
	link?: GraphQLTypes["FieldMeta"],
	lead?: GraphQLTypes["FieldMeta"],
	shortLead?: GraphQLTypes["FieldMeta"],
	image?: GraphQLTypes["FieldMeta"],
	content?: GraphQLTypes["FieldMeta"],
	category?: GraphQLTypes["FieldMeta"]
};
	["ArticleCategory"]: {
	__typename: "ArticleCategory",
	_meta?: GraphQLTypes["ArticleCategoryMeta"],
	id: GraphQLTypes["UUID"],
	label?: string,
	articles: Array<GraphQLTypes["Article"]>,
	site?: GraphQLTypes["Site"],
	seo?: GraphQLTypes["Seo"],
	link?: GraphQLTypes["Linkable"],
	lead?: string,
	originalPath?: string,
	articlesBySeo?: GraphQLTypes["Article"],
	articlesByLink?: GraphQLTypes["Article"],
	articlesByContent?: GraphQLTypes["Article"],
	paginateArticles: GraphQLTypes["ArticleConnection"]
};
	["ArticleCategoryMeta"]: {
	__typename: "ArticleCategoryMeta",
	id?: GraphQLTypes["FieldMeta"],
	label?: GraphQLTypes["FieldMeta"],
	articles?: GraphQLTypes["FieldMeta"],
	site?: GraphQLTypes["FieldMeta"],
	seo?: GraphQLTypes["FieldMeta"],
	link?: GraphQLTypes["FieldMeta"],
	lead?: GraphQLTypes["FieldMeta"],
	originalPath?: GraphQLTypes["FieldMeta"]
};
	["ArticleCategoryArticlesBySeoUniqueWhere"]: {
		seo?: GraphQLTypes["SeoUniqueWhere"]
};
	["SeoUniqueWhere"]: {
		id?: GraphQLTypes["UUID"],
	ogImage?: GraphQLTypes["ImageUniqueWhere"]
};
	["ImageUniqueWhere"]: {
		id?: GraphQLTypes["UUID"]
};
	["ArticleCategoryArticlesByLinkUniqueWhere"]: {
		link?: GraphQLTypes["LinkableUniqueWhere"]
};
	["LinkableUniqueWhere"]: {
		id?: GraphQLTypes["UUID"],
	url?: string,
	redirect?: GraphQLTypes["RedirectUniqueWhere"],
	homepage?: GraphQLTypes["HomepageUniqueWhere"],
	article?: GraphQLTypes["ArticleUniqueWhere"],
	articleCategory?: GraphQLTypes["ArticleCategoryUniqueWhere"],
	pose?: GraphQLTypes["PoseUniqueWhere"],
	course?: GraphQLTypes["CourseUniqueWhere"],
	articleList?: GraphQLTypes["ArticleListPageUniqueWhere"],
	courseList?: GraphQLTypes["CourseListPageUniqueWhere"],
	poseList?: GraphQLTypes["PoseListPageUniqueWhere"],
	courseLecturer?: GraphQLTypes["CourseLecturerUniqueWhere"],
	courseLecturerList?: GraphQLTypes["CourseLecturerListPageUniqueWhere"],
	newsletterSubscribeSuccessPage?: GraphQLTypes["NewsletterSubscribeSuccessPageUniqueWhere"],
	genericPage?: GraphQLTypes["GenericPageUniqueWhere"]
};
	["RedirectUniqueWhere"]: {
		id?: GraphQLTypes["UUID"],
	link?: GraphQLTypes["LinkableUniqueWhere"]
};
	["HomepageUniqueWhere"]: {
		id?: GraphQLTypes["UUID"],
	link?: GraphQLTypes["LinkableUniqueWhere"],
	seo?: GraphQLTypes["SeoUniqueWhere"],
	content?: GraphQLTypes["ContentUniqueWhere"],
	heroTiles?: GraphQLTypes["TileListUniqueWhere"],
	site?: GraphQLTypes["SiteUniqueWhere"]
};
	["ContentUniqueWhere"]: {
		id?: GraphQLTypes["UUID"],
	blocks?: GraphQLTypes["ContentBlockUniqueWhere"]
};
	["ContentBlockUniqueWhere"]: {
		id?: GraphQLTypes["UUID"],
	references?: GraphQLTypes["ContentReferenceUniqueWhere"]
};
	["TileListUniqueWhere"]: {
		id?: GraphQLTypes["UUID"],
	items?: GraphQLTypes["TileListItemUniqueWhere"]
};
	["TileListItemUniqueWhere"]: {
		id?: GraphQLTypes["UUID"],
	tile?: GraphQLTypes["TileUniqueWhere"]
};
	["SiteUniqueWhere"]: {
		id?: GraphQLTypes["UUID"],
	code?: string,
	articles?: GraphQLTypes["ArticleUniqueWhere"],
	articleCategories?: GraphQLTypes["ArticleCategoryUniqueWhere"],
	poses?: GraphQLTypes["PoseUniqueWhere"],
	poseCategories?: GraphQLTypes["PoseCategoryUniqueWhere"],
	poseDifficulties?: GraphQLTypes["PoseDifficultyUniqueWhere"],
	courses?: GraphQLTypes["CourseUniqueWhere"],
	articleListPage?: GraphQLTypes["ArticleListPageUniqueWhere"],
	courseListPage?: GraphQLTypes["CourseListPageUniqueWhere"],
	poseListPage?: GraphQLTypes["PoseListPageUniqueWhere"],
	courseLecturers?: GraphQLTypes["CourseLecturerUniqueWhere"],
	courseLecturerListPage?: GraphQLTypes["CourseLecturerListPageUniqueWhere"],
	homePages?: GraphQLTypes["HomepageUniqueWhere"],
	subscribeBox?: GraphQLTypes["SubscribeBoxUniqueWhere"],
	header?: GraphQLTypes["HeaderUniqueWhere"],
	footer?: GraphQLTypes["FooterUniqueWhere"],
	genericPages?: GraphQLTypes["GenericPageUniqueWhere"],
	courseEmail?: GraphQLTypes["CourseEmailUniqueWhere"],
	translations?: GraphQLTypes["TranslationsUniqueWhere"]
};
	["ArticleUniqueWhere"]: {
		id?: GraphQLTypes["UUID"],
	seo?: GraphQLTypes["SeoUniqueWhere"],
	link?: GraphQLTypes["LinkableUniqueWhere"],
	content?: GraphQLTypes["ContentUniqueWhere"]
};
	["ArticleCategoryUniqueWhere"]: {
		id?: GraphQLTypes["UUID"],
	articles?: GraphQLTypes["ArticleUniqueWhere"],
	seo?: GraphQLTypes["SeoUniqueWhere"],
	link?: GraphQLTypes["LinkableUniqueWhere"]
};
	["PoseUniqueWhere"]: {
		id?: GraphQLTypes["UUID"],
	link?: GraphQLTypes["LinkableUniqueWhere"],
	seo?: GraphQLTypes["SeoUniqueWhere"],
	content?: GraphQLTypes["ContentUniqueWhere"]
};
	["PoseCategoryUniqueWhere"]: {
		id?: GraphQLTypes["UUID"],
	poses?: GraphQLTypes["PoseUniqueWhere"]
};
	["PoseDifficultyUniqueWhere"]: {
		id?: GraphQLTypes["UUID"],
	poses?: GraphQLTypes["PoseUniqueWhere"]
};
	["CourseUniqueWhere"]: {
		id?: GraphQLTypes["UUID"],
	seo?: GraphQLTypes["SeoUniqueWhere"],
	link?: GraphQLTypes["LinkableUniqueWhere"],
	lessons?: GraphQLTypes["CourseLessonUniqueWhere"],
	content?: GraphQLTypes["ContentUniqueWhere"]
};
	["CourseLessonUniqueWhere"]: {
		id?: GraphQLTypes["UUID"]
};
	["ArticleListPageUniqueWhere"]: {
		id?: GraphQLTypes["UUID"],
	seo?: GraphQLTypes["SeoUniqueWhere"],
	link?: GraphQLTypes["LinkableUniqueWhere"],
	site?: GraphQLTypes["SiteUniqueWhere"]
};
	["CourseListPageUniqueWhere"]: {
		id?: GraphQLTypes["UUID"],
	seo?: GraphQLTypes["SeoUniqueWhere"],
	link?: GraphQLTypes["LinkableUniqueWhere"],
	site?: GraphQLTypes["SiteUniqueWhere"]
};
	["PoseListPageUniqueWhere"]: {
		id?: GraphQLTypes["UUID"],
	link?: GraphQLTypes["LinkableUniqueWhere"],
	seo?: GraphQLTypes["SeoUniqueWhere"],
	site?: GraphQLTypes["SiteUniqueWhere"]
};
	["CourseLecturerUniqueWhere"]: {
		id?: GraphQLTypes["UUID"],
	courses?: GraphQLTypes["CourseUniqueWhere"],
	link?: GraphQLTypes["LinkableUniqueWhere"],
	seo?: GraphQLTypes["SeoUniqueWhere"],
	content?: GraphQLTypes["ContentUniqueWhere"]
};
	["CourseLecturerListPageUniqueWhere"]: {
		id?: GraphQLTypes["UUID"],
	site?: GraphQLTypes["SiteUniqueWhere"],
	seo?: GraphQLTypes["SeoUniqueWhere"],
	link?: GraphQLTypes["LinkableUniqueWhere"]
};
	["SubscribeBoxUniqueWhere"]: {
		id?: GraphQLTypes["UUID"],
	site?: GraphQLTypes["SiteUniqueWhere"],
	logo?: GraphQLTypes["ImageUniqueWhere"]
};
	["HeaderUniqueWhere"]: {
		id?: GraphQLTypes["UUID"],
	primaryNavigationItems?: GraphQLTypes["HeaderPrimaryNavigationItemUniqueWhere"],
	site?: GraphQLTypes["SiteUniqueWhere"],
	logo?: GraphQLTypes["ImageUniqueWhere"],
	logoOnWhite?: GraphQLTypes["ImageUniqueWhere"]
};
	["HeaderPrimaryNavigationItemUniqueWhere"]: {
		id?: GraphQLTypes["UUID"],
	link?: GraphQLTypes["LinkUniqueWhere"],
	listNavigation?: GraphQLTypes["ListNavigationUniqueWhere"],
	tiles?: GraphQLTypes["TileListUniqueWhere"]
};
	["ListNavigationUniqueWhere"]: {
		id?: GraphQLTypes["UUID"],
	columns?: GraphQLTypes["ListNavigationColumnUniqueWhere"]
};
	["ListNavigationColumnUniqueWhere"]: {
		id?: GraphQLTypes["UUID"],
	items?: GraphQLTypes["ListNavigationColumnItemUniqueWhere"]
};
	["ListNavigationColumnItemUniqueWhere"]: {
		id?: GraphQLTypes["UUID"],
	link?: GraphQLTypes["LinkUniqueWhere"]
};
	["FooterUniqueWhere"]: {
		id?: GraphQLTypes["UUID"],
	navigation?: GraphQLTypes["ListNavigationUniqueWhere"],
	site?: GraphQLTypes["SiteUniqueWhere"],
	logo?: GraphQLTypes["ImageUniqueWhere"]
};
	["GenericPageUniqueWhere"]: {
		id?: GraphQLTypes["UUID"],
	content?: GraphQLTypes["ContentUniqueWhere"],
	seo?: GraphQLTypes["SeoUniqueWhere"],
	link?: GraphQLTypes["LinkableUniqueWhere"]
};
	["CourseEmailUniqueWhere"]: {
		id?: GraphQLTypes["UUID"],
	logo?: GraphQLTypes["ImageUniqueWhere"],
	cover?: GraphQLTypes["ImageUniqueWhere"],
	site?: GraphQLTypes["SiteUniqueWhere"]
};
	["TranslationsUniqueWhere"]: {
		id?: GraphQLTypes["UUID"],
	site?: GraphQLTypes["SiteUniqueWhere"]
};
	["NewsletterSubscribeSuccessPageUniqueWhere"]: {
		id?: GraphQLTypes["UUID"],
	link?: GraphQLTypes["LinkableUniqueWhere"],
	site?: GraphQLTypes["SiteUniqueWhere"],
	seo?: GraphQLTypes["SeoUniqueWhere"]
};
	["ArticleCategoryArticlesByContentUniqueWhere"]: {
		content?: GraphQLTypes["ContentUniqueWhere"]
};
	["ArticleConnection"]: {
	__typename: "ArticleConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["ArticleEdge"]>
};
	["ArticleEdge"]: {
	__typename: "ArticleEdge",
	node: GraphQLTypes["Article"]
};
	["Pose"]: {
	__typename: "Pose",
	_meta?: GraphQLTypes["PoseMeta"],
	id: GraphQLTypes["UUID"],
	headline?: string,
	publishedAt?: GraphQLTypes["Date"],
	link?: GraphQLTypes["Linkable"],
	site?: GraphQLTypes["Site"],
	image?: GraphQLTypes["Image"],
	seo?: GraphQLTypes["Seo"],
	subtitle?: string,
	content?: GraphQLTypes["Content"],
	category?: GraphQLTypes["PoseCategory"],
	difficulty?: GraphQLTypes["PoseDifficulty"]
};
	["PoseMeta"]: {
	__typename: "PoseMeta",
	id?: GraphQLTypes["FieldMeta"],
	headline?: GraphQLTypes["FieldMeta"],
	publishedAt?: GraphQLTypes["FieldMeta"],
	link?: GraphQLTypes["FieldMeta"],
	site?: GraphQLTypes["FieldMeta"],
	image?: GraphQLTypes["FieldMeta"],
	seo?: GraphQLTypes["FieldMeta"],
	subtitle?: GraphQLTypes["FieldMeta"],
	content?: GraphQLTypes["FieldMeta"],
	category?: GraphQLTypes["FieldMeta"],
	difficulty?: GraphQLTypes["FieldMeta"]
};
	["PoseCategory"]: {
	__typename: "PoseCategory",
	_meta?: GraphQLTypes["PoseCategoryMeta"],
	id: GraphQLTypes["UUID"],
	label?: string,
	poses: Array<GraphQLTypes["Pose"]>,
	site?: GraphQLTypes["Site"],
	icon?: GraphQLTypes["Image"],
	posesByLink?: GraphQLTypes["Pose"],
	posesBySeo?: GraphQLTypes["Pose"],
	posesByContent?: GraphQLTypes["Pose"],
	paginatePoses: GraphQLTypes["PoseConnection"]
};
	["PoseCategoryMeta"]: {
	__typename: "PoseCategoryMeta",
	id?: GraphQLTypes["FieldMeta"],
	label?: GraphQLTypes["FieldMeta"],
	poses?: GraphQLTypes["FieldMeta"],
	site?: GraphQLTypes["FieldMeta"],
	icon?: GraphQLTypes["FieldMeta"]
};
	["PoseCategoryPosesByLinkUniqueWhere"]: {
		link?: GraphQLTypes["LinkableUniqueWhere"]
};
	["PoseCategoryPosesBySeoUniqueWhere"]: {
		seo?: GraphQLTypes["SeoUniqueWhere"]
};
	["PoseCategoryPosesByContentUniqueWhere"]: {
		content?: GraphQLTypes["ContentUniqueWhere"]
};
	["PoseConnection"]: {
	__typename: "PoseConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["PoseEdge"]>
};
	["PoseEdge"]: {
	__typename: "PoseEdge",
	node: GraphQLTypes["Pose"]
};
	["PoseDifficulty"]: {
	__typename: "PoseDifficulty",
	_meta?: GraphQLTypes["PoseDifficultyMeta"],
	id: GraphQLTypes["UUID"],
	label?: string,
	poses: Array<GraphQLTypes["Pose"]>,
	site?: GraphQLTypes["Site"],
	icon?: GraphQLTypes["Image"],
	posesByLink?: GraphQLTypes["Pose"],
	posesBySeo?: GraphQLTypes["Pose"],
	posesByContent?: GraphQLTypes["Pose"],
	paginatePoses: GraphQLTypes["PoseConnection"]
};
	["PoseDifficultyMeta"]: {
	__typename: "PoseDifficultyMeta",
	id?: GraphQLTypes["FieldMeta"],
	label?: GraphQLTypes["FieldMeta"],
	poses?: GraphQLTypes["FieldMeta"],
	site?: GraphQLTypes["FieldMeta"],
	icon?: GraphQLTypes["FieldMeta"]
};
	["PoseDifficultyPosesByLinkUniqueWhere"]: {
		link?: GraphQLTypes["LinkableUniqueWhere"]
};
	["PoseDifficultyPosesBySeoUniqueWhere"]: {
		seo?: GraphQLTypes["SeoUniqueWhere"]
};
	["PoseDifficultyPosesByContentUniqueWhere"]: {
		content?: GraphQLTypes["ContentUniqueWhere"]
};
	["Course"]: {
	__typename: "Course",
	_meta?: GraphQLTypes["CourseMeta"],
	id: GraphQLTypes["UUID"],
	headline?: string,
	lead?: string,
	shortLead?: string,
	publishedAt?: GraphQLTypes["Date"],
	site?: GraphQLTypes["Site"],
	image?: GraphQLTypes["Image"],
	seo?: GraphQLTypes["Seo"],
	link?: GraphQLTypes["Linkable"],
	lessons: Array<GraphQLTypes["CourseLesson"]>,
	difficulty?: string,
	teaser?: GraphQLTypes["YoutubeVideo"],
	lessonIntervalInDays: number,
	content?: GraphQLTypes["Content"],
	lecturer?: GraphQLTypes["CourseLecturer"],
	paginateLessons: GraphQLTypes["CourseLessonConnection"]
};
	["CourseMeta"]: {
	__typename: "CourseMeta",
	id?: GraphQLTypes["FieldMeta"],
	headline?: GraphQLTypes["FieldMeta"],
	lead?: GraphQLTypes["FieldMeta"],
	shortLead?: GraphQLTypes["FieldMeta"],
	publishedAt?: GraphQLTypes["FieldMeta"],
	site?: GraphQLTypes["FieldMeta"],
	image?: GraphQLTypes["FieldMeta"],
	seo?: GraphQLTypes["FieldMeta"],
	link?: GraphQLTypes["FieldMeta"],
	lessons?: GraphQLTypes["FieldMeta"],
	difficulty?: GraphQLTypes["FieldMeta"],
	teaser?: GraphQLTypes["FieldMeta"],
	lessonIntervalInDays?: GraphQLTypes["FieldMeta"],
	content?: GraphQLTypes["FieldMeta"],
	lecturer?: GraphQLTypes["FieldMeta"]
};
	["CourseLesson"]: {
	__typename: "CourseLesson",
	_meta?: GraphQLTypes["CourseLessonMeta"],
	id: GraphQLTypes["UUID"],
	order: number,
	headline?: string,
	lead?: string,
	course?: GraphQLTypes["Course"],
	video?: GraphQLTypes["YoutubeVideo"]
};
	["CourseLessonMeta"]: {
	__typename: "CourseLessonMeta",
	id?: GraphQLTypes["FieldMeta"],
	order?: GraphQLTypes["FieldMeta"],
	headline?: GraphQLTypes["FieldMeta"],
	lead?: GraphQLTypes["FieldMeta"],
	course?: GraphQLTypes["FieldMeta"],
	video?: GraphQLTypes["FieldMeta"]
};
	["YoutubeVideo"]: {
	__typename: "YoutubeVideo",
	_meta?: GraphQLTypes["YoutubeVideoMeta"],
	id: GraphQLTypes["UUID"],
	videoId: string
};
	["YoutubeVideoMeta"]: {
	__typename: "YoutubeVideoMeta",
	id?: GraphQLTypes["FieldMeta"],
	videoId?: GraphQLTypes["FieldMeta"]
};
	["CourseLessonOrderBy"]: {
		_random?: boolean,
	_randomSeeded?: number,
	id?: GraphQLTypes["OrderDirection"],
	order?: GraphQLTypes["OrderDirection"],
	headline?: GraphQLTypes["OrderDirection"],
	lead?: GraphQLTypes["OrderDirection"],
	course?: GraphQLTypes["CourseOrderBy"],
	video?: GraphQLTypes["YoutubeVideoOrderBy"]
};
	["CourseLecturer"]: {
	__typename: "CourseLecturer",
	_meta?: GraphQLTypes["CourseLecturerMeta"],
	id: GraphQLTypes["UUID"],
	name?: string,
	courses: Array<GraphQLTypes["Course"]>,
	site?: GraphQLTypes["Site"],
	link?: GraphQLTypes["Linkable"],
	seo?: GraphQLTypes["Seo"],
	image?: GraphQLTypes["Image"],
	content?: GraphQLTypes["Content"],
	coursesBySeo?: GraphQLTypes["Course"],
	coursesByLink?: GraphQLTypes["Course"],
	coursesByLessons?: GraphQLTypes["Course"],
	coursesByContent?: GraphQLTypes["Course"],
	paginateCourses: GraphQLTypes["CourseConnection"]
};
	["CourseLecturerMeta"]: {
	__typename: "CourseLecturerMeta",
	id?: GraphQLTypes["FieldMeta"],
	name?: GraphQLTypes["FieldMeta"],
	courses?: GraphQLTypes["FieldMeta"],
	site?: GraphQLTypes["FieldMeta"],
	link?: GraphQLTypes["FieldMeta"],
	seo?: GraphQLTypes["FieldMeta"],
	image?: GraphQLTypes["FieldMeta"],
	content?: GraphQLTypes["FieldMeta"]
};
	["CourseLecturerCoursesBySeoUniqueWhere"]: {
		seo?: GraphQLTypes["SeoUniqueWhere"]
};
	["CourseLecturerCoursesByLinkUniqueWhere"]: {
		link?: GraphQLTypes["LinkableUniqueWhere"]
};
	["CourseLecturerCoursesByLessonsUniqueWhere"]: {
		lessons?: GraphQLTypes["CourseLessonUniqueWhere"]
};
	["CourseLecturerCoursesByContentUniqueWhere"]: {
		content?: GraphQLTypes["ContentUniqueWhere"]
};
	["CourseConnection"]: {
	__typename: "CourseConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["CourseEdge"]>
};
	["CourseEdge"]: {
	__typename: "CourseEdge",
	node: GraphQLTypes["Course"]
};
	["CourseLessonConnection"]: {
	__typename: "CourseLessonConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["CourseLessonEdge"]>
};
	["CourseLessonEdge"]: {
	__typename: "CourseLessonEdge",
	node: GraphQLTypes["CourseLesson"]
};
	["ArticleListPage"]: {
	__typename: "ArticleListPage",
	_meta?: GraphQLTypes["ArticleListPageMeta"],
	id: GraphQLTypes["UUID"],
	seo?: GraphQLTypes["Seo"],
	link?: GraphQLTypes["Linkable"],
	title?: string,
	site?: GraphQLTypes["Site"]
};
	["ArticleListPageMeta"]: {
	__typename: "ArticleListPageMeta",
	id?: GraphQLTypes["FieldMeta"],
	seo?: GraphQLTypes["FieldMeta"],
	link?: GraphQLTypes["FieldMeta"],
	title?: GraphQLTypes["FieldMeta"],
	site?: GraphQLTypes["FieldMeta"]
};
	["CourseListPage"]: {
	__typename: "CourseListPage",
	_meta?: GraphQLTypes["CourseListPageMeta"],
	id: GraphQLTypes["UUID"],
	seo?: GraphQLTypes["Seo"],
	link?: GraphQLTypes["Linkable"],
	title?: string,
	site?: GraphQLTypes["Site"]
};
	["CourseListPageMeta"]: {
	__typename: "CourseListPageMeta",
	id?: GraphQLTypes["FieldMeta"],
	seo?: GraphQLTypes["FieldMeta"],
	link?: GraphQLTypes["FieldMeta"],
	title?: GraphQLTypes["FieldMeta"],
	site?: GraphQLTypes["FieldMeta"]
};
	["PoseListPage"]: {
	__typename: "PoseListPage",
	_meta?: GraphQLTypes["PoseListPageMeta"],
	id: GraphQLTypes["UUID"],
	link?: GraphQLTypes["Linkable"],
	seo?: GraphQLTypes["Seo"],
	title?: string,
	site?: GraphQLTypes["Site"]
};
	["PoseListPageMeta"]: {
	__typename: "PoseListPageMeta",
	id?: GraphQLTypes["FieldMeta"],
	link?: GraphQLTypes["FieldMeta"],
	seo?: GraphQLTypes["FieldMeta"],
	title?: GraphQLTypes["FieldMeta"],
	site?: GraphQLTypes["FieldMeta"]
};
	["CourseLecturerListPage"]: {
	__typename: "CourseLecturerListPage",
	_meta?: GraphQLTypes["CourseLecturerListPageMeta"],
	id: GraphQLTypes["UUID"],
	title?: string,
	site?: GraphQLTypes["Site"],
	seo?: GraphQLTypes["Seo"],
	link?: GraphQLTypes["Linkable"]
};
	["CourseLecturerListPageMeta"]: {
	__typename: "CourseLecturerListPageMeta",
	id?: GraphQLTypes["FieldMeta"],
	title?: GraphQLTypes["FieldMeta"],
	site?: GraphQLTypes["FieldMeta"],
	seo?: GraphQLTypes["FieldMeta"],
	link?: GraphQLTypes["FieldMeta"]
};
	["SubscribeBox"]: {
	__typename: "SubscribeBox",
	_meta?: GraphQLTypes["SubscribeBoxMeta"],
	id: GraphQLTypes["UUID"],
	title?: string,
	text?: string,
	shapeColorA: string,
	shapeColorB: string,
	shapeColorC: string,
	externalMailingListId: string,
	site?: GraphQLTypes["Site"],
	logo?: GraphQLTypes["Image"],
	emailLabel?: string,
	submitLabel?: string
};
	["SubscribeBoxMeta"]: {
	__typename: "SubscribeBoxMeta",
	id?: GraphQLTypes["FieldMeta"],
	title?: GraphQLTypes["FieldMeta"],
	text?: GraphQLTypes["FieldMeta"],
	shapeColorA?: GraphQLTypes["FieldMeta"],
	shapeColorB?: GraphQLTypes["FieldMeta"],
	shapeColorC?: GraphQLTypes["FieldMeta"],
	externalMailingListId?: GraphQLTypes["FieldMeta"],
	site?: GraphQLTypes["FieldMeta"],
	logo?: GraphQLTypes["FieldMeta"],
	emailLabel?: GraphQLTypes["FieldMeta"],
	submitLabel?: GraphQLTypes["FieldMeta"]
};
	["Header"]: {
	__typename: "Header",
	_meta?: GraphQLTypes["HeaderMeta"],
	id: GraphQLTypes["UUID"],
	primaryNavigationItems: Array<GraphQLTypes["HeaderPrimaryNavigationItem"]>,
	site?: GraphQLTypes["Site"],
	logo?: GraphQLTypes["Image"],
	logoOnWhite?: GraphQLTypes["Image"],
	primaryNavigationItemsByLink?: GraphQLTypes["HeaderPrimaryNavigationItem"],
	primaryNavigationItemsByListNavigation?: GraphQLTypes["HeaderPrimaryNavigationItem"],
	primaryNavigationItemsByTiles?: GraphQLTypes["HeaderPrimaryNavigationItem"],
	paginatePrimaryNavigationItems: GraphQLTypes["HeaderPrimaryNavigationItemConnection"]
};
	["HeaderMeta"]: {
	__typename: "HeaderMeta",
	id?: GraphQLTypes["FieldMeta"],
	primaryNavigationItems?: GraphQLTypes["FieldMeta"],
	site?: GraphQLTypes["FieldMeta"],
	logo?: GraphQLTypes["FieldMeta"],
	logoOnWhite?: GraphQLTypes["FieldMeta"]
};
	["HeaderPrimaryNavigationItem"]: {
	__typename: "HeaderPrimaryNavigationItem",
	_meta?: GraphQLTypes["HeaderPrimaryNavigationItemMeta"],
	id: GraphQLTypes["UUID"],
	order?: number,
	header?: GraphQLTypes["Header"],
	link?: GraphQLTypes["Link"],
	listNavigation?: GraphQLTypes["ListNavigation"],
	tiles?: GraphQLTypes["TileList"]
};
	["HeaderPrimaryNavigationItemMeta"]: {
	__typename: "HeaderPrimaryNavigationItemMeta",
	id?: GraphQLTypes["FieldMeta"],
	order?: GraphQLTypes["FieldMeta"],
	header?: GraphQLTypes["FieldMeta"],
	link?: GraphQLTypes["FieldMeta"],
	listNavigation?: GraphQLTypes["FieldMeta"],
	tiles?: GraphQLTypes["FieldMeta"]
};
	["ListNavigation"]: {
	__typename: "ListNavigation",
	_meta?: GraphQLTypes["ListNavigationMeta"],
	id: GraphQLTypes["UUID"],
	columns: Array<GraphQLTypes["ListNavigationColumn"]>,
	columnsByItems?: GraphQLTypes["ListNavigationColumn"],
	paginateColumns: GraphQLTypes["ListNavigationColumnConnection"]
};
	["ListNavigationMeta"]: {
	__typename: "ListNavigationMeta",
	id?: GraphQLTypes["FieldMeta"],
	columns?: GraphQLTypes["FieldMeta"]
};
	["ListNavigationColumn"]: {
	__typename: "ListNavigationColumn",
	_meta?: GraphQLTypes["ListNavigationColumnMeta"],
	id: GraphQLTypes["UUID"],
	order?: number,
	label?: string,
	listNavigation?: GraphQLTypes["ListNavigation"],
	items: Array<GraphQLTypes["ListNavigationColumnItem"]>,
	itemsByLink?: GraphQLTypes["ListNavigationColumnItem"],
	paginateItems: GraphQLTypes["ListNavigationColumnItemConnection"]
};
	["ListNavigationColumnMeta"]: {
	__typename: "ListNavigationColumnMeta",
	id?: GraphQLTypes["FieldMeta"],
	order?: GraphQLTypes["FieldMeta"],
	label?: GraphQLTypes["FieldMeta"],
	listNavigation?: GraphQLTypes["FieldMeta"],
	items?: GraphQLTypes["FieldMeta"]
};
	["ListNavigationColumnItem"]: {
	__typename: "ListNavigationColumnItem",
	_meta?: GraphQLTypes["ListNavigationColumnItemMeta"],
	id: GraphQLTypes["UUID"],
	order?: number,
	column?: GraphQLTypes["ListNavigationColumn"],
	link?: GraphQLTypes["Link"]
};
	["ListNavigationColumnItemMeta"]: {
	__typename: "ListNavigationColumnItemMeta",
	id?: GraphQLTypes["FieldMeta"],
	order?: GraphQLTypes["FieldMeta"],
	column?: GraphQLTypes["FieldMeta"],
	link?: GraphQLTypes["FieldMeta"]
};
	["ListNavigationColumnItemOrderBy"]: {
		_random?: boolean,
	_randomSeeded?: number,
	id?: GraphQLTypes["OrderDirection"],
	order?: GraphQLTypes["OrderDirection"],
	column?: GraphQLTypes["ListNavigationColumnOrderBy"],
	link?: GraphQLTypes["LinkOrderBy"]
};
	["ListNavigationColumnOrderBy"]: {
		_random?: boolean,
	_randomSeeded?: number,
	id?: GraphQLTypes["OrderDirection"],
	order?: GraphQLTypes["OrderDirection"],
	label?: GraphQLTypes["OrderDirection"],
	listNavigation?: GraphQLTypes["ListNavigationOrderBy"]
};
	["ListNavigationColumnItemsByLinkUniqueWhere"]: {
		link?: GraphQLTypes["LinkUniqueWhere"]
};
	["ListNavigationColumnItemConnection"]: {
	__typename: "ListNavigationColumnItemConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["ListNavigationColumnItemEdge"]>
};
	["ListNavigationColumnItemEdge"]: {
	__typename: "ListNavigationColumnItemEdge",
	node: GraphQLTypes["ListNavigationColumnItem"]
};
	["ListNavigationColumnsByItemsUniqueWhere"]: {
		items?: GraphQLTypes["ListNavigationColumnItemUniqueWhere"]
};
	["ListNavigationColumnConnection"]: {
	__typename: "ListNavigationColumnConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["ListNavigationColumnEdge"]>
};
	["ListNavigationColumnEdge"]: {
	__typename: "ListNavigationColumnEdge",
	node: GraphQLTypes["ListNavigationColumn"]
};
	["HeaderPrimaryNavigationItemOrderBy"]: {
		_random?: boolean,
	_randomSeeded?: number,
	id?: GraphQLTypes["OrderDirection"],
	order?: GraphQLTypes["OrderDirection"],
	header?: GraphQLTypes["HeaderOrderBy"],
	link?: GraphQLTypes["LinkOrderBy"],
	listNavigation?: GraphQLTypes["ListNavigationOrderBy"],
	tiles?: GraphQLTypes["TileListOrderBy"]
};
	["HeaderPrimaryNavigationItemsByLinkUniqueWhere"]: {
		link?: GraphQLTypes["LinkUniqueWhere"]
};
	["HeaderPrimaryNavigationItemsByListNavigationUniqueWhere"]: {
		listNavigation?: GraphQLTypes["ListNavigationUniqueWhere"]
};
	["HeaderPrimaryNavigationItemsByTilesUniqueWhere"]: {
		tiles?: GraphQLTypes["TileListUniqueWhere"]
};
	["HeaderPrimaryNavigationItemConnection"]: {
	__typename: "HeaderPrimaryNavigationItemConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["HeaderPrimaryNavigationItemEdge"]>
};
	["HeaderPrimaryNavigationItemEdge"]: {
	__typename: "HeaderPrimaryNavigationItemEdge",
	node: GraphQLTypes["HeaderPrimaryNavigationItem"]
};
	["Footer"]: {
	__typename: "Footer",
	_meta?: GraphQLTypes["FooterMeta"],
	id: GraphQLTypes["UUID"],
	navigation?: GraphQLTypes["ListNavigation"],
	site?: GraphQLTypes["Site"],
	logo?: GraphQLTypes["Image"],
	copyright?: string,
	attribution?: string
};
	["FooterMeta"]: {
	__typename: "FooterMeta",
	id?: GraphQLTypes["FieldMeta"],
	navigation?: GraphQLTypes["FieldMeta"],
	site?: GraphQLTypes["FieldMeta"],
	logo?: GraphQLTypes["FieldMeta"],
	copyright?: GraphQLTypes["FieldMeta"],
	attribution?: GraphQLTypes["FieldMeta"]
};
	["GenericPage"]: {
	__typename: "GenericPage",
	_meta?: GraphQLTypes["GenericPageMeta"],
	id: GraphQLTypes["UUID"],
	headline?: string,
	lead?: string,
	publishedAt?: GraphQLTypes["Date"],
	site?: GraphQLTypes["Site"],
	content?: GraphQLTypes["Content"],
	seo?: GraphQLTypes["Seo"],
	link?: GraphQLTypes["Linkable"]
};
	["GenericPageMeta"]: {
	__typename: "GenericPageMeta",
	id?: GraphQLTypes["FieldMeta"],
	headline?: GraphQLTypes["FieldMeta"],
	lead?: GraphQLTypes["FieldMeta"],
	publishedAt?: GraphQLTypes["FieldMeta"],
	site?: GraphQLTypes["FieldMeta"],
	content?: GraphQLTypes["FieldMeta"],
	seo?: GraphQLTypes["FieldMeta"],
	link?: GraphQLTypes["FieldMeta"]
};
	["CourseEmail"]: {
	__typename: "CourseEmail",
	_meta?: GraphQLTypes["CourseEmailMeta"],
	id: GraphQLTypes["UUID"],
	title?: string,
	text?: string,
	buttonLabel?: string,
	logo?: GraphQLTypes["Image"],
	cover?: GraphQLTypes["Image"],
	site?: GraphQLTypes["Site"],
	contactInfoEmailAddress?: string,
	senderEmail?: string
};
	["CourseEmailMeta"]: {
	__typename: "CourseEmailMeta",
	id?: GraphQLTypes["FieldMeta"],
	title?: GraphQLTypes["FieldMeta"],
	text?: GraphQLTypes["FieldMeta"],
	buttonLabel?: GraphQLTypes["FieldMeta"],
	logo?: GraphQLTypes["FieldMeta"],
	cover?: GraphQLTypes["FieldMeta"],
	site?: GraphQLTypes["FieldMeta"],
	contactInfoEmailAddress?: GraphQLTypes["FieldMeta"],
	senderEmail?: GraphQLTypes["FieldMeta"]
};
	["Translations"]: {
	__typename: "Translations",
	_meta?: GraphQLTypes["TranslationsMeta"],
	id: GraphQLTypes["UUID"],
	home?: string,
	articles?: string,
	article?: string,
	articleMore?: string,
	categories?: string,
	lecturers?: string,
	lecturer?: string,
	courses?: string,
	course?: string,
	poses?: string,
	pose?: string,
	site?: GraphQLTypes["Site"],
	posesLong?: string,
	posesMore?: string,
	listAll?: string,
	difficulty?: string,
	skillLevel?: string,
	signIn?: string,
	error?: string
};
	["TranslationsMeta"]: {
	__typename: "TranslationsMeta",
	id?: GraphQLTypes["FieldMeta"],
	home?: GraphQLTypes["FieldMeta"],
	articles?: GraphQLTypes["FieldMeta"],
	article?: GraphQLTypes["FieldMeta"],
	articleMore?: GraphQLTypes["FieldMeta"],
	categories?: GraphQLTypes["FieldMeta"],
	lecturers?: GraphQLTypes["FieldMeta"],
	lecturer?: GraphQLTypes["FieldMeta"],
	courses?: GraphQLTypes["FieldMeta"],
	course?: GraphQLTypes["FieldMeta"],
	poses?: GraphQLTypes["FieldMeta"],
	pose?: GraphQLTypes["FieldMeta"],
	site?: GraphQLTypes["FieldMeta"],
	posesLong?: GraphQLTypes["FieldMeta"],
	posesMore?: GraphQLTypes["FieldMeta"],
	listAll?: GraphQLTypes["FieldMeta"],
	difficulty?: GraphQLTypes["FieldMeta"],
	skillLevel?: GraphQLTypes["FieldMeta"],
	signIn?: GraphQLTypes["FieldMeta"],
	error?: GraphQLTypes["FieldMeta"]
};
	["SiteArticlesBySeoUniqueWhere"]: {
		seo?: GraphQLTypes["SeoUniqueWhere"]
};
	["SiteArticlesByLinkUniqueWhere"]: {
		link?: GraphQLTypes["LinkableUniqueWhere"]
};
	["SiteArticlesByContentUniqueWhere"]: {
		content?: GraphQLTypes["ContentUniqueWhere"]
};
	["SiteArticleCategoriesByArticlesUniqueWhere"]: {
		articles?: GraphQLTypes["ArticleUniqueWhere"]
};
	["SiteArticleCategoriesBySeoUniqueWhere"]: {
		seo?: GraphQLTypes["SeoUniqueWhere"]
};
	["SiteArticleCategoriesByLinkUniqueWhere"]: {
		link?: GraphQLTypes["LinkableUniqueWhere"]
};
	["SitePosesByLinkUniqueWhere"]: {
		link?: GraphQLTypes["LinkableUniqueWhere"]
};
	["SitePosesBySeoUniqueWhere"]: {
		seo?: GraphQLTypes["SeoUniqueWhere"]
};
	["SitePosesByContentUniqueWhere"]: {
		content?: GraphQLTypes["ContentUniqueWhere"]
};
	["SitePoseCategoriesByPosesUniqueWhere"]: {
		poses?: GraphQLTypes["PoseUniqueWhere"]
};
	["SitePoseDifficultiesByPosesUniqueWhere"]: {
		poses?: GraphQLTypes["PoseUniqueWhere"]
};
	["SiteCoursesBySeoUniqueWhere"]: {
		seo?: GraphQLTypes["SeoUniqueWhere"]
};
	["SiteCoursesByLinkUniqueWhere"]: {
		link?: GraphQLTypes["LinkableUniqueWhere"]
};
	["SiteCoursesByLessonsUniqueWhere"]: {
		lessons?: GraphQLTypes["CourseLessonUniqueWhere"]
};
	["SiteCoursesByContentUniqueWhere"]: {
		content?: GraphQLTypes["ContentUniqueWhere"]
};
	["SiteCourseLecturersByCoursesUniqueWhere"]: {
		courses?: GraphQLTypes["CourseUniqueWhere"]
};
	["SiteCourseLecturersByLinkUniqueWhere"]: {
		link?: GraphQLTypes["LinkableUniqueWhere"]
};
	["SiteCourseLecturersBySeoUniqueWhere"]: {
		seo?: GraphQLTypes["SeoUniqueWhere"]
};
	["SiteCourseLecturersByContentUniqueWhere"]: {
		content?: GraphQLTypes["ContentUniqueWhere"]
};
	["SiteGenericPagesByContentUniqueWhere"]: {
		content?: GraphQLTypes["ContentUniqueWhere"]
};
	["SiteGenericPagesBySeoUniqueWhere"]: {
		seo?: GraphQLTypes["SeoUniqueWhere"]
};
	["SiteGenericPagesByLinkUniqueWhere"]: {
		link?: GraphQLTypes["LinkableUniqueWhere"]
};
	["ArticleCategoryConnection"]: {
	__typename: "ArticleCategoryConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["ArticleCategoryEdge"]>
};
	["ArticleCategoryEdge"]: {
	__typename: "ArticleCategoryEdge",
	node: GraphQLTypes["ArticleCategory"]
};
	["PoseCategoryConnection"]: {
	__typename: "PoseCategoryConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["PoseCategoryEdge"]>
};
	["PoseCategoryEdge"]: {
	__typename: "PoseCategoryEdge",
	node: GraphQLTypes["PoseCategory"]
};
	["PoseDifficultyConnection"]: {
	__typename: "PoseDifficultyConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["PoseDifficultyEdge"]>
};
	["PoseDifficultyEdge"]: {
	__typename: "PoseDifficultyEdge",
	node: GraphQLTypes["PoseDifficulty"]
};
	["CourseLecturerConnection"]: {
	__typename: "CourseLecturerConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["CourseLecturerEdge"]>
};
	["CourseLecturerEdge"]: {
	__typename: "CourseLecturerEdge",
	node: GraphQLTypes["CourseLecturer"]
};
	["GenericPageConnection"]: {
	__typename: "GenericPageConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["GenericPageEdge"]>
};
	["GenericPageEdge"]: {
	__typename: "GenericPageEdge",
	node: GraphQLTypes["GenericPage"]
};
	["NewsletterSubscribeSuccessPage"]: {
	__typename: "NewsletterSubscribeSuccessPage",
	_meta?: GraphQLTypes["NewsletterSubscribeSuccessPageMeta"],
	id: GraphQLTypes["UUID"],
	title?: string,
	returnLinkText?: string,
	link?: GraphQLTypes["Linkable"],
	site?: GraphQLTypes["Site"],
	seo?: GraphQLTypes["Seo"]
};
	["NewsletterSubscribeSuccessPageMeta"]: {
	__typename: "NewsletterSubscribeSuccessPageMeta",
	id?: GraphQLTypes["FieldMeta"],
	title?: GraphQLTypes["FieldMeta"],
	returnLinkText?: GraphQLTypes["FieldMeta"],
	link?: GraphQLTypes["FieldMeta"],
	site?: GraphQLTypes["FieldMeta"],
	seo?: GraphQLTypes["FieldMeta"]
};
	["LinkConnection"]: {
	__typename: "LinkConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["LinkEdge"]>
};
	["LinkEdge"]: {
	__typename: "LinkEdge",
	node: GraphQLTypes["Link"]
};
	["LinkableConnection"]: {
	__typename: "LinkableConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["LinkableEdge"]>
};
	["LinkableEdge"]: {
	__typename: "LinkableEdge",
	node: GraphQLTypes["Linkable"]
};
	["RedirectConnection"]: {
	__typename: "RedirectConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["RedirectEdge"]>
};
	["RedirectEdge"]: {
	__typename: "RedirectEdge",
	node: GraphQLTypes["Redirect"]
};
	["FooterConnection"]: {
	__typename: "FooterConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["FooterEdge"]>
};
	["FooterEdge"]: {
	__typename: "FooterEdge",
	node: GraphQLTypes["Footer"]
};
	["ImageConnection"]: {
	__typename: "ImageConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["ImageEdge"]>
};
	["ImageEdge"]: {
	__typename: "ImageEdge",
	node: GraphQLTypes["Image"]
};
	["SeoConnection"]: {
	__typename: "SeoConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["SeoEdge"]>
};
	["SeoEdge"]: {
	__typename: "SeoEdge",
	node: GraphQLTypes["Seo"]
};
	["HomepageConnection"]: {
	__typename: "HomepageConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["HomepageEdge"]>
};
	["HomepageEdge"]: {
	__typename: "HomepageEdge",
	node: GraphQLTypes["Homepage"]
};
	["HeaderConnection"]: {
	__typename: "HeaderConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["HeaderEdge"]>
};
	["HeaderEdge"]: {
	__typename: "HeaderEdge",
	node: GraphQLTypes["Header"]
};
	["ListNavigationConnection"]: {
	__typename: "ListNavigationConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["ListNavigationEdge"]>
};
	["ListNavigationEdge"]: {
	__typename: "ListNavigationEdge",
	node: GraphQLTypes["ListNavigation"]
};
	["SiteConnection"]: {
	__typename: "SiteConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["SiteEdge"]>
};
	["SiteEdge"]: {
	__typename: "SiteEdge",
	node: GraphQLTypes["Site"]
};
	["ArticleListPageConnection"]: {
	__typename: "ArticleListPageConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["ArticleListPageEdge"]>
};
	["ArticleListPageEdge"]: {
	__typename: "ArticleListPageEdge",
	node: GraphQLTypes["ArticleListPage"]
};
	["CourseListPageConnection"]: {
	__typename: "CourseListPageConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["CourseListPageEdge"]>
};
	["CourseListPageEdge"]: {
	__typename: "CourseListPageEdge",
	node: GraphQLTypes["CourseListPage"]
};
	["PoseListPageConnection"]: {
	__typename: "PoseListPageConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["PoseListPageEdge"]>
};
	["PoseListPageEdge"]: {
	__typename: "PoseListPageEdge",
	node: GraphQLTypes["PoseListPage"]
};
	["ContentConnection"]: {
	__typename: "ContentConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["ContentEdge"]>
};
	["ContentEdge"]: {
	__typename: "ContentEdge",
	node: GraphQLTypes["Content"]
};
	["TileConnection"]: {
	__typename: "TileConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["TileEdge"]>
};
	["TileEdge"]: {
	__typename: "TileEdge",
	node: GraphQLTypes["Tile"]
};
	["TileListConnection"]: {
	__typename: "TileListConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["TileListEdge"]>
};
	["TileListEdge"]: {
	__typename: "TileListEdge",
	node: GraphQLTypes["TileList"]
};
	["YoutubeVideoUniqueWhere"]: {
		id?: GraphQLTypes["UUID"]
};
	["YoutubeVideoConnection"]: {
	__typename: "YoutubeVideoConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["YoutubeVideoEdge"]>
};
	["YoutubeVideoEdge"]: {
	__typename: "YoutubeVideoEdge",
	node: GraphQLTypes["YoutubeVideo"]
};
	["SeoDefault"]: {
	__typename: "SeoDefault",
	_meta?: GraphQLTypes["SeoDefaultMeta"],
	id: GraphQLTypes["UUID"],
	unique: GraphQLTypes["One"],
	seo?: GraphQLTypes["Seo"]
};
	["SeoDefaultMeta"]: {
	__typename: "SeoDefaultMeta",
	id?: GraphQLTypes["FieldMeta"],
	unique?: GraphQLTypes["FieldMeta"],
	seo?: GraphQLTypes["FieldMeta"]
};
	["One"]: One;
	["SeoDefaultUniqueWhere"]: {
		id?: GraphQLTypes["UUID"],
	unique?: GraphQLTypes["One"],
	seo?: GraphQLTypes["SeoUniqueWhere"]
};
	["SeoDefaultWhere"]: {
		id?: GraphQLTypes["UUIDCondition"],
	unique?: GraphQLTypes["OneCondition"],
	seo?: GraphQLTypes["SeoWhere"],
	and?: Array<GraphQLTypes["SeoDefaultWhere"] | undefined>,
	or?: Array<GraphQLTypes["SeoDefaultWhere"] | undefined>,
	not?: GraphQLTypes["SeoDefaultWhere"]
};
	["OneCondition"]: {
		and?: Array<GraphQLTypes["OneCondition"]>,
	or?: Array<GraphQLTypes["OneCondition"]>,
	not?: GraphQLTypes["OneCondition"],
	null?: boolean,
	isNull?: boolean,
	eq?: GraphQLTypes["One"],
	notEq?: GraphQLTypes["One"],
	in?: Array<GraphQLTypes["One"]>,
	notIn?: Array<GraphQLTypes["One"]>,
	lt?: GraphQLTypes["One"],
	lte?: GraphQLTypes["One"],
	gt?: GraphQLTypes["One"],
	gte?: GraphQLTypes["One"]
};
	["SeoDefaultOrderBy"]: {
		_random?: boolean,
	_randomSeeded?: number,
	id?: GraphQLTypes["OrderDirection"],
	unique?: GraphQLTypes["OrderDirection"],
	seo?: GraphQLTypes["SeoOrderBy"]
};
	["SeoDefaultConnection"]: {
	__typename: "SeoDefaultConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["SeoDefaultEdge"]>
};
	["SeoDefaultEdge"]: {
	__typename: "SeoDefaultEdge",
	node: GraphQLTypes["SeoDefault"]
};
	["CourseLecturerListPageConnection"]: {
	__typename: "CourseLecturerListPageConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["CourseLecturerListPageEdge"]>
};
	["CourseLecturerListPageEdge"]: {
	__typename: "CourseLecturerListPageEdge",
	node: GraphQLTypes["CourseLecturerListPage"]
};
	["NewsletterSubscription"]: {
	__typename: "NewsletterSubscription",
	_meta?: GraphQLTypes["NewsletterSubscriptionMeta"],
	id: GraphQLTypes["UUID"],
	startDate?: GraphQLTypes["Date"],
	visitor?: GraphQLTypes["Visitor"],
	site?: GraphQLTypes["Site"]
};
	["NewsletterSubscriptionMeta"]: {
	__typename: "NewsletterSubscriptionMeta",
	id?: GraphQLTypes["FieldMeta"],
	startDate?: GraphQLTypes["FieldMeta"],
	visitor?: GraphQLTypes["FieldMeta"],
	site?: GraphQLTypes["FieldMeta"]
};
	["Visitor"]: {
	__typename: "Visitor",
	_meta?: GraphQLTypes["VisitorMeta"],
	id: GraphQLTypes["UUID"],
	email: string,
	newsletterSubscriptions: Array<GraphQLTypes["NewsletterSubscription"]>,
	courseSubscriptions: Array<GraphQLTypes["CourseSubscription"]>,
	newsletterSubscriptionsBySite?: GraphQLTypes["NewsletterSubscription"],
	courseSubscriptionsByCourse?: GraphQLTypes["CourseSubscription"],
	paginateNewsletterSubscriptions: GraphQLTypes["NewsletterSubscriptionConnection"],
	paginateCourseSubscriptions: GraphQLTypes["CourseSubscriptionConnection"]
};
	["VisitorMeta"]: {
	__typename: "VisitorMeta",
	id?: GraphQLTypes["FieldMeta"],
	email?: GraphQLTypes["FieldMeta"],
	newsletterSubscriptions?: GraphQLTypes["FieldMeta"],
	courseSubscriptions?: GraphQLTypes["FieldMeta"]
};
	["NewsletterSubscriptionWhere"]: {
		id?: GraphQLTypes["UUIDCondition"],
	startDate?: GraphQLTypes["DateCondition"],
	visitor?: GraphQLTypes["VisitorWhere"],
	site?: GraphQLTypes["SiteWhere"],
	and?: Array<GraphQLTypes["NewsletterSubscriptionWhere"] | undefined>,
	or?: Array<GraphQLTypes["NewsletterSubscriptionWhere"] | undefined>,
	not?: GraphQLTypes["NewsletterSubscriptionWhere"]
};
	["VisitorWhere"]: {
		id?: GraphQLTypes["UUIDCondition"],
	email?: GraphQLTypes["StringCondition"],
	newsletterSubscriptions?: GraphQLTypes["NewsletterSubscriptionWhere"],
	courseSubscriptions?: GraphQLTypes["CourseSubscriptionWhere"],
	and?: Array<GraphQLTypes["VisitorWhere"] | undefined>,
	or?: Array<GraphQLTypes["VisitorWhere"] | undefined>,
	not?: GraphQLTypes["VisitorWhere"]
};
	["CourseSubscriptionWhere"]: {
		id?: GraphQLTypes["UUIDCondition"],
	startDate?: GraphQLTypes["DateCondition"],
	visitor?: GraphQLTypes["VisitorWhere"],
	course?: GraphQLTypes["CourseWhere"],
	and?: Array<GraphQLTypes["CourseSubscriptionWhere"] | undefined>,
	or?: Array<GraphQLTypes["CourseSubscriptionWhere"] | undefined>,
	not?: GraphQLTypes["CourseSubscriptionWhere"]
};
	["NewsletterSubscriptionOrderBy"]: {
		_random?: boolean,
	_randomSeeded?: number,
	id?: GraphQLTypes["OrderDirection"],
	startDate?: GraphQLTypes["OrderDirection"],
	visitor?: GraphQLTypes["VisitorOrderBy"],
	site?: GraphQLTypes["SiteOrderBy"]
};
	["VisitorOrderBy"]: {
		_random?: boolean,
	_randomSeeded?: number,
	id?: GraphQLTypes["OrderDirection"],
	email?: GraphQLTypes["OrderDirection"]
};
	["CourseSubscription"]: {
	__typename: "CourseSubscription",
	_meta?: GraphQLTypes["CourseSubscriptionMeta"],
	id: GraphQLTypes["UUID"],
	startDate?: GraphQLTypes["Date"],
	visitor?: GraphQLTypes["Visitor"],
	course?: GraphQLTypes["Course"]
};
	["CourseSubscriptionMeta"]: {
	__typename: "CourseSubscriptionMeta",
	id?: GraphQLTypes["FieldMeta"],
	startDate?: GraphQLTypes["FieldMeta"],
	visitor?: GraphQLTypes["FieldMeta"],
	course?: GraphQLTypes["FieldMeta"]
};
	["CourseSubscriptionOrderBy"]: {
		_random?: boolean,
	_randomSeeded?: number,
	id?: GraphQLTypes["OrderDirection"],
	startDate?: GraphQLTypes["OrderDirection"],
	visitor?: GraphQLTypes["VisitorOrderBy"],
	course?: GraphQLTypes["CourseOrderBy"]
};
	["VisitorNewsletterSubscriptionsBySiteUniqueWhere"]: {
		site?: GraphQLTypes["SiteUniqueWhere"]
};
	["VisitorCourseSubscriptionsByCourseUniqueWhere"]: {
		course?: GraphQLTypes["CourseUniqueWhere"]
};
	["NewsletterSubscriptionConnection"]: {
	__typename: "NewsletterSubscriptionConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["NewsletterSubscriptionEdge"]>
};
	["NewsletterSubscriptionEdge"]: {
	__typename: "NewsletterSubscriptionEdge",
	node: GraphQLTypes["NewsletterSubscription"]
};
	["CourseSubscriptionConnection"]: {
	__typename: "CourseSubscriptionConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["CourseSubscriptionEdge"]>
};
	["CourseSubscriptionEdge"]: {
	__typename: "CourseSubscriptionEdge",
	node: GraphQLTypes["CourseSubscription"]
};
	["NewsletterSubscriptionUniqueWhere"]: {
		id?: GraphQLTypes["UUID"],
	visitor?: GraphQLTypes["VisitorUniqueWhere"],
	site?: GraphQLTypes["SiteUniqueWhere"]
};
	["VisitorUniqueWhere"]: {
		id?: GraphQLTypes["UUID"],
	email?: string,
	newsletterSubscriptions?: GraphQLTypes["NewsletterSubscriptionUniqueWhere"],
	courseSubscriptions?: GraphQLTypes["CourseSubscriptionUniqueWhere"]
};
	["CourseSubscriptionUniqueWhere"]: {
		id?: GraphQLTypes["UUID"],
	visitor?: GraphQLTypes["VisitorUniqueWhere"],
	course?: GraphQLTypes["CourseUniqueWhere"]
};
	["_ValidationResult"]: {
	__typename: "_ValidationResult",
	valid: boolean,
	errors: Array<GraphQLTypes["_ValidationError"]>
};
	["_ValidationError"]: {
	__typename: "_ValidationError",
	path: Array<GraphQLTypes["_PathFragment"]>,
	message: GraphQLTypes["_ValidationMessage"]
};
	["_PathFragment"]:{
	__typename:"_FieldPathFragment" | "_IndexPathFragment"
	['...on _FieldPathFragment']: '__union' & GraphQLTypes["_FieldPathFragment"];
	['...on _IndexPathFragment']: '__union' & GraphQLTypes["_IndexPathFragment"];
};
	["_FieldPathFragment"]: {
	__typename: "_FieldPathFragment",
	field: string
};
	["_IndexPathFragment"]: {
	__typename: "_IndexPathFragment",
	index: number,
	alias?: string
};
	["_ValidationMessage"]: {
	__typename: "_ValidationMessage",
	text: string
};
	["NewsletterSubscriptionCreateInput"]: {
		startDate?: GraphQLTypes["Date"],
	visitor?: GraphQLTypes["NewsletterSubscriptionCreateVisitorEntityRelationInput"],
	site?: GraphQLTypes["NewsletterSubscriptionCreateSiteEntityRelationInput"],
	_dummy_field_?: boolean
};
	["NewsletterSubscriptionCreateVisitorEntityRelationInput"]: {
		connect?: GraphQLTypes["VisitorUniqueWhere"],
	create?: GraphQLTypes["VisitorWithoutNewsletterSubscriptionsCreateInput"]
};
	["VisitorWithoutNewsletterSubscriptionsCreateInput"]: {
		email?: string,
	courseSubscriptions?: Array<GraphQLTypes["VisitorCreateCourseSubscriptionsEntityRelationInput"]>,
	_dummy_field_?: boolean
};
	["VisitorCreateCourseSubscriptionsEntityRelationInput"]: {
		connect?: GraphQLTypes["CourseSubscriptionUniqueWhere"],
	create?: GraphQLTypes["CourseSubscriptionWithoutVisitorCreateInput"],
	alias?: string
};
	["CourseSubscriptionWithoutVisitorCreateInput"]: {
		startDate?: GraphQLTypes["Date"],
	course?: GraphQLTypes["CourseSubscriptionCreateCourseEntityRelationInput"],
	_dummy_field_?: boolean
};
	["CourseSubscriptionCreateCourseEntityRelationInput"]: {
		connect?: GraphQLTypes["CourseUniqueWhere"]
};
	["NewsletterSubscriptionCreateSiteEntityRelationInput"]: {
		connect?: GraphQLTypes["SiteUniqueWhere"]
};
	["VisitorConnection"]: {
	__typename: "VisitorConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["VisitorEdge"]>
};
	["VisitorEdge"]: {
	__typename: "VisitorEdge",
	node: GraphQLTypes["Visitor"]
};
	["VisitorCreateInput"]: {
		email?: string,
	newsletterSubscriptions?: Array<GraphQLTypes["VisitorCreateNewsletterSubscriptionsEntityRelationInput"]>,
	courseSubscriptions?: Array<GraphQLTypes["VisitorCreateCourseSubscriptionsEntityRelationInput"]>,
	_dummy_field_?: boolean
};
	["VisitorCreateNewsletterSubscriptionsEntityRelationInput"]: {
		connect?: GraphQLTypes["NewsletterSubscriptionUniqueWhere"],
	create?: GraphQLTypes["NewsletterSubscriptionWithoutVisitorCreateInput"],
	alias?: string
};
	["NewsletterSubscriptionWithoutVisitorCreateInput"]: {
		startDate?: GraphQLTypes["Date"],
	site?: GraphQLTypes["NewsletterSubscriptionCreateSiteEntityRelationInput"],
	_dummy_field_?: boolean
};
	["CourseSubscriptionCreateInput"]: {
		startDate?: GraphQLTypes["Date"],
	visitor?: GraphQLTypes["CourseSubscriptionCreateVisitorEntityRelationInput"],
	course?: GraphQLTypes["CourseSubscriptionCreateCourseEntityRelationInput"],
	_dummy_field_?: boolean
};
	["CourseSubscriptionCreateVisitorEntityRelationInput"]: {
		connect?: GraphQLTypes["VisitorUniqueWhere"],
	create?: GraphQLTypes["VisitorWithoutCourseSubscriptionsCreateInput"]
};
	["VisitorWithoutCourseSubscriptionsCreateInput"]: {
		email?: string,
	newsletterSubscriptions?: Array<GraphQLTypes["VisitorCreateNewsletterSubscriptionsEntityRelationInput"]>,
	_dummy_field_?: boolean
};
	["SubscribeBoxConnection"]: {
	__typename: "SubscribeBoxConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["SubscribeBoxEdge"]>
};
	["SubscribeBoxEdge"]: {
	__typename: "SubscribeBoxEdge",
	node: GraphQLTypes["SubscribeBox"]
};
	["NewsletterSubscribeSuccessPageConnection"]: {
	__typename: "NewsletterSubscribeSuccessPageConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["NewsletterSubscribeSuccessPageEdge"]>
};
	["NewsletterSubscribeSuccessPageEdge"]: {
	__typename: "NewsletterSubscribeSuccessPageEdge",
	node: GraphQLTypes["NewsletterSubscribeSuccessPage"]
};
	["PersonUniqueWhere"]: {
		id?: GraphQLTypes["UUID"],
	image?: GraphQLTypes["ImageUniqueWhere"]
};
	["PersonConnection"]: {
	__typename: "PersonConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["PersonEdge"]>
};
	["PersonEdge"]: {
	__typename: "PersonEdge",
	node: GraphQLTypes["Person"]
};
	["PersonListUniqueWhere"]: {
		id?: GraphQLTypes["UUID"],
	items?: GraphQLTypes["PersonListItemUniqueWhere"]
};
	["PersonListItemUniqueWhere"]: {
		id?: GraphQLTypes["UUID"]
};
	["PersonListConnection"]: {
	__typename: "PersonListConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["PersonListEdge"]>
};
	["PersonListEdge"]: {
	__typename: "PersonListEdge",
	node: GraphQLTypes["PersonList"]
};
	["CourseEmailConnection"]: {
	__typename: "CourseEmailConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["CourseEmailEdge"]>
};
	["CourseEmailEdge"]: {
	__typename: "CourseEmailEdge",
	node: GraphQLTypes["CourseEmail"]
};
	["TranslationsConnection"]: {
	__typename: "TranslationsConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["TranslationsEdge"]>
};
	["TranslationsEdge"]: {
	__typename: "TranslationsEdge",
	node: GraphQLTypes["Translations"]
};
	["QueryTransaction"]: {
	__typename: "QueryTransaction",
	getLink?: GraphQLTypes["Link"],
	listLink: Array<GraphQLTypes["Link"]>,
	paginateLink: GraphQLTypes["LinkConnection"],
	getLinkable?: GraphQLTypes["Linkable"],
	listLinkable: Array<GraphQLTypes["Linkable"]>,
	paginateLinkable: GraphQLTypes["LinkableConnection"],
	getRedirect?: GraphQLTypes["Redirect"],
	listRedirect: Array<GraphQLTypes["Redirect"]>,
	paginateRedirect: GraphQLTypes["RedirectConnection"],
	getFooter?: GraphQLTypes["Footer"],
	listFooter: Array<GraphQLTypes["Footer"]>,
	paginateFooter: GraphQLTypes["FooterConnection"],
	getImage?: GraphQLTypes["Image"],
	listImage: Array<GraphQLTypes["Image"]>,
	paginateImage: GraphQLTypes["ImageConnection"],
	getSeo?: GraphQLTypes["Seo"],
	listSeo: Array<GraphQLTypes["Seo"]>,
	paginateSeo: GraphQLTypes["SeoConnection"],
	getHomepage?: GraphQLTypes["Homepage"],
	listHomepage: Array<GraphQLTypes["Homepage"]>,
	paginateHomepage: GraphQLTypes["HomepageConnection"],
	getHeader?: GraphQLTypes["Header"],
	listHeader: Array<GraphQLTypes["Header"]>,
	paginateHeader: GraphQLTypes["HeaderConnection"],
	getHeaderPrimaryNavigationItem?: GraphQLTypes["HeaderPrimaryNavigationItem"],
	listHeaderPrimaryNavigationItem: Array<GraphQLTypes["HeaderPrimaryNavigationItem"]>,
	paginateHeaderPrimaryNavigationItem: GraphQLTypes["HeaderPrimaryNavigationItemConnection"],
	getListNavigation?: GraphQLTypes["ListNavigation"],
	listListNavigation: Array<GraphQLTypes["ListNavigation"]>,
	paginateListNavigation: GraphQLTypes["ListNavigationConnection"],
	getListNavigationColumn?: GraphQLTypes["ListNavigationColumn"],
	listListNavigationColumn: Array<GraphQLTypes["ListNavigationColumn"]>,
	paginateListNavigationColumn: GraphQLTypes["ListNavigationColumnConnection"],
	getListNavigationColumnItem?: GraphQLTypes["ListNavigationColumnItem"],
	listListNavigationColumnItem: Array<GraphQLTypes["ListNavigationColumnItem"]>,
	paginateListNavigationColumnItem: GraphQLTypes["ListNavigationColumnItemConnection"],
	getArticle?: GraphQLTypes["Article"],
	listArticle: Array<GraphQLTypes["Article"]>,
	paginateArticle: GraphQLTypes["ArticleConnection"],
	getSite?: GraphQLTypes["Site"],
	listSite: Array<GraphQLTypes["Site"]>,
	paginateSite: GraphQLTypes["SiteConnection"],
	getArticleCategory?: GraphQLTypes["ArticleCategory"],
	listArticleCategory: Array<GraphQLTypes["ArticleCategory"]>,
	paginateArticleCategory: GraphQLTypes["ArticleCategoryConnection"],
	getPose?: GraphQLTypes["Pose"],
	listPose: Array<GraphQLTypes["Pose"]>,
	paginatePose: GraphQLTypes["PoseConnection"],
	getPoseCategory?: GraphQLTypes["PoseCategory"],
	listPoseCategory: Array<GraphQLTypes["PoseCategory"]>,
	paginatePoseCategory: GraphQLTypes["PoseCategoryConnection"],
	getPoseDifficulty?: GraphQLTypes["PoseDifficulty"],
	listPoseDifficulty: Array<GraphQLTypes["PoseDifficulty"]>,
	paginatePoseDifficulty: GraphQLTypes["PoseDifficultyConnection"],
	getCourse?: GraphQLTypes["Course"],
	listCourse: Array<GraphQLTypes["Course"]>,
	paginateCourse: GraphQLTypes["CourseConnection"],
	getCourseLesson?: GraphQLTypes["CourseLesson"],
	listCourseLesson: Array<GraphQLTypes["CourseLesson"]>,
	paginateCourseLesson: GraphQLTypes["CourseLessonConnection"],
	getArticleListPage?: GraphQLTypes["ArticleListPage"],
	listArticleListPage: Array<GraphQLTypes["ArticleListPage"]>,
	paginateArticleListPage: GraphQLTypes["ArticleListPageConnection"],
	getCourseListPage?: GraphQLTypes["CourseListPage"],
	listCourseListPage: Array<GraphQLTypes["CourseListPage"]>,
	paginateCourseListPage: GraphQLTypes["CourseListPageConnection"],
	getPoseListPage?: GraphQLTypes["PoseListPage"],
	listPoseListPage: Array<GraphQLTypes["PoseListPage"]>,
	paginatePoseListPage: GraphQLTypes["PoseListPageConnection"],
	getContent?: GraphQLTypes["Content"],
	listContent: Array<GraphQLTypes["Content"]>,
	paginateContent: GraphQLTypes["ContentConnection"],
	getContentBlock?: GraphQLTypes["ContentBlock"],
	listContentBlock: Array<GraphQLTypes["ContentBlock"]>,
	paginateContentBlock: GraphQLTypes["ContentBlockConnection"],
	getContentReference?: GraphQLTypes["ContentReference"],
	listContentReference: Array<GraphQLTypes["ContentReference"]>,
	paginateContentReference: GraphQLTypes["ContentReferenceConnection"],
	getTile?: GraphQLTypes["Tile"],
	listTile: Array<GraphQLTypes["Tile"]>,
	paginateTile: GraphQLTypes["TileConnection"],
	getTileList?: GraphQLTypes["TileList"],
	listTileList: Array<GraphQLTypes["TileList"]>,
	paginateTileList: GraphQLTypes["TileListConnection"],
	getTileListItem?: GraphQLTypes["TileListItem"],
	listTileListItem: Array<GraphQLTypes["TileListItem"]>,
	paginateTileListItem: GraphQLTypes["TileListItemConnection"],
	getYoutubeVideo?: GraphQLTypes["YoutubeVideo"],
	listYoutubeVideo: Array<GraphQLTypes["YoutubeVideo"]>,
	paginateYoutubeVideo: GraphQLTypes["YoutubeVideoConnection"],
	getSeoDefault?: GraphQLTypes["SeoDefault"],
	listSeoDefault: Array<GraphQLTypes["SeoDefault"]>,
	paginateSeoDefault: GraphQLTypes["SeoDefaultConnection"],
	getCourseLecturer?: GraphQLTypes["CourseLecturer"],
	listCourseLecturer: Array<GraphQLTypes["CourseLecturer"]>,
	paginateCourseLecturer: GraphQLTypes["CourseLecturerConnection"],
	getCourseLecturerListPage?: GraphQLTypes["CourseLecturerListPage"],
	listCourseLecturerListPage: Array<GraphQLTypes["CourseLecturerListPage"]>,
	paginateCourseLecturerListPage: GraphQLTypes["CourseLecturerListPageConnection"],
	getNewsletterSubscription?: GraphQLTypes["NewsletterSubscription"],
	listNewsletterSubscription: Array<GraphQLTypes["NewsletterSubscription"]>,
	paginateNewsletterSubscription: GraphQLTypes["NewsletterSubscriptionConnection"],
	validateCreateNewsletterSubscription: GraphQLTypes["_ValidationResult"],
	getVisitor?: GraphQLTypes["Visitor"],
	listVisitor: Array<GraphQLTypes["Visitor"]>,
	paginateVisitor: GraphQLTypes["VisitorConnection"],
	validateCreateVisitor: GraphQLTypes["_ValidationResult"],
	getCourseSubscription?: GraphQLTypes["CourseSubscription"],
	listCourseSubscription: Array<GraphQLTypes["CourseSubscription"]>,
	paginateCourseSubscription: GraphQLTypes["CourseSubscriptionConnection"],
	validateCreateCourseSubscription: GraphQLTypes["_ValidationResult"],
	getSubscribeBox?: GraphQLTypes["SubscribeBox"],
	listSubscribeBox: Array<GraphQLTypes["SubscribeBox"]>,
	paginateSubscribeBox: GraphQLTypes["SubscribeBoxConnection"],
	getNewsletterSubscribeSuccessPage?: GraphQLTypes["NewsletterSubscribeSuccessPage"],
	listNewsletterSubscribeSuccessPage: Array<GraphQLTypes["NewsletterSubscribeSuccessPage"]>,
	paginateNewsletterSubscribeSuccessPage: GraphQLTypes["NewsletterSubscribeSuccessPageConnection"],
	getGenericPage?: GraphQLTypes["GenericPage"],
	listGenericPage: Array<GraphQLTypes["GenericPage"]>,
	paginateGenericPage: GraphQLTypes["GenericPageConnection"],
	getPerson?: GraphQLTypes["Person"],
	listPerson: Array<GraphQLTypes["Person"]>,
	paginatePerson: GraphQLTypes["PersonConnection"],
	getPersonList?: GraphQLTypes["PersonList"],
	listPersonList: Array<GraphQLTypes["PersonList"]>,
	paginatePersonList: GraphQLTypes["PersonListConnection"],
	getPersonListItem?: GraphQLTypes["PersonListItem"],
	listPersonListItem: Array<GraphQLTypes["PersonListItem"]>,
	paginatePersonListItem: GraphQLTypes["PersonListItemConnection"],
	getCourseEmail?: GraphQLTypes["CourseEmail"],
	listCourseEmail: Array<GraphQLTypes["CourseEmail"]>,
	paginateCourseEmail: GraphQLTypes["CourseEmailConnection"],
	getTranslations?: GraphQLTypes["Translations"],
	listTranslations: Array<GraphQLTypes["Translations"]>,
	paginateTranslations: GraphQLTypes["TranslationsConnection"]
};
	["Info"]: {
	__typename: "Info",
	description?: string
};
	["Mutation"]: {
	__typename: "Mutation",
	createNewsletterSubscription: GraphQLTypes["NewsletterSubscriptionCreateResult"],
	deleteNewsletterSubscription: GraphQLTypes["NewsletterSubscriptionDeleteResult"],
	createVisitor: GraphQLTypes["VisitorCreateResult"],
	createCourseSubscription: GraphQLTypes["CourseSubscriptionCreateResult"],
	deleteCourseSubscription: GraphQLTypes["CourseSubscriptionDeleteResult"],
	transaction: GraphQLTypes["MutationTransaction"],
	query: GraphQLTypes["Query"]
};
	["NewsletterSubscriptionCreateResult"]: {
	__typename: "NewsletterSubscriptionCreateResult",
	ok: boolean,
	errorMessage?: string,
	errors: Array<GraphQLTypes["_MutationError"]>,
	node?: GraphQLTypes["NewsletterSubscription"],
	validation: GraphQLTypes["_ValidationResult"]
};
	["MutationResult"]: {
	__typename:"NewsletterSubscriptionCreateResult" | "NewsletterSubscriptionDeleteResult" | "VisitorCreateResult" | "CourseSubscriptionCreateResult" | "CourseSubscriptionDeleteResult",
	ok: boolean,
	errorMessage?: string,
	errors: Array<GraphQLTypes["_MutationError"]>
	['...on NewsletterSubscriptionCreateResult']: '__union' & GraphQLTypes["NewsletterSubscriptionCreateResult"];
	['...on NewsletterSubscriptionDeleteResult']: '__union' & GraphQLTypes["NewsletterSubscriptionDeleteResult"];
	['...on VisitorCreateResult']: '__union' & GraphQLTypes["VisitorCreateResult"];
	['...on CourseSubscriptionCreateResult']: '__union' & GraphQLTypes["CourseSubscriptionCreateResult"];
	['...on CourseSubscriptionDeleteResult']: '__union' & GraphQLTypes["CourseSubscriptionDeleteResult"];
};
	["_MutationError"]: {
	__typename: "_MutationError",
	path: Array<GraphQLTypes["_PathFragment"]>,
	paths: Array<GraphQLTypes["_PathFragment"]>,
	type: GraphQLTypes["_MutationErrorType"],
	message?: string
};
	["_MutationErrorType"]: _MutationErrorType;
	["NewsletterSubscriptionDeleteResult"]: {
	__typename: "NewsletterSubscriptionDeleteResult",
	ok: boolean,
	errorMessage?: string,
	errors: Array<GraphQLTypes["_MutationError"]>,
	node?: GraphQLTypes["NewsletterSubscription"]
};
	["VisitorCreateResult"]: {
	__typename: "VisitorCreateResult",
	ok: boolean,
	errorMessage?: string,
	errors: Array<GraphQLTypes["_MutationError"]>,
	node?: GraphQLTypes["Visitor"],
	validation: GraphQLTypes["_ValidationResult"]
};
	["CourseSubscriptionCreateResult"]: {
	__typename: "CourseSubscriptionCreateResult",
	ok: boolean,
	errorMessage?: string,
	errors: Array<GraphQLTypes["_MutationError"]>,
	node?: GraphQLTypes["CourseSubscription"],
	validation: GraphQLTypes["_ValidationResult"]
};
	["CourseSubscriptionDeleteResult"]: {
	__typename: "CourseSubscriptionDeleteResult",
	ok: boolean,
	errorMessage?: string,
	errors: Array<GraphQLTypes["_MutationError"]>,
	node?: GraphQLTypes["CourseSubscription"]
};
	["MutationTransaction"]: {
	__typename: "MutationTransaction",
	ok: boolean,
	errorMessage?: string,
	errors: Array<GraphQLTypes["_MutationError"]>,
	validation: GraphQLTypes["_ValidationResult"],
	createNewsletterSubscription: GraphQLTypes["NewsletterSubscriptionCreateResult"],
	deleteNewsletterSubscription: GraphQLTypes["NewsletterSubscriptionDeleteResult"],
	createVisitor: GraphQLTypes["VisitorCreateResult"],
	createCourseSubscription: GraphQLTypes["CourseSubscriptionCreateResult"],
	deleteCourseSubscription: GraphQLTypes["CourseSubscriptionDeleteResult"],
	query?: GraphQLTypes["Query"]
};
	["MutationTransactionOptions"]: {
		deferForeignKeyConstraints?: boolean
};
	/** Json custom scalar type */
["Json"]:any;
	["_Schema"]: {
	__typename: "_Schema",
	enums: Array<GraphQLTypes["_Enum"]>,
	entities: Array<GraphQLTypes["_Entity"]>
};
	["_Entity"]: {
	__typename: "_Entity",
	name: string,
	customPrimaryAllowed: boolean,
	fields: Array<GraphQLTypes["_Field"]>,
	unique: Array<GraphQLTypes["_UniqueConstraint"]>
};
	["_UniqueConstraint"]: {
	__typename: "_UniqueConstraint",
	fields: Array<string>
};
	["_Field"]: {
	__typename:"_Column" | "_Relation",
	name: string,
	type: string,
	nullable?: boolean,
	rules: Array<GraphQLTypes["_Rule"]>,
	validators: Array<GraphQLTypes["_Validator"]>
	['...on _Column']: '__union' & GraphQLTypes["_Column"];
	['...on _Relation']: '__union' & GraphQLTypes["_Relation"];
};
	["_Column"]: {
	__typename: "_Column",
	name: string,
	type: string,
	enumName?: string,
	defaultValue?: GraphQLTypes["Json"],
	nullable: boolean,
	rules: Array<GraphQLTypes["_Rule"]>,
	validators: Array<GraphQLTypes["_Validator"]>
};
	["_OnDeleteBehaviour"]: _OnDeleteBehaviour;
	["_RelationSide"]: _RelationSide;
	["_OrderByDirection"]: _OrderByDirection;
	["_OrderBy"]: {
	__typename: "_OrderBy",
	path: Array<string>,
	direction: GraphQLTypes["_OrderByDirection"]
};
	["_Relation"]: {
	__typename: "_Relation",
	name: string,
	type: string,
	side: GraphQLTypes["_RelationSide"],
	targetEntity: string,
	ownedBy?: string,
	inversedBy?: string,
	nullable?: boolean,
	onDelete?: GraphQLTypes["_OnDeleteBehaviour"],
	orphanRemoval?: boolean,
	orderBy?: Array<GraphQLTypes["_OrderBy"]>,
	rules: Array<GraphQLTypes["_Rule"]>,
	validators: Array<GraphQLTypes["_Validator"]>
};
	["_Rule"]: {
	__typename: "_Rule",
	message?: GraphQLTypes["_RuleMessage"],
	validator: number
};
	["_Validator"]: {
	__typename: "_Validator",
	operation: string,
	arguments: Array<GraphQLTypes["_Argument"]>
};
	["_Argument"]:{
	__typename:"_ValidatorArgument" | "_PathArgument" | "_LiteralArgument"
	['...on _ValidatorArgument']: '__union' & GraphQLTypes["_ValidatorArgument"];
	['...on _PathArgument']: '__union' & GraphQLTypes["_PathArgument"];
	['...on _LiteralArgument']: '__union' & GraphQLTypes["_LiteralArgument"];
};
	["_ValidatorArgument"]: {
	__typename: "_ValidatorArgument",
	validator: number
};
	["_PathArgument"]: {
	__typename: "_PathArgument",
	path: Array<string>
};
	["_LiteralArgument"]: {
	__typename: "_LiteralArgument",
	value?: GraphQLTypes["Json"]
};
	["_RuleMessage"]: {
	__typename: "_RuleMessage",
	text?: string
};
	["_Enum"]: {
	__typename: "_Enum",
	name: string,
	values: Array<string>
}
    }
export const enum LinkType {
	internal = "internal",
	external = "external"
}
export const enum ContentReferenceType {
	image = "image",
	poses = "poses",
	courses = "courses",
	articles = "articles",
	subscribeBanner = "subscribeBanner",
	rawHtml = "rawHtml",
	fundraisingToken = "fundraisingToken",
	team = "team"
}
export const enum OrderDirection {
	asc = "asc",
	desc = "desc",
	ascNullsFirst = "ascNullsFirst",
	descNullsLast = "descNullsLast"
}
export const enum One {
	One = "One"
}
export const enum _MutationErrorType {
	NotNullConstraintViolation = "NotNullConstraintViolation",
	UniqueConstraintViolation = "UniqueConstraintViolation",
	ForeignKeyConstraintViolation = "ForeignKeyConstraintViolation",
	NotFoundOrDenied = "NotFoundOrDenied",
	NonUniqueWhereInput = "NonUniqueWhereInput",
	InvalidDataInput = "InvalidDataInput",
	SqlError = "SqlError"
}
export const enum _OnDeleteBehaviour {
	restrict = "restrict",
	cascade = "cascade",
	setNull = "setNull"
}
export const enum _RelationSide {
	owning = "owning",
	inverse = "inverse"
}
export const enum _OrderByDirection {
	asc = "asc",
	desc = "desc"
}
export class GraphQLError extends Error {
    constructor(public response: GraphQLResponse) {
      super("");
      console.error(response);
    }
    override toString() {
      return "GraphQL Response Error";
    }
  }


export type UnwrapPromise<T> = T extends Promise<infer R> ? R : T;
export type ZeusState<T extends (...args: any[]) => Promise<any>> = NonNullable<
  UnwrapPromise<ReturnType<T>>
>;
export type ZeusHook<
  T extends (
    ...args: any[]
  ) => Record<string, (...args: any[]) => Promise<any>>,
  N extends keyof ReturnType<T>
> = ZeusState<ReturnType<T>[N]>;

type WithTypeNameValue<T> = T & {
  __typename?: true;
};
type AliasType<T> = WithTypeNameValue<T> & {
  __alias?: Record<string, WithTypeNameValue<T>>;
};
export interface GraphQLResponse {
  data?: Record<string, any>;
  errors?: Array<{
    message: string;
  }>;
}
type DeepAnify<T> = {
  [P in keyof T]?: any;
};
type IsPayLoad<T> = T extends [any, infer PayLoad] ? PayLoad : T;
type IsArray<T, U> = T extends Array<infer R> ? InputType<R, U>[] : InputType<T, U>;
type FlattenArray<T> = T extends Array<infer R> ? R : T;

type IsInterfaced<SRC extends DeepAnify<DST>, DST> = FlattenArray<SRC> extends ZEUS_INTERFACES | ZEUS_UNIONS
  ? {
      [P in keyof SRC]: SRC[P] extends '__union' & infer R
        ? P extends keyof DST
          ? IsArray<R, '__typename' extends keyof DST ? DST[P] & { __typename: true } : DST[P]>
          : {}
        : never;
    }[keyof DST] &
      {
        [P in keyof Omit<
          Pick<
            SRC,
            {
              [P in keyof DST]: SRC[P] extends '__union' & infer R ? never : P;
            }[keyof DST]
          >,
          '__typename'
        >]: IsPayLoad<DST[P]> extends true ? SRC[P] : IsArray<SRC[P], DST[P]>;
      }
  : {
      [P in keyof Pick<SRC, keyof DST>]: IsPayLoad<DST[P]> extends true ? SRC[P] : IsArray<SRC[P], DST[P]>;
    };

export type MapType<SRC, DST> = SRC extends DeepAnify<DST> ? IsInterfaced<SRC, DST> : never;
export type InputType<SRC, DST> = IsPayLoad<DST> extends { __alias: infer R }
  ? {
      [P in keyof R]: MapType<SRC, R[P]>;
    } &
      MapType<SRC, Omit<IsPayLoad<DST>, '__alias'>>
  : MapType<SRC, IsPayLoad<DST>>;
type Func<P extends any[], R> = (...args: P) => R;
type AnyFunc = Func<any, any>;
export type ArgsType<F extends AnyFunc> = F extends Func<infer P, any> ? P : never;
export type OperationOptions = {
  variables?: Record<string, any>;
  operationName?: string;
};
export type OperationToGraphQL<V, T> = <Z extends V>(o: Z | V, options?: OperationOptions) => Promise<InputType<T, Z>>;
export type SubscriptionToGraphQL<V, T> = <Z extends V>(
  o: Z | V,
  options?: OperationOptions,
) => {
  ws: WebSocket;
  on: (fn: (args: InputType<T, Z>) => void) => void;
  off: (fn: (e: { data?: InputType<T, Z>; code?: number; reason?: string; message?: string }) => void) => void;
  error: (fn: (e: { data?: InputType<T, Z>; errors?:string[] }) => void) => void;
  open: () => void;
};
export type SelectionFunction<V> = <T>(t: T | V) => T;
export type fetchOptions = ArgsType<typeof fetch>;
type websocketOptions = typeof WebSocket extends new (
  ...args: infer R
) => WebSocket
  ? R
  : never;
export type chainOptions =
  | [fetchOptions[0], fetchOptions[1] & {websocket?: websocketOptions}]
  | [fetchOptions[0]];
export type FetchFunction = (
  query: string,
  variables?: Record<string, any>,
) => Promise<any>;
export type SubscriptionFunction = (query: string) => void;
type NotUndefined<T> = T extends undefined ? never : T;
export type ResolverType<F> = NotUndefined<F extends [infer ARGS, any] ? ARGS : undefined>;



export const ZeusSelect = <T>() => ((t: any) => t) as SelectionFunction<T>;

export const ScalarResolver = (scalar: string, value: any) => {
  switch (scalar) {
    case 'String':
      return  `${JSON.stringify(value)}`;
    case 'Int':
      return `${value}`;
    case 'Float':
      return `${value}`;
    case 'Boolean':
      return `${value}`;
    case 'ID':
      return `"${value}"`;
    case 'enum':
      return `${value}`;
    case 'scalar':
      return `${value}`;
    default:
      return false;
  }
};


export const TypesPropsResolver = ({
    value,
    type,
    name,
    key,
    blockArrays
}: {
    value: any;
    type: string;
    name: string;
    key?: string;
    blockArrays?: boolean;
}): string => {
    if (value === null) {
        return `null`;
    }
    let resolvedValue = AllTypesProps[type][name];
    if (key) {
        resolvedValue = resolvedValue[key];
    }
    if (!resolvedValue) {
        throw new Error(`Cannot resolve ${type} ${name}${key ? ` ${key}` : ''}`)
    }
    const typeResolved = resolvedValue.type;
    const isArray = resolvedValue.array;
    const isArrayRequired = resolvedValue.arrayRequired;
    if (typeof value === 'string' && value.startsWith(`ZEUS_VAR$`)) {
        const isRequired = resolvedValue.required ? '!' : '';
        let t = `${typeResolved}`;
        if (isArray) {
          if (isRequired) {
              t = `${t}!`;
          }
          t = `[${t}]`;
          if(isArrayRequired){
            t = `${t}!`;
          }
        }else{
          if (isRequired) {
                t = `${t}!`;
          }
        }
        return `\$${value.split(`ZEUS_VAR$`)[1]}__ZEUS_VAR__${t}`;
    }
    if (isArray && !blockArrays) {
        return `[${value
        .map((v: any) => TypesPropsResolver({ value: v, type, name, key, blockArrays: true }))
        .join(',')}]`;
    }
    const reslovedScalar = ScalarResolver(typeResolved, value);
    if (!reslovedScalar) {
        const resolvedType = AllTypesProps[typeResolved];
        if (typeof resolvedType === 'object') {
        const argsKeys = Object.keys(resolvedType);
        return `{${argsKeys
            .filter((ak) => value[ak] !== undefined)
            .map(
            (ak) => `${ak}:${TypesPropsResolver({ value: value[ak], type: typeResolved, name: ak })}`
            )}}`;
        }
        return ScalarResolver(AllTypesProps[typeResolved], value) as string;
    }
    return reslovedScalar;
};


const isArrayFunction = (
  parent: string[],
  a: any[]
) => {
  const [values, r] = a;
  const [mainKey, key, ...keys] = parent;
  const keyValues = Object.keys(values).filter((k) => typeof values[k] !== 'undefined');

  if (!keys.length) {
      return keyValues.length > 0
        ? `(${keyValues
            .map(
              (v) =>
                `${v}:${TypesPropsResolver({
                  value: values[v],
                  type: mainKey,
                  name: key,
                  key: v
                })}`
            )
            .join(',')})${r ? traverseToSeekArrays(parent, r) : ''}`
        : traverseToSeekArrays(parent, r);
    }

  const [typeResolverKey] = keys.splice(keys.length - 1, 1);
  let valueToResolve = ReturnTypes[mainKey][key];
  for (const k of keys) {
    valueToResolve = ReturnTypes[valueToResolve][k];
  }

  const argumentString =
    keyValues.length > 0
      ? `(${keyValues
          .map(
            (v) =>
              `${v}:${TypesPropsResolver({
                value: values[v],
                type: valueToResolve,
                name: typeResolverKey,
                key: v
              })}`
          )
          .join(',')})${r ? traverseToSeekArrays(parent, r) : ''}`
      : traverseToSeekArrays(parent, r);
  return argumentString;
};


const resolveKV = (k: string, v: boolean | string | { [x: string]: boolean | string }) =>
  typeof v === 'boolean' ? k : typeof v === 'object' ? `${k}{${objectToTree(v)}}` : `${k}${v}`;


const objectToTree = (o: { [x: string]: boolean | string }): string =>
  `{${Object.keys(o).map((k) => `${resolveKV(k, o[k])}`).join(' ')}}`;


const traverseToSeekArrays = (parent: string[], a?: any): string => {
  if (!a) return '';
  if (Object.keys(a).length === 0) {
    return '';
  }
  let b: Record<string, any> = {};
  if (Array.isArray(a)) {
    return isArrayFunction([...parent], a);
  } else {
    if (typeof a === 'object') {
      Object.keys(a)
        .filter((k) => typeof a[k] !== 'undefined')
        .forEach((k) => {
        if (k === '__alias') {
          Object.keys(a[k]).forEach((aliasKey) => {
            const aliasOperations = a[k][aliasKey];
            const aliasOperationName = Object.keys(aliasOperations)[0];
            const aliasOperation = aliasOperations[aliasOperationName];
            b[
              `${aliasOperationName}__alias__${aliasKey}: ${aliasOperationName}`
            ] = traverseToSeekArrays([...parent, aliasOperationName], aliasOperation);
          });
        } else {
          b[k] = traverseToSeekArrays([...parent, k], a[k]);
        }
      });
    } else {
      return '';
    }
  }
  return objectToTree(b);
};  


const buildQuery = (type: string, a?: Record<any, any>) => 
  traverseToSeekArrays([type], a);


const inspectVariables = (query: string) => {
  const regex = /\$\b\w*__ZEUS_VAR__\[?[^!^\]^\s^,^\)^\}]*[!]?[\]]?[!]?/g;
  let result;
  const AllVariables: string[] = [];
  while ((result = regex.exec(query))) {
    if (AllVariables.includes(result[0])) {
      continue;
    }
    AllVariables.push(result[0]);
  }
  if (!AllVariables.length) {
    return query;
  }
  let filteredQuery = query;
  AllVariables.forEach((variable) => {
    while (filteredQuery.includes(variable)) {
      filteredQuery = filteredQuery.replace(variable, variable.split('__ZEUS_VAR__')[0]);
    }
  });
  return `(${AllVariables.map((a) => a.split('__ZEUS_VAR__'))
    .map(([variableName, variableType]) => `${variableName}:${variableType}`)
    .join(', ')})${filteredQuery}`;
};


export const queryConstruct = (t: 'query' | 'mutation' | 'subscription', tName: string, operationName?: string) => (o: Record<any, any>) =>
  `${t.toLowerCase()}${operationName ? ' ' + operationName : ''}${inspectVariables(buildQuery(tName, o))}`;
  

const fullChainConstruct = (fn: FetchFunction) => (t: 'query' | 'mutation' | 'subscription', tName: string) => (
  o: Record<any, any>,
  options?: OperationOptions,
) => fn(queryConstruct(t, tName, options?.operationName)(o), options?.variables).then((r:any) => { 
  seekForAliases(r)
  return r
});

export const fullChainConstructor = <F extends FetchFunction, R extends keyof ValueTypes>(
  fn: F,
  operation: 'query' | 'mutation' | 'subscription',
  key: R,
) =>
  ((o, options) => fullChainConstruct(fn)(operation, key)(o as any, options)) as OperationToGraphQL<
    ValueTypes[R],
    GraphQLTypes[R]
  >;


const fullSubscriptionConstruct = (fn: SubscriptionFunction) => (
  t: 'query' | 'mutation' | 'subscription',
  tName: string,
) => (o: Record<any, any>, options?: OperationOptions) =>
  fn(queryConstruct(t, tName, options?.operationName)(o));

export const fullSubscriptionConstructor = <F extends SubscriptionFunction, R extends keyof ValueTypes>(
  fn: F,
  operation: 'query' | 'mutation' | 'subscription',
  key: R,
) =>
  ((o, options) => fullSubscriptionConstruct(fn)(operation, key)(o as any, options)) as SubscriptionToGraphQL<
    ValueTypes[R],
    GraphQLTypes[R]
  >;


const seekForAliases = (response: any) => {
  const traverseAlias = (value: any) => {
    if (Array.isArray(value)) {
      value.forEach(seekForAliases);
    } else {
      if (typeof value === 'object') {
        seekForAliases(value);
      }
    }
  };
  if (typeof response === 'object' && response) {
    const keys = Object.keys(response);
    if (keys.length < 1) {
      return;
    }
    keys.forEach((k) => {
      const value = response[k];
      if (k.indexOf('__alias__') !== -1) {
        const [operation, alias] = k.split('__alias__');
        response[alias] = {
          [operation]: value,
        };
        delete response[k];
      }
      traverseAlias(value);
    });
  }
};


export const $ = (t: TemplateStringsArray): any => `ZEUS_VAR$${t.join('')}`;


export const resolverFor = <
  X,
  T extends keyof ValueTypes,
  Z extends keyof ValueTypes[T],
>(
  type: T,
  field: Z,
  fn: (
    args: Required<ValueTypes[T]>[Z] extends [infer Input, any] ? Input : any,
    source: any,
  ) => Z extends keyof ModelTypes[T] ? ModelTypes[T][Z] | Promise<ModelTypes[T][Z]> | X : any,
) => fn as (args?: any,source?: any) => any;


const handleFetchResponse = (
  response: Parameters<Extract<Parameters<ReturnType<typeof fetch>['then']>[0], Function>>[0]
): Promise<GraphQLResponse> => {
  if (!response.ok) {
    return new Promise((_, reject) => {
      response.text().then(text => {
        try { reject(JSON.parse(text)); }
        catch (err) { reject(text); }
      }).catch(reject);
    });
  }
  return response.json();
};

export const apiFetch = (options: fetchOptions) => (query: string, variables: Record<string, any> = {}) => {
    let fetchFunction = fetch;
    let queryString = query;
    let fetchOptions = options[1] || {};
    if (fetchOptions.method && fetchOptions.method === 'GET') {
      queryString = encodeURIComponent(query);
      return fetchFunction(`${options[0]}?query=${queryString}`, fetchOptions)
        .then(handleFetchResponse)
        .then((response: GraphQLResponse) => {
          if (response.errors) {
            throw new GraphQLError(response);
          }
          return response.data;
        });
    }
    return fetchFunction(`${options[0]}`, {
      body: JSON.stringify({ query: queryString, variables }),
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      ...fetchOptions
    })
      .then(handleFetchResponse)
      .then((response: GraphQLResponse) => {
        if (response.errors) {
          throw new GraphQLError(response);
        }
        return response.data;
      });
  };
  

export const apiSubscription = (options: chainOptions) => (
    query: string,
  ) => {
    try {
      const queryString = options[0] + '?query=' + encodeURIComponent(query);
      const wsString = queryString.replace('http', 'ws');
      const host = (options.length > 1 && options[1]?.websocket?.[0]) || wsString;
      const webSocketOptions = options[1]?.websocket || [host];
      const ws = new WebSocket(...webSocketOptions);
      return {
        ws,
        on: (e: (args: any) => void) => {
          ws.onmessage = (event:any) => {
            if(event.data){
              const parsed = JSON.parse(event.data)
              const data = parsed.data
              if (data) {
                seekForAliases(data);
              }
              return e(data);
            }
          };
        },
        off: (e: (args: any) => void) => {
          ws.onclose = e;
        },
        error: (e: (args: any) => void) => {
          ws.onerror = e;
        },
        open: (e: () => void) => {
          ws.onopen = e;
        },
      };
    } catch {
      throw new Error('No websockets implemented');
    }
  };


export const Thunder = (fn: FetchFunction, subscriptionFn: SubscriptionFunction) => ({
  query: fullChainConstructor(fn,'query', 'Query'),
mutation: fullChainConstructor(fn,'mutation', 'Mutation')
});

export const Chain = (...options: chainOptions) => ({
  query: fullChainConstructor(apiFetch(options),'query', 'Query'),
mutation: fullChainConstructor(apiFetch(options),'mutation', 'Mutation')
});
export const Zeus = {
  query: (o:ValueTypes["Query"], operationName?: string) => queryConstruct('query', 'Query', operationName)(o),
mutation: (o:ValueTypes["Mutation"], operationName?: string) => queryConstruct('mutation', 'Mutation', operationName)(o)
};
export const Selectors = {
  query: ZeusSelect<ValueTypes["Query"]>(),
mutation: ZeusSelect<ValueTypes["Mutation"]>()
};
  
export type FragmentResult<K extends keyof ModelTypes, Q extends (...args: any) => any> = MapType<
	ModelTypes[K],
	ReturnType<Q>
>