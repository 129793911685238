export const formDataToObject = (formData: FormData) => {
	const jsonData: {
		[key: string]: unknown
	} = {}

	for (const [key, value] of formData.entries()) {
		jsonData[key] = value
	}

	return jsonData
}
