import { useState } from 'react'
import { useTranslate } from '../app/contexts/TranslationsContext'
import type { CommonPageProps } from '../pages/[[...page]]'
import { Breadcrumbs } from './Breadcrumbs'
import { Container } from './Container'
import { GroupedPoseTiles } from './GroupedPoseTiles'
import { GroupsSorts } from './GroupSorts'
import { PageListTitle } from './PageListTitle'
import { PoseTiles } from './PoseTiles'
import { Spacer } from './Spacer'

export type PoseListPageProps = Exclude<Pick<CommonPageProps['page'], 'poseList'>['poseList'], null>

export const PoseListPage: React.FunctionComponent<PoseListPageProps> = ({
	title,
	poses,
	poseCategories,
	poseDifficulties,
}) => {
	const [selectedGroupSortId, setSelectedGroupSortId] = useState('category')
	const translate = useTranslate()

	return (
		<Container>
			<Breadcrumbs
				items={[
					{
						label: title ?? translate('poses'),
					},
				]}
			/>
			<Spacer initial={32} break1000={80} />
			<PageListTitle>{title}</PageListTitle>
			<Spacer initial={8} break1000={16} />
			<GroupsSorts
				activeId={selectedGroupSortId}
				onChange={setSelectedGroupSortId}
				items={[
					{
						id: 'all',
						label: translate('listAll'),
					},
					{
						id: 'difficulty',
						label: translate('difficulty'),
					},
					{
						id: 'category',
						label: translate('Category'),
					},
				]}
			/>
			{selectedGroupSortId === 'all' ? (
				<PoseTiles poses={poses} />
			) : selectedGroupSortId === 'difficulty' ? (
				poseDifficulties.map((difficulty) => (
					<GroupedPoseTiles
						key={difficulty.id}
						label={{ icon: difficulty.icon, text: difficulty.label || translate('skillLevel') }}
						poses={poses.filter((pose) => pose.difficulty?.id === difficulty.id)}
					/>
				))
			) : selectedGroupSortId === 'category' ? (
				poseCategories.map((category) => (
					<GroupedPoseTiles
						key={category.id}
						label={{ icon: category.icon, text: category.label || translate('category') }}
						poses={poses.filter((pose) => pose.category?.id === category.id)}
					/>
				))
			) : null}
			<Spacer initial={40} break1000={100} />
		</Container>
	)
}
