import { useMemo } from 'react'
import { useTranslate } from '../app/contexts/TranslationsContext'
import type { InternalLinkResult } from '../app/data/content/InternalLinkFragment'
import style from './Breadcrumbs.module.sass'
import { ContemberInternalLink } from './ContemberLink'

interface BreadcrumbsProps {
	items: Array<{
		label: string
		link?: InternalLinkResult
	}>
}

export const Breadcrumbs: React.FunctionComponent<BreadcrumbsProps> = ({ items }) => {
	const translate = useTranslate()

	items = useMemo(() => {
		return [
			{
				label: translate('home'),
				link: {
					url: '/',
				},
			},
			...items,
		]
	}, [items, translate])
	return (
		<ul className={style.wrapper}>
			{items.map((item, i) => (
				<li className={style.item} key={i}>
					{item.link ? (
						<ContemberInternalLink link={item.link}>
							<a className={style.link}>{item.label}</a>
						</ContemberInternalLink>
					) : (
						item.label
					)}
				</li>
			))}
		</ul>
	)
}
