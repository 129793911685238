import clsx from 'clsx'
import { usePauseHidingHeader, useRunHidingHeader } from 'hiding-header-react'
import { FunctionComponent, useEffect, useState } from 'react'
import { isSpecialKeyPressed } from '../utils/isSpecialKeyPressed'
import { Container } from './Container'
import { ContemberLink } from './ContemberLink'
import type { HeaderProps } from './Header'
import style from './Submenu.module.sass'
import { SubmenuItem } from './SubmenuItem'

type Item = HeaderProps['primaryNavigationItems'][number]

export interface SubmenuProps {
	activeItemId: string | undefined
	onActiveItemChange: (item: Item | null) => void
	items: Item[]
}

export const Submenu: FunctionComponent<SubmenuProps> = ({
	items,
	activeItemId,
	onActiveItemChange,
}) => {
	const [lastActiveItemId, setLastActiveItemId] = useState(activeItemId)
	useEffect(
		() => setLastActiveItemId(activeItemId || lastActiveItemId),
		[activeItemId, lastActiveItemId]
	)

	const runHidingHeader = useRunHidingHeader()
	const pauseHidingHeader = usePauseHidingHeader()
	useEffect(() => {
		if (activeItemId) {
			pauseHidingHeader && pauseHidingHeader()
		} else {
			runHidingHeader && runHidingHeader()
		}
	}, [activeItemId, runHidingHeader, pauseHidingHeader])

	return (
		<>
			{/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
			<div
				className={style.wrapper}
				onClick={(event) => {
					const { target } = event
					if (isSpecialKeyPressed(event.nativeEvent) || !(target instanceof HTMLElement)) {
						return
					}
					const anchorElement = target.closest('a')
					if (anchorElement === null || anchorElement.classList.contains(style.navigationLink)) {
						return
					}
					onActiveItemChange(null)
				}}>
				<ul className={style.navigation}>
					{items.map((item) => (
						<li key={item.id} className={style.navigationItem}>
							{/* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */}
							<ContemberLink link={item.link!}>
								{/* eslint-disable-next-line */}
								<a
									className={clsx(
										style.navigationLink,
										item.id === activeItemId && style.is_active
									)}
									onClick={(event) => {
										if (isSpecialKeyPressed(event.nativeEvent)) {
											return
										}
										event.preventDefault()

										onActiveItemChange(item)
									}}>
									{item.link?.title}
								</a>
							</ContemberLink>
						</li>
					))}
				</ul>
				<Container>
					<div className={style.item}>
						{items
							.filter((item) => item.id === lastActiveItemId)
							.map((item) => (
								<SubmenuItem key={item.id} item={item} />
							))}
					</div>
				</Container>
			</div>
		</>
	)
}
