import type { InferGetServerSidePropsType } from 'next'
import Head from 'next/head'
import { useEffect } from 'react'
import { OriginContext } from '../app/contexts/OriginContext'
import { TranslationsProvider } from '../app/contexts/TranslationsContext'
import { ArticleCategoryDetailFragment } from '../app/data/content/ArticleCategoryDetailFragment'
import { ArticleDetailFragment } from '../app/data/content/ArticleDetailFragment'
import { ArticleListPageDetailFragment } from '../app/data/content/ArticleListPageDetailFragment'
import { ArticleTileFragment } from '../app/data/content/ArticleTileFragment'
import { CourseDetailFragment } from '../app/data/content/CourseDetailFragment'
import { CourseLecturerDetailFragment } from '../app/data/content/CourseLecturerDetailFragment'
import { CourseLecturerListPageDetailFragment } from '../app/data/content/CourseLecturerListPageDetailFragment'
import { CourseLecturerTileFragment } from '../app/data/content/CourseLecturerTileFragment'
import { CourseTileFragment } from '../app/data/content/CourseTileFragment'
import { GenericPageDetailFragment } from '../app/data/content/GenericPageDetailFragment'
import { HomepageDetailFragment } from '../app/data/content/HomepageDetailFragment'
import { ImageFragment } from '../app/data/content/ImageFragment'
import { LinkFragment } from '../app/data/content/LinkFragment'
import { ListNavigationFragment } from '../app/data/content/ListNavigationFragment'
import { NewsletterSubscribeSuccessPageDetailFragment } from '../app/data/content/NewsletterSubscribeSuccessPageDetailFragment'
import { SeoFragment } from '../app/data/content/OgSchemaFragment'
import { PoseCategoryChipFragment } from '../app/data/content/PoseCategoryChipFragment'
import { PoseDetailFragment } from '../app/data/content/PoseDetailFragment'
import { PoseListPageDetailFragment } from '../app/data/content/PoseListPageDetailFragment'
import { PoseTileFragment } from '../app/data/content/PoseTileFragment'
import { publishedFilter } from '../app/data/content/publishedFilter'
import { RedirectFragment } from '../app/data/content/RedirectFragment'
import { siteAndOnlyPublishedFilter } from '../app/data/content/siteAndOnlyPublishedFilter'
import { siteFilter } from '../app/data/content/siteFilter'
import { SiteSpecificContentFragment } from '../app/data/content/SiteSpecificContentFragment'
import { TileListFragment } from '../app/data/content/TileListFragment'
import { ArticleCategoryPage } from '../components/ArticleCategoryPage'
import { ArticleListPage } from '../components/ArticleListPage'
import { ArticlePage } from '../components/ArticlePage'
import { CourseLecturerListPage } from '../components/CourseLecturerListPage'
import { CourseLecturerPage } from '../components/CourseLecturerPage'
import { CourseListPage } from '../components/CourseListPage'
import { CoursePage } from '../components/CoursePage'
import { Error404Page } from '../components/Error404Page'
import { GenericPage } from '../components/GenericPage'
import { GoogleTag } from '../components/GoogleTag'
import { HomePage } from '../components/HomePage'
import { Layout } from '../components/Layout'
import { NewsletterSubscribeSuccessPage } from '../components/NewsletterSubscribeSuccessPage'
import { PoseListPage } from '../components/PoseListPage'
import { PosePage } from '../components/PosePage'
import { One, OrderDirection } from '../generated/content'
import { RespondWithRedirect, serverPropsHandler } from '../packages/@necktip/next/api/handlers'
import { getKeyStartDate, getNumberOfUnlockedLessons } from '../utils/course'
import { createContentContember } from '../utils/createContentContember'
import { getSiteCodeFromUrl } from '../utils/getSiteCodeFromUrl'

export type CommonPageProps = InferGetServerSidePropsType<typeof getServerSideProps>

export default function CommonPage({
	colorfulHeader,
	header,
	footer,
	translations,
	seo,
	canonicalUrl,
	page,
	origin,
	siteGroup,
	locale,
}: CommonPageProps) {
	useEffect(() => {
		if (locale) {
			document.querySelector('html')?.setAttribute('lang', locale)
		}
	}, [locale])
	return (
		<OriginContext.Provider value={origin}>
			<TranslationsProvider value={translations ?? {}}>
				<Head>
					<link rel="icon" href={`/${siteGroup}/favicon.ico`} />
					<link rel="icon" type="image/svg+xml" href={`/${siteGroup}/favicon.svg`} />
				</Head>
				<GoogleTag siteGroup={siteGroup} locale={locale} />
				<Layout
					colorfulHeader={colorfulHeader}
					header={header}
					footer={footer}
					seo={seo}
					canonicalUrl={canonicalUrl}>
					{page.homepage ? (
						<HomePage {...page.homepage} />
					) : page.article ? (
						<ArticlePage {...page.article} />
					) : page.articleList ? (
						<ArticleListPage {...page.articleList} />
					) : page.articleCategory ? (
						<ArticleCategoryPage {...page.articleCategory} />
					) : page.newsletterSubscribeSuccessPage ? (
						<NewsletterSubscribeSuccessPage {...page.newsletterSubscribeSuccessPage} />
					) : page.course ? (
						<CoursePage {...page.course} />
					) : page.courseList ? (
						<CourseListPage {...page.courseList} />
					) : page.courseLecturerList ? (
						<CourseLecturerListPage {...page.courseLecturerList} />
					) : page.courseLecturer ? (
						<CourseLecturerPage {...page.courseLecturer} />
					) : page.pose ? (
						<PosePage {...page.pose} />
					) : page.poseList ? (
						<PoseListPage {...page.poseList} />
					) : page.genericPage ? (
						<GenericPage {...page.genericPage} />
					) : (
						<Error404Page />
					)}
				</Layout>
			</TranslationsProvider>
		</OriginContext.Provider>
	)
}

export const getServerSideProps = serverPropsHandler(async (context) => {
	const contember = createContentContember()
	let origin =
		(String(context.query.origin ?? '') || process.env.DEV_ORIGIN) ??
		(process.env.VERCEL_URL ? `https://${context.req.headers.host}` : null)

	if (context.req.headers['x-vercel-deployment-url'] === context.req.headers['host']) {
		throw new Error('Unavailable from deployment url')
	}

	// Temporary development override
	if (origin === 'https://mindfulness-foundation.vercel.app') {
		origin = 'https://mindfulness.foundation'
	}

	const url = (() => {
		const source = context.params?.page ?? []
		const parts = Array.isArray(source) ? source : [source]
		return `/${parts.join('/')}`
	})()

	if (origin === null) {
		throw new Error(`Missing origin, set your env probably to DEV_JOGAONLINE_SITE=jogaOnline.`)
	}

	const contemberUrlWithoutSlash = `${origin}${url}`.replace(/\/$/, '')
	const contemberUrl = contemberUrlWithoutSlash + '/'

	const siteCode = getSiteCodeFromUrl(contemberUrl)

	const [locale, siteGroup] = siteCode.split('-') as ['cs' | 'en', 'jogaOnline' | 'plneVedomi']

	const now = new Date().toISOString()

	const data = await contember.query({
		listLinkable: [
			{ filter: { url: { in: [contemberUrl, contemberUrlWithoutSlash] } } },
			{
				id: true,
				url: true,
				article: [{}, ArticleDetailFragment()],
				genericPage: [{}, GenericPageDetailFragment()],
				homepage: [{}, HomepageDetailFragment()],
				newsletterSubscribeSuccessPage: [{}, NewsletterSubscribeSuccessPageDetailFragment()],
				course: [{}, CourseDetailFragment()],
				pose: [{}, PoseDetailFragment()],
				articleCategory: [{}, ArticleCategoryDetailFragment(now)],
				articleList: [{}, ArticleListPageDetailFragment()],
				courseList: [{}, CourseLecturerListPageDetailFragment()],
				poseList: [{}, PoseListPageDetailFragment()],
				courseLecturerList: [{}, CourseLecturerListPageDetailFragment()],
				courseLecturer: [{}, CourseLecturerDetailFragment()],
				redirect: [{}, RedirectFragment()],
			},
		],
		getSite: [{ by: { code: siteCode } }, { id: true }],
		getSeoDefault: [
			{
				by: { unique: One.One },
			},
			{
				seo: [{}, SeoFragment()],
			},
		],
	})

	const linkable = data.listLinkable[0]

	const siteId = data.getSite?.id

	if (!siteId) {
		throw new Error('Missing siteId')
	}

	const redirectUrl = linkable?.redirect?.target?.url

	if (redirectUrl) {
		throw new RespondWithRedirect({ permanent: true, destination: redirectUrl })
	}

	const homepage = await (async () => {
		const initial = linkable?.homepage
		if (!initial) {
			return null
		}
		const { getSite: siteSpecificContent } = await contember.query({
			getSite: [
				{
					by: {
						id: siteId,
					},
				},
				SiteSpecificContentFragment(now),
			],
		})
		return {
			...initial,
			siteSpecificContent,
			locale,
		}
	})()
	const article = await (async () => {
		const initial = linkable?.article
		if (!initial) {
			return null
		}
		const { listArticle: otherArticles } = await contember.query({
			listArticle: [
				{
					limit: 2,
					filter: {
						and: [
							{
								id: {
									notEq: initial.id,
								},
							},
							siteFilter(siteId, now),
						],
					},
					orderBy: [{ publishedAt: OrderDirection.desc }],
				},
				ArticleTileFragment(),
			],
		})
		return {
			...initial,
			otherArticles,
		}
	})()
	const course = await (async () => {
		const initial = linkable?.course
		if (!initial) {
			return null
		}
		const { listCourse: courses } = await contember.query({
			listCourse: [
				{
					limit: 4,
					filter: {
						and: [siteAndOnlyPublishedFilter(siteId, now, initial.id)],
					},
					orderBy: [{ publishedAt: OrderDirection.desc }],
				},
				CourseTileFragment(),
			],
		})
		const lessonId = context.query.lesson?.toString() ?? ''
		const userKey = context.query.key?.toString()
		const numberOfUnlockedLessons = Math.min(
			getNumberOfUnlockedLessons(initial.id, userKey ?? '', initial.lessonIntervalInDays),
			initial.lessons.length
		)
		const unlockKey = userKey
			? {
					key: userKey,
					startDate: getKeyStartDate(userKey).toISOString(),
			  }
			: null
		return {
			...initial,
			courses,
			numberOfUnlockedLessons,
			openedLesson:
				numberOfUnlockedLessons === 0
					? null
					: initial.lessons.find(
							(lesson, i) => lesson.id === lessonId && i < numberOfUnlockedLessons
					  ) ?? initial.lessons[0],
			unlockKey,
		}
	})()
	const pose = await (async () => {
		const initial = linkable?.pose
		if (!initial) {
			return null
		}
		const { listPose: poses, listArticle: otherArticles } = await contember.query({
			listPose: [
				{
					limit: 3,
					filter: {
						and: [siteAndOnlyPublishedFilter(siteId, now, initial.id)],
					},
					orderBy: [{ publishedAt: OrderDirection.desc }],
				},
				PoseTileFragment(),
			],
			listArticle: [
				{
					limit: 2,
					filter: {
						and: [
							publishedFilter(now),
							{
								...(initial.site?.id
									? {
											site: {
												id: {
													eq: initial.site.id,
												},
											},
									  }
									: {}),
							},
						],
					},
					orderBy: [{ publishedAt: OrderDirection.desc }],
				},
				ArticleTileFragment(),
			],
		})
		return {
			...initial,
			poses,
			otherArticles,
		}
	})()
	const articleList = await (async () => {
		const initial = linkable?.articleList
		if (!initial) {
			return null
		}
		const { listArticle: articles } = await contember.query({
			listArticle: [
				{
					filter: siteFilter(siteId, now),
					orderBy: [{ publishedAt: OrderDirection.desc }],
				},
				ArticleTileFragment(),
			],
		})
		return {
			...initial,
			articles,
		}
	})()
	const genericPage = linkable?.genericPage || null
	const articleCategory = linkable?.articleCategory || null
	const newsletterSubscribeSuccessPage = linkable?.newsletterSubscribeSuccessPage || null
	const courseList = await (async () => {
		const initial = linkable?.courseList
		if (!initial) {
			return null
		}
		const { listCourse: courses } = await contember.query({
			listCourse: [
				{
					filter: siteFilter(siteId, now),
					orderBy: [{ publishedAt: OrderDirection.desc }],
				},
				CourseTileFragment(),
			],
		})
		return {
			...initial,
			courses,
		}
	})()
	const courseLecturerList = await (async () => {
		const initial = linkable?.courseLecturerList
		if (!initial) {
			return null
		}
		const { listCourseLecturer: lecturers } = await contember.query({
			listCourseLecturer: [
				{
					filter: {
						site: {
							id: { eq: siteId },
						},
					},
				},
				CourseLecturerTileFragment(),
			],
		})
		return {
			...initial,
			lecturers,
		}
	})()

	const courseLecturer = await (async () => {
		const initial = linkable?.courseLecturer
		if (!initial) {
			return null
		}
		const { listCourse: courses } = await contember.query({
			listCourse: [
				{
					filter: {
						and: [
							{
								lecturer: {
									id: {
										eq: initial.id,
									},
								},
							},
							...(initial.site?.id
								? [
										{
											site: {
												id: {
													eq: initial.site.id,
												},
											},
										},
								  ]
								: []),
						],
					},
					orderBy: [{ publishedAt: OrderDirection.desc }],
				},
				CourseTileFragment(),
			],
		})
		return {
			...initial,
			courses,
		}
	})()
	const poseList = await (async () => {
		const initial = linkable?.poseList
		if (!initial) {
			return null
		}
		const {
			listPose: poses,
			listPoseCategory: poseCategories,
			listPoseDifficulty: poseDifficulties,
		} = await contember.query({
			listPose: [
				{
					filter: siteFilter(siteId, now),
					orderBy: [{ publishedAt: OrderDirection.desc }],
				},
				PoseTileFragment(),
			],
			listPoseCategory: [
				{
					filter: {
						site: {
							id: { eq: siteId },
						},
					},
				},
				PoseCategoryChipFragment(),
			],
			listPoseDifficulty: [
				{
					filter: {
						site: {
							id: { eq: siteId },
						},
					},
				},
				{
					id: true,
					label: true,
					icon: [{}, ImageFragment()],
				},
			],
		})
		return {
			...initial,
			poses,
			poseCategories,
			poseDifficulties,
		}
	})()

	const page = {
		homepage,
		article,
		articleList,
		articleCategory,
		genericPage,
		newsletterSubscribeSuccessPage,
		course,
		courseList,
		courseLecturerList,
		courseLecturer,
		pose,
		poseList,
	}

	const pageNotFound = Object.values(page).every((item) => item === null)

	if (pageNotFound) {
		context.res.statusCode = 404
	}

	const {
		getHeader: header,
		getFooter: footer,
		getTranslations: translations,
	} = await contember.query({
		getHeader: [
			{
				by: { site: { id: siteId } },
			},
			{
				logo: [{}, ImageFragment()],
				logoOnWhite: [{}, ImageFragment()],
				primaryNavigationItems: [
					{},
					{
						id: true,
						link: [{}, LinkFragment()],
						listNavigation: [{}, ListNavigationFragment()],
						tiles: [{}, TileListFragment()],
					},
				],
			},
		],
		getFooter: [
			{
				by: { site: { id: siteId } },
			},
			{
				logo: [{}, ImageFragment()],
				navigation: [{}, ListNavigationFragment()],
				attribution: true,
				copyright: true,
			},
		],
		getTranslations: [
			{
				by: { site: { id: siteId } },
			},
			{
				home: true,
				articles: true,
				article: true,
				difficulty: true,
				articleMore: true,
				categories: true,
				lecturers: true,
				lecturer: true,
				courses: true,
				course: true,
				poses: true,
				pose: true,
				signIn: true,
				error: true,
			},
		],
	})

	return {
		props: {
			colorfulHeader: Boolean(page.homepage),
			canonicalUrl: linkable?.url ?? null,
			siteGroup,
			origin,
			locale,
			page,
			pageNotFound,
			header,
			footer,
			translations,
			seo: {
				siteCode,
				base: data.getSeoDefault?.seo ?? {},
				pageSpecific: Object.values(page).find((page) => page !== null)?.seo ?? {},
			},
		},
	}
})
