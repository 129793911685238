import type { PoseTileResult } from '../app/data/content/PoseTileFragment'
import { PoseTile } from './PoseTile'
import style from './PoseTiles.module.sass'

export interface PoseTilesProps {
	poses: PoseTileResult[]
}

export const PoseTiles: React.FunctionComponent<PoseTilesProps> = ({ poses }) => {
	return (
		<article className={style.wrapper}>
			{poses.map((pose) => (
				<article key={pose.id}>
					<PoseTile data={pose} />
				</article>
			))}
		</article>
	)
}
