import clsx from 'clsx'
import Image from 'next/image'
import type { ImageResult } from '../app/data/content/ImageFragment'
import style from './TileImage.module.sass'
import { UndefinedImageTile } from './UndefinedImageTile'

export type TileImageProps = {
	image: ImageResult | undefined
	borderRadius?: 'all' | 'top' | 'bottom'
	reversedHover?: boolean
	sizes: string
}

export const TileImage: React.FunctionComponent<TileImageProps> = ({
	image,
	borderRadius,
	reversedHover,
	sizes,
}) => {
	return (
		<div className={clsx(style.wrapper, style[`view_borderRadius_${borderRadius}`])}>
			<div className={clsx(style.main, reversedHover && style.view_reversed_hover)}>
				{image ? (
					<Image
						src={image.url}
						alt={image.alt ?? ''}
						layout="fill"
						objectFit="cover"
						sizes={sizes}
					/>
				) : (
					<UndefinedImageTile />
				)}
			</div>
		</div>
	)
}
