import type { FunctionComponent } from 'react'
import type { ImageResult } from '../app/data/content/ImageFragment'
import type { PoseTileResult } from '../app/data/content/PoseTileFragment'
import { webalize } from '../utils/webalize'
import { Chip } from './Chip'
import style from './GroupedPoseTiles.module.sass'
import { PoseTiles } from './PoseTiles'

export interface GroupedPoseTilesProps {
	label: {
		text: string
		icon?: ImageResult
	}
	poses: PoseTileResult[]
}

export const makePoseCategoryAnchor = (label: string) => webalize(label)

export const GroupedPoseTiles: FunctionComponent<GroupedPoseTilesProps> = ({ label, poses }) => {
	return (
		<section className={style.wrapper} id={makePoseCategoryAnchor(label.text)}>
			<Chip icon={label.icon}>{label.text}</Chip>
			<div className={style.list}>
				<PoseTiles poses={poses} />
			</div>
		</section>
	)
}
