import Image from 'next/image'
import type { ImageResult } from '../app/data/content/ImageFragment'
import { Container } from './Container'
import { EmailNewsletter, EmailNewsletterPreset } from './EmailNewsletter'
import { Icon } from './Icon'
import style from './SubscribeBannerNewsletter.module.sass'
export interface SubscribeBannerNewsletterProps {
	title?: string
	logo?: ImageResult
	shapeColorA: string
	shapeColorB: string
	shapeColorC: string
	formAction: string
	emailLabel?: string
	submitLabel?: string
}

export const SubscribeBannerNewsletter: React.FunctionComponent<SubscribeBannerNewsletterProps> = ({
	title,
	logo,
	shapeColorA,
	shapeColorB,
	shapeColorC,
	emailLabel,
	submitLabel,
	children,
	formAction,
}) => {
	return (
		<form method="post" target="_self" action={formAction} className={style.wrapper}>
			<div className={style.background}>
				<div
					className={style.green}
					style={{
						['--subscribeBannerNewsletter-color']: shapeColorB,
					}}>
					<Icon name="subscribeBoxB" />
				</div>
				<div
					className={style.purple}
					style={{
						['--subscribeBannerNewsletter-color']: shapeColorA,
					}}>
					<Icon name="subscribeBoxA" />
				</div>
				<div
					className={style.turquoise}
					style={{
						['--subscribeBannerNewsletter-color']: shapeColorC,
					}}>
					<Icon name="subscribeBoxC" />
				</div>
			</div>
			<Container>
				<div className={style.content}>
					<div className={style.heading}>
						<div
							className={style.logo}
							style={{
								'--subscribeBannerNewsletter-logo-width': logo?.width,
								'--subscribeBannerNewsletter-logo-height': logo?.height,
							}}>
							{logo && <Image src={logo.url} alt={logo.alt} layout="fill" />}
						</div>
						{title && <h2 className={style.title}>{title}</h2>}
					</div>
					<div className={style.image}>
						<Image
							src="/images/sb_meditation.png"
							alt=""
							width="337"
							height="420"
							objectFit="contain"
							unoptimized
						/>
					</div>
					<div className={style.subscribe}>
						<h3 className={style.text}>{children}</h3>
						<br />
						<div className={style.newsletter}>
							<EmailNewsletter
								preset={EmailNewsletterPreset.SmartEmailing}
								emailLabel={emailLabel}
								buttonLabel={submitLabel}
							/>
						</div>
					</div>
				</div>
			</Container>
		</form>
	)
}
