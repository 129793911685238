import type { ImageResult } from '../app/data/content/ImageFragment'
import type { LinkResult } from '../app/data/content/LinkFragment'
import { ContemberLink } from './ContemberLink'
import style from './HeroTile.module.sass'
import { PlayButton } from './PlayButton'
import { TileImage } from './TileImage'

interface HeroTileProps {
	image?: ImageResult
	title: string
	link: LinkResult
	video?: boolean
}

export const HeroTile: React.FunctionComponent<HeroTileProps> = ({ image, title, link, video }) => {
	return (
		<ContemberLink link={link}>
			<a className={style.wrapper} target={link.targetBlank ? '_blank' : undefined}>
				<div className={style.image}>
					<TileImage image={image} borderRadius="all" sizes="365px" />
					<h3 className={style.title}>{title}</h3>
					{video && <PlayButton size="big" position="center" responsive />}
				</div>
			</a>
		</ContemberLink>
	)
}
