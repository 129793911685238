import type { RootEditorNode } from '@contember/react-client'

const RICHTEXT_MARK = '{"formatVersion":1,'

export function isRichText(source: string) {
	if (source.indexOf(RICHTEXT_MARK) === 0) {
		try {
			JSON.parse(source)
			return true
		} catch (e) {}
	}
	return false
}

type RichTextNode =
	| {
			text: string
	  }
	| { children: RichTextNode[] }

function importTexts(children: RichTextNode[], save: (str: string) => void) {
	children.forEach((ch) => {
		if ('text' in ch) {
			save(ch.text)
		}
		if ('children' in ch) {
			importTexts(ch.children, save)
		}
	})
}

export function richTextToPlaintext(source: string) {
	if (isRichText(source)) {
		const parsed = JSON.parse(source) as RootEditorNode
		const parts: string[] = []

		importTexts(parsed.children, (str) => parts.push(str))

		return parts.join('')
	}
	return source
}
