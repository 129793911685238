/* eslint-disable react/jsx-no-target-blank */
import { RichTextRenderer } from '@contember/react-client'
import Image from 'next/image'
import { FunctionComponent, useMemo } from 'react'
import type { CommonPageProps } from '../pages/[[...page]]'
import { Container } from './Container'
import style from './Footer.module.sass'
import { Icon } from './Icon'
import { ListNavigation } from './ListNavigation'

export interface FooterProps {
	data: NonNullable<Pick<CommonPageProps, 'footer'>['footer']>
}

export const Footer: FunctionComponent<FooterProps> = ({
	data: { logo, navigation, attribution, copyright },
}) => {
	const copyrightWithYear = useMemo(
		() => (copyright ?? '').replace('{year}', new Date().getFullYear().toString()),
		[copyright]
	)

	return (
		<footer className={style.wrapper}>
			{logo && (
				<Container size="wide">
					<div
						className={style.logo}
						style={{
							'--footer-logo-width': logo.width,
							'--footer-logo-height': logo.height,
						}}>
						<Image src={logo.url} alt={logo.alt} layout="fill" />
					</div>
				</Container>
			)}
			<Container>
				<div className={style.navigation}>
					<ListNavigation navigation={navigation} />
				</div>
				<div className={style.attribution}>
					<p className={style.copyright}>{copyrightWithYear}</p>
					<div className={style.mangoweb}>
						{attribution && (
							<div>
								<RichTextRenderer source={attribution} />
							</div>
						)}
						<a href="https://www.mangoweb.cz/" target="_blank" className={style.mangowebLogo}>
							<Icon name="go" />
						</a>
					</div>
				</div>
			</Container>
		</footer>
	)
}
