import { FunctionComponent, useEffect, useMemo, useState } from 'react'
import YouTube from 'react-youtube'
import { useIsFirstRender } from '../utils/useIsFirstRender'
import style from './Video.module.sass'

export interface VideoProps {
	videoId: string
	aspectRatioMobile?: number
	aspectRatioDesktop?: number
}

export const Video: React.FunctionComponent<VideoProps> = ({
	videoId,
	aspectRatioMobile = 16 / 9,
	aspectRatioDesktop = aspectRatioMobile,
}) => {
	const isFirstRender = useIsFirstRender()

	return (
		<div
			className={style.wrapper}
			style={{
				'--video-aspect-ratio-mobile': `${aspectRatioMobile}`,
				'--video-aspect-ratio-desktop': `${aspectRatioDesktop}`,
			}}>
			{!isFirstRender && <VideoInner videoId={videoId} />}
		</div>
	)
}

const VideoInner: FunctionComponent<Pick<VideoProps, 'videoId'>> = ({ videoId }) => {
	const localStorageStartKey = useMemo(() => `video-start-${videoId}`, [videoId])
	const start = useMemo(
		() => Number(localStorage.getItem(localStorageStartKey)) || 0,
		[localStorageStartKey]
	)

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const [player, setPlayer] = useState<null | any /* YouTubePlayer */>(null)

	useEffect(() => {
		if (player) {
			let timer: number
			const loop = () => {
				timer = window.setTimeout(() => {
					const seconds = Math.floor(player.getCurrentTime())
					if (seconds > 10) {
						// Don't remember first ten seconds played
						localStorage.setItem(localStorageStartKey, `${seconds}`)
					}
					loop()
				}, 1000)
			}
			loop()

			return () => {
				window.clearTimeout(timer)
			}
		}
	}, [player, localStorageStartKey])

	return (
		<YouTube
			videoId={videoId}
			opts={{
				playerVars: {
					start,
				},
			}}
			className={style.main}
			onReady={(event) => {
				setPlayer(event.target)
			}}
		/>
	)
}
