import { useTranslate } from '../app/contexts/TranslationsContext'
import type { CommonPageProps } from '../pages/[[...page]]'
import { ArticleTiles } from './ArticleTiles'
import { Breadcrumbs } from './Breadcrumbs'
import { Container } from './Container'
import { PageListTitle } from './PageListTitle'
import { Spacer } from './Spacer'

export type ArticleListPageProps = Exclude<
	Pick<CommonPageProps['page'], 'articleList'>['articleList'],
	null
>

export const ArticleListPage: React.FunctionComponent<ArticleListPageProps> = ({
	title,
	articles,
}) => {
	const translate = useTranslate()

	return (
		<Container>
			<Breadcrumbs
				items={[
					{
						label: title ?? translate('articles'),
					},
				]}
			/>
			<Spacer initial={32} break1000={80} />
			<PageListTitle>{title}</PageListTitle>
			<Spacer initial={24} />
			<ArticleTiles articles={articles} />
			<Spacer initial={40} break1000={100} />
		</Container>
	)
}
