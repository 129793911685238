import type { FunctionComponent } from 'react'
import style from './Spacer.module.sass'

export interface SpacerProps {
	initial?: number
	break768?: number
	break1000?: number
}

const getBreakCustomProperties = (props: SpacerProps): React.CSSProperties => {
	const breaks = [
		{ name: 'initial', value: props.initial },
		{ name: '768', value: props.break768 },
		{ name: '1000', value: props.break1000 },
	]

	for (let i = 1; i < breaks.length; i++) {
		if (typeof breaks[i].value === 'undefined' && typeof breaks[i - 1].value !== 'undefined') {
			breaks[i].value = breaks[i - 1].value
		}
	}

	const styles: React.CSSProperties = {}
	breaks.forEach((item) => {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		;(styles as any)[`--spacer-break-${item.name}`] = item.value
	})

	return styles
}

export const Spacer: FunctionComponent<SpacerProps> = (props) => {
	return <div className={style.wrapper} style={getBreakCustomProperties(props)}></div>
}
