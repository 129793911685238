import clsx from 'clsx'
import type { FunctionComponent } from 'react'
import style from './GroupsSorts.module.sass'

export interface GroupSortsProps {
	activeId: string
	onChange: (newActiveId: string) => void
	items: Array<{
		id: string
		label: string
	}>
}

export const GroupsSorts: FunctionComponent<GroupSortsProps> = ({ activeId, onChange, items }) => {
	return (
		<div className={style.wrapper}>
			<ul className={style.list}>
				{items.map((item) => (
					<li key={item.id} className={style.item}>
						<button
							type="button"
							onClick={() => onChange(item.id)}
							className={clsx(style.button, item.id === activeId && style.is_active)}>
							{item.label}
						</button>
					</li>
				))}
			</ul>
		</div>
	)
}
