import type { FunctionComponent } from 'react'
import type { TileListResult } from '../app/data/content/TileListFragment'
import { HeroTile } from './HeroTile'
import style from './TileList.module.sass'

export interface TileListProps {
	tileList: TileListResult
}

export const TileList: FunctionComponent<TileListProps> = ({ tileList }) => {
	return (
		<div className={style.wrapper}>
			{tileList.items.map(
				(tile) =>
					tile.tile?.link && (
						<div key={tile.id}>
							<HeroTile
								image={tile.tile.image}
								title={tile.tile.link.title || ''}
								link={tile.tile.link}
								video={tile.tile.isVideo}
							/>
						</div>
					)
			)}
		</div>
	)
}
