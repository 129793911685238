import clsx from 'clsx'
import type { ArticleTileResult } from '../app/data/content/ArticleTileFragment'
import { ArticleTile } from './ArticleTile'
import style from './ArticleTiles.module.sass'

export interface ArticleTilesProps {
	hasMain?: boolean
	offsetColumn?: 'left' | 'right'
	articles: ArticleTileResult[]
}

export const ArticleTiles: React.FunctionComponent<ArticleTilesProps> = ({
	articles,
	hasMain,
	offsetColumn = 'left',
}) => {
	return (
		<article
			className={clsx(
				style.wrapper,
				hasMain && style.view_main,
				style[`view_offset_${offsetColumn}`]
			)}>
			{articles.map((article, index) => (
				<article className={style.item} key={article.id}>
					<ArticleTile isMain={hasMain === true && index === 0} data={article} />
				</article>
			))}
		</article>
	)
}
