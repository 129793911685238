import type { ListNavigationResult } from '../app/data/content/ListNavigationFragment'
import { isDefined } from '../utils/isDefined'
import { ContemberLink } from './ContemberLink'
import style from './ListNavigation.module.sass'

export interface ListNavigationProps {
	navigation: ListNavigationResult | undefined
}

export const ListNavigation: React.FunctionComponent<ListNavigationProps> = ({ navigation }) => {
	return (
		<ul className={style.columns}>
			{(navigation?.columns || []).map((column) => (
				<li key={column.id} className={style.column}>
					<h3 className={style.columnTitle}>{column.label}</h3>
					<ul className={style.columnItems}>
						{column.items
							.filter((item) => isDefined(item.link))
							.map((item) => (
								<li key={item.id} className={style.columnItem}>
									{/* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */}
									<ContemberLink link={item.link!}>
										<a
											className={style.columnItemLink}
											target={item.link?.targetBlank ? '_blank' : undefined}>
											{item.link?.title}
										</a>
									</ContemberLink>
								</li>
							))}
					</ul>
				</li>
			))}
		</ul>
	)
}
